import React from "react";
import { Slide, Typography } from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EmailSetting from "./AdmintrativeBody/EmailSetting";
import ImportSetting from "./AdmintrativeBody/ImportSetting";
import CaseSetting from "./AdmintrativeBody/CaseSetting";
import ProxyDelegateSetting from "./AdmintrativeBody/ProxyDelegateSetting";
import UserSetting from "./AdmintrativeBody/UserSetting";
import ReportSetting from "./AdmintrativeBody/ReportSetting";
import GroupSetting from "./AdmintrativeBody/GroupSetting";
import PasswordPolicy from "./AdmintrativeBody/PasswordPolicySetting";
import LicenseSetting from "./AdmintrativeBody/LicenseSetting";
import ConnectionSetting from "./AdmintrativeBody/ConnectionSetting";

export default function AdminConsoleRouting() {
  const divRef = React.useRef();
  const { t } = useTranslation();
  const [showAnimate, setShowAnimate] = React.useState(false);
  const handleMouseDown = () => {
    setShowAnimate(false);

    window.setTimeout(() => {
      setShowAnimate(true);
    }, 300);
  };

  React.useEffect(() => {
    setShowAnimate(true);
  }, []);

  return (
    <Routes>
      <Route path="email" element={<EmailSetting />} />
      <Route path="import" element={<ImportSetting />} />
      <Route path="proxy-delegation" element={<ProxyDelegateSetting />} />
      <Route path="report" element={<ReportSetting />} />
      <Route path="user" element={<UserSetting />} />
      <Route path="group" element={<GroupSetting />} />
      <Route path="case-security" element={<CaseSetting />} />
      <Route path="password-policy" element={<PasswordPolicy />} />
      <Route path="connection" element={<ConnectionSetting />} />
      <Route path="license" element={<LicenseSetting />} />
      <Route
        path=""
        element={
          <div ref={divRef} style={{ color: "#aaa", overflow: "hidden" }}>
            <div style={{ height: "80px" }}>
              <Slide
                direction="up"
                in={showAnimate}
                mountOnEnter
                unmountOnExit
                onMouseDown={handleMouseDown}
                style={{ visibility: showAnimate ? "visible" : "hidden" }}
                container={divRef.current}
              >
                <ArrowUpwardIcon
                  sx={{ fontSize: "72px", strokeWidth: 3, stroke: "#aaa" }}
                />
              </Slide>
            </div>
            <Typography sx={{ userSelect: "none" }}>
              {t("administrative_console.no_selection")}
            </Typography>
          </div>
        }
      />
      {/* Empty */}
      <Route path="*" element={<Navigate to="" replace />}></Route>
      {/* Incorrect page */}
    </Routes>
  );
}
