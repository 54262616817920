import React from "react";
import { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { Backdrop, Box, CssBaseline, Paper } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/system";
import "./App.css";

import urlJoin from "url-join";
import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from "@cometchat/chat-uikit-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { setLicenseKey } from "@grapecity/wijmo";
import PropTypes from "prop-types";

import Home from "./Home";
import { useAuthentication } from "./providers/AuthenticationProvider";
import { useProfile } from "./providers/ProfileProvider";
import { authServerBaseUrl } from "./Config";
import { CallApiWithContext, CallApiWithoutContext } from "./helpers/ApiHelper";
import SharedDialog from "./shared/SharedDialog";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Login from "./components/Login/Login";
import Logo from "./content/img/FlexGreen logo 1024x1024.png";
import { useThemeColor } from "./providers/ThemeColorProvider";
import { MessageType } from "./Constants";

function Loading(props) {
  const loading = props.loading;

  return (
    <Backdrop open={loading}>
      <Grid container display={"flex"} direction={"column"} pb={"200px"}>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Box
            component="img"
            sx={{
              height: "200px",
            }}
            src={Logo}
          />
        </Grid>
        <Grid item></Grid>
        <Grid item xs={12} mt={5}>
          <Container maxWidth={"xs"}>
            <LinearProgress />
          </Container>
        </Grid>
      </Grid>
    </Backdrop>
  );
}

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function App() {
  const navigation = useRef(useNavigate());
  const { t } = useTranslation();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useAuthentication();
  const [profile, setProfile] = useProfile();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [token, setToken] = useState("");
  const [login, setLogin] = React.useState("");
  const themeColorContext = useThemeColor();

  const COMETCHAT_CONSTANTS = {
    APP_ID: "266349de36e64abb",
    REGION: "us",
    AUTH_KEY: "81c3bb33d55207a2b1ae83581f3bfe765e0ab07b",
  };

  //light theme
  let themeLight = createTheme({
    palette: {
      primary: {
        light: "#3A6015",
        main: "#3A6015",
        dark: "#283d17",
      },
      mainContainer: {
        light: "#eaeff1",
      },
      secondaryContainer: {
        light: "white",
      },
      formMainContainer: {
        light: "white",
      },
      flowEdge: {
        light: "#000000",
      },
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        ml: 1100,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  themeLight = {
    ...themeLight,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#283d17",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            "&.MuiLoadingButton-colorPrimary": {
              backgroundColor: "#3a6015",
            },
          },
          contained: {
            boxShadow: "none",
            "&:active": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: themeLight.spacing(1),
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: themeLight.palette.common.white,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            margin: "0 16px",
            minWidth: 0,
            padding: 0,
            [themeLight.breakpoints.up("md")]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: themeLight.spacing(1),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: "rgb(255,255,255,0.15)",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            paddingLeft: "16px",
            "&.MuiListItemButton-gutters": {
              backgroundColor: "#3a6015",
              color: "rgba(255, 255, 255, 0.7)",
              "&.Mui-selected": {
                color: "#47c6ff",
              },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
              "&:focus": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: themeLight.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "inherit",
            minWidth: "auto",
            marginRight: themeLight.spacing(2),
            "& svg": {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            "&.MuiBreadcrumbs-separator": {
              color: "gray",
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "lightgray",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            color: "gray",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            minWidth: "250px",
          },
        },
      },
    },
  };

  //dark theme

  let themeDark = createTheme({
    palette: {
      mode: "dark",
      primary: {
        light: "#80d02f",
        main: "#599121",
        dark: "#3A6015",
      },
      mainContainer: {
        dark: "#121212",
      },
      secondaryContainer: {
        dark: "#1a1a1a",
      },
      formMainContainer: {
        dark: "rgba(255, 255, 255, 0.05)",
      },
      flowEdge: {
        dark: "#fff",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: themeLight.spacing(1),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor: "#1a1a1a",
            boxShadow: "none",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#1a1a1a",
            backgroundImage: "none",
          },
          root: {
            "&.MuiDrawer-docked": {
              backgroundColor: "#1a1a1a",
            },
            "&.MuiDrawer-root": {
              backgroundColor: "#1a1a1a",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "#1a1a1a",

            "&.MuiToolbar-gutters": {
              backgroundColor: "#1a1a1a",
              justifyContent: "space-between",
            },
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            margin: "0 16px",
            minWidth: 0,
            padding: 0,
            [themeLight.breakpoints.up("md")]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: themeLight.spacing(1),
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: themeLight.palette.common.white,
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            "&.MuiBreadcrumbs-separator": {
              color: "gray",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            paddingLeft: "16px",
            "&.MuiListItemButton-gutters": {
              backgroundColor: "#1a1a1a",
              color: "rgba(255, 255, 255, 0.7)",
            },
            "&.Mui-selected": {
              color: "#47c6ff",
            },
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
            "&:focus": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: themeLight.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "auto",
            marginRight: themeLight.spacing(2),
            "& svg": {
              fontSize: 20,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            "&.MuiLoadingButton-colorPrimary": {
              backgroundColor: "#3a6015",
            },
          },
          contained: {
            boxShadow: "none",
            "&:active": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRight: "none",
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: "#1a1a1a",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            minWidth: "250px",
          },
        },
      },
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
    breakpoints: {
      values: {
        ...themeLight.breakpoints.values,
      },
    },
  });

  let colorMode = () => {
    switch (themeColorContext[0]) {
      case "light":
        return themeLight;
      case "dark":
        return themeDark;
      default:
        return themeLight;
    }
  };

  const onSignOut = () => {
    const url = urlJoin(authServerBaseUrl, "/sign-out");
    CallApiWithContext(url, [isAuthenticated, setIsAuthenticated], null)
      .then((response) => {
        if (process.env.NODE_ENV === "development") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("i18nextLng");
        }

        setProfile(null);
        setIsAuthenticated(
          false,
          "sign_out_dialog.sign_out_succeeded",
          MessageType.Success
        );
      })
      .catch((error) => console.log(error));

    setIsOpenDialog(false);
    // setAccessToken(null);
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');
  };

  const openDialog = () => {
    setIsOpenDialog(true);
  };

  const onDialogClose = () => {
    setIsOpenDialog(false);
  };

  const initCometChat = () => {
    const uiKitSettings = new UIKitSettingsBuilder()
      .setAppId(COMETCHAT_CONSTANTS.APP_ID)
      .setRegion(COMETCHAT_CONSTANTS.REGION)
      .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
      .subscribePresenceForAllUsers()
      .build();

    CometChatUIKit.init(uiKitSettings)
      .then(() => {
        console.log("init cometchat completed successfully.");
      })
      .catch(console.log);
  };

  function CheckResetPasswordToken() {
    if (location.search !== "") {
      setToken(location.search.split("=")[1]);
    }
  }

  function ValidateToken() {
    //Call api to check if key is valid
    const url = urlJoin(authServerBaseUrl, "/checkResetPasswordKey");
    const data = {
      Token: location.search.split("=")[1],
    };
    CallApiWithoutContext(url, data)
      .then((response) => {
        setLogin(response.login);
        setIsChangePassword(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    CheckResetPasswordToken();
    initCometChat();

    if (location.search != "") {
      ValidateToken();
    }
  }, []);

  useEffect(() => {
    // if (accessToken) {
    //   setCanLogin(true)
    // }
    // else {
    //   setCanLogin(false)
    //   navigation.current("/");
    // }
    if (isAuthenticated === false) {
      CometChatUIKit.getLoggedinUser()
        .then((user) => {
          if (user) {
            CometChatUIKit.logout()
              .then(() => console.log("logout success"))
              .catch(console.log);
          }
        })
        .finally(() => {
          navigation.current("/");
        });
    } else {
      const uid = profile?.uuid;

      if (uid) {
        CometChatUIKit.getLoggedinUser().then((user) => {
          if (!user) {
            CometChatUIKit.login(uid)
              .then((user) => {
                console.log("Login successful:", { user });
              })
              .catch((error) => {
                if (error.code === "ERR_UID_NOT_FOUND") {
                  const user = new CometChat.User(uid);

                  user.setName(profile.name);

                  if (profile.profileImage) {
                    user.setAvatar(
                      `http://lite.flexworkflow.com:8080/api/authServer/User/GetAvatar/${uid}`
                    );
                  }

                  CometChat.createUser(user, COMETCHAT_CONSTANTS.AUTH_KEY)
                    .then((user) => {
                      CometChatUIKit.login(uid);
                    })
                    .catch(console.log);
                }
              });
          } else if (user.getUid() !== uid) {
            CometChatUIKit.logout()
              .then(() => {
                CometChatUIKit.login(uid)
                  .then((user) => {
                    console.log("Login successful:", { user });
                  })
                  .catch(console.log);
              })
              .catch(console.log);
          } else {
            const user = new CometChat.User(uid);

            user.setName(profile.name);

            if (profile.profileImage) {
              user.setAvatar(
                `http://lite.flexworkflow.com:8080/api/authServer/User/GetAvatar/${uid}`
              );
            } else {
              user.setAvatar("");
            }

            CometChat.updateCurrentUserDetails(user)
              .then((user) => console.log("user updated"))
              .catch(console.log);
          }
        });
      }
    }

    // const url = new URL("/validate-token", authServerBaseUrl);
    // CallApiWithToken(url, accessToken)
    //   .then(response => {
    //     setCanLogin(response.isValid);

    //     if (!response.isValid) {
    //       navigation.current("/");
    //     }
    //   })
    //   .catch(error => {
    //     setCanLogin(false);
    //     console.log(error)
    //     navigation.current("/");
    //   })
  }, [isAuthenticated, profile]);

  // if (canLogin === null) {
  //   return null;
  // }

  if (isAuthenticated === null) {
    return <Loading loading={true} />;
  }

  setLicenseKey(
    "lite.flexworkflow.com,689725217452641#B0ijPyNHZisnOiwmbBJye0ICRiwiI34zZBhjTj3iSIB7LnNlQ5wWSIN4MFdTY0Z5K4IHOIxkeKdzc9lDV6EzYzIHOHpXZ6g7dMB5ahZHahdVWy2meXlla5FVdTJFVnxWRshGT7cmV9U7RPRDZshjakx6dtFXTmZlTNVGa03iW746UEJ5RrZENvtUN8QmevgUdL9WVmZlaVNDc4k4cG56UMJzYuNlcZpGZpR6aG9kZq5WcwJ4csJ4Z7MUQhNHNVV5UzIUYk3iVPhzMqlDdnV6TUVlbFFneUlDSFxERstCdkxUMGZET8AVerdjaqF7TXhjbQd7byMna5hzc7NWQJZlRilmWMlHVW5GZDFTMjlna6QDWjVVMuV6bz2EclxmYsl6YQtUTrMUQwhkRxhUaiJjRLl6axZ5U4dmTmBzMQhDW7sUOCZXcYdWURpXcLRzaOdGZTBVOqdFUq3UZNVlVURHWzcFV7JjS6ljWQV7d4JkI0IyUiwiIzQ4M7Y4N9MjI0ICSiwCN8ETM5IjM6MTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIwATMxQDMgYDMzADNyAjMiojI4J7QiwiIt36Yuc7bsZ6ay36d8VGbm9SZ4lGbiojIz5GRiwiI73GbmtmcvdFelxmRiojIh94QiwiIxQjNyUDN7EjM5IzN9gjNiojIklkIs4XXbpjInxmZiwiIyY7MyAjMiojIyVmdiwSZzyWXm"
  );

  return (
    <ThemeProvider theme={colorMode()}>
      <CssBaseline />
      <Box className="App">
        {isChangePassword ? (
          <ResetPassword token={token} login={login} />
        ) : isAuthenticated ? (
          <Home onSignOut={openDialog}></Home>
        ) : (
          <Login></Login>
        )}

        <SharedDialog
          isOpen={isOpenDialog}
          onClose={onDialogClose}
          title={t("sign_out_dialog.sign_out")}
          content={t("sign_out_dialog.sign_out_confirmation")}
          buttons={[
            {
              text: t("sign_out_dialog.yes"),
              action: onSignOut,
              color: "primary",
            },
            {
              text: t("sign_out_dialog.no"),
              action: onDialogClose,
              variant: "outlined",
              color: "error",
            },
          ]}
        ></SharedDialog>
        {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      </Box>
    </ThemeProvider>
  );
}

export default App;
