import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/system";
import { useTheme } from "@emotion/react";

import DoneIcon from "@mui/icons-material/Done";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloseIcon from "@mui/icons-material/Close";

import { ProgressBar, Step } from "react-step-progress-bar";
import { useComponentSize } from "react-use-size";

import "react-step-progress-bar/styles.css";
import "./FormRuntime.css";
import { Skeleton } from "@mui/material";

const StageProgressBar = (props) => {
  const caseInfo = props.caseInfo;
  const stages =
    caseInfo?.state == "4"
      ? props.stages.filter(
          (s) => s[0].discretionary === false && s[0].currentState == "4"
        )
      : props.stages.filter((s) => s[0].discretionary === false);
  const { currentStage, isLoading = false } = props;
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMlUp = useMediaQuery(theme.breakpoints.up("ml"));
  const [progress, setProgress] = React.useState(0);
  const progressBarLength =
    window.innerWidth -
    document.querySelector(".bar")?.offsetWidth / stages.length;
  const progressBarSkeletonLength =
    window.innerWidth - document.querySelector(".bar")?.offsetWidth / 5;

  const {
    ref: progressBarRef,
    height: progressBarHeight,
    width: progressBarWidth,
  } = useComponentSize();

  React.useEffect(() => {
    var totalStageNum = stages.length - 1;
    var nonPendingStage = 0;
    var currentStageOrdinal = -1;

    stages.forEach((element) => {
      if (currentStage && currentStage === element[0].id) {
        setProgress((element[0].ordinal / totalStageNum) * 100);
      } else if (!currentStage && element[0].currentState == 4) {
        setProgress((element[0].ordinal / totalStageNum) * 100);
      }
    });
  }, [stages]);

  //handle progress bar dragging
  const slider = document.querySelector(".bar");
  let startX, scrollLeft;
  let mouseDown = false;
  const handleProgressbarDraggingMouseDown = (e) => {
    document.addEventListener("mousedown", startDragging, true);
    document.addEventListener("mousemove", move, true);
    document.addEventListener("mouseup", stopDragging, true);
  };

  const startDragging = (e) => {
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const stopDragging = (e) => {
    mouseDown = false;
  };

  const move = (e) => {
    e.preventDefault();
    if (!mouseDown) {
      return;
    }

    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  };

  return (
    <div
      onMouseDown={(e) => handleProgressbarDraggingMouseDown(e)}
      className="bar"
      ref={progressBarRef}
      style={{
        overflowX: isLoading || stages.length <= 5 ? "hidden" : "auto",
        overflowY: "hidden",
        scrollbarHeight: "5px",
      }}
    >
      <div
        style={{
          margin: !isLoading
            ? isSmUp
              ? `24px ${
                  document.querySelector(".RSPBprogressBar")?.offsetWidth /
                  (stages.length - 1) /
                  2
                }px 40px`
              : `1.5rem ${
                  document.querySelector(".RSPBprogressBar")?.offsetWidth /
                  (stages.length - 1) /
                  2
                }px 3.5rem`
            : "24px 10% 40px",
          display: "inline-block",
        }}
      >
        <ProgressBar
          percent={Math.ceil(progress)}
          filledBackground="linear-gradient(to right, #E0F0F2, #14a6a6)"
          height="5px"
          width={
            isLoading
              ? isMlUp
                ? `${progressBarSkeletonLength - 256}px`
                : `${progressBarSkeletonLength - 48}px`
              : stages.length <= 1
              ? "0px"
              : stages.length <= 5
              ? isMlUp
                ? `${progressBarLength - 256}px`
                : `${progressBarLength - 50}px`
              : `${
                  (stages.length *
                    document.querySelector(".bar")?.offsetWidth) /
                  3
                }px`
          }
        >
          {isLoading
            ? Array.from(new Array(3)).map((stage, index) => (
                <Step key={`stage_${index}`} transition="scale">
                  {() => (
                    <div>
                      <div
                        className="number-circle--wrapper"
                        style={{ filter: "grayscale(100%)" }}
                      >
                        <div className="number-circle" />
                      </div>
                      <div style={{ height: "24px" }}>
                        <Typography component="h1" variant="body2">
                          <Skeleton sx={{ width: "60px" }} />
                        </Typography>
                      </div>
                    </div>
                  )}
                </Step>
              ))
            : stages.map((stage, index) => (
                <Step key={`stage_${index}`} transition="scale">
                  {({ accomplished }) => (
                    <div>
                      {accomplished ? (
                        <div
                          className={
                            isSmUp
                              ? "number-circle--wrapper"
                              : "number-circle--wrapper-mobile"
                          }
                          style={{ filter: `grayscale(${0}%)` }}
                        >
                          <div
                            className={
                              isSmUp ? "number-circle" : "number-circle-mobile"
                            }
                          >
                            {stage[0].currentState == 4 ? (
                              <DoneIcon sx={{ height: 15, width: 15 }} />
                            ) : (
                              <HourglassBottomIcon
                                sx={{ height: 15, width: 15 }}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            isSmUp
                              ? "number-circle--wrapper"
                              : "number-circle--wrapper-mobile"
                          }
                          style={{ filter: `grayscale(${100}%)` }}
                        >
                          <div
                            className={
                              isSmUp ? "number-circle" : "number-circle-mobile"
                            }
                          >
                            {stage[0].currentState == 10 ? (
                              <CloseIcon
                                sx={{ height: 15, width: 15 }}
                              ></CloseIcon>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <Typography
                        component="h1"
                        variant="body2"
                        maxHeight="45px"
                        lineHeight={isSmUp ? null : "1.1"}
                      >
                        <Tooltip
                          title={stage[0].name}
                          placement="top"
                          enterTouchDelay={300}
                        >
                          <span
                            className={
                              isSmUp ? "stageName" : "stageName-mobile"
                            }
                            style={{
                              display: "inline-block",
                              maxWidth:
                                stages.length <= 1
                                  ? null
                                  : document.querySelector(".RSPBprogressBar")
                                      .offsetWidth /
                                    (stages.length - 1),
                              minWidth:
                                stages.length <= 1
                                  ? "200px"
                                  : document.querySelector(".RSPBprogressBar")
                                      .offsetWidth /
                                    (stages.length - 1),
                            }}
                          >
                            {stage[0].name}
                          </span>
                        </Tooltip>
                      </Typography>
                    </div>
                  )}
                </Step>
              ))}
        </ProgressBar>
      </div>
    </div>
  );
};

export default StageProgressBar;
