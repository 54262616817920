import * as React from "react";
import { Box } from "@mui/system";
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import urlJoin from "url-join";
import ApiIcon from "@mui/icons-material/Api";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useTranslation } from "react-i18next";

import { resServerBaseUrl } from "../../../../Config";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import SharedDialog from "../../../../shared/SharedDialog";
import { LoadingButton } from "@mui/lab";

import OracleIcon from "./icon/oracle_icon.svg";
import MsSqlIcon from "./icon/microsoft_icon.svg";
import MySqlIcon from "./icon/mysql_icon.svg";

//import DatabaseMySqlIcon from "./icon/Database-MySQL.svg";
import DatabaseMsSqlIcon from "./icon/Database-SQL.svg";

const ConnectionType = {
  MsSql: 1,
  MySql: 2,
  Isam: 3,
  Oracle: 4,
  Restful: 5,
};
const AuthenticationType = {
  NoAuth: 0,
  BasicAuth: 1,
  BearerToken: 2,
  OAuth20: 3,
};
const GrantType = {
  PasswordCredentials: 1,
  ClientCredentials: 2,
  AuthorizationCode: 3,
};
const EncryptionType = {
  Optional: 0,
  Mandatory: 1,
  Strict: 2,
};

const ConnectionSetting = () => {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const fakePassword = "xxxxxxxx";

  const [currentConnection, setCurrentConnection] = React.useState(null);
  const [connections, setConnections] = React.useState([]);
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [dialogInfo, setDialogInfo] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [testingConnection, setTestingConnection] = React.useState(false);

  const handleConnectionNameChange = (e, value, reason) => {
    if (value) {
      setCurrentConnection({
        authenticationType: AuthenticationType.BasicAuth,
        encryption:
          value.type === ConnectionType.MsSql
            ? EncryptionType.Optional
            : undefined,
        ...value,
        password: fakePassword,
      });
    }
  };
  const handleConnectionNameInputChange = (e, value, reason) => {
    if (reason === "clear") {
      setCurrentConnection(null);
    }
  };
  const handleValueChange = (e, value, reason, field) => {
    setCurrentConnection((prev) => ({
      ...prev,
      trustServerCert:
        field === "encryption" && value === EncryptionType.Strict
          ? false
          : prev.trustServerCert,
      encryption: EncryptionType.Optional,
      [field]: value,
      passwordChanged: field === "password" ? true : prev.passwordChanged,
    }));
  };
  const handleEditButtonClick = () => {
    setEnableEdit(true);
    setErrorMessage(null);

    document.querySelector("#new_connection_name").focus();
  };
  const handleSaveButtonClick = () => {
    if (validate()) {
      const url = urlJoin(
        resServerBaseUrl,
        currentConnection.sid
          ? "/DataSource/UpdateConnection"
          : "/DataSource/AddConnection"
      );

      setEnableEdit(false);

      CallApiWithContext(
        url,
        authenticationContext,
        JSON.stringify(currentConnection)
      )
        .then((res) => {
          if (res.succeeded) {
            loadConnections((connections) => {
              const conn = connections.find(
                (c) => c.name === currentConnection.name
              );

              if (conn) {
                setCurrentConnection({
                  authenticationType: AuthenticationType.BasicAuth,
                  ...conn,
                  password: fakePassword,
                });
              } else {
                setCurrentConnection(null);
              }
            });
            setDialogInfo({
              show: true,
              title: "Save Connection",
              content: "Save connection succeeded.",
              buttons: [
                {
                  text: "OK",
                  action: () =>
                    setDialogInfo((prev) => ({ ...prev, show: false })),
                  color: "primary",
                },
              ],
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const handleAddButtonClick = () => {
    setEnableEdit(true);
    setCurrentConnection({
      enabled: true,
      authenticationType: AuthenticationType.NoAuth,
      encryption: EncryptionType.Optional,
    });
    document.querySelector("#new_connection_name").focus();
  };
  const handleDeleteButtonClick = () => {
    setDialogInfo({
      show: true,
      title: t("administrative_console.connection_page.delete_conn_title"),
      content: t("administrative_console.connection_page.delete_conn_content", {
        name: currentConnection.name,
      }),
      buttons: [
        { text: t("common.yes"), action: confirmDelete, color: "primary" },
        {
          text: t("common.no"),
          action: cancelDelete,
          variant: "outlined",
          color: "error",
        },
      ],
    });
  };
  const handleCancelButtonClick = () => {
    if (currentConnection.sid) {
      const conn = connections.find((c) => c.sid === currentConnection.sid);

      if (conn) {
        setCurrentConnection({
          authenticationType: AuthenticationType.BasicAuth,
          ...conn,
          password: fakePassword,
        });
      } else {
        setCurrentConnection(null);
      }
    } else {
      setCurrentConnection(null);
    }

    setEnableEdit(false);
    setErrorMessage(null);
  };
  const handleTestButtonClick = () => {
    const url = urlJoin(resServerBaseUrl, "/DataSource/TestConnection");

    setTestingConnection(true);

    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify(currentConnection)
    )
      .then((res) => {
        setDialogInfo({
          show: true,
          title: t("administrative_console.connection_page.test_connection"),
          content: res.succeeded
            ? t(
                "administrative_console.connection_page.test_connection_succeed"
              )
            : t(
                "administrative_console.connection_page.test_connection_failed",
                {
                  errorMessage: res.errorMessage,
                }
              ),
          buttons: [
            {
              text: t("common.ok"),
              action: () => setDialogInfo((prev) => ({ ...prev, show: false })),
              color: "primary",
            },
          ],
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setTestingConnection(false));
  };

  const showServerAndDbName = () => {
    return (
      currentConnection?.type === ConnectionType.MySql ||
      currentConnection?.type === ConnectionType.MsSql
    );
  };
  const showToken = () => {
    return (
      currentConnection?.authenticationType === AuthenticationType.BearerToken
    );
  };
  const showOAuth = () => {
    return currentConnection?.authenticationType === AuthenticationType.OAuth20;
  };
  const supportTokenAuthType = () => {
    return currentConnection?.type === ConnectionType.Restful;
  };
  const validate = () => {
    let result = true;

    setErrorMessage(null);

    if (!currentConnection.name) {
      setErrorMessage((prev) => ({ ...prev, name: "Cannot be empty" }));
      result = false;
    } else if (
      connections.some(
        (c) =>
          c.sid !== currentConnection.sid && c.name === currentConnection.name
      )
    ) {
      setErrorMessage((prev) => ({ ...prev, name: "Duplicated name" }));
      result = false;
    }

    if (currentConnection.authenticationType === AuthenticationType.BasicAuth) {
      if (!currentConnection.userName) {
        setErrorMessage((prev) => ({ ...prev, userName: "Cannot be empty" }));
        result = false;
      }
      if (
        !currentConnection.password &&
        (currentConnection.passwordChanged || !currentConnection.sid)
      ) {
        setErrorMessage((prev) => ({ ...prev, password: "Cannot be empty" }));
        result = false;
      }
    }

    return result;
  };

  const confirmDelete = () => {
    const url = urlJoin(resServerBaseUrl, "/DataSource/DeleteConnection");
    const index = connections.findIndex((e) => e.sid === currentConnection.sid);

    setDialogInfo((prev) => ({ ...prev, show: false }));

    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify(currentConnection)
    )
      .then((res) => {
        if (res.succeeded) {
          loadConnections((connections) => {
            if (connections.length > index) {
              setCurrentConnection({ ...connections[index] });
            } else if (connections.length > 0) {
              setCurrentConnection({ ...connections[connections.length - 1] });
            } else {
              setCurrentConnection(null);
            }
          });
          setDialogInfo({
            show: true,
            title: t(
              "administrative_console.connection_page.delete_conn_title"
            ),
            content: t(
              "administrative_console.connection_page.delete_conn_success",
              { name: currentConnection.name }
            ),
            buttons: [
              {
                text: t("common.ok"),
                action: () =>
                  setDialogInfo((prev) => ({ ...prev, show: false })),
                color: "primary",
              },
            ],
          });
        }
      })
      .catch((error) => console.log(error));
  };
  const cancelDelete = () => {
    setDialogInfo((prev) => ({ ...prev, show: false }));
  };

  const loadConnections = (callback) => {
    const url = urlJoin(resServerBaseUrl, "/DataSource/GetConnections");

    CallApiWithContext(url, authenticationContext)
      .then((res) => {
        if (res.result) {
          setConnections(res.result);
        }

        return res.result;
      })
      .then((connections) => {
        if (callback) {
          callback(connections);
        }
      })
      .catch((error) => console.log(error));
  };
  const getConnectionIcon = (option) => {
    switch (option.type) {
      case ConnectionType.MsSql:
        return (
          <img
            src={DatabaseMsSqlIcon}
            style={{ width: "48px", height: "28px" }}
          />
        );
      case ConnectionType.MySql:
        return (
          <img src={MySqlIcon} style={{ maxWidth: "32px", height: "28px" }} />
        );
      case ConnectionType.Oracle:
        return <img src={OracleIcon} style={{ width: "48px" }} />;
      case ConnectionType.Restful:
        return <ApiIcon sx={{ height: "32px" }} />;
      default:
        return <QuestionMarkIcon sx={{ height: "32px" }} />;
    }

    return null;
  };

  React.useEffect(() => {
    loadConnections();
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Toolbar disableGutters sx={{ justifyContent: "flex-end" }}>
        <IconButton disabled={enableEdit} onClick={handleAddButtonClick}>
          <Tooltip title={t("common.add")}>
            <AddIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          disabled={!currentConnection?.sid || enableEdit}
          onClick={handleEditButtonClick}
        >
          <Tooltip title={t("common.edit")}>
            <EditIcon
              color={
                !currentConnection?.id || enableEdit ? "#DEDEDE" : "primary"
              }
            />
          </Tooltip>
        </IconButton>
        <div style={{ flexGrow: 1 }} />
        <IconButton
          disabled={!currentConnection?.sid}
          onClick={handleDeleteButtonClick}
        >
          <Tooltip title={t("common.delete")}>
            <DeleteIcon color={!currentConnection?.sid ? "#DEDEDE" : "error"} />
          </Tooltip>
        </IconButton>
      </Toolbar>
      <Grid container gap={2}>
        {/* connection name */}
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            options={connections}
            fullWidth
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;

              return (
                <Box key={key} component={"li"} {...optionProps}>
                  <span
                    style={{
                      display: "inline-flex",
                      width: "48px",
                      height: "32px",
                      alignItmes: "center",
                      justifyContent: "center",
                      marginRight: "8px",
                    }}
                  >
                    {getConnectionIcon(option)}
                  </span>
                  {option.name}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "administrative_console.connection_page.connection_name"
                )}
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: "new-password",
                  },
                }}
              />
            )}
            sx={{ display: enableEdit ? "none" : "block" }}
            value={currentConnection}
            onChange={handleConnectionNameChange}
            onInputChange={handleConnectionNameInputChange}
          />
          <TextField
            id="new_connection_name"
            fullWidth
            required
            label={t("administrative_console.connection_page.connection_name")}
            disabled={!enableEdit}
            sx={{ display: enableEdit ? "block" : "none" }}
            value={currentConnection?.name ?? ""}
            onChange={(e) =>
              handleValueChange(e, e.target.value, "change", "name")
            }
            helperText={errorMessage?.name}
            error={errorMessage?.name}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          gap={2}
          display={currentConnection ? "flex" : "none"}
        >
          {/* enabled */}
          <Grid item xs={12} textAlign={"left"}>
            <FormControl disabled={!enableEdit}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentConnection?.enabled ?? false}
                    onChange={(e) =>
                      handleValueChange(
                        e,
                        e.target.checked,
                        "change",
                        "enabled"
                      )
                    }
                  />
                }
                label={t(
                  "administrative_console.connection_page.connection_enable"
                )}
              />
            </FormControl>
          </Grid>
          {/* connection type */}
          <Grid item xs>
            <FormControl fullWidth disabled={!enableEdit}>
              <InputLabel>
                {t("administrative_console.connection_page.connection_type")}
              </InputLabel>
              <Select
                fullWidth
                defaultValue=""
                label={t(
                  "administrative_console.connection_page.connection_type"
                )}
                value={currentConnection?.type ?? ""}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "type")
                }
                sx={{ textAlign: "left" }}
              >
                <MenuItem value={ConnectionType.MySql}>
                  {t(
                    "administrative_console.connection_page.connection_type_my_sql"
                  )}
                </MenuItem>
                <MenuItem value={ConnectionType.MsSql}>
                  {t(
                    "administrative_console.connection_page.connection_type_ms_sql"
                  )}
                </MenuItem>
                <MenuItem value={ConnectionType.Isam}>
                  {t(
                    "administrative_console.connection_page.connection_type_isam"
                  )}
                </MenuItem>
                <MenuItem value={ConnectionType.Oracle}>
                  {t(
                    "administrative_console.connection_page.connection_type_oracle"
                  )}
                </MenuItem>
                <MenuItem value={ConnectionType.Restful}>
                  {t(
                    "administrative_console.connection_page.connection_type_restful_api"
                  )}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* authentication type */}
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!enableEdit}>
              <InputLabel>
                {t(
                  "administrative_console.connection_page.authentication_type"
                )}
              </InputLabel>
              <Select
                fullWidth
                defaultValue=""
                label={t(
                  "administrative_console.connection_page.authentication_type"
                )}
                value={currentConnection?.authenticationType ?? ""}
                onChange={(e) =>
                  handleValueChange(
                    e,
                    e.target.value,
                    "change",
                    "authenticationType"
                  )
                }
                sx={{ textAlign: "left" }}
              >
                <MenuItem value={0}>
                  {t(
                    "administrative_console.connection_page.authentication_type_no_auth"
                  )}
                </MenuItem>
                <MenuItem value={1}>
                  {t(
                    "administrative_console.connection_page.authentication_type_basic_auth"
                  )}
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: supportTokenAuthType() ? "block" : "none" }}
                >
                  {t(
                    "administrative_console.connection_page.authentication_type_bearer_token"
                  )}
                </MenuItem>
                <MenuItem
                  value={3}
                  sx={{ display: supportTokenAuthType() ? "block" : "none" }}
                >
                  {t(
                    "administrative_console.connection_page.authentication_type_oauth20"
                  )}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* user name & password */}
          <Grid
            container
            item
            xs={12}
            gap={2}
            sx={{
              display:
                currentConnection?.authenticationType ===
                AuthenticationType.BasicAuth
                  ? "flex"
                  : "none",
            }}
          >
            <Grid item xs>
              <TextField
                label={t("administrative_console.connection_page.user_name")}
                fullWidth
                disabled={!enableEdit}
                value={currentConnection?.userName ?? ""}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "userName")
                }
                helperText={errorMessage?.userName}
                error={errorMessage?.userName}
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label={t("administrative_console.connection_page.password")}
                type="password"
                disabled={!enableEdit}
                value={currentConnection?.password ?? ""}
                onFocus={() => {
                  if (currentConnection?.password === fakePassword)
                    setCurrentConnection((prev) => ({ ...prev, password: "" }));
                }}
                onBlur={(e) => {
                  if (
                    currentConnection?.password === "" &&
                    !currentConnection?.passwordChanged
                  )
                    setCurrentConnection((prev) => ({
                      ...prev,
                      password: fakePassword,
                    }));
                }}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "password")
                }
                helperText={errorMessage?.password}
                error={errorMessage?.password}
              />
            </Grid>
          </Grid>
          {/* server name & database name */}
          <Grid
            container
            item
            xs={12}
            gap={2}
            style={{ display: showServerAndDbName() ? "flex" : "none" }}
          >
            <Grid item xs>
              <TextField
                label={t("administrative_console.connection_page.server_name")}
                fullWidth
                disabled={!enableEdit}
                value={currentConnection?.serverName ?? ""}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "serverName")
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label={t(
                  "administrative_console.connection_page.default_database"
                )}
                disabled={!enableEdit}
                value={currentConnection?.database ?? ""}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "database")
                }
              />
            </Grid>
          </Grid>
          {/* encryption */}
          <Grid
            container
            item
            xs={12}
            gap={2}
            alignItems={"center"}
            display={
              currentConnection?.type === ConnectionType.MsSql ? "flex" : "none"
            }
          >
            <Grid item xs={12}>
              <FormControl fullWidth disabled={!enableEdit}>
                <InputLabel>
                  {t("administrative_console.connection_page.encryption")}
                </InputLabel>
                <Select
                  fullWidth
                  defaultValue=""
                  label="Encryption"
                  value={currentConnection?.encryption ?? ""}
                  onChange={(e) =>
                    handleValueChange(e, e.target.value, "change", "encryption")
                  }
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value={EncryptionType.Optional}>
                    {t(
                      "administrative_console.connection_page.encryption_optional"
                    )}
                  </MenuItem>
                  <MenuItem value={EncryptionType.Mandatory}>
                    {t(
                      "administrative_console.connection_page.encryption_mandatory"
                    )}
                  </MenuItem>
                  <MenuItem value={EncryptionType.Strict}>
                    {t(
                      "administrative_console.connection_page.encryption_strict"
                    )}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={"auto"} textAlign={"left"}>
              <FormControl
                disabled={
                  !enableEdit ||
                  currentConnection?.encryption === EncryptionType.Strict
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        currentConnection?.encryption === EncryptionType.Strict
                          ? false
                          : currentConnection?.trustServerCert ?? false
                      }
                      onChange={(e) =>
                        handleValueChange(
                          e,
                          e.target.checked,
                          "change",
                          "trustServerCert"
                        )
                      }
                    />
                  }
                  label={t(
                    "administrative_console.connection_page.trust_server_cert"
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                required={
                  currentConnection?.encryption === EncryptionType.Strict
                }
                label={t(
                  "administrative_console.connection_page.hostname_in_cert"
                )}
                disabled={!enableEdit || currentConnection?.trustServerCert}
                value={currentConnection?.certHostName ?? ""}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "certHostName")
                }
              />
            </Grid>
          </Grid>
          {/* token */}
          <Grid
            item
            xs={12}
            style={{ display: showToken() ? "block" : "none" }}
          >
            <TextField
              label="Token"
              fullWidth
              disabled={!enableEdit}
              value={currentConnection?.token ?? ""}
              onChange={(e) =>
                handleValueChange(e, e.target.value, "change", "token")
              }
            />
          </Grid>
          {/* oauth 2.0 */}
          <Grid
            container
            item
            xs={12}
            gap={2}
            style={{ display: showOAuth() ? "flex" : "none" }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth disabled={!enableEdit}>
                <InputLabel>Grant type</InputLabel>
                <Select
                  fullWidth
                  defaultValue=""
                  label="Grant type"
                  value={currentConnection?.grantType ?? ""}
                  onChange={(e) =>
                    handleValueChange(e, e.target.value, "change", "grantType")
                  }
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value={GrantType.PasswordCredentials}>
                    Password Credentials
                  </MenuItem>
                  <MenuItem value={GrantType.ClientCredentials}>
                    Client Credentials
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Access Token Url"
                fullWidth
                disabled={!enableEdit}
                value={currentConnection?.accessTokenUrl ?? ""}
                onChange={(e) =>
                  handleValueChange(
                    e,
                    e.target.value,
                    "change",
                    "accessTokenUrl"
                  )
                }
              />
            </Grid>
            {/* client id & client secret */}
            <Grid container item xs={12} gap={2}>
              <Grid item xs>
                <TextField
                  label="Client ID"
                  fullWidth
                  disabled={!enableEdit}
                  value={currentConnection?.clientId ?? ""}
                  onChange={(e) =>
                    handleValueChange(e, e.target.value, "change", "clientId")
                  }
                />
              </Grid>
              <Grid item xs>
                <TextField
                  label="Client Secret"
                  fullWidth
                  disabled={!enableEdit}
                  value={currentConnection?.clientSecret ?? ""}
                  onChange={(e) =>
                    handleValueChange(
                      e,
                      e.target.value,
                      "change",
                      "clientSecret"
                    )
                  }
                />
              </Grid>
            </Grid>
            {/* username & password */}
            <Grid
              container
              item
              xs={12}
              gap={2}
              sx={{
                display:
                  currentConnection?.grantType === GrantType.PasswordCredentials
                    ? "flex"
                    : "none",
              }}
            >
              <Grid item xs>
                <TextField
                  label="User Name"
                  fullWidth
                  disabled={!enableEdit}
                  value={currentConnection?.userName ?? ""}
                  onChange={(e) =>
                    handleValueChange(e, e.target.value, "change", "userName")
                  }
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  disabled={!enableEdit}
                  value={currentConnection?.password ?? ""}
                  onFocus={() => {
                    if (currentConnection?.password === fakePassword)
                      setCurrentConnection((prev) => ({
                        ...prev,
                        password: "",
                      }));
                  }}
                  onBlur={(e) => {
                    if (currentConnection?.password === "")
                      setCurrentConnection((prev) => ({
                        ...prev,
                        password: fakePassword,
                      }));
                  }}
                  onChange={(e) =>
                    handleValueChange(e, e.target.value, "change", "password")
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Scope"
                fullWidth
                disabled={!enableEdit}
                value={currentConnection?.scope ?? ""}
                onChange={(e) =>
                  handleValueChange(e, e.target.value, "change", "scope")
                }
              />
            </Grid>
          </Grid>
          {/* test connection */}
          <Grid item xs={12}>
            <LoadingButton
              loading={testingConnection}
              fullWidth
              variant={"contained"}
              onClick={handleTestButtonClick}
            >
              {t("administrative_console.connection_page.test_connection")}
            </LoadingButton>
          </Grid>
          {/* save & cancel button */}
          <Grid
            container
            item
            xs={12}
            justifyContent={"flex-end"}
            columnGap={1}
            rowGap={2}
            display={enableEdit ? "flex" : "none"}
          >
            <Divider sx={{ width: "100%" }} />
            <Grid item>
              <Button variant="contained" onClick={handleSaveButtonClick}>
                {t("common.save")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                onClick={handleCancelButtonClick}
              >
                {t("common.cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SharedDialog
        isOpen={dialogInfo?.show}
        title={dialogInfo?.title}
        content={dialogInfo?.content}
        buttons={dialogInfo?.buttons ?? []}
      />
    </Paper>
  );
};

export default ConnectionSetting;
