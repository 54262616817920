import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.react.grid";
import "@grapecity/wijmo.styles/wijmo.css";
import Grid from "@mui/material/Grid";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import urlJoin from "url-join";
import SharedDialog from "../../shared/SharedDialog";
import Checkbox from "@mui/material/Checkbox";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { saveAs } from "file-saver";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingButton } from "@mui/lab";
import { TablePagination, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  CallApiWithContext,
  CallApiWithDownloadFile,
} from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { resServerBaseUrl } from "../../Config";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use-size";
import { CaseTplPermission, DRAWER_WIDTH } from "../../Constants";
import TitleWithIcon from "../../shared/TitleWithIcon";
import CriteriaComboBox from "../../shared/CriteriaComboBox";
import { useProfile } from "../../providers/ProfileProvider";
import i18n from "../../localization/i18n";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Grid sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Grid>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Report(props) {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [caseTemplates, setCaseTemplates] = React.useState(null);
  const [reportTemplates, setReportTemplates] = React.useState(null);
  const [selectedReport, setSelectedReport] = React.useState(null);
  const [selectedCaseTemplate, setSelectedCaseTemplate] = React.useState(null);
  const [selected, setSelected] = React.useState([]);
  const [reportColumn, setReportColumn] = React.useState(null);
  const [orderingNumber, setOrderingNumber] = React.useState(null);
  const [rowNumber, setRowNumber] = React.useState(null);
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = React.useState(10);
  const [dialog, setDialog] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [criteriaDialog, setCriteriaDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [systemDialog, setSystemDialog] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState("");
  const [dataType, setDataType] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchByFromDate, setSearchByFromDate] = React.useState("");
  const [searchByEndDate, setSearchByEndDate] = React.useState("");
  const [criteria, setCriteria] = React.useState(null);
  const [operator, setOperator] = React.useState(null);
  const [opernad1, setOpernad1] = React.useState(null);
  const [opernad2, setOpernad2] = React.useState(null);
  const [caseTplUUID, setCaseTplUUID] = React.useState(null);
  const [fileData, setFileData] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [previewData, setPreviewData] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [users, setUsers] = React.useState(null);
  const [isUser, setIsUser] = React.useState(false);
  const [isStatus, setIsStatus] = React.useState(false);
  const [columnName, setColumnName] = React.useState(null);
  const [field, setField] = React.useState(null);
  const [selectedField, setSelectedField] = React.useState(null);
  const [systemColumnExtraData, setSystemColumnExtraData] = React.useState({});
  const [empty, setEmpty] = React.useState(false);
  const [selectedCaseTpl, setSelectedCaseTpl] = React.useState();
  const [availablePage, setAvailablePage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filterCriteria, setFilterCriteria] = React.useState(
    JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) })
  );
  const [submittedCriterias, setSubmittedCriterias] = React.useState(
    JSON.stringify({})
  );
  const [criterias, setCriterias] = React.useState({
    "": [""],
  });
  const setTitle = props.settitle;
  var test = [];
  const operatorOption = React.useCallback((type) => {
    switch (type) {
      case "String":
        return [
          { label: "=", value: 7 },
          { label: "in", value: 14 },
          { label: "like", value: 13 },
        ];
      case "Number":
        return [
          { label: ">", value: 9 },
          { label: "<", value: 10 },
          { label: "=", value: 7 },
          { label: "<=", value: 11 },
          { label: ">=", value: 12 },
          { label: "between", value: 15 },
          { label: "in", value: 14 },
          { label: "<>", value: 8 },
        ];
      case "Date":
        return [
          { label: ">", value: 9 },
          { label: "<", value: 10 },
          { label: "between", value: 15 },
          { label: "<=", value: 11 },
          { label: ">=", value: 12 },
        ];
      default:
        return [
          { label: "=", value: 7 },
          { label: "in", value: 14 },
          { label: "like", value: 13 },
        ];
    }
  });
  const commonOperators = [
    "=",
    "<>",
    ">",
    "<",
    "<=",
    ">=",
    "Between",
    "Not Between",
  ];
  const stringOperators = ["=", "<>", "Includes", "Not Includes"];
  const status = [
    "Case_Initial",
    "Case_Active",
    "Case_Completed",
    "Case_Terminated",
    "Case_Failed",
    "Case_Suspended",
    "Case_Closed",
  ];
  const { height: bodyHeight, width: bodyWidth } = useWindowSize();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const pageWidth = React.useMemo(
    () => bodyWidth - (isMdUp ? DRAWER_WIDTH + 48 : 32),
    [bodyWidth, isMdUp]
  );
  const [profile] = useProfile();

  const rowsPerPage = 8;

  dayjs.extend(utc);

  React.useEffect(() => {
    setTitle(t("left_menu.report"));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    var url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates");
    const data = JSON.stringify({ IsAdministrator: true });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        console.log(response);
        if (Object.keys(response).includes("message")) {
          setIsLoading(false);
        } else {
          var url_caseTplpPrmission = urlJoin(
            resServerBaseUrl,
            "/Permission/GetCaseTemplatePermissions"
          );

          CallApiWithContext(url_caseTplpPrmission, authenticationContext).then(
            (permissionResponse) => {
              var filteredResponses = [];
              response.forEach((element) => {
                var filteredTemplates = element.templateInfos.filter((tInfo) =>
                  permissionResponse.some(
                    (permission) =>
                      permission.permissionType > CaseTplPermission.None
                  )
                );
                if (filteredTemplates && filteredTemplates.length > 0) {
                  var toAddResponse = element;
                  toAddResponse.templateInfos = filteredTemplates;
                  filteredResponses.push(toAddResponse);
                }
              });
              setCaseTemplates(filteredResponses);
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    url = urlJoin(resServerBaseUrl, "/User/GetUsersFullnameByPermission");
    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify({ Language: "" })
    )
      .then((response) => {
        let userNameIDArr = [];
        Object.keys(response).map((r) => {
          userNameIDArr.push({ label: response[r], value: r });
        });
        setSystemColumnExtraData({ "case initiator": userNameIDArr });
        setUsers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authenticationContext]);

  const CaseTemplatesComboBox = (caseTemplates) => {
    if (caseTemplates) {
      test.push({
        firstLetter: "*",
        uuid: -1,
        categoryName: "*",
        templateName: "System",
      });
      caseTemplates.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return option.templateInfos.forEach((t) =>
          test.push({
            firstLetter: firstLetter,
            uuid: t.uuid,
            categoryName: option.name,
            templateName: t.name,
          })
        );
      });

      return (
        <Grid xs={12} sm={6} md={"auto"} item alignItems={"flex-end"}>
          <Grid item style={{ textAlign: "left", paddingBottom: "12px" }}>
            <Typography variant={"h4"} fontSize={"16px"}>
              {t("administrative_console.report_page.select_case")}
            </Typography>
          </Grid>
          <Grid item>
            <Autocomplete
              id="CaseTemplates"
              disableClearable
              options={test.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              isOptionEqualToValue={(option, value) =>
                option.templateName === value.templateName
              }
              groupBy={(test) => test.categoryName}
              getOptionLabel={(test) => test.templateName}
              sx={{ width: { xs: "100%", sm: "100%", md: "300px" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("administrative_console.report_page.case_template")}
                />
              )}
              onChange={(event, value) => handleTemplateOnChange(value)}
            />
          </Grid>
        </Grid>
      );
    }
  };
  const handleTemplateOnChange = (template) => {
    setReportTemplates(null);
    setReportColumn(null);
    setPreviewData(null);
    setFileData(null);
    setFilterCriteria(JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) }));
    setSubmittedCriterias(
      JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) })
    );
    if (template) {
      setIsLoading(true);
      if (template.templateName !== "System") {
        setSelectedCaseTpl(template.templateName);
      }
      var uuid =
        template.templateName !== "System"
          ? template.uuid
          : "00000000-0000-0000-0000-000000000000";
      const data = JSON.stringify({ CaseTemplateUUID: uuid });
      var url = urlJoin(resServerBaseUrl, "/Report/GetExistingReport");
      CallApiWithContext(url, authenticationContext, data)
        .then((response) => {
          console.log(response);
          if (response) {
            if (template.templateName === "System") {
              setSystemDialog(true);
            }
            setReportTemplates(response.reports);
            setField(response.field);
            setCaseTplUUID(uuid);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      url = urlJoin(resServerBaseUrl, "/Report/GetSystemColumnExtraData");
      CallApiWithContext(url, authenticationContext, data)
        .then((response) => {
          console.log(response);
          if (response) {
            if (template.templateName === "System") {
              setSystemDialog(true);
            }
            let obj =
              Object.keys(systemColumnExtraData).length > 0
                ? systemColumnExtraData
                : {};
            Object.keys(response).map((r) => {
              let arr = response[r].map((res) => {
                return { label: res, value: res };
              });
              obj[r.toLowerCase()] = arr;
            });
            setSystemColumnExtraData(obj);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleReportTemplateOnChange = (report) => {
    setReportColumn(null);
    setFileData(null);
    setPreviewData(null);
    setSelectedPage(0);
    setFilterCriteria(JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) }));
    setSubmittedCriterias(
      JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) })
    );
    if (report) {
      let reportColumnsData = JSON.parse(report.data);
      let columns = [];
      reportColumnsData.map((e, i) => {
        e.OrderNo = i + 1;
        if (e.ChildColumn) {
          columns.push(e);
          e.ChildColumn.map((c) => columns.push(c));
        } else {
          columns.push(e);
        }
      });
      setReportColumn(columns);
      setSelectedReport(report);
    }
  };
  const handleChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };
  const handleChangeSelectedRowsPerPage = (event) => {
    setSelectedRowsPerPage(parseInt(event.target.value, 10));
    setSelectedPage(0);
  };
  const handleDialogOpen = (row, index, edit) => {
    setColumnName(row.ColumnName);
    let obj = JSON.parse(filterCriteria);
    if (!obj[row.ColumnName]) {
      obj[row.ColumnName] = JSON.stringify({ [""]: ["", ""] });

      setFilterCriteria(JSON.stringify(obj));
    } else {
      setOperator(parseInt(Object.keys(JSON.parse(obj[row.ColumnName]))[0]));
    }
    if (!criterias[row.ColumnName]) {
      let rowObj = criterias;
      rowObj[row.ColumnName] = [""];
      setCriterias(rowObj);
    }
    setIsUser(
      row.ColumnName === "Case Initator" ||
        (columnName && columnName === "Case Initator")
    );
    setIsStatus(
      row.ColumnName === "Case Status" ||
        (columnName && columnName === "Case Status")
    );
    if (field && (field[row.ColumnName] || field[columnName])) {
      setSelectedField(
        row.ColumnName ? field[row.ColumnName] : field[columnName]
      );
    } else if (
      systemColumnExtraData &&
      (systemColumnExtraData[row.ColumnName] ||
        systemColumnExtraData[columnName])
    ) {
      setSelectedField(
        row.ColumnName
          ? systemColumnExtraData[row.ColumnName]
          : systemColumnExtraData[columnName]
      );
    } else {
      setSelectedField(null);
    }
    if (edit) {
      setOpernad1(row.Opernad1);
      setOpernad2(row.Opernad2);
      setDataType(row.DataType);
      setRowNumber(index);
    } else {
      if (
        row.ColumnName === "Case Initator" ||
        (columnName && columnName === "Case Initator")
      ) {
        setOpernad1(Object.values(users)[0]);
      } else if (
        row.ColumnName === "Case Status" ||
        (columnName && columnName === "Case Status")
      ) {
        setOpernad1(status[0]);
      } else if (field && field[row.ColumnName]) {
        setOpernad1(field[row.ColumnName][0]);
      } else if (
        systemColumnExtraData &&
        systemColumnExtraData[row.ColumnName]
      ) {
        setOpernad1(systemColumnExtraData[row.ColumnName][0]);
      } else if (row.DataType === "Date") {
        setSearchByFromDate(new Date());
        setOpernad1(new Date());
      } else {
        setOpernad1(null);
      }
      setOpernad2(null);
      setOrderingNumber(index);
      setDataType(row.DataType);
    }
    setEdit(edit);
    setDialog(true);
  };
  const handleDialogClose = () => {
    setOpernad1(null);
    setOpernad2(null);
    setDialog(false);
    let temCriteriaObj = JSON.parse(filterCriteria);
    let criteriaObj = JSON.parse(submittedCriterias);
    let criteriaDisplayObj = criterias;
    if (!criteriaObj[columnName]) {
      delete temCriteriaObj[columnName];
      delete criteriaDisplayObj[columnName];
    }

    setCriterias(criteriaDisplayObj);
    setFilterCriteria(JSON.stringify(temCriteriaObj));
  };
  const handleCriteriaDialogOpen = (row, index) => {
    setColumnName(row.ColumnName);
    setOrderingNumber(index);
    setCriteria(row.Criteria);
    setCriteriaDialog(true);
    // JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) })
  };
  const handleCriteriaDialogClose = () => {
    setColumnName("");
    setCriteriaDialog(false);
  };
  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };
  const handleDialogEdit = () => {
    let obj = JSON.parse(filterCriteria);
    let newobj = JSON.parse(submittedCriterias);
    if (columnName != "") {
      newobj[columnName] = obj[columnName];
    }

    setSubmittedCriterias(JSON.stringify(obj));

    setDialog(false);
    // var result = false,
    //   opernad2_changed = opernad2;
    // if (!opernad1) {
    //   setErrorContent(t("report.criteria_dialog.please_input_an_opernad_1"));
    //   setErrorDialog(true);
    //   result = true;
    // }
    // if (!result && (operator === "Between" || operator === "Not Between")) {
    //   if (opernad2) {
    //     if (opernad2 < opernad1) {
    //       setErrorContent(
    //         t(
    //           "report.criteria_dialog.opernad_2_should_not_be_larger_then_opernad_1"
    //         )
    //       );
    //       setErrorDialog(true);
    //       result = true;
    //     }
    //   } else if (searchByEndDate) {
    //     if (searchByEndDate < searchByFromDate) {
    //       setErrorContent(
    //         t(
    //           "report.criteria_dialog.end_date_should_not_be_larger_than_from_date"
    //         )
    //       );
    //       setErrorDialog(true);
    //       result = true;
    //     }
    //   } else {
    //     setErrorContent(t("report.criteria_dialog.please_input_an_opernad_2"));
    //     setErrorDialog(true);
    //     result = true;
    //   }
    // } else if (operator !== "Between" && operator !== "Not Between") {
    //   opernad2_changed = null;
    //   setOpernad2(null);
    // }
    // if (opernad1 && !result) {
    //   var selectedReport = reportColumn;
    //   var selecteditem = {};
    //   if (selectedReport[orderingNumber].Criteria) {
    //     if (edit) {
    //       selecteditem = selectedReport[orderingNumber].Criteria.splice(
    //         rowNumber,
    //         1
    //       );
    //     }
    //     var item = checkCriteria(
    //       {
    //         Operator: operator,
    //         Opernad1: opernad1,
    //         Opernad2: opernad2_changed,
    //         DataType: dataType,
    //       },
    //       selectedReport[orderingNumber].Criteria
    //     );
    //     if (item) {
    //       if (edit) {
    //         selectedReport[orderingNumber].Criteria.splice(rowNumber, 0, item);
    //       } else {
    //         selectedReport[orderingNumber].Criteria.push(item);
    //       }
    //     } else {
    //       result = true;
    //     }
    //   } else {
    //     selectedReport[orderingNumber].Criteria = [
    //       {
    //         Operator: operator,
    //         Opernad1: opernad1,
    //         Opernad2: opernad2_changed,
    //         DataType: dataType,
    //       },
    //     ];
    //   }

    //   if (!result) {
    //     setUpdate(true);
    //     setReportColumn((reportColumn) => [...selectedReport]);
    //     setDialog(false);
    //   } else if (result && edit) {
    //     selectedReport[orderingNumber].Criteria.splice(
    //       rowNumber,
    //       0,
    //       selecteditem[0]
    //     );
    //   }
    // }
  };
  const checkCriteria = (item, list) => {
    var haveDuplicates = list.filter((c) => {
      return (
        c.Operator === item.Operator &&
        c.Opernad1 === item.Opernad1 &&
        c.Opernad2 === item.Opernad2
      );
    });
    var invaildInputs = list.filter((c) => {
      if (c.Opernad1 === item.Opernad1 && c.Opernad2 === item.Opernad2) {
        switch (item.Operator) {
          case "=":
            if (c.Operator === "<>") return true;
            break;
          case "<>":
            if (c.Operator === "=") return true;
            break;
          case ">":
            if (c.Operator === "<") return true;
            break;
          case "<":
            if (c.Operator === ">") return true;
            break;
          case ">=":
            if (c.Operator === "<=") return true;
            break;
          case "<=":
            if (c.Operator === ">=") return true;
            break;
          case "Between":
            if (c.Operator === "Not Between") return true;
            break;
          case "Not Between":
            if (c.Operator === "Between") return true;
            break;
          case "Includes":
            if (c.Operator === "Not Includes") return true;
            break;
          case "Not Includes":
            if (c.Operator === "Includes") return true;
            break;
          default:
            break;
        }
      }
      return false;
    });
    if (
      (!haveDuplicates || haveDuplicates.length === 0) &&
      (!invaildInputs || invaildInputs.length === 0)
    ) {
      return item;
    } else {
      if (invaildInputs && invaildInputs.length !== 0) {
        setErrorContent(t("report.criteria_dialog.invaild_operator"));
      } else {
        setErrorContent(t("report.criteria_dialog.have_duplicate"));
      }
      setErrorDialog(true);
    }
  };
  const handleOperatorOnChange = (value) => {
    setOperator(() => value.value);
    let criteriaRow = criterias;
    criteriaRow[columnName] = value.value === 15 ? ["", ""] : [""];
    setCriterias(criteriaRow);
    let column = JSON.parse(filterCriteria);
    let c = JSON.parse(column[columnName]);
    column[columnName] = JSON.stringify({
      [value.value]: Object.values(c)[0],
    });

    setFilterCriteria(JSON.stringify(column));
    // if (
    //   (event.target.innerText === "Between" ||
    //     event.target.innerText === "Not Between") &&
    //   dataType === "Date"
    // ) {
    //   setOpernad2(new Date());
    //   setSearchByEndDate(new Date());
    // }
  };
  const handleOpernad1OnChange = (event) => {
    if (dataType !== "Date") {
      setOpernad1(event.target.value);
    } else {
      setSearchByFromDate(event);
      setOpernad1(event);
    }
  };
  const handleOpernad2OnChange = (event) => {
    if (dataType !== "Date") {
      setOpernad2(event.target.value);
    } else {
      setSearchByEndDate(event);
      setOpernad2(event);
    }
  };
  const handleUserOnChange = (event) => {
    setOpernad1(event.target.innerText);
  };
  const handleCriteriaDelete = () => {
    let selectedCriForEdit = JSON.parse(submittedCriterias);
    let cacheCri = JSON.parse(filterCriteria);
    let criteriasObj = criterias;
    if (selectedCriForEdit[columnName]) {
      delete selectedCriForEdit[columnName];
      setSubmittedCriterias(JSON.stringify(selectedCriForEdit));
    }
    if (cacheCri[columnName]) {
      delete cacheCri[columnName];
      setFilterCriteria(JSON.stringify(cacheCri));
    }
    if (criteriasObj[columnName]) {
      delete criteriasObj[columnName];
      setCriterias(criteriasObj);
    }
    // var selectedReport = reportColumn;
    // selectedReport[orderingNumber].Criteria.splice(rowNumber, 1);
    // setReportColumn((reportColumn) => [...selectedReport]);
    // setUpdate(true);
    setDeleteDialog(false);
  };
  const handleDeleteDialogOpen = (colName) => {
    setColumnName(colName);
    setDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };
  const handlePreviewButtonClick = () => {
    let obj = JSON.parse(submittedCriterias);
    if (obj[""]) {
      delete obj[""];
    }
    Object.keys(obj).map((o) => (obj[o] = JSON.parse(obj[o])));
    setIsLoading(true);
    const data = JSON.stringify({
      TplSID: selectedReport.sid,
      // Data: JSON.stringify(reportColumn),
      CaseTplUUID: caseTplUUID,
      // SelectedCaseTemplate: selectedCaseTemplate,
      // Users: users,
      IsDownload: false,
      FilterCriteria: JSON.stringify(obj),
    });
    var url = urlJoin(resServerBaseUrl, "/Report/GenerateReport");
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        if (response) {
          setUpdate(false);
          const pd = [];
          response.previewData.map((p) => {
            Object.keys(p).map((key) => {
              if (dayjs(p[key]).isValid()) {
                p[key] = dayjs(p[key]).format("YYYY-MM-DD HH:mm:ss");
              }
              if (key === "CaseSID") {
                delete p[key];
              }
            });
            pd.push(p);
          });

          setPreviewData(pd);
          setEmpty((previewData?.length ?? 0) === 0);
        }
      })
      .catch((error) => {
        console.log(error);
        setPreviewData([]);
        setEmpty(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDownloadButtonClick = () => {
    setIsLoading(true);
    let obj = JSON.parse(submittedCriterias);
    if (obj[""]) {
      delete obj[""];
    }
    Object.keys(obj).map((o) => (obj[o] = JSON.parse(obj[o])));
    const data = JSON.stringify({
      TplSID: selectedReport.sid,
      // Data: JSON.stringify(reportColumn),
      CaseTplUUID: caseTplUUID,
      // SelectedCaseTemplate: selectedCaseTemplate,
      // Users: users,
      IsDownload: true,
      FilterCriteria: JSON.stringify(obj),
    });
    let name =
      selectedCaseTpl + "_" + dayjs.utc().format("MM_DD_YYYY HH_mm_ss");
    var url = urlJoin(resServerBaseUrl, "/Report/GenerateReport");
    CallApiWithDownloadFile(url, authenticationContext, data, name)
      .then((response) => {
        if (response) {
          setUpdate(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSystemDialogClose = () => {
    var arr = [];
    selected.forEach((s) => {
      arr.push(
        test.filter((t) => {
          return t.templateName === s;
        })[0]
      );
    });
    setSelectedCaseTemplate(arr);
    setSystemDialog(false);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      var newSelecteds = test.map((n) => n.templateName);
      newSelecteds.splice(newSelecteds.indexOf("System"), 1);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const SelectAllCheckBox = (props) => {
    const { onSelectAllClick, numSelected, rowCount } = props;
    return (
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          "aria-label": "select_all",
        }}
      />
    );
  };
  const AddRow = (xy) => {
    let arr = criterias[xy];
    arr = [...arr, ""];
    setCriterias({
      ...criterias,
      [xy]: arr,
    });
    if (arr.length >= availablePage * rowsPerPage) {
      setAvailablePage(availablePage + 1);
    }
    console.log(criterias[xy].length);
  };
  const DeleteRow = (index, xy) => {
    if (criterias[xy].length > 1) {
      const tempRow = [...criterias[xy]];
      tempRow.splice(index, 1);
      // setXCriteriaRows(tempRow);
      setCriterias({
        ...criterias,
        [xy]: tempRow,
      });
      if (criterias[xy].length < (availablePage - 1) * rowsPerPage + 1) {
        setAvailablePage(availablePage - 1);
        if (currentPage > availablePage - 1) {
          setCurrentPage(currentPage[xy] - 1);
        }
      }
    }
  };
  const handleCriteriaChange = (value, xy, index) => {
    let arr = criterias[xy];
    let input = null;
    let input2 = null;

    switch (dataType) {
      case "Date":
        if (value != null) {
          if (
            Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 7 ||
            Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 15
          ) {
            input = dayjs(value, "YYYY-MM-DD HH:mm:ss")
              .startOf("date")
              .format("YYYY-MM-DD HH:mm:ss");
            if (index > 0) {
              input2 = dayjs(value, "YYYY-MM-DD HH:mm:ss")
                .endOf("date")
                .format("YYYY-MM-DD HH:mm:ss");
            }
            // if (index == 0) {
            //   input = dayjs(value, "YYYY-MM-DD HH:mm:ss")
            //     .startOf("day")
            //     .format("YYYY-MM-DD HH:mm:ss");
            // } else if (index == 1) {
            //   input = dayjs(value, "YYYY-MM-DD HH:mm:ss")
            //     .endOf("day")
            //     .format("YYYY-MM-DD HH:mm:ss");
            // }
          } else {
            input =
              Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 9 ||
              Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 12
                ? dayjs(value, "YYYY-MM-DD HH:mm:ss")
                    .endOf("date")
                    .format("YYYY-MM-DD HH:mm:ss")
                : Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] ==
                    10 ||
                  Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] ==
                    11
                ? dayjs(value, "YYYY-MM-DD HH:mm:ss")
                    .startOf("date")
                    .format("YYYY-MM-DD HH:mm:ss")
                : dayjs(value, "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
          }
        } else {
          input = "";
        }
        break;
      case "String":
        input = value;
        break;
      case "Number":
        input = value == "" ? "" : parseInt(value);
        break;
    }
    arr[index] = input;
    if (input2) {
      arr[1] = input2;
    }
    console.log(arr);
    let rows = criterias;
    rows[xy] = arr;
    setCriterias(rows);

    let obj = {
      [Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 7 &&
      dataType === "Date"
        ? 15
        : Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0]]: arr,
    };

    let filterCriteriaObj = JSON.parse(filterCriteria);
    filterCriteriaObj[xy] = JSON.stringify(obj);
    setFilterCriteria(JSON.stringify(filterCriteriaObj));

    // if (value == "") {
    //   let filterCriteriaObj = JSON.parse(filterCriteria);
    //   filterCriteriaObj[xy] = JSON.stringify(obj);
    //   setFilterCriteria(JSON.stringify(filterCriteriaObj));
    // } else {
    //   delete JSON.parse(filterCriteria)[xy];
    // }
  };

  return (
    <Grid
      container
      direction="row"
      rowGap={2}
      justifyContent="space-around"
      p={1}
    >
      <Grid container item>
        <TitleWithIcon
          icon={<AssessmentIcon />}
          title={{
            content: t("report.report_export"),
            variant: "h6",
            alignItems: "center",
            fontWeight: "bold",
          }}
        />
      </Grid>
      <Grid container item columnSpacing={2} rowGap={2} alignItems={"flex-end"}>
        {/* Case Templates Combo Box */}
        {caseTemplates ? CaseTemplatesComboBox(caseTemplates) : null}
        {/* Report Templates Combo Box */}
        {reportTemplates ? (
          <Grid item xs={12} sm={6} md={"auto"}>
            <Autocomplete
              id="CaseTemplates"
              disabled={!(reportTemplates && reportTemplates.length > 0)}
              options={reportTemplates}
              getOptionLabel={(option) => option.reportName}
              sx={{ width: { xs: "100%", sm: "100%", md: "300px" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("administrative_console.report_page.report")}
                />
              )}
              onChange={(event, value) => handleReportTemplateOnChange(value)}
            />
          </Grid>
        ) : null}
      </Grid>
      {reportColumn ? (
        <Grid container item>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{ fontWeight: "600" }}>
                {isMdUp ? (
                  <TableRow>
                    <TableCell>
                      {t("administrative_console.report_page.order_num")}
                    </TableCell>
                    <TableCell>
                      {t("administrative_console.report_page.column_name")}
                    </TableCell>
                    <TableCell>
                      {t("administrative_console.report_page.dialog_data")}
                    </TableCell>
                    <TableCell>
                      {t("administrative_console.report_page.dialog_format")}
                    </TableCell>
                    <TableCell>{t("report.add_criteria")}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>
                      {t("administrative_console.report_page.order_num")}
                    </TableCell>
                    <TableCell>
                      {t("administrative_console.report_page.column_name")}
                    </TableCell>
                    <TableCell>
                      {t("administrative_console.report_page.dialog_data")}
                    </TableCell>

                    <TableCell>{t("report.add_criteria")}</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {reportColumn
                  ? reportColumn
                      .slice(
                        selectedPage * selectedRowsPerPage,
                        selectedPage * selectedRowsPerPage + selectedRowsPerPage
                      )
                      .map((row, rowIndex) => {
                        var currentRowIndex = rowIndex;
                        return (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.OrderNo}
                              {/* {rowIndex + selectedPage * selectedRowsPerPage} */}
                            </TableCell>
                            <TableCell>{row.ColumnName}</TableCell>
                            <TableCell>{row.DataType}</TableCell>
                            {isMdUp ? (
                              <TableCell>
                                {row.Format
                                  ? row.Format
                                  : t("report.no_format")}
                              </TableCell>
                            ) : null}

                            <TableCell>
                              {row.AllowCriteria &&
                              !row.Parent &&
                              row.DataType !== "DynamicList" &&
                              row.DataType !== "DataGroup" ? (
                                <IconButton
                                  id={currentRowIndex}
                                  onClick={(event) =>
                                    handleDialogOpen(row, rowIndex, false)
                                  }
                                  color="primary"
                                >
                                  {Object.keys(
                                    JSON.parse(submittedCriterias)
                                  ).includes(row.ColumnName) ? (
                                    <EditIcon />
                                  ) : (
                                    <AddBoxIcon />
                                  )}
                                </IconButton>
                              ) : null}
                              {Object.keys(
                                JSON.parse(submittedCriterias)
                              ).includes(row.ColumnName) ? (
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    handleDeleteDialogOpen(row.ColumnName)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
            <TablePagination
              style={{
                justifyContent: "center",
                display: "flex",
              }}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              labelRowsPerPage={t("inbox_outbox.display_row")}
              count={reportColumn ? reportColumn.length : 0}
              rowsPerPage={selectedRowsPerPage}
              page={selectedPage}
              sx={{
                position: "relative",
                bottom: "10px",
                right: 0,
                overflow: "hidden",
              }}
              onPageChange={handleChangeSelectedPage}
              onRowsPerPageChange={handleChangeSelectedRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableContainer>
          {/* Action Buttons */}
          <Grid
            container
            item
            direction="row"
            columnGap={2}
            sx={{ pt: 2 }}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                type="btn1"
                fullWidth
                variant="contained"
                onClick={() => {
                  handleDownloadButtonClick();
                }}
              >
                {t("report.export")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="btn1"
                fullWidth
                variant="contained"
                onClick={() => {
                  handlePreviewButtonClick();
                }}
              >
                {t("report.preview")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {previewData ? (
        !empty ? (
          <Grid container item>
            <wjcGrid.FlexGrid
              alternatingRowStep={0}
              itemsSource={previewData}
              style={{
                maxHeight: `300px`,
                maxWidth: `${pageWidth}px`,
              }}
            />
          </Grid>
        ) : (
          <Typography variant="p">{t("report.no_matched_record")}</Typography>
        )
      ) : null}
      {/* Add Criteria Dialog */}
      <Dialog open={dialog} onClose={handleDialogClose}>
        <DialogTitle>
          {!edit
            ? t("report.criteria_dialog.add")
            : t("report.criteria_dialog.edit")}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={1} p={1}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                loadingText={"Loading..."}
                id="combo-box-demo"
                defaultValue={
                  operatorOption(dataType).find((o) => o.value === operator)
                    ?.label ?? ""
                }
                disableClearable
                onChange={(e, v) => {
                  handleOperatorOnChange(v);
                }}
                options={operatorOption(dataType)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("report.criteria_dialog.operator")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <CriteriaComboBox
                columnName={columnName}
                operatorEnum={operator}
                type={dataType}
                AddRow={AddRow}
                DeleteRow={DeleteRow}
                handleCriteriaChange={handleCriteriaChange}
                rowsPerPage={rowsPerPage}
                criteriaRows={criterias}
                dropdownDataObj={systemColumnExtraData}
              />
            </Grid>
            {/* {Object.keys(JSON.parse(filterCriteria)).map((k, i) => {
              console.log(k);
              return (
                <Grid xs={12} sm={8}>
                 
                  {dataType !== "Date" ? (
                    <Grid>
                      {(isUser && users) || isStatus || selectedField ? (
                        <Autocomplete
                          loadingText={t("inbox_outbox.load_text")}
                          id="combo-box-demo"
                          defaultValue={
                            opernad1
                              ? opernad1
                              : isStatus
                              ? status[0]
                              : selectedField
                              ? selectedField[0]
                              : Object.values(users)[0]
                          }
                          disableClearable
                          onChange={handleUserOnChange}
                          options={
                            isStatus
                              ? status
                              : selectedField
                              ? selectedField
                              : Object.values(users)
                          }
                          sx={{ width: 259 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("report.criteria_dialog.operand1")}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={{ width: 259 }}
                          onChange={handleOpernad1OnChange}
                          defaultValue={opernad1 ? opernad1 : null}
                        />
                      )}
                    </Grid>
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={t("search_bar.from")}
                        defaultValue={dayjs(opernad1 ? opernad1 : new Date())}
                        value={dayjs(opernad1 ? opernad1 : new Date())}
                        onChange={handleOpernad1OnChange}
                        inputFormat="yyyy/MM/dd"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                </Grid>
              );
            })} */}
            {/* {operator === "Between" || operator === "Not Between" ? (
              <Grid container direction="row" spacing={2} pr="50px" pt="40px">
                <Grid xs={12} sm={4}>
                  <Typography
                    variant="p"
                    style={{ paddingTop: "15px", color: "gray" }}
                  >
                    {t("report.criteria_dialog.operand2")}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8}>
                  {dataType !== "Date" ? (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={{ width: 259 }}
                      onChange={handleOpernad2OnChange}
                      defaultValue={opernad2 ? opernad2 : null}
                    />
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={t("search_bar.to")}
                        defaultValue={opernad2 ? opernad2 : new Date()}
                        value={opernad2 ? opernad2 : new Date()}
                        onChange={handleOpernad2OnChange}
                        inputFormat="yyyy/MM/dd"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                </Grid>
              </Grid>
            ) : null} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={(event) => handleDialogEdit(event)}
          >
            {edit
              ? t("administrative_console.report_page.dialog_edit")
              : t("report.add")}
          </LoadingButton>
          <Button onClick={handleDialogClose} variant="outlined" color="error">
            {t("administrative_console.report_page.dialog_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Preview Existing Criteria Dialog */}
      <Dialog open={criteriaDialog} onClose={handleCriteriaDialogClose}>
        <DialogTitle>{t("report.exist_dialog.existing_criteria")}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead sx={{ fontWeight: "600" }}>
                <TableRow>
                  <TableCell>{t("report.criteria_dialog.operator")}</TableCell>
                  <TableCell>{t("report.criteria_dialog.operand1")}</TableCell>
                  <TableCell>{t("report.criteria_dialog.operand2")}</TableCell>
                  <TableCell>{t("report.exist_dialog.action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {criteria
                  ? criteria.map((row, rowIndex) => {
                      var currentRowIndex = rowIndex;
                      return (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.Operator}</TableCell>
                          <TableCell>
                            {row.DataType === "Date"
                              ? row.Opernad1.toDateString()
                              : row.Opernad1}
                          </TableCell>
                          <TableCell>
                            {row.Opernad2
                              ? row.DataType === "Date"
                                ? row.Opernad2.toDateString()
                                : row.Opernad2
                              : "None"}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              id={currentRowIndex}
                              onClick={(event) => handleDeleteDialogOpen()}
                              color="primary"
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              id={currentRowIndex}
                              onClick={(event) => {
                                handleDialogOpen(row, rowIndex, true);
                              }}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaDialogClose} color="error">
            {t("report.exist_dialog.close")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Select Case Template Dialog for System Report Temaplte */}
      {test.length > 0 ? (
        <Dialog open={systemDialog}>
          <DialogTitle>
            {t("report.system_dialog.select_case_template")}
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <TableHead sx={{ fontWeight: "600", width: "100%" }}>
                <TableRow>
                  <TableCell>
                    <SelectAllCheckBox
                      numSelected={selected ? selected.length : 0}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={test ? test.length - 1 : 0}
                    />
                  </TableCell>
                  <TableCell>
                    {t("report.system_dialog.case_template_name")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {test
                  .slice(
                    selectedPage * selectedRowsPerPage,
                    selectedPage * selectedRowsPerPage + selectedRowsPerPage
                  )
                  .map((row, rowIndex) => {
                    if (row.templateName !== "System") {
                      var currentRowIndex = rowIndex;
                      const isItemSelected = isSelected(row.templateName);
                      const labelId = `enhanced-table-checkbox-${currentRowIndex}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.templateName)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell component="th" scope="row">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.templateName}</TableCell>
                        </TableRow>
                      );
                    }

                    return null;
                  })}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                labelRowsPerPage={t("inbox_outbox.display_row")}
                count={test ? test.length : 0}
                rowsPerPage={selectedRowsPerPage}
                page={selectedPage}
                sx={{
                  position: "relative",
                  bottom: "10px",
                  right: 0,
                  overflow: "hidden",
                }}
                onPageChange={handleChangeSelectedPage}
                onRowsPerPageChange={handleChangeSelectedRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleSystemDialogClose}>
              {t("report.system_dialog.select")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {/* Error Dialog */}
      <SharedDialog
        isOpen={errorDialog}
        onClose={handleErrorDialogClose}
        title={t("report.error")}
        content={errorContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleErrorDialogClose,
          },
        ]}
      ></SharedDialog>
      {/* Confirm Delete Dialog */}
      <SharedDialog
        isOpen={deleteDialog}
        onClose={handleDeleteDialogClose}
        title={t("administrative_console.report_page.confirm_delete")}
        content={t("administrative_console.report_page.are_you_sure")}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleCriteriaDelete,
            variant: "contained",
          },
          {
            text: t("administrative_console.report_page.cancel"),
            action: handleDeleteDialogClose,
            variant: "outlined",
            color: "error",
          },
        ]}
      ></SharedDialog>
      <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
    </Grid>
  );
}
