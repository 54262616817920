import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useEvent from "react-use-event-hook";

const ComboBox = (props) => {
  const {
    label = "",
    value,
    onChange,
    readOnly,
    required,
    menuItems,
    multiple = false,
    description,
  } = props;

  const getValue = () => {
    if (multiple) {
      if (Array.isArray(value)) {
        return value;
      }

      return typeof value === "string" && value.length > 0
        ? value.split(",")
        : [];
    }

    return value;
  };
  const handleSelectChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };
  const renderValue = (selected) => {
    return multiple
      ? selected
          ?.filter((s) => s)
          .map((s) => menuItems.find((m) => m.value === s)?.label)
          .join(", ")
      : menuItems.find((m) => m.value === selected)?.label;
  };

  return (
    <FormControl
      required={required}
      variant={readOnly ? "filled" : "outlined"}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={getValue()}
        onChange={handleSelectChange}
        readOnly={readOnly}
        multiple={multiple}
        IconComponent={readOnly ? null : KeyboardArrowDownIcon}
        renderValue={renderValue}
      >
        {menuItems.map((v, index) => (
          <MenuItem key={`combo_item_${v.value}`} value={v.value}>
            {multiple ? (
              <>
                <Checkbox checked={getValue().indexOf(v.value) > -1} />
                <ListItemText primary={v.label} />
              </>
            ) : (
              v.label
            )}
          </MenuItem>
        ))}
      </Select>
      {description ? <FormHelperText>{description}</FormHelperText> : null}
    </FormControl>
  );
};

export default ComboBox;
