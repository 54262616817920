import * as React from "react";
import {
  Avatar,
  Badge,
  Breadcrumbs,
  IconButton,
  Paper,
  Skeleton,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack, useMediaQuery } from "@mui/system";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CrossIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import TickIcon from "@mui/icons-material/Check";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urlJoin from "url-join";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import MUIDataTable from "mui-datatables";

import SharedDialog from "../../shared/SharedDialog";
import { resServerBaseUrl } from "../../Config";
import {
  CallApiWithContext,
  CallApiWithDownloadFile,
  CallApiWithUploadFile,
} from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useProfile } from "../../providers/ProfileProvider";
import { stringAvatar } from "../../shared/Utils";
import { NoPermissionPage } from "./CaseList";

export const HoverButtonIcon = styled(IconButton)(({ theme, active }) => ({
  opacity: active ? 0.5 : 0.2,
  "&:hover": { opacity: 0.8 },
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const StyledSkeleon = styled(Skeleton)(({ theme }) => ({
  display: "inline-block",
  width: "24px",
  height: "24px",
}));

const PermissionViewItem = (props) => {
  const { loading = false, value } = props;

  return loading ? (
    <StyledSkeleon variant={"circular"} />
  ) : value ? (
    <TickIcon color={"success"} />
  ) : (
    <CrossIcon color={"error"} />
  );
};
const CaseTplTable = (props) => {
  const { rows = null, caseTplName, showMessageDialog } = props;
  const { uuid } = useParams();
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  dayjs.extend(utc);
  dayjs.extend(tz);

  const userTimezone = dayjs.tz.guess();

  const getVersionString = (version) => {
    return version?.toLocaleString
      ? version.toLocaleString(undefined, {
          minimumFractionDigits: 1,
        })
      : version || "-";
  };
  const boolColumnRender = (dataIndex, fieldName) => {
    return (
      <PermissionViewItem
        loading={!rows}
        value={rows ? rows[dataIndex][fieldName] : false}
      />
    );
  };
  const versionColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      const value = rows[dataIndex][fieldName];

      return getVersionString(value);
    }

    return <Skeleton sx={{ width: "40px" }} />;
  };
  const dateTimeColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      const value = rows[dataIndex][fieldName];

      return dayjs.utc(value).tz(userTimezone).format("DD/MM/YYYY HH:mm");
    }

    return <Skeleton sx={{ width: "100px" }} />;
  };
  const stringColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      return rows[dataIndex][fieldName] ?? "-";
    }

    return <Skeleton sx={{ width: "100px" }} />;
  };
  const actionColumnRender = (dataIndex) => {
    const version = rows ? rows[dataIndex].version : 0;

    const handleDownloadClick = (e) => {
      const url = urlJoin(
        resServerBaseUrl,
        "Case/DownloadCaseTemplateExcelData"
      );
      const data = { uuid, version };
      CallApiWithDownloadFile(
        url,
        authenticationContext,
        JSON.stringify(data),
        `Case Template - ${caseTplName} v${getVersionString(version)}.xlsx`
      )
        .then((res) => {})
        .catch((error) => {
          console.log(error);

          if (error.response?.status === 403) {
            showMessageDialog((prev) => ({
              ...prev,
              show: true,
              messageTitle: "Download failure",
              messageContent:
                "You are not permitted to download this template.",
            }));
          }
        });
    };

    return (
      <>
        {rows ? (
          <Tooltip
            title={`${t(
              "case_list.manage_case.toolbar.download"
            )} v${getVersionString(version)}`}
          >
            <HoverButtonIcon onClick={handleDownloadClick}>
              <CloudDownloadIcon />
            </HoverButtonIcon>
          </Tooltip>
        ) : (
          <StyledSkeleon variant={"circular"} />
        )}
      </>
    );
  };

  const columns = [
    {
      name: "version",
      label: t("case_list.manage_case.column_names.version"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          versionColumnRender(dataIndex, "version"),
      },
    },
    {
      name: "enabled",
      label: t("case_list.manage_case.column_names.enabled"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          boolColumnRender(dataIndex, "enabled"),
      },
    },
    {
      name: "createDate",
      label: t("case_list.manage_case.column_names.published_date"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          dateTimeColumnRender(dataIndex, "createDate"),
      },
    },
    {
      name: "createUserName",
      label: t("case_list.manage_case.column_names.published_by"),
      options: {
        searchable: true,
        customBodyRenderLite: (dataIndex) =>
          stringColumnRender(dataIndex, "createUserName"),
      },
    },
    {
      name: "action",
      options: {
        searchable: false,
        sort: false,
        empty: true,
        customHeadLabelRender: (column) => "",
        customBodyRenderLite: (dataIndex) => actionColumnRender(dataIndex),
        setCellProps: () => ({
          style: {
            verticalAlign: "middle",
            width: isSmUp ? "40px" : null,
            whiteSpace: "nowrap",
          },
        }),
      },
    },
  ];

  return (
    <div style={{ textAlign: "left" }}>
      <MUIDataTable
        title={t("case_list.manage_case.template_versioning")}
        data={rows ?? Array.from(new Array(5))}
        columns={columns}
        options={{
          selectableRows: "none",
          download: false,
          print: false,
          viewColumns: false,
          filter: false,
          search: true,
          rowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 25, 100],
          sortOrder: { name: "version", direction: "desc" },
          setTableProps: () => ({ size: "small" }),
          textLabels: {
            body: {
              noMatch: t("case_list.manage_case.toolbar.no_record_found"),
              toolTip: t("case_list.manage_case.toolbar.sort"),
            },
            pagination: {
              next: t("case_list.manage_case.toolbar.next_page"),
              previous: t("case_list.manage_case.toolbar.prev_page"),
              rowsPerPage: t("case_list.manage_case.toolbar.rows_per_page"),
              displayRows: t("case_list.manage_case.toolbar.display_rows"),
            },
            toolbar: {
              search: t("case_list.manage_case.toolbar.search"),
            },
          },
        }}
      />
    </div>
  );
};
const FormTplTable = (props) => {
  const { rows = null } = props;
  const { uuid } = useParams();
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();

  dayjs.extend(utc);
  dayjs.extend(tz);

  const userTimezone = dayjs.tz.guess();

  const languageColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      const value = rows[dataIndex][fieldName];

      return value ?? "<Default>";
    }

    return <Skeleton sx={{ width: "60px" }} />;
  };
  const dateTimeColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      const value = rows[dataIndex][fieldName];

      return dayjs.utc(value).tz(userTimezone).format("DD/MM/YYYY HH:mm");
    }

    return <Skeleton sx={{ width: "100px" }} />;
  };
  const stringColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      return rows[dataIndex][fieldName] || "-";
    }

    return <Skeleton sx={{ width: "100px" }} />;
  };
  const numberColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      return `${rows[dataIndex][fieldName]}` ?? "-";
    }

    return <Skeleton sx={{ width: "40px" }} />;
  };
  const actionColumnRender = (dataIndex) => {
    const handleDownloadClick = () => {
      const url = urlJoin(
        resServerBaseUrl,
        "JsonForm/DownloadJsonFormTemplate"
      );
      const data = { caseTplUUID: uuid, sid: rows[dataIndex].sid };
      CallApiWithDownloadFile(
        url,
        authenticationContext,
        JSON.stringify(data),
        `${rows[dataIndex].name}${
          rows[dataIndex].language ? `.${rows[dataIndex].language}` : ""
        }.json`
      )
        .then((res) => {})
        .catch((error) => console.log(error));
    };

    return (
      <>
        <Tooltip title={t("case_list.manage_case.toolbar.download")}>
          <HoverButtonIcon>
            <CloudDownloadIcon onClick={handleDownloadClick} />
          </HoverButtonIcon>
        </Tooltip>
        {/* <HoverButtonIcon>
          <DeleteIcon />
        </HoverButtonIcon> */}
      </>
    );
  };

  const columns = [
    {
      name: "name",
      label: t("case_list.manage_case.column_names.name"),
      options: {
        searchable: true,
        customBodyRenderLite: (dataIndex) =>
          stringColumnRender(dataIndex, "name"),
      },
    },
    {
      name: "order",
      label: t("case_list.manage_case.column_names.order"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          numberColumnRender(dataIndex, "order"),
      },
    },
    {
      name: "language",
      label: t("case_list.manage_case.column_names.language"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          languageColumnRender(dataIndex, "language"),
      },
    },
    {
      name: "createdDate",
      label: t("case_list.manage_case.column_names.published_date"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          dateTimeColumnRender(dataIndex, "createdDate"),
      },
    },
    {
      name: "createdByUserName",
      label: t("case_list.manage_case.column_names.published_by"),
      options: {
        searchable: true,
        customBodyRenderLite: (dataIndex) =>
          stringColumnRender(dataIndex, "createdByUserName"),
      },
    },
    {
      name: "action",
      options: {
        searchable: false,
        sort: false,
        empty: true,
        customHeadLabelRender: (column) => "",
        customBodyRenderLite: (dataIndex) => actionColumnRender(dataIndex),
        setCellProps: () => ({
          style: {
            width: "10px",
            whiteSpace: "nowrap",
          },
        }),
      },
    },
  ];

  return (
    <div style={{ textAlign: "left" }}>
      <MUIDataTable
        title={t("case_list.manage_case.form_template")}
        data={rows ?? Array.from(new Array(3))}
        columns={columns}
        options={{
          selectableRows: "none",
          download: false,
          print: false,
          viewColumns: false,
          filter: false,
          search: true,
          rowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 25, 100],
          sortOrder: { name: "language", direction: "asc" },
          setTableProps: () => ({ size: "small" }),
          textLabels: {
            body: {
              noMatch: t("case_list.manage_case.toolbar.no_record_found"),
              toolTip: t("case_list.manage_case.toolbar.sort"),
            },
            pagination: {
              next: t("case_list.manage_case.toolbar.next_page"),
              previous: t("case_list.manage_case.toolbar.prev_page"),
              rowsPerPage: t("case_list.manage_case.toolbar.rows_per_page"),
              displayRows: t("case_list.manage_case.toolbar.display_rows"),
            },
            toolbar: {
              search: t("case_list.manage_case.toolbar.search"),
            },
          },
          //   customToolbar: (displayData) => (
          //     <Tooltip title="Add or update form">
          //       <IconButton>
          //         <CloudUploadIcon />
          //       </IconButton>
          //     </Tooltip>
          //   ),
        }}
      />
    </div>
  );
};
const PermittedUserTable = (props) => {
  const { rows = null, caseTplName, onRefresh, showMessageDialog } = props;
  const { uuid } = useParams();
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [profile, _, getCachedAvatar] = useProfile();
  const inputRef = React.useRef();

  dayjs.extend(utc);
  dayjs.extend(tz);

  const userTimezone = dayjs.tz.guess();

  const permissionColumnRender = (dataIndex, compareValue) => {
    return (
      <PermissionViewItem
        loading={rows === null}
        value={rows ? (rows[dataIndex].permission & compareValue) > 0 : false}
      />
    );
  };
  const dateTimeColumnRender = (dataIndex, fieldName) => {
    if (rows) {
      const value = rows[dataIndex][fieldName];

      return dayjs.utc(value).tz(userTimezone).format("DD/MM/YYYY HH:mm");
    }

    return <Skeleton sx={{ width: "100px" }} />;
  };

  const handleDownloadClick = () => {
    const url = urlJoin(
      resServerBaseUrl,
      "Case/DownloadCaseTemplateSecurityExcel"
    );
    const data = { uuid };
    CallApiWithDownloadFile(
      url,
      authenticationContext,
      JSON.stringify(data),
      `Recipient Matrix - ${caseTplName}.xlsx`
    )
      .then((res) => {})
      .catch((error) => {
        if (error.response?.status === 403) {
          showMessageDialog((prev) => ({
            ...prev,
            show: true,
            messageTitle: "Download failure",
            messageContent:
              "You are not permitted to download this permission file.",
          }));
        }
      });
  };
  const handleUploadClick = () => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };
  const handleUploadChange = (files) => {
    if (files.length > 0) {
      const url = urlJoin(
        resServerBaseUrl,
        "Import/ImportCaseTemplatePermission"
      );

      files.forEach((f) => {
        const formData = new FormData();

        formData.append("file", files[0]);
        formData.append("caseTplUUID", uuid);

        CallApiWithUploadFile(url, authenticationContext, formData)
          .then((res) => {
            res.succeeded
              ? showMessageDialog((prev) => ({
                  ...prev,
                  show: true,
                  messageTitle: "Upload succeeded",
                  messageContent: "Resolving file in engine.",
                }))
              : showMessageDialog((prev) => ({
                  ...prev,
                  show: true,
                  messageTitle: "Upload failure",
                  messageContent: `0x${res.errorCode
                    .toString("16")
                    .padStart(8, "0")}: ${res.message}`,
                }));
          })
          .catch((error) => {
            console.log(error);

            showMessageDialog((prev) => ({
              ...prev,
              show: true,
              messageTitle: "Upload failure",
              messageContent: `0x${error.response.data.errorCode
                .toString("16")
                .padStart(8, "0")}: ${error.response.data.message}`,
            }));
          });

        inputRef.current.value = "";
      });
    }
  };
  const handleRefreshClick = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  const columns = [
    {
      name: "userName",
      label: t("case_list.manage_case.column_names.user_name"),
      options: {
        searchable: true,
        customBodyRenderLite: (dataIndex) =>
          rows ? (
            (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Badge
                  badgeContent={rows[dataIndex].accountType === 2 ? "R" : null}
                  color={"primary"}
                  overlap={"circular"}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  {getCachedAvatar(rows[dataIndex].userID) ? (
                    <Avatar src={getCachedAvatar(rows[dataIndex].userID)} />
                  ) : (
                    <Avatar
                      {...stringAvatar(rows[dataIndex].userName, {
                        fontSize: "14px",
                      })}
                    />
                  )}
                </Badge>

                {rows[dataIndex].userName}
              </Stack>
            ) ?? "-"
          ) : (
            <Skeleton sx={{ width: "100px" }} />
          ),
      },
    },
    {
      name: "view",
      label: t("case_list.manage_case.column_names.view"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          permissionColumnRender(dataIndex, 1),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data ? 1 : 0;
            const val2 = obj2.data ? 1 : 0;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "initiate",
      label: t("case_list.manage_case.column_names.initiate"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          permissionColumnRender(dataIndex, 2),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data ? 1 : 0;
            const val2 = obj2.data ? 1 : 0;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "manage",
      label: t("case_list.manage_case.column_names.manage"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          permissionColumnRender(dataIndex, 4),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data ? 1 : 0;
            const val2 = obj2.data ? 1 : 0;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "updateOn",
      label: t("case_list.manage_case.column_names.update_on"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          dateTimeColumnRender(dataIndex, "updateOn"),
      },
    },
    {
      name: "triggerBy",
      label: t("case_list.manage_case.column_names.update_reason"),
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex) =>
          rows ? (
            rows[dataIndex].triggerBy === 1 ? (
              t("case_list.manage_case.user_profile_updated")
            ) : (
              t("case_list.manage_case.security_rules_updated")
            )
          ) : (
            <Skeleton sx={{ width: "100px" }} />
          ),
      },
    },
  ];

  return (
    <div style={{ textAlign: "left" }}>
      <MUIDataTable
        title={t("case_list.manage_case.user_permission")}
        data={
          rows?.map((r) => ({
            ...r,
            view: r.permission & 1,
            initiate: r.permission & 2,
            manage: r.permission & 4,
          })) ?? Array.from(new Array(5))
        }
        columns={columns}
        options={{
          selectableRows: "none",
          download: false,
          print: false,
          viewColumns: false,
          filter: false,
          search: true,
          rowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 25, 100],
          sortOrder: { name: "userName", direction: "asc" },
          setTableProps: () => ({ size: "small" }),
          customToolbar: (displayData) => (
            <>
              <Tooltip title={t("case_list.manage_case.toolbar.refresh")}>
                <IconButton onClick={handleRefreshClick}>
                  <RefreshOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("case_list.manage_case.toolbar.download_permission")}
              >
                <IconButton onClick={handleDownloadClick}>
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("case_list.manage_case.toolbar.update_permission")}
              >
                <IconButton onClick={handleUploadClick}>
                  <CloudUploadIcon />
                  <VisuallyHiddenInput
                    ref={inputRef}
                    type="file"
                    onChange={(e) => handleUploadChange(e.target.files)}
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
          textLabels: {
            body: {
              noMatch: t("case_list.manage_case.toolbar.no_record_found"),
              toolTip: t("case_list.manage_case.toolbar.sort"),
            },
            pagination: {
              next: t("case_list.manage_case.toolbar.next_page"),
              previous: t("case_list.manage_case.toolbar.prev_page"),
              rowsPerPage: t("case_list.manage_case.toolbar.rows_per_page"),
              displayRows: t("case_list.manage_case.toolbar.display_rows"),
            },
            toolbar: {
              search: t("case_list.manage_case.toolbar.search"),
            },
          },
        }}
      />
    </div>
  );
};

const ManageCase = (props) => {
  const { setTitle } = props;
  const { uuid } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [caseTplMngInfo, setCaseTplMngInfo] = React.useState();
  const [messageDialog, setMessageDialog] = React.useState({
    show: false,
    messageTitle: "",
    messageContent: "",
    buttons: [],
  });
  const [noPermission, setNoPermission] = React.useState(false);

  const caseTplName = React.useMemo(() => {
    if (caseTplMngInfo != null) {
      return caseTplMngInfo.templateInfos.find((t) => t.enabled)?.name;
    } else if (state?.caseTplName) {
      return state.caseTplName;
    }

    return undefined;
  }, [state, caseTplMngInfo]);

  const getCaseTplManageInfo = () => {
    const url = urlJoin(resServerBaseUrl, "Case/GetCaseTplManageInfo");
    const data = { uuid: uuid, language: localStorage.getItem("i18nextLng") };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((res) => {
        if (res.succeeded) {
          // const tplInfos = res.result.templateInfos;
          // const permittedUsers = res.result.permittedUsers;

          // console.log(permittedUsers);
          setCaseTplMngInfo(res.result);
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.status === 403) {
          setNoPermission(true);
        }
      });
  };
  const handleMessageDialogClose = () => {
    setMessageDialog((prev) => ({ ...prev, show: false }));
  };
  const handleRefreshData = () => {
    setCaseTplMngInfo(undefined);
    getCaseTplManageInfo();
  };

  React.useEffect(() => {
    if (caseTplMngInfo != null) {
      const tplName = caseTplMngInfo.templateInfos.find((t) => t.enabled)?.name;

      setTitle(tplName);
    }
  }, [caseTplMngInfo]);

  React.useEffect(() => {
    setMessageDialog((prev) => ({
      ...prev,
      buttons: [
        {
          text: t("common.ok"),
          action: handleMessageDialogClose,
        },
      ],
    }));

    getCaseTplManageInfo();
  }, []);

  return (
    <div>
      {noPermission ? (
        <NoPermissionPage />
      ) : (
        <>
          <Breadcrumbs separator={<KeyboardArrowRightIcon />} sx={{ mx: 1 }}>
            <Link
              to={`/workspace/${uuid}`}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography variant={"body2"}>
                {caseTplName ?? (
                  <Skeleton variant={"rounded"} sx={{ width: "80px" }} />
                )}
              </Typography>
            </Link>
            <Typography variant={"body2"}>
              {t("case_list.manage_case.manage")}
            </Typography>
          </Breadcrumbs>
          <Stack direction={"column"} sx={{ m: { xs: 0.5, md: 2 } }} gap={2}>
            <Paper>
              <CaseTplTable
                rows={caseTplMngInfo?.templateInfos}
                caseTplName={caseTplName}
                showMessageDialog={setMessageDialog}
              />
            </Paper>
            <Paper>
              <FormTplTable
                rows={caseTplMngInfo?.formTplInfos}
                caseTplName={caseTplName}
              />
            </Paper>
            <Paper>
              <PermittedUserTable
                rows={caseTplMngInfo?.permittedUsers}
                caseTplName={caseTplName}
                onRefresh={handleRefreshData}
                showMessageDialog={setMessageDialog}
              />
            </Paper>
          </Stack>
          <SharedDialog
            isOpen={messageDialog.show}
            title={messageDialog.messageTitle}
            content={messageDialog.messageContent}
            buttons={messageDialog.buttons}
            onClose={handleMessageDialogClose}
          />
        </>
      )}
    </div>
  );
};

export default ManageCase;
