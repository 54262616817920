import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function SharedDialog(props) {
  const isOpen = props.isOpen;
  const title = props.title;
  const content = props.content;
  const onClose = props.onClose;
  const buttons = props.buttons;

  const handleBtnText = (text) => {
    if (!text) {
      return "Close";
    } else {
      return text;
    }
  };

  const handleDialog = (text) => {
    if (!text) {
      return "Error";
    } else {
      return text;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{handleDialog(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-line" }}>
          <div
            dangerouslySetInnerHTML={{ __html: handleDialog(content) }}
          ></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        {buttons.map((btn) => {
          return (
            <Button
              key={handleBtnText(btn.text)}
              fullWidth
              variant={btn.variant || "contained"}
              color={btn.color || "primary"}
              sx={{ mt: 2, mb: 2 }}
              onClick={btn.action}
            >
              {handleBtnText(btn.text)}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}
