import React from "react";
import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import "./LoadingSpinner.css";
import { DRAWER_WIDTH } from "../Constants";
import { useAuthentication } from "../providers/AuthenticationProvider";
import PropTypes from "prop-types";

export default function LoadingSpinner(props) {
  const { isOpen } = props;
  const [isAuthenticated] = useAuthentication();
  const theme = useTheme();

  return (
    <Box>
      <Backdrop
        sx={
          isAuthenticated
            ? {
                //position: 'absolute',
                left: { lg: DRAWER_WIDTH },
                zIndex: theme.zIndex.drawer + 10,
              }
            : null
        }
        open={isOpen}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}

LoadingSpinner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
