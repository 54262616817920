import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
//try enhance adminconsole
import TextField from "@mui/material/TextField";

import urlJoin from "url-join";
import { useTranslation } from "react-i18next";
//Add breadcrumb in admin console
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import AdminConsoleRouting from "./AdminConsoleRouting";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import { CallApiWithContext } from "../../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../../Config";
import { useAuthentication } from "../../../providers/AuthenticationProvider";
import { useProfile } from "../../../providers/ProfileProvider";
import { MODULE_PERMISSIONS } from "../../../Constants";

export default function AdministrativeConsole(props) {
  const { settitle: setTitle } = props;
  const location = useLocation();
  const { page } = useParams();
  const { t } = useTranslation();
  const nav = useNavigate();
  const [profile] = useProfile();

  const [tabValue, setTabValue] = React.useState("administrativeConsole");
  const [isLoading, setIsLoading] = React.useState(false);

  //try enhance adminConsole
  const [routingOptions, setRoutingOptions] = React.useState([]);

  const handleAutoCompleteOptionSelected = (event, value) => {
    if (value) {
      nav(`${process.env.PUBLIC_URL}/admin-console/${value.url}`);
    }
  };

  React.useEffect(() => {
    const items = [];

    if (profile) {
      setIsLoading(false);

      if (
        profile.security["User_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.User_Setting) > 0
      ) {
        items.push({
          guid: "1235",
          key: "User_Setting",
          header: t("administrative_console.user"),
          iconName: "Person",
          url: "user",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["Proxy_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Proxy_Setting) > 0
      ) {
        items.push({
          guid: "1232",
          key: "Proxy_Setting",
          header: t("administrative_console.proxy-delegation"),
          iconName: "ProxyDelegation",
          url: "proxy-delegation",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["Connection_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Connection_Setting) > 0
      ) {
        items.push({
          guid: "1232",
          key: "Connection_Setting",
          header: t("administrative_console.connection"),
          iconName: "Connection",
          url: "connection",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["Email_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Email_Setting) > 0
      ) {
        items.push({
          guid: "1233",
          key: "Email_Setting",
          header: t("administrative_console.email"),
          iconName: "Mail",
          url: "email",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["Import_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Import_Setting) > 0
      ) {
        items.push({
          guid: "1231",
          key: "Email_Setting",
          header: t("administrative_console.import"),
          iconName: "Import",
          url: "import",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["Report_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Report_Setting) > 0
      ) {
        items.push({
          guid: "1236",
          key: "Report_Setting",
          header: t("administrative_console.report"),
          iconName: "Assessment",
          url: "report",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["Group_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Group_Setting) > 0
      ) {
        items.push({
          guid: "1237",
          key: "Group_Setting",
          header: t("administrative_console.group"),
          iconName: "Group",
          url: "group",
          childTreeNodeObjects: [],
        });
      }
      if (
        profile.security["PasswordPolicy_Setting"] == "Read_Write" &&
        (profile.modulePermissions & MODULE_PERMISSIONS.Password_Policy) > 0
      ) {
        items.push({
          guid: "1237",
          key: "PasswordPolicy_Setting",
          header: t("administrative_console.password-policy"),
          iconName: "Password",
          url: "password-policy",
          childTreeNodeObjects: [],
        });
      }

      if ((profile.modulePermissions & MODULE_PERMISSIONS.License) > 0) {
        items.push({
          guid: "1238",
          key: "License",
          header: t("administrative_console.license"),
          iconName: "Password",
          url: "license",
          childTreeNodeObjects: [],
        });
      }

      //try enhance adminConsole
      setRoutingOptions(
        items.map((i) => ({ label: i.header, key: i.key, url: i.url }))
      );

      // if page not exist in items, then it returns to not select page.
      // to prevent user read no permission page.
      if (page && !items.find((i) => i.url === page.toLowerCase())) {
        nav(`${process.env.PUBLIC_URL}/admin-console/`);
      }
    } else {
      setIsLoading(true);
    }
  }, [profile]);

  return (
    <Box id="AdminConsoleBox" style={{ height: "inherit", flex: 1 }} xs={12}>
      <TabContext value={tabValue}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
        >
          <Tab
            label={t("profile.profile")}
            component={Link}
            to="/profile"
            value="profile"
          />
          <Tab
            label={t("profile.administrative_console")}
            component={Link}
            to="/admin-console"
            value="administrativeConsole"
          />
        </Tabs>

        {(profile?.modulePermissions & MODULE_PERMISSIONS.AdminConsole) > 0 ? (
          <TabPanel
            value="administrativeConsole"
            sx={{
              height: "100%",
              width: "100%",
              p: { xs: "10px", sm: "20px" },
            }}
          >
            <Grid container id="AdminConsoleContainer" direction="column">
              <Grid
                container
                direction="row"
                id="AutocompleteGridContainer"
                style={{
                  display: "flex",
                }}
              >
                <Grid xs={12} item id="AutocompleteGridItem_Autocomplete">
                  <Autocomplete
                    xs={12}
                    id="settingAutoComplete"
                    name="settingAutoComplete"
                    onChange={(event, value) =>
                      handleAutoCompleteOptionSelected(event, value)
                    }
                    autoComplete="off"
                    options={routingOptions}
                    value={routingOptions?.find((r) => r.url === page)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        defaultValue={
                          routingOptions?.find((r) => r.url === page)?.label
                        }
                        label={t("administrative_console.select_setting")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item id="SpacingGrid" sx={{ height: "20px" }}></Grid>
              <Grid
                item
                xs={12}
                id="routingBox"
                sx={{
                  height: "auto",
                  borderRadius: 1,
                  bgcolor: page ? "background.paper" : null,
                }}
                style={{
                  overflow: "auto",
                }}
              >
                {/* {routing} */}
                <AdminConsoleRouting routing={page} />
              </Grid>
            </Grid>
          </TabPanel>
        ) : isLoading ? null : (
          <Grid>
            {t("profile.no_permission_to_access_administrative_console")}
          </Grid>
        )}
      </TabContext>
      <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
    </Box>
  );
}
