import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import HorizontalRule from "@mui/icons-material/HorizontalRule";
import utc from "dayjs/plugin/utc";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

dayjs.extend(utc);

const CriteriaComboBox = (props) => {
  const {
    columnName,
    operatorEnum,
    type,
    AddRow,
    DeleteRow,
    handleCriteriaChange,
    rowsPerPage,
    criteriaRows,
    dropdownDataObj,
  } = props;
  const { t } = useTranslation();
  const [availablePage, setAvailablePage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const handleCriteriaPageChange = (e, value) => {
    console.log(value);
    setCurrentPage(() => value);
  };

  React.useEffect(() => {
    let arr = criteriaRows[columnName];
    let avaPage = availablePage;
    if ((arr?.length ?? 0) >= availablePage * rowsPerPage) {
      setAvailablePage(avaPage + 1);
    }
    if (
      availablePage > 1 &&
      (criteriaRows[columnName]?.length ?? 0) < (avaPage - 1) * rowsPerPage + 1
    ) {
      avaPage -= 1;
      setAvailablePage(avaPage);
    }
    if (currentPage > 1 && currentPage > avaPage) {
      setCurrentPage(currentPage - 1);
    }
  }, [criteriaRows]);

  const CriteriaField = (operatorEnum, type) => {
    let typeForSwitch = type;
    if (
      columnName.toLowerCase() === "decision" ||
      columnName.toLowerCase() === "milestone" ||
      columnName.toLowerCase() === "case initiator"
    ) {
      typeForSwitch = "ExtraData";
    }

    const textOperatorType = () => {
      switch (parseInt(operatorEnum)) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          return textField(type, 0);
        case 15:
          return (
            <Grid container item direction={"column"}>
              <Grid item>{textField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{textField(type, 1)}</Grid>
            </Grid>
          );
        case 14:
          return (
            <>
              {(criteriaRows[columnName] ?? [])
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  (currentPage - 1) * rowsPerPage + rowsPerPage
                )
                .map((item, i) => (
                  <Grid
                    container
                    item
                    key={(currentPage - 1) * rowsPerPage + i}
                    pb={1}
                  >
                    <Grid item>
                      {textField(type, (currentPage - 1) * rowsPerPage + i)}
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          AddRow(columnName);
                        }}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          DeleteRow(
                            (currentPage - 1) * rowsPerPage + i,
                            columnName
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              <Grid container item mt={1}>
                <Pagination
                  count={availablePage}
                  variant="outlined"
                  onChange={(e, v) => handleCriteriaPageChange(e, v)}
                />
              </Grid>
            </>
          );
        default:
          return textField(type, 0);
      }
    };
    const dropdownOperatorType = () => {
      switch (parseInt(operatorEnum)) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          return <Grid item>{dropdownField(type, 0)}</Grid>;
        case 15:
          return (
            <Grid container item xs={"content"} direction={"column"}>
              <Grid item>{dropdownField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{dropdownField(type, 1)}</Grid>
            </Grid>
          );
        case 14:
          return (
            <>
              {criteriaRows[columnName]
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  (currentPage - 1) * rowsPerPage + rowsPerPage
                )
                .map((item, i) => (
                  <Grid
                    container
                    item
                    xs={"auto"}
                    key={(currentPage - 1) * rowsPerPage + i}
                    spacing={1}
                  >
                    <Grid item>
                      {dropdownField(type, (currentPage - 1) * rowsPerPage + i)}
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          AddRow(columnName);
                        }}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          DeleteRow(
                            (currentPage - 1) * rowsPerPage + i,
                            columnName
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              <Grid container item mt={1}>
                <Pagination
                  count={availablePage}
                  variant="outlined"
                  onChange={(e, v) => handleCriteriaPageChange(e, v)}
                />
              </Grid>
            </>
          );
        default:
          return <Grid item>{dropdownField(type, 0)}</Grid>;
      }
    };
    const dayOperatorType = () => {
      // let operator = parseInt(operatorEnum);
      // if (criteriaRows?.length ?? 0 > 1) {
      //   let startDate = dayjs(criteriaRows[index]);
      //   let endDate = dayjs(criteriaRows[index]);

      //   console.log(startDate.diff(endDate, "hour"));

      //   if (endDate.diff(startDate, "hour") == 23) {
      //     operator = 7;
      //   }
      // }
      switch (parseInt(operatorEnum)) {
        case 7:
        case 9:
        case 10:
        case 11:
        case 12:
          return dateField(type, 0);
        case 15:
          return (
            <Grid container item xs={"content"} direction={"column"}>
              <Grid item>{dateField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{dateField(type, 1)}</Grid>
            </Grid>
          );
        default:
          return dateField(type, 0);
      }
    };
    const textField = (type, index) => {
      return (
        <TextField
          fullWidth
          label={t("dashboard.criteria")}
          type={type}
          onBlur={(event) => {
            handleCriteriaChange(event.target.value, columnName, index);
          }}
          key={type + index}
          defaultValue={
            Object.keys(criteriaRows).includes(columnName)
              ? criteriaRows[columnName][index]
              : null
          }
        />
      );
    };
    const dateField = (type, index) => {
      return (
        <Grid container item alignSelf={"center"} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label={t("dashboard.criteria")}
              inputFormat="YYYY-MM-DD"
              onChange={(event) => {
                handleCriteriaChange(
                  event?.$d ?? false
                    ? dayjs(event.$d)
                        .startOf("date")
                        .format("YYYY-MM-DD HH:mm:ss")
                    : null,
                  columnName,
                  index
                );
              }}
              views={["year", "month", "day"]}
              value={
                Object.keys(criteriaRows).includes(columnName)
                  ? criteriaRows[columnName][index] == ""
                    ? null
                    : dayjs(
                        criteriaRows[columnName][index],
                        "YYYY-MM-DD HH:mm:ss"
                      )
                  : null
              }
              slotProps={{
                field: {
                  clearable: true,
                },
                clearButton: {
                  onClick: (e) => {
                    handleCriteriaChange(null, columnName, index);
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      );
    };
    const dropdownField = (type, index) => {
      return (
        <Grid container item alignSelf={"center"}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="dateType">
              {columnName.toLowerCase() === "case initiator"
                ? t(
                    "administrative_console.report_page.system_column.case_initiator"
                  )
                : t(
                    `administrative_console.report_page.system_column.${columnName.toLowerCase()}`
                  )}
            </InputLabel>
            <Select
              fullWidth
              id={"extraData" + index}
              value={
                Object.keys(criteriaRows).includes(columnName)
                  ? criteriaRows[columnName][index]
                  : null
              }
              input={
                <OutlinedInput
                  label={
                    columnName.toLowerCase() === "case initiator"
                      ? t(
                          "administrative_console.report_page.system_column.case_initiator"
                        )
                      : t(
                          `administrative_console.report_page.system_column.${columnName.toLowerCase()}`
                        )
                  }
                />
              }
              onChange={(e) =>
                handleCriteriaChange(e.target.value, columnName, index)
              }
            >
              {(dropdownDataObj[columnName.toLowerCase()] ?? []).map(
                (option) => (
                  <MenuItem
                    key={option.label}
                    value={option.value}
                    disabled={
                      Object.keys(criteriaRows).includes(columnName)
                        ? criteriaRows[columnName].includes(option)
                        : false
                    }
                  >
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      );
    };

    switch (typeForSwitch) {
      case "String":
      case "Number":
        return textOperatorType();
      case "ExtraData":
        return dropdownOperatorType();
      case "Date":
        return dayOperatorType();
      default:
        return textOperatorType();
    }
  };

  return CriteriaField(operatorEnum, type);
};

CriteriaComboBox.propTypes = {
  columnName: PropTypes.string.isRequired,
  operatorEnum: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  handleCriteriaPageChange: PropTypes.func.isRequired,
  AddRow: PropTypes.func.isRequired,
  DeleteRow: PropTypes.func.isRequired,
  handleCriteriaChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
  criteriaRows: PropTypes.object.isRequired,
  extraDataObj: PropTypes.object,
};

export default CriteriaComboBox;
