import * as React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Button,
  Stack,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import LockIcon from "@mui/icons-material/Lock";
import PauseIcon from "@mui/icons-material/Pause";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import PropTypes from "prop-types";
import urlJoin from "url-join";
import {
  Link,
  useParams,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

import LoadingSpinner from "../../shared/LoadingSpinner";
import SearchBar from "../SearchBar/SearchBar";
import SharedDialog from "../../shared/SharedDialog";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../Config";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import {
  ConvertUTCDateToLocal,
  ConvertLocalDateToUTC,
} from "../../shared/Utils";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import { useProfile } from "../../providers/ProfileProvider";
import RouteConfig from "../../RouteConfig";
import CustomToolbar from "../../shared/CustomToolbar";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, parseInt(page) - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, parseInt(page) + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Grid sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Grid>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const NoPermissionPage = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const width = isMdUp ? "60px" : "0px";

  return (
    <Typography sx={{ py: 5, px: 2 }} color="text.secondary" align="center">
      <LockIcon
        sx={{
          fontSize: 80,
          justifyContent: "center",
          marginRight: width,
        }}
      ></LockIcon>
      <br></br>
      <div
        style={{
          justifyContent: "center",
          marginRight: width,
        }}
      >
        {t("case_list.no_permission")}
      </div>
    </Typography>
  );
};

const CaseTplPermission = {
  None: 0,
  View: 1,
  Initiate: 2,
  Manage: 4,
};

const CaseList = (props) => {
  const { uuid, pages } = useParams();
  const nav = useNavigate();
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [totalRecordNum, setTotalRecordNum] = React.useState(0);
  const [page, setPage] = React.useState(pages && !isNaN(pages) ? pages : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [caseListObjects, setCaseListObjects] = React.useState([]);
  const [caselistName, setCaseListName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchData, setSearchData] = React.useState("");
  const setTitle = props.settitle;
  const setFormIdentity = props.setFormIdentity;
  const [isDisabledNewCaseButton, setIsDisabledNewCaseButton] =
    React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [noPermission, setNoPermission] = React.useState(false);
  const [caseTplPermission, setCaseTplPermission] = React.useState(0);

  const [profile] = useProfile();
  const themeColorContext = useThemeColor();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  dayjs.extend(utc);
  dayjs.extend(tz);

  const userTimezone = dayjs.tz.guess();

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const TableToolbar = () => {
    return (
      <Toolbar>
        <Tooltip title={t("inbox_outbox.refresh")}>
          <IconButton
            disabled={noPermission}
            onClick={() => {
              GetCaseList(authenticationContext, caseListObjects);
            }}
          >
            <RefreshIcon color="inherit" sx={{ display: "block" }} />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }} />
        {(caseTplPermission & CaseTplPermission.Manage) > 0 ? (
          <Tooltip title={"Manage"}>
            <Link to={`manage`} state={{ caseTplName: caselistName }}>
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </Link>
          </Tooltip>
        ) : null}
      </Toolbar>
    );
  };

  const noRecordUrl = (
    <TableRow>
      <TableCell sx={{ borderBottom: "none" }}>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          {isLoading
            ? t("inbox_outbox.load_text")
            : t("inbox_outbox.no_record")}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const unauthorized = () => {
    setCaseListName(t("case_list.no_permission_title"));
    setTitle(t("case_list.no_permission_title"));
    setNoPermission(true);
    setCaseListObjects([]);
    setIsDisabledNewCaseButton(true);
    setTotalRecordNum(0);
  };

  const GetCaseList = React.useCallback(() => {
    if (noPermission) {
      return;
    }

    setIsLoading(true);
    setCaseListObjects(null);
    const url = urlJoin(resServerBaseUrl, "/Case/GetCasesByTplUUID");
    const caseData = [];

    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify({
        searchCrtiria: searchData,
        startIndex: rowsPerPage * page ? rowsPerPage * page : 0,
        pageSize: rowsPerPage,
        uuid: uuid,
      })
    )
      .then((json) => {
        if (json.message === "No permission to Get Case List") {
          unauthorized();
        } else if (typeof json.message !== "undefined") {
          setIsDisabledNewCaseButton(true);
          setTotalRecordNum(0);
        } else {
          const caseTplInfo = json?.result;

          setCaseTplPermission(json?.caseTplPermission || 0);

          if (caseTplInfo?.caseLists && json !== "unauthorized") {
            setNoPermission(false);
            setIsDisabledNewCaseButton(false);
            setTotalRecordNum(json.result.totalRecordNum);

            caseTplInfo.caseLists.forEach((element) => {
              var caseListObject = {
                CaseID: element.id,
                CaseSID: element.sid,
                tplId: element.tplId,
                tplUUID: element.tplUUID,
                createUserName: element.createUserName,
                createUserId: element.createUserId,
                name: element.name,
                milestone: element.milestone,
                caseNumber: element.caseNumber,
                startDate: element.startDate,
                completeDate: element.completeDate,
                state: element.state,
                description: element.description,
                title: element.title,
              };
              caseData.push(caseListObject);
            });
            setCaseListObjects(
              caseData.sort((a, b) => {
                return new Date(b.startDate) - new Date(a.startDate);
              })
            );
            if (caseData != null) {
              setCaseListName(caseTplInfo.caseName);
            }
          } else {
            unauthorized();
          }
        }
      })
      .catch((error) => {
        setCaseListName("Case");
        setIsDisabledNewCaseButton(true);
        setCaseListObjects([]);
        setTotalRecordNum(0);

        if (error.status === 403) {
          unauthorized();
        } else {
          alert(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > 0) {
      nav({
        pathname: `/workspace/${uuid}/${newPage + 1}`,
        search: searchParams.toString(),
      });
    } else {
      nav({ pathname: `/workspace/${uuid}`, search: searchParams.toString() });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(Math.floor((page * rowsPerPage) / event.target.value));
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleAddNewCaseButtonOnClick = (event) => {
    setAlertDialog(true);
    setAlertContent(t("case_list.no_right_to_add_new_case_message"));
    setAlertTitle(t("profile.proxy_and_delegation.error"));
  };

  React.useEffect(() => {
    if (profile) {
      if (profile.licenseState === 1) {
        GetCaseList(authenticationContext, caseListObjects);
      } else {
        setNoPermission(true);
      }
    }
  }, [
    rowsPerPage,
    authenticationContext,
    searchData,
    page,
    uuid,
    profile,
    noPermission,
  ]);
  React.useEffect(() => {
    if (caselistName == null) {
      setTitle("Case");
    } else {
      setTitle(caselistName);
    }
    setFormIdentity({
      tplUUID: "",
      name: caselistName,
      taskId: null,
      inboxId: null,
      CaseSID: null,
      caseId: null,
      action: "",
      isOutbox: "",
    });
  }, [caselistName]);

  const setSearchBoxItem = React.useCallback((item) => {
    item.SearchByFromDate = item.SearchByFromDate
      ? item.SearchByFromDate.toJSON()
      : null;
    item.SearchByEndDate = item.SearchByEndDate
      ? item.SearchByEndDate.toJSON()
      : null;
    setSearchData(JSON.stringify(item));
    setPage(pages ? pages - 1 : 0); //reset to first page
  }, []);

  React.useEffect(() => {
    setNoPermission(false);
    var data = "";
    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        data += `"${key}":"${value}",`;
        setSearchData(searchParams ? `{${data}}` : "");
      });
    } else {
      setSearchData("");
    }
    setPage(pages ? pages - 1 : 0);
  }, [location]);

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          display: pages
            ? "flex"
            : location.pathname.split("/").pop() !== uuid
            ? "none"
            : "flex",
        }}
      >
        {/* search bar and toolbar (new button)*/}
        <CustomToolbar
          componentArr={[
            {
              size: "auto",
              component: (
                <>
                  {!isDisabledNewCaseButton &&
                  !noPermission &&
                  (caseTplPermission & CaseTplPermission.Initiate) > 0 ? (
                    <Link
                      aria-disabled={noPermission}
                      rel="Button"
                      style={{ color: "white" }}
                      to={`/case/${uuid}/new`}
                      onClick={localStorage.setItem(
                        "startPageURL",
                        location.pathname + location.search
                      )}
                    >
                      <Button
                        className="ActionButton"
                        color="primary"
                        variant="contained"
                      >
                        {t("case_list.new")}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      disabled
                      color="primary"
                      variant="contained"
                      className="ActionButton"
                    >
                      {t("case_list.new")}
                    </Button>
                  )}
                  <Tooltip title={t("inbox_outbox.refresh")}>
                    <IconButton
                      disabled={noPermission}
                      onClick={() => {
                        GetCaseList(authenticationContext, caseListObjects);
                      }}
                    >
                      <RefreshIcon color="inherit" sx={{ display: "block" }} />
                    </IconButton>
                  </Tooltip>
                  {isSmallDevice ? (
                    (caseTplPermission & CaseTplPermission.Manage) > 0 ? (
                      <Tooltip title={"Manage"}>
                        <Link
                          to={`manage`}
                          state={{ caseTplName: caselistName }}
                        >
                          <IconButton>
                            <SettingsIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    ) : null
                  ) : null}
                </>
              ),
            },
            {
              size: isSmallDevice ? 12 : 7,
              component: (
                <>
                  {noPermission ? null : <SearchBar ref={setSearchBoxItem} />}
                </>
              ),
            },
            {
              size: "auto",
              component: (
                <>
                  {isSmallDevice ? null : (caseTplPermission &
                      CaseTplPermission.Manage) >
                    0 ? (
                    <Tooltip title={"Manage"}>
                      <Link to={`manage`} state={{ caseTplName: caselistName }}>
                        <IconButton>
                          <SettingsIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  ) : null}
                </>
              ),
            },
          ]}
        />

        {/* main content */}
        <Grid container className="paper">
          <Grid item xs={12} sm={12}>
            {/* case list */}
            <TableContainer>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  overflow: "auto",
                  marginBottom: "40px",
                  // ...(caseListObjects?.length < 10
                  //   ? {}
                  //   : { marginBottom: "40px" }),
                }}
              >
                <Table aria-labelledby="tableTitle" className="mail">
                  <TableBody>
                    {noPermission ? (
                      <NoPermissionPage />
                    ) : caseListObjects?.length > 0 ? (
                      caseListObjects.map((item, index) => {
                        // console.log("item", item);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return !isSmallDevice ? (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                            className="mail-item"
                            sx={{
                              "& .MuiTableCell-root": {
                                padding: "16px",
                              },
                            }}
                          >
                            <TableCell width={30}>
                              {item.state === 1 ? (
                                <HourglassBottomIcon />
                              ) : item.state === 4 ? (
                                <DoneIcon />
                              ) : item.state === 9 ? (
                                <PauseIcon />
                              ) : item.state === 10 ? (
                                <CloseIcon />
                              ) : null}
                            </TableCell>
                            <TableCell className="mail-user" id={labelId}>
                              {item.createUserName}
                            </TableCell>

                            <TableCell
                              onClick={() => {
                                localStorage.setItem(
                                  "startPageURL",
                                  location.pathname + location.search
                                );
                              }}
                            >
                              <Link
                                to={`/case/${item.CaseSID}/`}
                                state={{ tplUUID: item.tplUUID }}
                                style={{
                                  color: "gray",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {item?.title ??
                                  `${item.name} [${item.caseNumber}]`}
                                {/* {item.name}
                              &nbsp; [{item.caseNumber}] */}
                              </Link>
                            </TableCell>

                            <TableCell
                              className=".mail-date"
                              sx={{ textAlign: "right" }}
                            >
                              {item.milestone}
                              &nbsp; &nbsp; &nbsp;
                              {ConvertUTCDateToLocal(new Date(item.startDate))}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <Table>
                            <TableRow
                              tabIndex={-1}
                              key={index}
                              className="mail-item"
                              sx={{
                                "& .MuiTableCell-root": {
                                  padding: "10px",
                                },
                              }}
                            >
                              <TableCell
                                rowSpan={2}
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                              >
                                {item.state === 1 ? (
                                  <HourglassBottomIcon fontSize="small" />
                                ) : item.state === 4 ? (
                                  <DoneIcon fontSize="small" />
                                ) : item.state === 9 ? (
                                  <PauseIcon fontSize="small" />
                                ) : item.state === 10 ? (
                                  <CloseIcon fontSize="small" />
                                ) : null}
                              </TableCell>
                              <TableCell
                                className="mail-user"
                                id={labelId}
                                sx={{ borderBottom: 0, paddingBottom: 0 }}
                              >
                                {item.createUserName}
                              </TableCell>

                              <TableCell
                                className=".mail-date"
                                sx={{
                                  textAlign: "right",
                                  borderBottom: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                }}
                              >
                                {item.milestone}
                                &nbsp; &nbsp;
                                {dayjs
                                  .utc(item.startDate)
                                  .tz(userTimezone)
                                  .format("DD/MM/YYYY HH:mm")}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              tabIndex={-1}
                              key={index}
                              className="mail-item"
                            >
                              <TableCell
                                colSpan={3}
                                onClick={() => {
                                  localStorage.setItem(
                                    "startPageURL",
                                    location.pathname + location.search
                                  );
                                }}
                              >
                                <Link
                                  to={`/case/${item.CaseSID}/`}
                                  state={{ tplUUID: item.tplUUID }}
                                  style={{
                                    color: "gray",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {item?.title ??
                                    `${item.name} [${item.caseNumber}]`}
                                  {/* {item.name}
                                                    &nbsp; [{item.caseNumber}] */}
                                </Link>
                              </TableCell>
                            </TableRow>
                          </Table>
                        );
                      })
                    ) : (
                      noRecordUrl
                    )}
                  </TableBody>
                </Table>
              </Grid>
              {/* pagination */}
              <TablePagination
                component="div"
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                labelRowsPerPage={t("inbox_outbox.display_row")}
                count={totalRecordNum}
                rowsPerPage={rowsPerPage}
                page={page}
                sx={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  overflow: "hidden",
                  width: "100%",
                  bgcolor: `mainContainer.${themeColorContext[0]}`,
                  justifyContent: "right",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableContainer>
            <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
          </Grid>
        </Grid>
      </Stack>
      {location.pathname.split("/").pop() !== uuid &&
      location.pathname.split("/").pop() !== pages ? (
        <Outlet />
      ) : null}
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </>
  );
};
CaseList.propTypes = {
  settitle: PropTypes.func.isRequired,
  setFormIdentity: PropTypes.func.isRequired,
};
export default CaseList;
