import * as React from "react";

import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

const HightlightWords = (props) => {
  const originalWord = props.originalWord;
  const highlightWord = props.highlightWord;

  return (
    <React.Fragment key={"highLight"}>
      {highlightWord === "" ? (
        <Grid item>
          <Typography variant="p">{originalWord}</Typography>
        </Grid>
      ) : (
        originalWord.split(highlightWord).map((word, index) => {
          if (index === originalWord.split(highlightWord).length - 1) {
            return (
              <Typography variant="p" key={`highlight_word_${index}`}>
                {word}
              </Typography>
            );
          } else {
            return (
              <React.Fragment key={`highlight_word_${index}`}>
                <Typography variant="p">{word}</Typography>
                <Typography variant="p" bgcolor={"rgba(225, 235,23, 0.5)"}>
                  {highlightWord}
                </Typography>
              </React.Fragment>
            );
          }
        })
      )}
    </React.Fragment>
  );
};
HightlightWords.propTypes = {
  originalWord: PropTypes.string.isRequired,
  highlightWord: PropTypes.string.isRequired,
};

export default HightlightWords;
