import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PageNotFound = (props) => {
  const { setTitle } = props;
  const { t } = useTranslation();

  if (setTitle) {
    setTitle(t("common.PageNotFound"));
  }

  return (
    <div style={{ padding: "20px" }}>
      <SearchOffIcon sx={{ fontSize: 80, color: "text.secondary" }} />
      <Typography>{t("common.PageNotFound")}</Typography>
    </div>
  );
};

export default PageNotFound;
