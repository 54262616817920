import * as React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import useEvent from "react-use-event-hook";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "react-use-size";
import * as wjCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjcInput from "@grapecity/wijmo.input";
import * as wjcGridSearch from "@grapecity/wijmo.grid.search";
import { ComboBox } from "@grapecity/wijmo.input";
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";
// import "@grapecity/wijmo.styles/themes/wijmo.theme.dark.css";

import "./InlineDataGrid.css";
import { DRAWER_WIDTH } from "../../Constants";

const InlineDataGrid = (props) => {
  const {
    columns,
    value = [],
    readonly = false,
    onChange = null,
    title = null,
    allowAddRemove = true,
    name,
    getBoolCondition = null,
  } = props;
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMlUp = useMediaQuery(theme.breakpoints.up("ml"));
  const { width: bodyWidth } = useWindowSize();
  const [initialized, setInitialized] = React.useState(false);
  const rows = React.useRef();
  const baseDiv = React.useRef();
  const mainGrid = React.useRef();
  const multiColEditors = React.useRef({});
  const columnNames = React.useMemo(() => {
    return columns.map((c) => c.key);
  }, [columns]);

  const initDropDownDataGrid = (theCombo, column) => {
    if (!theCombo.dropDownGrid) {
      // it's multiple column combo box.
      if (column) {
        const itemsSource = column.itemsSource;
        const gridCols =
          itemsSource?.length > 0
            ? Object.keys(itemsSource[0]).map((c) => ({
                binding: c,
                header: c,
                width: 120,
              }))
            : [];
        const theGrid = new wjcGrid.FlexGrid(
          wjCore.createElement(
            "<div style='max-height: 190px'></div>",
            theCombo.dropDown
          ),
          {
            itemsSource: itemsSource,
            stickyHeaders: true,
            headersVisibility: wjcGrid.HeadersVisibility.Column,
            selectionMode: wjcGrid.SelectionMode.Row,
            isReadOnly: true,
            autoGenerateColumns: gridCols === null,
            columns: gridCols,
          }
        );
        const searchBox = new wjcGridSearch.FlexGridSearch(
          document.createElement("div"),
          {
            placeholder: "",
            grid: theGrid,
          }
        );

        theGrid.hostElement.addEventListener("mousedown", (e) => {
          const hti = theGrid.hitTest(e);

          if (hti.panel && hti.panel.cellType == wjcGrid.CellType.Cell) {
            theCombo.text = hti.panel.getCellData(
              hti.row,
              theCombo.displayMemberPath
            );
            theCombo.isDroppedDown = false;
          }
        });
        theGrid.hostElement.addEventListener("click", (e) => {
          let hti = theGrid.hitTest(e);

          if (!hti.panel || hti.panel.cellType !== wjcGrid.CellType.Cell) {
            e.preventDefault();
            e.stopPropagation();
          }
        });

        theGrid.keepEditOnSelect = true;
        theCombo.dropDownGrid = theGrid;
        theCombo.searchBox = searchBox;
      }
    }
  };
  const handleGridInitialized = useEvent((s, e) => {
    console.log("grid initialized");
  });
  const handleGridDeletedRow = useEvent((s, e) => {
    if (
      columnNames.some((c) => c === "RowNo") &&
      s.collectionView.itemCount > e.row
    ) {
      for (let i = e.row; i < s.collectionView.itemCount; i++) {
        s.collectionView.items[i]["RowNo"] = i + 1;
      }
    }
    if (onChange) {
      onChange({
        target: {
          values: s.collectionView.sourceCollection,
        },
      });
    }
  });
  const handleGridRowAdded = useEvent((s, e) => {
    if (columnNames.some((c) => c === "RowNo")) {
      s.setCellData(e.row, "RowNo", s.collectionView.itemCount);
    }
  });
  const handleGridBeginningEdit = useEvent((s, e) => {
    const col = s.columns[e.col];
    const theCombo = multiColEditors.current[col.binding];

    if (theCombo) {
      const column = columns.find((c) => c.key === col.binding);

      if (!theCombo.dropDownGrid) {
        initDropDownDataGrid(theCombo, column);
      } else {
        // theCombo.dropDownGrid.itemsSource = column.itemsSource;
      }
    }
  });
  const handleGridCellEditEnded = useEvent((s, e) => {
    let col = s.columns[e.col];
    const oldVal = e.data;
    const newVal = s.getCellData(e.row, e.col);

    console.log(`${col.binding} with value ${newVal}`);

    if (oldVal != newVal) {
      if (onChange) {
        onChange({
          target: {
            row: e.row,
            columnName: col.binding,
            value: newVal,
            values: s.collectionView.sourceCollection,
          },
        });
      }
    }
  });
  const handleGridPasted = useEvent((s, e) => {
    if (onChange) {
      onChange({
        target: {
          values: s.collectionView.sourceCollection,
        },
      });
    }
  });
  const createItemsSource = (value) => {
    const view = new wjCore.CollectionView(value);

    const createNewRow = () => {
      const newRow = Object.fromEntries(columnNames.map((c) => [c, null]));

      return { ...newRow, id: uuidv4() };
    };

    view.collectionChanged.addHandler((s, e) => {
      switch (e.action) {
        case wjCore.NotifyCollectionChangedAction.Add:
          e.item.id = uuidv4();
          break;
        case wjCore.NotifyCollectionChangedAction.Reset:
          // if (onChange) {
          //   onChange({
          //     target: {
          //       row: null,
          //       columnName: "",
          //       value: null,
          //       values: rows.current,
          //     },
          //   });
          // }
          break;
      }
    });

    return view;
  };
  const createColumn = (c) => {
    const baseWidth = 130;

    const raiseCustomEvent = (ele, eventName) => {
      let event;
      if (typeof Event === "function") {
        event = new Event(eventName);
      } else {
        event = document.createEvent("Event");
        event.initEvent(eventName, true, true);
      }
      ele.dispatchEvent(event);
    };
    const buildDataMap = (values) => {
      return new wjcGrid.DataMap(values, "value", "label");
    };
    const createMultiColumnComboBox = (control) => {
      let displayMember = control.properties?.displayMember;
      let valueMember = control.properties?.valueMember;
      const itemsSource = control.itemsSource;

      if (itemsSource?.length > 0) {
        const columnNames = Object.keys(itemsSource[0]);

        if (!columnNames.some((c) => c === displayMember)) {
          displayMember = columnNames[0];
        }
        if (!columnNames.some((c) => c === valueMember)) {
          valueMember = columnNames[0];
        }
      }

      const theCombo = new ComboBox(document.createElement("div"), {
        headerPath: displayMember,
        displayMemberPath: displayMember,
        selectedValuePath: valueMember,
        itemsSource: itemsSource,
        isRequired: false,
        isEditable: true,
        dropDownCssClass: "multicol-combobox-dropdown",
        isAnimated: false,
      });

      theCombo.inputElement.addEventListener("keydown", (e) => {
        if (!theCombo.dropDownGrid) {
          const col = Object.keys(multiColEditors.current).find(
            (k) => !!multiColEditors.current[k]
          );
          const column = columns.find((c) => c.key === col);

          initDropDownDataGrid(theCombo, column);
        }

        const theGrid = theCombo.dropDownGrid;

        if (theGrid) {
          theGrid.startEditing();

          if (!theCombo.isDroppedDown) {
            theCombo.isDroppedDown = true;
          }

          switch (e.key) {
            case "ArrowDown": {
              let rowIndex = -1;

              if (theGrid.selectedRows.length > 0) {
                rowIndex = theGrid.selectedRows[0].index;
              }
              if (theGrid.rows.length > rowIndex + 1) {
                rowIndex++;
                theGrid.select(
                  new wjcGrid.CellRange(rowIndex, 0, rowIndex, 0),
                  true
                );
              }
              if (theGrid.rows.length > rowIndex && rowIndex > -1) {
                theCombo.selectedIndex = theCombo.itemsSource.indexOf(
                  theGrid.rows[rowIndex].dataItem
                );
              }
              e.returnValue = false;
              e.preventDefault();

              return false;
            }
            case "ArrowUp": {
              let rowIndex = theGrid.rows.length;

              if (theGrid.selectedRows.length > 0) {
                rowIndex = theGrid.selectedRows[0].index;
              }

              if (rowIndex > 0) {
                rowIndex -= 1;
                // ** modified the wijmo.grid.min.js code to support select drop down grid
                // and won't end the edit immediately.
                theGrid.select(
                  new wjcGrid.CellRange(rowIndex, 0, rowIndex, 0),
                  true
                );
              }

              if (theGrid.rows.length > rowIndex) {
                theCombo.selectedIndex = theCombo.itemsSource.indexOf(
                  theGrid.rows[rowIndex].dataItem
                );
              }

              e.returnValue = false;
              e.preventDefault();

              return false;
            }
            case "Backspace": {
              const input = e.currentTarget;
              let text = input.value;

              if (input.selectionStart >= 0) {
                let startPos = input.selectionStart;
                let endPos = input.selectionEnd;
                if (startPos === endPos) {
                  input.value =
                    text.substring(0, startPos - 1) + text.substring(endPos);
                } else {
                  input.value =
                    text.substring(0, startPos) + text.substring(endPos);
                }
                input.selectionStart = startPos - 1;
                input.selectionEnd = input.selectionStart;
              }

              raiseCustomEvent(input, "search_input");

              e.returnValue = false;
              e.preventDefault();

              return false;
            }
            case "Delete": {
              const input = e.currentTarget;
              let text = input.value;

              if (input.selectionStart !== undefined) {
                let startPos = input.selectionStart;
                let endPos = input.selectionEnd;

                if (startPos === endPos) {
                  input.value =
                    text.substring(0, startPos) + text.substring(endPos + 1);
                } else {
                  input.value =
                    text.substring(0, startPos) + text.substring(endPos);
                }

                input.selectionStart = startPos;
                input.selectionEnd = input.selectionStart;
              } else {
                input.value = text.substring(0, text.length - 1);
              }

              raiseCustomEvent(input, "search_input");
              e.returnValue = false;
              e.preventDefault();

              return false;
            }
            default: {
              if (!e.ctrlKey && !e.shiftKey && e.key.length === 1) {
                theGrid.startEditing();
                const input = theCombo.inputElement;
                if (input.selectionStart !== undefined) {
                  let startPos = input.selectionStart;
                  let endPos = input.selectionEnd;
                  let text = input.value;
                  input.value =
                    text.substring(0, startPos) +
                    e.key +
                    text.substring(endPos);
                  input.selectionStart = startPos + 1;
                  input.selectionEnd = input.selectionStart;
                } else {
                  input.value += e.key;
                }
                raiseCustomEvent(input, "search_input");
                e.returnValue = false;
                e.preventDefault();
                return false;
              }
              break;
            }
          }
        }
      });
      theCombo.inputElement.addEventListener("search_input", (e) => {
        const theGrid = theCombo.dropDownGrid;
        const theSearchBox = theCombo.searchBox;

        if (theGrid && theSearchBox) {
          theSearchBox.text = theCombo.inputElement.value;

          if (theCombo.inputElement.value === "") {
            theGrid.select(new wjcGrid.CellRange(-1, 0, -1, 0), true);
            theCombo.selectedIndex = -1;
          }
        }
      });
      theCombo.isDroppedDownChanging.addHandler((s, e) => {
        if (!s.isDroppedDown && s.dropDownGrid) {
          const dropDownGrid = s.dropDownGrid;

          if (mainGrid.current?.control) {
            const inlineGrid = mainGrid.current.control;
            const selection = inlineGrid.selection;

            if (inlineGrid.columns[selection.col].binding === "ContactPerson") {
              const curRow = inlineGrid.collectionView.currentItem;

              dropDownGrid.collectionView.filter = (item) => {
                return item.SupplierCode === curRow.SupplierCode;
              };
            }
          }
        }
      });
      theCombo.isDroppedDownChanged.addHandler((s, e) => {
        if (s.isDroppedDown && !s.dropDownGrid) {
          const col = Object.keys(multiColEditors.current).find(
            (k) => !!multiColEditors.current[k]
          );
          const column = columns.find((c) => c.key === col);

          if (column) initDropDownDataGrid(s, column);
        }

        const dropDownGrid = theCombo.dropDownGrid;
        const theSearchBox = theCombo.searchBox;

        if (s.isDroppedDown && dropDownGrid) {
          theSearchBox.text = "";

          dropDownGrid.selectedRows.forEach((row, index, array) => {
            row.isSelected = false;
          });

          if (s.selectedIndex >= 0) {
            const selectedRow = dropDownGrid.rows.find((r) => {
              return (
                r.dataItem ===
                dropDownGrid.collectionView.sourceCollection[s.selectedIndex]
              );
            });

            if (selectedRow) {
              dropDownGrid.select(
                new wjcGrid.CellRange(
                  selectedRow.dataIndex,
                  0,
                  selectedRow.dataIndex,
                  0
                ),
                true
              );
            }
          } else {
            dropDownGrid.scrollPosition = new wjCore.Point(
              dropDownGrid.scrollPosition.x,
              0
            );
          }
        }
      });
      theCombo.selectedIndexChanged.addHandler((s) => {
        const theGrid = theCombo.dropDownGrid;

        if (s.isDroppedDown && theGrid) {
          theGrid.selectedRows.forEach((row) => {
            row.isSelected = false;
          });

          if (s.selectedIndex >= 0) {
            const selectedRow = theGrid.rows.find((r) => {
              return (
                r.dataItem ===
                theGrid.collectionView.sourceCollection[s.selectedIndex]
              );
            });

            if (selectedRow) {
              selectedRow.isSelected = true;

              const rc = theGrid.cells.getCellBoundingRect(
                selectedRow.index,
                0,
                true
              );
              theGrid.scrollPosition = new wjCore.Point(
                theGrid.scrollPosition.x,
                -rc.top
              );
            }
          } else {
            theGrid.scrollPosition = new wjCore.Point(
              theGrid.scrollPosition.x,
              0
            );
          }
        }
      });

      multiColEditors.current[control.key] = theCombo;

      return theCombo;
    };
    const getDataType = () => {
      if (c.key.toUpperCase() === "ROWNO") {
        return wjCore.DataType.Number;
      }

      switch (c.type) {
        case "number":
          return wjCore.DataType.Number;
        case "datetime":
          return wjCore.DataType.Date;
        case "checkbox":
          return wjCore.DataType.Boolean;
      }

      return wjCore.DataType.String;
    };
    const getAlignment = () => {
      if (c.key.toUpperCase() === "ROWNO") {
        return "right";
      }

      switch (c.type) {
        case "number":
          return "right";
        case "checkbox":
          return "center";
        default:
          return "left";
      }
    };
    const getDataMap = () => {
      if (c.type === "select" && c.values?.length > 0) {
        return buildDataMap(c.values);
      }

      return undefined;
    };
    const getHeader = () => {
      return `${c.dateLabel || c.label}${c.validate?.required ? " *" : ""}`;
    };
    const getEditor = () => {
      switch (c.type) {
        case "datetime":
          switch (c.subtype) {
            case "date":
              return new wjcInput.InputDate(document.createElement("div"), {
                isRequired: c.validate?.required || false,
              });
            case "time":
              return new wjcInput.InputTime(document.createElement("div"), {
                isRequired: c.validate?.required || false,
              });
            default:
              return new wjcInput.InputDateTime(document.createElement("div"), {
                isRequired: c.validate?.required || false,
              });
          }
        case "number":
          return new wjcInput.InputNumber(document.createElement("div"), {
            format: getFormat(),
            isRequired: c.validate?.required || false,
          });
        case "select":
          if (c.itemsSource) {
            return createMultiColumnComboBox(c);
          }

          return undefined;
        default:
          return undefined;
      }
    };
    const getFormat = () => {
      switch (c.type) {
        case "number":
          // if (c.label?.toLocaleLowerCase().includes("total")) return "F2";

          if (c.label.includes("%")) {
            return `p${c.decimalDigits ?? 0}`;
          } else if (c.decimalDigits !== undefined) {
            return `F${c.decimalDigits}`;
          }

          return "F0";
        case "datetime":
          switch (c.subtype) {
            case "date":
              return "dd/MM/yyyy";
            case "time":
              return "HH:mm:ss";
            default:
              return "dd/MM/yyyy HH:mm:ss";
          }
        default:
          return null;
      }
    };

    return (
      <FlexGridColumn
        align={getAlignment()}
        binding={c.key}
        dataType={getDataType()}
        dataMap={getDataMap()}
        editor={getEditor()}
        format={getFormat()}
        header={getHeader()}
        visible={!c.hide}
        isRequired={c.validate?.required || false}
        isReadOnly={
          c.key.toUpperCase() === "ROWNO" || readonly === true
            ? true
            : getBoolCondition
            ? getBoolCondition(c.readonly)
            : false
        }
        width={`${c.layout?.columns || 1}*`}
        minWidth={c.key.toUpperCase() === "ROWNO" ? 50 : 120}
        key={c.key}
      />
    );
  };
  const maxSize = () => {
    // form container padding
    const padding = 40 * 2;
    const formCntrEle = document.querySelector("#formContainer");
    // add the size of scrollbar if the json form longer than form container.
    const scrollbarWidth =
      formCntrEle?.scrollHeight > formCntrEle?.offsetHeight ? 17 : 0;
    // store the parent padding value. 112 is a magic value.
    let parentPadding = 112;

    // get the data grid total padding on every parent layer.
    if (baseDiv.current) {
      let parent = baseDiv.current.parentElement;

      parentPadding = 0;

      while (parent != null) {
        parentPadding +=
          parseFloat(getComputedStyle(parent).paddingLeft) +
          parseFloat(getComputedStyle(parent).paddingRight);
        if (parent.id === "jsonForm") {
          break;
        } else {
          parent = parent.parentElement;
        }
      }
    }

    // body - drawer - form container padding - scrollbar - all parent layer padding
    // form min width is 398 and max width is 998
    return (
      Math.min(
        Math.max(
          bodyWidth -
            (isMlUp ? DRAWER_WIDTH : 0) -
            (isMdUp ? DRAWER_WIDTH : 0) -
            padding -
            scrollbarWidth,
          398
        ),
        2000 //998 (original form size max is 1000 - border width)
      ) - parentPadding
    );
  };

  React.useEffect(() => {
    if (mainGrid.current) {
      const grid = mainGrid.current.control;
      const oldValue = value || [];
      const newValue = oldValue.map((r) => ({
        ...Object.fromEntries(columnNames.map((c) => [c, null])),
        id: uuidv4(),
        ...r,
      }));

      rows.current = newValue;

      if (!initialized) {
        setInitialized(true);

        grid.itemsSource = createItemsSource(newValue);
      } else {
        if (newValue.length !== grid.collectionView.sourceCollection.length) {
          grid.itemsSource = createItemsSource(newValue);
        } else {
          columns.forEach((column) => {
            const columnName = column.key;

            for (let rowIndex = 0; rowIndex < newValue.length; rowIndex++) {
              try {
                if (
                  (column.properties?.formula ||
                    column.properties?.dataSource) &&
                  grid.collectionView.sourceCollection[rowIndex][columnName] !==
                    newValue[rowIndex][columnName]
                ) {
                  grid.setCellData(
                    rowIndex,
                    columnName,
                    newValue[rowIndex][columnName]
                  );
                }
              } catch (ex) {}
            }
          });
        }
      }
    }

    return () => {
      const theGrid = mainGrid.current?.control;

      if (theGrid) {
        // grid.beginningEdit.removeHandler(handleGridBeginningEdit);
        // grid.cellEditEnded.removeHandler(handleGridCellEditEnded);
      }
    };
  }, [value, columnNames]);

  return (
    <div
      ref={baseDiv}
      style={{
        maxWidth: `${maxSize()}px`,
      }}
    >
      {title ? (
        <Grid item>
          <div
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
        </Grid>
      ) : null}
      <Grid container>
        <FlexGrid
          ref={mainGrid}
          autoGenerateColumns={false}
          allowAddNew={!readonly && allowAddRemove}
          allowDelete={!readonly && allowAddRemove}
          keyActionTab={wjcGrid.KeyAction.Cycle}
          newRowAtTop={false}
          showMarquee
          selectionMode={wjcGrid.SelectionMode.RowRange}
          beginningEdit={handleGridBeginningEdit}
          cellEditEnded={handleGridCellEditEnded}
          initialized={handleGridInitialized}
          deletedRow={handleGridDeletedRow}
          rowAdded={handleGridRowAdded}
          pasted={handleGridPasted}
          allowSorting={false}
        >
          <FlexGridColumn
            header={"id"}
            binding={"id"}
            visible={false}
            isReadOnly={true}
          />
          {columns.map((c) => createColumn(c))}
        </FlexGrid>
      </Grid>
    </div>
  );
};

export default InlineDataGrid;
