import * as React from "react";
import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef((props, ref) => {
  const {
    onChange,
    decimalDigits,
    serializeToString,
    prefix,
    suffix,
    ...other
  } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      decimalScale={decimalDigits}
      fixedDecimalScale
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: serializeToString ? values.value : values.floatValue,
            floatValue: values.floatValue,
          },
        });
      }}
      autoComplete="off"
      spellCheck={false}
      prefix={prefix}
      suffix={suffix}
      thousandSeparator
      valueIsNumericString={serializeToString}
    />
  );
});

const NumericBox = (props) => {
  const {
    onChange,
    onFocus,
    onBlur,
    InputProps,
    decimalDigits,
    defaultValue,
    serializeToString,
    helperText,
    min,
    max,
    ...other
  } = props;
  const [errorText, setErrorText] = React.useState(null);
  const [onChangeEvent, setOnChangeEvent] = React.useState();

  const setNumber = (number) => {
    onChange(number);
  };

  const generateValidateErrorText = () => {
    if (min !== undefined && max !== undefined) {
      return `The value must between ${min} and ${max}`;
    } else if (min !== undefined) {
      return `The value must larger than or equals to ${min}`;
    } else if (max !== undefined) {
      return `The value must smaller than or equals to ${max}`;
    }

    return null;
  };

  const getHelperText = () => {
    if (errorText) {
      return errorText;
    }

    return helperText;
  };

  const handleInputFocus = (e) => {
    e.target.oldValue = e.target.value;

    if (onFocus) {
      onFocus(onChangeEvent || e);
    }
  };
  const handleInputBlur = (e) => {
    if (onBlur) {
      const event = onChangeEvent || e;

      event.target.valueChanged = e.target.oldValue !== e.target.value;

      onBlur(event);
    }

    e.target.oldValue = undefined;
    e.target.valueChanged = undefined;
  };

  return (
    <TextField
      {...other}
      error={errorText !== null}
      helperText={getHelperText()}
      onChange={(n) => {
        if (min !== undefined && n.target.floatValue < min) {
          setErrorText(generateValidateErrorText());
          return;
        }
        if (max !== undefined && n.target.floatValue > max) {
          setErrorText(generateValidateErrorText());
          return;
        }
        setErrorText(null);
        setOnChangeEvent(n);
        setNumber(n);
      }}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      /*onChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: serializeToString ? values.value : values.floatValue,
          },
        });
      }}*/
      spellCheck={false}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...InputProps.inputProps,
          decimalDigits: decimalDigits,
          defaultValue: defaultValue,
          serializeToString: serializeToString,
        },
        inputComponent: NumericFormatCustom,
      }}
    />
  );
};

export default NumericBox;
