import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  OutlinedInput,
  Popper,
  Skeleton,
  TextField,
  Alert,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PropTypes from "prop-types";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogContent } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import urlJoin from "url-join";
import {
  CallApiWithContext,
  CallApiWithoutContext,
} from "../helpers/ApiHelper";
import { resServerBaseUrl, authServerBaseUrl } from "../Config";
import { useAuthentication } from "../providers/AuthenticationProvider";
import SharedDialog from "./SharedDialog";
import { useThemeColor } from "../providers/ThemeColorProvider";

const ValidatePassword = (pw, passwordRule, passwordLength, userLogin) => {
  const numericTest = /^(?=.*\d)(.{1,})$/;
  const symbolTest = /^(?=.*[@$!%*?&])(.{1,})$/;
  const alphabetircTest = /^(?=.*[a-z])(.{1,})|(?=.*[A-Z])(.{1,})$/;
  const upperLowerTest = /^(?=.*[a-z])(?=.*[A-Z])(.{2,})$/;

  //no need to check history

  const rule = {
    passwordMinLength: false,
    passwordAlphabeticRule: false,
    passwordUpperLowerCaseRule: false,
    passwordNumericRule: false,
    passwordSymbolsRule: false,
    passwordHistoryRule: false,
    passwordLoginRule: false,
  };

  if (passwordRule !== undefined) {
    Object.keys(passwordRule).map((e) => {
      switch (e) {
        case "passwordMinLength":
          if (
            passwordLength < passwordRule.passwordMinLength &&
            passwordRule[e] != 0
          ) {
            rule.passwordMinLength = true;
          } else {
            rule.passwordMinLength = false;
          }
          break;
        case "passwordAlphabeticRule":
          if (!alphabetircTest.test(pw) && passwordRule[e] == true) {
            rule.passwordAlphabeticRule = true;
          } else {
            rule.passwordAlphabeticRule = false;
          }
          break;
        case "passwordUpperLowerCaseRule":
          if (!upperLowerTest.test(pw) && passwordRule[e] == true) {
            rule.passwordUpperLowerCaseRule = true;
          } else {
            rule.passwordUpperLowerCaseRule = false;
          }
          break;
        case "passwordNumericRule":
          if (!numericTest.test(pw) && passwordRule[e] == true) {
            rule.passwordNumericRule = true;
          } else {
            rule.passwordNumericRule = false;
          }
          break;
        case "passwordSymbolsRule":
          if (!symbolTest.test(pw) && passwordRule[e] == true) {
            rule.passwordSymbolsRule = true;
          } else {
            rule.passwordSymbolsRule = false;
          }
          break;
        case "passwordLoginRule":
          if (!userLogin) {
            let passwordSplit = pw?.split("") ?? [];
            let loginSplit = userLogin?.split("") ?? [];
            const intersact = passwordSplit
              .filter((c, index) => {
                if (index < 3) {
                  return loginSplit.indexOf(c) !== -1;
                }
              })
              .join("");

            if (
              intersact.split("").length === 3 &&
              userLogin.indexOf(intersact) > -1 &&
              passwordRule[e] == true
            ) {
              rule.passwordLoginRule = true;
            } else {
              rule.passwordLoginRule = false;
            }
          }
          break;
      }
    });
  }

  return rule;
};

const Display = (
  isSmallDevice,
  passwordRule,
  passwordRuleValid,
  isAdminChange,
  passwordLength
) => {
  const { t } = useTranslation();
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item>
        <List>
          {passwordRule == null || passwordRule == undefined ? (
            <>
              <ListItem>
                <Skeleton
                  height={48}
                  width={isSmallDevice ? 250 : 500}
                ></Skeleton>
              </ListItem>
              <ListItem>
                <Skeleton
                  height={48}
                  width={isSmallDevice ? 250 : 500}
                ></Skeleton>
              </ListItem>
              <ListItem>
                <Skeleton
                  height={48}
                  width={isSmallDevice ? 250 : 500}
                ></Skeleton>
              </ListItem>
            </>
          ) : (
            Object.keys(passwordRule).map((e, index) => {
              switch (isAdminChange) {
                case true:
                  if (
                    (passwordRule[e] === true || passwordRule[e] > 0) &&
                    e !== "passwordHistoryRule" &&
                    e !== "passwordLoginRule"
                  ) {
                    return (
                      <ListItem key={index}>
                        <ListItemIcon>
                          {passwordLength === 0 ? (
                            <FiberManualRecordIcon color="primary" />
                          ) : passwordRuleValid[e] === false ? (
                            <CheckCircleIcon color="primary" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </ListItemIcon>
                        <ListItemText>
                          {t(`forgot_password_dialog.${e}`).replace(
                            "X",
                            passwordRule[e]
                          )}
                        </ListItemText>
                      </ListItem>
                    );
                  }
                  break;
                case false:
                  if (passwordRule[e] === true || passwordRule[e] > 0) {
                    if (e !== "passwordHistoryRule") {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon>
                            {e === "passwordLoginRule" ? (
                              <ErrorOutlineIcon color="primary" />
                            ) : passwordLength === 0 ? (
                              <FiberManualRecordIcon color="primary" />
                            ) : passwordRuleValid[e] === false ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </ListItemIcon>
                          <ListItemText>
                            {t(`forgot_password_dialog.${e}`).replace(
                              "X",
                              passwordRule[e]
                            )}
                          </ListItemText>
                        </ListItem>
                      );
                    } else {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <ErrorOutlineIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText>
                            {t(`forgot_password_dialog.${e}`).replace(
                              "X",
                              passwordRule[e]
                            )}
                          </ListItemText>
                        </ListItem>
                      );
                    }
                  }
                  break;
              }
            })
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export const ChangePasswordDialog = React.forwardRef((props, ref) => {
  const { rules, userUUID, userLogin, isAdminChange, LoginFunction } = props;
  const { t } = useTranslation();
  const [changePasswordDialog, setChangePasswordDialog] = React.useState(false);
  const [showCurrPassword, setShowCurrPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [isChangePwBtnLoading, setIsChangePwBtnLoading] = React.useState(false);
  const [isFieldDisable, setIsFieldDisable] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const passwordInputRef = React.useRef();
  const authenticationContext = useAuthentication();

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const onPasswordChanged = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePasswordDialogClose = () => {
    setIsChangePwBtnLoading(false);
    setIsFieldDisable(false);
    setChangePasswordDialog(false);
  };

  const handleChangePasswordDialogOpen = () => {
    setPassword(null);
    setNewPassword(null);
    setChangePasswordDialog(true);
  };

  const onNewPasswordChanged = (event) => {
    setNewPassword(event.target.value);
  };

  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const changePassword = (e) => {
    if (newPassword) {
      if (isAdminChange) {
        AdminChangePassword();
      } else {
        UserChangePassword(e);
      }
    }
  };

  const UserChangePassword = (e) => {
    setIsChangePwBtnLoading(true);
    setIsFieldDisable(true);
    var url = urlJoin(resServerBaseUrl, "/user/ChangePassword");
    var data = {
      CurrentPassword: password,
      NewPassword: newPassword,
    };
    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (response.message !== "Cannot Change Password") {
          setChangePasswordDialog(false);
          if (LoginFunction) {
            LoginFunction(e, newPassword);
          }
        } else {
          showAlertMessage({
            title: t("reset_password_dialog.change_password_failed"),
            content: t("reset_password_dialog.change_password_failed_content"),
            buttons: [
              {
                text: t("report.exist_dialog.close"),
                action: (e) => {
                  closeAlertMessage();
                  setChangePasswordDialog(false);
                },
              },
            ],
          });
        }
      })
      .catch((error) => {
        showAlertMessage({
          title: t("reset_password_dialog.change_password_failed"),
          content: t("reset_password_dialog.change_password_failed_content"),
          buttons: [
            {
              text: t("report.exist_dialog.close"),
              action: () => {
                closeAlertMessage();
                setChangePasswordDialog(false);
              },
            },
          ],
        });
      })
      .finally(() => {
        setIsFieldDisable(false);
        setIsChangePwBtnLoading(false);
      });
  };

  const AdminChangePassword = () => {
    setIsChangePwBtnLoading(true);
    setIsFieldDisable(true);
    const url = urlJoin(resServerBaseUrl, "/user/AdminChangePassword");
    const data = {
      UserUUID: userUUID,
      NewPassword: newPassword,
    };
    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if ((response?.message ?? "") === "Password Changed") {
          showAlertMessage({
            title: t("reset_password_dialog.change_password"),
            content: t("reset_password_dialog.change_password_successfully"),
            buttons: [
              {
                text: t("report.exist_dialog.close"),
                action: () => {
                  closeAlertMessage();
                  setIsChangePwBtnLoading(false);
                  setIsFieldDisable(false);
                  handleChangePasswordDialogClose();
                },
              },
            ],
          });
        } else if (
          (response?.message ?? "") ===
          "Unauthorized User cannot execute this action"
        ) {
          showAlertMessage({
            title: t("reset_password_dialog.change_password"),
            content: t("reset_password_dialog.change_password_failed"),
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  setIsChangePwBtnLoading(false);
                  handleChangePasswordDialogClose();
                },
              },
            ],
          });
        } else {
          //setAlertDialog(true);
          showAlertMessage({
            title: t("reset_password_dialog.change_password"),
            content: t("reset_password_dialog.change_password_failed"),
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  setIsChangePwBtnLoading(false);
                  setIsFieldDisable(false);
                  handleChangePasswordDialogClose();
                },
              },
            ],
          });
        }
      })
      .catch(() => {
        showAlertMessage({
          title: t("reset_password_dialog.change_password_failed"),
          content: t("reset_password_dialog.change_password_failed_content"),
          buttons: [
            {
              text: t("report.exist_dialog.close"),
              action: () => {
                closeAlertMessage();
                setChangePasswordDialog(false);
              },
            },
          ],
        });
      });
  };

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };

  React.useImperativeHandle(ref, () => ({
    openChangePasswordDialog: () => {
      handleChangePasswordDialogOpen();
    },
    closeChangePasswordDialog: () => {
      handleChangePasswordDialogClose();
    },
  }));

  return (
    <Dialog
      open={changePasswordDialog}
      onClose={handleChangePasswordDialogClose}
      maxWidth={"md"}
    >
      <DialogTitle>
        {isAdminChange
          ? t("profile.change_password")
          : t("forgot_password_dialog.reset_password")}
      </DialogTitle>
      <DialogContent>
        {isAdminChange ? null : (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("reset_password_dialog.old_password")}
            type={showCurrPassword ? "text" : "password"}
            fullWidth
            variant="standard"
            disabled={isFieldDisable}
            onChange={onPasswordChanged}
            error={newPassword == ""}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowCurrPassword(!showCurrPassword)}
                  edge="end"
                >
                  {showCurrPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
        )}

        <TextField
          inputRef={passwordInputRef}
          autoFocus
          margin="dense"
          id="name"
          label={t("reset_password_dialog.new_password")}
          type={showNewPassword ? "text" : "password"}
          fullWidth
          variant="standard"
          disabled={isFieldDisable}
          onChange={onNewPasswordChanged}
          // eslint-disable-next-line
          error={newPassword == ""}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setShowNewPassword(!showNewPassword)}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />

        {Display(
          isSmallDevice,
          rules,
          ValidatePassword(
            password,
            rules,
            newPassword?.split("")?.length ?? 0,
            userLogin
          ),
          isAdminChange,
          true,
          newPassword?.split("")?.length ?? 0
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          disabled={
            isAdminChange
              ? Object.values(
                  ValidatePassword(
                    newPassword,
                    rules,
                    newPassword?.length ?? 0,
                    userLogin
                  )
                ).includes(true)
              : Object.values(
                  ValidatePassword(
                    newPassword,
                    rules,
                    newPassword?.length ?? 0,
                    userLogin
                  )
                ).includes(true) || !(password?.length ?? 0) > 0
          }
          variant="contained"
          loading={isChangePwBtnLoading}
          onClick={(e) => {
            changePassword(e);
          }}
        >
          {t("reset_password_dialog.change_password")}
        </LoadingButton>
      </DialogActions>
      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Dialog>
  );
});

export const AddUserPasswordField = React.forwardRef((props, ref) => {
  const { password, rules, userLogin, onChange } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const themeColorContext = useThemeColor();

  const popperOpen = Boolean(anchorEl);

  React.useImperativeHandle(ref, () => ({
    getPasswordInvalidaty: () => {
      if (password != "") {
        return Object.values(
          ValidatePassword(
            password,
            rules,
            password?.split("")?.length ?? 0,
            userLogin
          )
        ).includes(true);
      } else {
        return false;
      }
    },
  }));

  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {t("administrative_console.user_page.password")}
          </InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            label={t("administrative_console.user_page.password")}
            value={password}
            onChange={(e) => onChange("password", e)}
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onFocus={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          />
        </FormControl>
      </ClickAwayListener>
      <Popper
        open={popperOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          border: 1,
          borderColor: `mainContainer.${themeColorContext[0]}`,
          bgcolor: `secondaryContainer.${themeColorContext[0]}`,
          width: "auto",
          zIndex: 1,
        }}
      >
        <Grid container direction={"column"}>
          {Display(
            false,
            rules,
            ValidatePassword(
              password,
              rules,
              password?.split("")?.length ?? 0,
              userLogin
            ),
            true,
            password?.split("")?.length ?? 0
          )}
        </Grid>
      </Popper>
    </>
  );
});

export const ResetPasswordSection = React.forwardRef((props, ref) => {
  const { rules, token, login } = props;
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const [passwordNotMatch, setPasswordNotMatch] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const ResetPassword = () => {
    setLoading(true);
    //Send new password and
    if (newPassword === confirmPassword) {
      //ask api
      const url = urlJoin(authServerBaseUrl, "/resetPassword");
      const data = {
        NewPassword: newPassword,
        Token: token,
      };
      CallApiWithoutContext(url, data)
        .then((response) => {
          if (response.message === "Change password successfully") {
            showAlertMessage({
              title: t("forgot_password_dialog.reset_password"),
              content: t("reset_password_dialog.change_password_successfully"),
              buttons: [
                {
                  text: "OK",
                  action: () => {
                    window.location.reload();
                    closeAlertMessage();
                  },
                },
              ],
            });
          } else {
            showAlertMessage({
              title: t("forgot_password_dialog.reset_password"),
              content: t("reset_password_dialog.change_password_failed"),
              buttons: [
                {
                  text: "OK",
                  action: () => {
                    closeAlertMessage();
                  },
                },
              ],
            });
          }
        })
        .catch((ex) => {
          showAlertMessage({
            title: t("forgot_password_dialog.reset_password"),
            content: t("reset_password_dialog.change_password_failed"),
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                },
              },
            ],
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPasswordNotMatch(true);
      setLoading(false);
    }
  };

  const ClearAlert = () => {
    setPasswordNotMatch(false);
  };

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);
    ClearAlert();
  };

  const handleComfirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };

  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  React.useImperativeHandle(ref, () => ({
    ruleLoadingTrue: () => {
      setLoading(true);
    },
    ruleLoadingFalse: () => {
      setLoading(false);
    },
  }));

  return (
    <Grid container item spacing={2} p={2}>
      <Grid item container direction={"column"} spacing={2}>
        {[
          {
            key: "newPw",
            label: "reset_password_dialog.new_password",
            visibilityToggle: showNewPassword,
          },
          {
            key: "confirmPw",
            label: "reset_password_dialog.confirm_password",
            visibilityToggle: showConfirmPassword,
          },
        ].map((k, count) => {
          return (
            <Grid item key={count}>
              <TextField
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ResetPassword();
                  }
                }}
                fullWidth
                type={
                  k.label === "login_page.user_name"
                    ? "text"
                    : k.visibilityToggle
                    ? "text"
                    : "password"
                }
                disabled={k.label === "login_page.user_name"}
                label={t(`${k.label}`)}
                variant={"outlined"}
                onChange={(event) =>
                  k.key === "newPw"
                    ? handleNewPasswordChange(event.target.value)
                    : handleComfirmPasswordChange(event.target.value)
                }
                InputProps={{
                  endAdornment:
                    k.label === "reset_password_dialog.new_password" ||
                    k.label === "reset_password_dialog.confirm_password" ? (
                      <IconButton
                        onClick={() => {
                          if (
                            k.label === "reset_password_dialog.new_password"
                          ) {
                            setShowNewPassword(!showNewPassword);
                          } else if (
                            k.label === "reset_password_dialog.confirm_password"
                          ) {
                            setShowConfirmPassword(!showConfirmPassword);
                          }
                        }}
                        edge="end"
                      >
                        {k.visibilityToggle ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ) : null,
                }}
              />
            </Grid>
          );
        })}
        {passwordNotMatch ? (
          <Grid item pt={1}>
            <Alert severity="error">
              {t("reset_password_dialog.password_not_match")}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        {Display(
          false,
          rules,
          ValidatePassword(
            newPassword,
            rules,
            newPassword?.split("")?.length ?? 0,
            login
          ),
          false,
          newPassword?.split("")?.length ?? 0
        )}
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          disabled={
            Object.values(
              ValidatePassword(
                newPassword,
                rules,
                newPassword?.length ?? 0,
                login
              )
            ).includes(true) ||
            !(confirmPassword?.length ?? 0) > 0 ||
            !(newPassword?.length ?? 0) > 0
          }
          variant="contained"
          loading={loading}
          onClick={() => {
            ResetPassword();
          }}
        >
          {t("reset_password_dialog.change_password")}
        </LoadingButton>
      </Grid>
      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => {
          setShowAlertDialog(false);
          window.location.reload();
        }}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
});
