import React, { useContext, createContext, useState } from "react";

const ThemeColorProvider = (props) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") === null
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      : localStorage.getItem("theme")
  );

  return <themeColorContext.Provider value={[theme, setTheme]} {...props} />;
};

const themeColorContext = createContext(null);
const useThemeColor = () => useContext(themeColorContext);

export { ThemeColorProvider, useThemeColor };
