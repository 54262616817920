import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneIcon from "@mui/icons-material/Done";
import PauseIcon from "@mui/icons-material/Pause";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import "../MailBox.css";
import { NoPermissionPage } from "../components/CaseList/CaseList";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

const InOutbox = (props) => {
  const { isInbox, objects, isLoading, noPermission, allSelected } = props;
  const setIsSelected = props.setIsSelected;
  const location = useLocation();
  const [selected, setSelected] = React.useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  dayjs.extend(utc);
  dayjs.extend(tz);

  const userTimezone = dayjs.tz.guess();

  const noRecordUrl = (
    <TableRow>
      <TableCell sx={{ borderBottom: "none" }}>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          {isLoading
            ? t("inbox_outbox.load_text")
            : t("inbox_outbox.no_record")}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setIsSelected(newSelected);
  };
  React.useEffect(() => {
    setSelected(allSelected);
  }, [allSelected]);
  const isSelected = (name) => selected?.indexOf(name) !== -1;

  return (
    <Grid container className="paper">
      <Grid item xs={12} sm={12}>
        <TableContainer>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "auto",
              marginBottom: "40px",
            }}
          >
            <Table aria-labelledby="tableTitle" className="mail">
              <TableBody>
                {noPermission ? (
                  <NoPermissionPage />
                ) : objects?.length > 0 ? (
                  objects.map((item, index) => {
                    const style = item.asRead ? "normal" : "bold";
                    const isItemSelected = isSelected(item.planItemSID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return !isSmallDevice ? (
                      <TableRow
                        hover
                        onClick={
                          isInbox
                            ? (event) => handleClick(event, item.planItemSID)
                            : null
                        }
                        role={isInbox ? "checkbox" : null}
                        aria-checked={isInbox ? isItemSelected : null}
                        tabIndex={-1}
                        selected={isInbox ? isItemSelected : null}
                        key={item.planItemSID}
                        className="mail-item"
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: "16px",
                          },
                        }}
                      >
                        {isInbox ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              className="padding-left"
                            />
                          </TableCell>
                        ) : null}
                        {isInbox ? null : (
                          <TableCell width={30}>
                            {item.state == 1 ? (
                              <HourglassBottomIcon />
                            ) : item.state == 4 ? (
                              <DoneIcon />
                            ) : item.state == 9 ? (
                              <PauseIcon />
                            ) : item.state == 10 ? (
                              <CloseIcon />
                            ) : null}
                          </TableCell>
                        )}

                        <TableCell className="mail-user" id={labelId}>
                          {item.initiatorName}
                        </TableCell>

                        <TableCell
                          onClick={() => {
                            localStorage.setItem(
                              "startPageURL",
                              location.pathname + location.search
                            );
                          }}
                        >
                          <Link
                            //to={`/inbox/${item.caseID}/${item.planItemSID}`}
                            to={`/case/${item.caseSID}/${item.planItemSID}`}
                            style={{
                              color: "gray",
                              fontWeight: style,
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item.planItemName}
                            &nbsp; [{item.caseNumber}]
                          </Link>
                        </TableCell>

                        <TableCell
                          className=".mail-date"
                          sx={{ textAlign: "right" }}
                        >
                          {dayjs
                            .utc(item.startDate)
                            .tz(userTimezone)
                            .format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableRow
                          onClick={
                            isInbox
                              ? (event) => handleClick(event, item.planItemSID)
                              : null
                          }
                          role="checkbox"
                          aria-checked={isInbox ? isItemSelected : null}
                          tabIndex={-1}
                          selected={isInbox ? isItemSelected : null}
                          key={item.planItemID}
                          className="mail-item"
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "10px",
                            },
                            "&.Mui-selected": {
                              backgroundColor:
                                theme.palette.mode == "dark"
                                  ? "#5991213d"
                                  : "#3a60151f",
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor:
                                theme.palette.mode == "dark"
                                  ? "#5991213d"
                                  : "#3a60151f",
                            },
                          }}
                        >
                          {isInbox ? (
                            <TableCell padding="checkbox" rowSpan={2}>
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                className="padding-left"
                              />
                            </TableCell>
                          ) : (
                            <TableCell rowSpan={2}>
                              {item.state == 1 ? (
                                <HourglassBottomIcon fontSize="small" />
                              ) : item.state == 4 ? (
                                <DoneIcon fontSize="small" />
                              ) : item.state == 9 ? (
                                <PauseIcon fontSize="small" />
                              ) : item.state == 10 ? (
                                <CloseIcon fontSize="small" />
                              ) : null}
                            </TableCell>
                          )}
                          <TableCell
                            className="mail-user"
                            id={labelId}
                            sx={{ borderBottom: 0 }}
                          >
                            {item.initiatorName}
                          </TableCell>
                          <TableCell
                            className=".mail-date"
                            sx={{ textAlign: "right", borderBottom: 0 }}
                          >
                            {item.milestone}
                            &nbsp; &nbsp; &nbsp;
                            {dayjs
                              .utc(item.startDate)
                              .tz(userTimezone)
                              .format("DD/MM/YYYY HH:mm")}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          onClick={
                            isInbox
                              ? (event) => handleClick(event, item.planItemSID)
                              : null
                          }
                          role={isInbox ? "checkbox" : null}
                          aria-checked={isInbox ? isItemSelected : null}
                          tabIndex={-1}
                          selected={isInbox ? isItemSelected : null}
                          key={item.planItemSID}
                          className="mail-item"
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "10px",
                            },
                            "&.Mui-selected": {
                              backgroundColor:
                                theme.palette.mode == "dark"
                                  ? "#5991213d"
                                  : "#3a60151f",
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor:
                                theme.palette.mode == "dark"
                                  ? "#5991213d"
                                  : "#3a60151f",
                            },
                          }}
                        >
                          <TableCell
                            colSpan={3}
                            onClick={() => {
                              localStorage.setItem(
                                "startPageURL",
                                location.pathname + location.search
                              );
                            }}
                          >
                            <Link
                              to={`/case/${item.caseSID}/${item.planItemSID}`}
                              style={{
                                color: "gray",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {item.planItemName}
                              &nbsp; [{item.caseNumber}]
                            </Link>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  noRecordUrl
                )}
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>
        <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
      </Grid>
    </Grid>
  );
};

InOutbox.propTypes = {
  isInbox: PropTypes.bool.isRequired,
  objects: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  noPermission: PropTypes.bool.isRequired,
  setIsSelected: PropTypes.func,
  allSelected: PropTypes.array,
};

export default InOutbox;
