import * as React from "react";
import urlJoin from "url-join";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { TablePagination } from "@mui/material";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import {
  CallApiWithContext,
  CallApiWithDownloadFile,
} from "../../../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../../../Config";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Grid sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Grid>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function GroupSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [groups, setGroups] = React.useState(null);
  const [securityDialog, setSecurityDialog] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [permissionList, setPermissionList] = React.useState(null);
  const [unsavePermissionList, setUnsavePermissionList] = React.useState(null);
  const [columns, setColumns] = React.useState(null);
  var columnStatus = [];

  React.useEffect(() => {
    setIsLoading(true);
    var url = urlJoin(resServerBaseUrl, "/User/GetGroupsByPermissions");
    const data = JSON.stringify({ IsAdministrator: true });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        console.log(response);
        if (response) {
          setGroups(response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [authenticationContext]);

  const handleAddSecurityDialogOpen = (rowIndex) => {
    setPermissionList(groups[rowIndex].permission);
    setColumns(Object.keys(groups[rowIndex].permission[rowIndex].data));
    setUnsavePermissionList(
      JSON.parse(JSON.stringify(groups[rowIndex].permission))
    );
    setSelectedIndex(rowIndex);
    setSecurityDialog(true);
  };

  const handleAddSecurityDialogClose = () => {
    groups[selectedIndex].permission = unsavePermissionList;
    setSecurityDialog(false);
  };

  const handleChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };
  const handleChangeSelectedRowsPerPage = (event) => {
    setSelectedRowsPerPage(parseInt(event.target.value, 10));
    setSelectedPage(0);
  };

  const handleSelectAllClick = (event, column) => {
    groups[selectedIndex].permission.forEach((p) => {
      if (columnStatus[column]) {
        p.data[column] = false;
      } else if (columnStatus[column] === false) {
        p.data[column] = null;
      } else {
        p.data[column] = true;
      }
    });

    if (columnStatus[column]) {
      columnStatus[column] = false;
    } else if (columnStatus[column] === false) {
      columnStatus[column] = null;
    } else {
      columnStatus[column] = true;
      var data = Object.keys(columnStatus).filter(
        (key) => !key.includes(column)
      );
      data.forEach((d) => {
        columnStatus[d] = false;
        groups[selectedIndex].permission.forEach((p) => {
          p.data[d] = false;
        });
      });
    }
    setPermissionList((permissionList) => [
      ...groups[selectedIndex].permission,
    ]);
  };

  const SelectAllCheckBox = (props) => {
    const { onSelectAllClick, name, rowCount } = props;
    let trueCount = 0,
      falseCount = 0;
    groups[selectedIndex].permission.forEach((p) => {
      if (p.data[name]) {
        trueCount++;
      } else if (p.data[name] === false) {
        falseCount++;
      }
    });
    if (trueCount === rowCount) {
      columnStatus[name] = true;
    } else if (falseCount === rowCount) {
      columnStatus[name] = false;
    }
    return (
      <Checkbox
        color="primary"
        indeterminate={columnStatus[name] === false}
        indeterminateIcon={<CloseIcon></CloseIcon>}
        checked={columnStatus[name] === true}
        onChange={onSelectAllClick}
        inputProps={{
          "aria-label": "select_all",
        }}
      />
    );
  };

  const handleAddSecurityDialogApply = () => {
    var url = urlJoin(resServerBaseUrl, "/User/UpdateGroupSecurity");
    const data = JSON.stringify({
      GroupID: groups[selectedIndex].id,
      Permission: JSON.stringify(groups[selectedIndex].permission),
    });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        console.log(response);
        if (response) {
          setSecurityDialog(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckBoxClicked = (event, row, column) => {
    event.preventDefault();
    event.stopPropagation();
    if (row[column]) {
      row[column] = false;
    } else if (row[column] === false) {
      row[column] = null;
    } else {
      row[column] = true;
    }

    if (row[column] === true) {
      var data = Object.keys(row).filter((key) => !key.includes(column));
      data.map((d) => (row[d] = false));
    }
    setPermissionList([...permissionList]);
  };

  const handleSelectedPemission = (name, selectedRow) => {
    if (selectedRow) {
      Object.entries(selectedRow).forEach(([Key, Value]) => {
        if (Value) {
          name = Key;
        }
      });
    }
    switch (name) {
      case "NA":
        return t("administrative_console.group_page.permission.no_access");
      case "RW":
        return t("administrative_console.group_page.permission.read_write");
      case "RO":
        return t("administrative_console.group_page.permission.read_only");
      default:
        return "---";
    }
  };

  const handleDownloadOnClick = () => {
    setIsLoading(true);
    var url = urlJoin(resServerBaseUrl, "/User/DownloadGroup");

    CallApiWithDownloadFile(url, authenticationContext, null, "Group.xlsx")
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // CallApiWithContext(url, authenticationContext, null)
    //   .then((response) => {
    //     console.log(response);
    //     if (response && response.data) {
    //       var byteString = atob(response.data);
    //       var arrayBuffer = new ArrayBuffer(byteString.length);
    //       var intArray = new Uint8Array(arrayBuffer);

    //       for (var i = 0; i < byteString.length; i++) {
    //         intArray[i] = byteString.charCodeAt(i);
    //       }
    //       const blob = new Blob([intArray], {
    //         type: "application/vnd.ms-excel",
    //       });
    //       saveAs(blob, response.fileName);
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsLoading(false);
    //   });
  };

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      sx={{ p: "1.5%" }}
      className="Profile-container"
    >
      <Grid item style={{ textAlign: "left" }}>
        <h3>{t("administrative_console.group_page.title")}</h3>
        <p>{t("administrative_console.group_page.description")}</p>
        {groups ? (
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 1 }}
            onClick={handleDownloadOnClick}
          >
            {t("administrative_console.group_page.download")}
          </Button>
        ) : null}
      </Grid>
      <Grid container sx={{ m: 1 }}>
        {groups ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead sx={{ fontWeight: "600" }}>
                <TableRow>
                  <TableCell>
                    {t("administrative_console.group_page.group_name")}
                  </TableCell>
                  <TableCell>
                    {t("administrative_console.group_page.group_description")}
                  </TableCell>
                  <TableCell>
                    {t("administrative_console.group_page.group_action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups !== null ? (
                  groups.map((row, rowIndex) => {
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <Button
                            onClick={(event) => {
                              handleAddSecurityDialogOpen(rowIndex);
                            }}
                            startIcon={<AddModeratorIcon />}
                          ></Button>
                        </TableCell>
                        {/* Security Dialog */}
                        <Dialog open={securityDialog} maxWidth={false}>
                          <DialogTitle>
                            {t(
                              "administrative_console.group_page.dialog.title"
                            )}
                          </DialogTitle>
                          <DialogContent style={{ overflow: "hidden" }}>
                            <TableContainer component={Paper}>
                              <TableHead
                                sx={{
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                              >
                                <TableRow>
                                  <TableCell align="center">
                                    {t(
                                      "administrative_console.group_page.dialog.name"
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t(
                                      "administrative_console.group_page.dialog.selected"
                                    )}
                                  </TableCell>
                                  {columns
                                    ? columns.map((column, columnIndex) => {
                                        return (
                                          <TableCell align="center">
                                            <SelectAllCheckBox
                                              name={column}
                                              onSelectAllClick={(event) => {
                                                handleSelectAllClick(
                                                  event,
                                                  column
                                                );
                                              }}
                                              rowCount={row.permission.length}
                                            />
                                            {handleSelectedPemission(column)}
                                          </TableCell>
                                        );
                                      })
                                    : null}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {permissionList
                                  ? permissionList
                                      .slice(
                                        selectedPage * selectedRowsPerPage,
                                        selectedPage * selectedRowsPerPage +
                                          selectedRowsPerPage
                                      )
                                      .map((permissionRow, rowIndex) => {
                                        var currentRowIndex = rowIndex;
                                        const labelId = `enhanced-table-checkbox-${currentRowIndex}`;
                                        return (
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={permissionRow.name}
                                          >
                                            <TableCell align="center">
                                              {permissionRow.name}
                                            </TableCell>
                                            <TableCell align="center">
                                              {handleSelectedPemission(
                                                null,
                                                permissionRow.data
                                              )}
                                            </TableCell>
                                            {Object.entries(
                                              permissionRow.data
                                            ).map(([Key, Value]) => {
                                              return (
                                                <TableCell align="center">
                                                  <Checkbox
                                                    color="primary"
                                                    checked={Value === true}
                                                    indeterminate={
                                                      Value === false
                                                    }
                                                    indeterminateIcon={
                                                      <CloseIcon></CloseIcon>
                                                    }
                                                    onClick={(event) => {
                                                      handleCheckBoxClicked(
                                                        event,
                                                        permissionRow.data,
                                                        Key
                                                      );
                                                    }}
                                                    inputProps={{
                                                      "aria-labelledby":
                                                        labelId,
                                                    }}
                                                  />
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        );
                                      })
                                  : null}
                              </TableBody>
                              <TablePagination
                                rowsPerPageOptions={[5]}
                                labelRowsPerPage={t("inbox_outbox.display_row")}
                                count={row.permission.length}
                                rowsPerPage={selectedRowsPerPage}
                                page={selectedPage}
                                sx={{
                                  position: "relative",

                                  right: 0,
                                  overflow: "hidden",
                                }}
                                onPageChange={handleChangeSelectedPage}
                                onRowsPerPageChange={
                                  handleChangeSelectedRowsPerPage
                                }
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableContainer>
                            <p>
                              {t(
                                "administrative_console.group_page.dialog.permissions"
                              )}
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleAddSecurityDialogApply}>
                              {t(
                                "administrative_console.group_page.dialog.apply"
                              )}
                            </Button>
                            <Button
                              onClick={handleAddSecurityDialogClose}
                              color="error"
                            >
                              {t(
                                "administrative_console.group_page.dialog.close"
                              )}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>{t("administrative_console.group_page.no_existing")}</p>
        )}
      </Grid>
      <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
    </Grid>
  );
}
