import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  CardMedia,
  Chip,
  CssBaseline,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import FaxIcon from "@mui/icons-material/Fax";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PhoneIcon from "@mui/icons-material/Phone";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import TabContext from "@mui/lab/TabContext";
import WorkIcon from "@mui/icons-material/Work";
import {
  SocialDistance,
  Title,
  Visibility,
  VisibilityOff,
  WifiProtectedSetup,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import urlJoin from "url-join";

// eslint-disable-next-line
import AdministrativeConsole from "./AdminConsole/AdministrativeConsole";
import SharedDialog from "../../shared/SharedDialog";
import TitleWithIcon from "../../shared/TitleWithIcon";
import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../helpers/ApiHelper";
import { resServerBaseUrl, authServerBaseUrl } from "../../Config";
import { ConvertUTCDateToLocal } from "../../shared/Utils";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useProfile } from "../../providers/ProfileProvider";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import ProxyDelegationEditor, {
  ProxyDelegateType,
} from "./ProxyDelegationEditor";
import "./Profile.css";
import { MessageType } from "../../Constants";
import PrimaryPositionTable from "../../shared/PrimaryPositionTable";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import MuiPhoneNumber from "mui-phone-number";
import { useCountry } from "../../providers/CountryProvider";
import { ChangePasswordDialog } from "../../shared/PasswordRuleDisplay";
import UserEditor from "./AdminConsole/AdmintrativeBody/UserEditor";

dayjs.extend(tz);

const PersonalInfoSkeleton = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ m: 1 }}>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Skeleton width="100%">
          <Typography>.</Typography>
        </Skeleton>
      </Box>
    </Box>
  );
};

const Profile = (props) => {
  const { security } = props;
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = React.useState({});
  const [userAttr, setUserAttr] = React.useState({});
  // eslint-disable-next-line
  const [companyAttr, setCompanyAttr] = React.useState({});
  const setTitle = props.settitle;
  const authenticationContext = useAuthentication();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [tabValue, setTabValue] = React.useState(
    searchParams.size > 0 ? "administrativeConsole" : "profile"
  );
  // new add
  const [adminConsoleSecurity, setAdminConsoleSecurity] = React.useState(null);
  const [adminConsole, setAdminConsole] = React.useState(null);
  //
  const [imageMessage, setImageMessage] = React.useState(null);
  const [ProxyTableRows, setProxyTableRows] = React.useState([]);
  const [DelegationTableRows, setDelegationTableRows] = React.useState([]);
  const [originalUserId, setOriginalUserId] = React.useState(null);
  const [isLoadingPersonalInfo, setIsLoadingPersonalInfo] =
    React.useState(true);
  const [isLoadingProxyTable, setIsLoadingProxyTable] = React.useState(true);
  const [isLoadingDelegationTable, setIsLoadingDelegationTable] =
    React.useState(true);
  const [isLoadingPersonalInfoSave, setIsLoadingPersonalInfoSave] =
    React.useState(false);
  const [isLoadingCustomPropTable, setIsLoadingCustomPropTable] =
    React.useState(true);
  const [isLoadingCustomPropDeleteDialog, setIsLoadingCustomPropDeleteDialog] =
    React.useState(false);
  const [isLoadingCustomPropSave, setIsLoadingCustomPropSave] =
    React.useState(false);
  const [isLoadingCustomPropDelete, setIsLoadingCustomPropDelete] =
    React.useState(false);
  const [isLoadingCustomSettingSave, setIsLoadingCustomSettingSave] =
    React.useState(false);
  const [isDisableAddEditCustomPropSave, setIsDisableAddEditCustomPropSave] =
    React.useState(true);
  const [isDisablePersonalInfoSaveButton, setIsDisablePersonalInfoSaveButton] =
    React.useState(true);
  const [isAddCustomProp, setIsAddCustomProp] = React.useState(true);
  const [isAddEditCustomPropSave, setIsAddEditCustomPropSave] =
    React.useState(false);
  const [isAddEditCustomPropNameError, setIsAddEditCustomPropNameError] =
    React.useState(false);
  const [isAddEditCustomPropValueError, setIsAddEditCustomPropValueError] =
    React.useState(false);
  const [caseTplRecord, setCaseTplRecord] = React.useState([]);
  const customPropHeaders = [
    t("profile.custom_property.property"),
    t("profile.custom_property.value"),
    t("profile.custom_property.action"),
  ];
  const customSettingHeaders = [t("profile.custom_setting.setting"), ""];
  const [noCustomProp, setNoCustomProp] = React.useState(false);
  const [openAddEditCustomPropDialog, setOpenAddEditCustomPropDialog] =
    React.useState(false);
  const [openDeleteCustomPropDialog, setOpenDeleteCustomPropDialog] =
    React.useState(false);
  const [openSaveCustomSettingDialog, setOpenSaveCustomSettingDialog] =
    React.useState(false);
  // eslint-disable-next-line
  const [caseTplAuthen, setCaseTplAuthen] = React.useState(null);
  const [customProp, setCustomProp] = React.useState({});
  const [editedCustomProp, setEditedCustomProp] = React.useState({});
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [customSetting, setCustomSetting] = React.useState({});

  const [isPersonalInfoInputError, setIsPersonalInfoInputError] =
    React.useState({
      MOBILE: false,
      PHONE_HOME: false,
      PHONE_OFFICE: false,
      EMAIL: false,
      FAX: false,
      FIRSTNAME: false,
      LASTNAME: false,
      OTHERNAME: false,
    });
  // eslint-disable-next-line
  const [nonCustomProp, setNonCustomProp] = React.useState([
    "FIRSTNAME",
    "MIDDLENAME",
    "LASTNAME",
    "FULLNAME",
    "OTHERNAME",
    "NICKNAME",
    "MOBILE",
    "PHONE_HOME",
    "PHONE_OFFICE",
    "EMAIL",
    "DEPARTMENT",
    "JOBTITLE",
    "FAX",
    "DESCRIPTION",
    "TITLE",
    "WEBPAGE",
    "ADDRESS_HOME",
    "ADDRESS_OFFICE",
    "ADDRESS_CORRESPONDING",
    "ABOUTME",
    "LANGUAGE",
  ]);
  const [tempCustomProp, setTempCustomProp] = React.useState({
    propertyName: "",
    propertyValue: "",
  });

  const [release, setRelease] = React.useState(false);
  const [profile, setProfile] = useProfile();
  const [loadingImg, setLoadingImg] = React.useState(true);
  const [rules, setRules] = React.useState();
  const [availablePos, setAvailablePos] = React.useState([]);
  const [primaryPos, setPrimaryPos] = React.useState({});
  const [transferDialogOpen, setTransferDialogOpen] = React.useState(false);
  const [userListForTransfer, setUserListForTransfer] = React.useState([]);
  const [userAutocompleteLoading, setUserAutocompleteLoading] =
    React.useState(false);
  const [transferBtnLoading, setTransferBtnLoading] = React.useState(false);
  const [newAdminUserID, setNewAdminUserID] = React.useState("");
  const [availablePosLoading, setAvailablePosLoading] = React.useState(false);
  const [userUUID, setUserUUID] = React.useState({});
  const [isAuthenticated, setIsAuthenticated] = useAuthentication();
  const changePasswordRef = React.useRef();
  const userEditorRef = React.useRef();
  const profileImage = React.useMemo(() => {
    return profile?.profileImage
      ? `data:image/jpeg;base64,${profile.profileImage}`
      : "/unknown-logo.png";
  }, [profile]);
  const isPrimaryPositionChanged = React.useMemo(() => {
    let originalPrimPosArr = availablePos
      .map((r) => r.Positions)
      .flat()
      .filter((e) => e.IsPrimaryPosition == true)
      .map((m) => m.UUID);
    return !Object.values(primaryPos)
      .map((p) => originalPrimPosArr.includes(p))
      .includes(false);
  }, [primaryPos, availablePos]);

  let theme = createTheme();
  theme.typography.h6 = {
    fontSize: "1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem",
    },
  };
  const isPositionChangable = React.useMemo(() => {
    let positionArr = availablePos.map((e) => e.Positions.length > 1);
    if (availablePos.length > 0) {
      if (positionArr.includes(true)) {
        return true;
      } else {
        let chartWithSinglePos = availablePos.filter(
          (a) => a.Positions.length == 1
        );
        if (
          chartWithSinglePos
            .map((c) => c.SID)
            .map((n) => Object.keys(primaryPos).includes(n))
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }, [primaryPos, availablePos]);

  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const themeColorContext = useThemeColor();

  const handleCustomPropertyChange = (prop) => (event) => {
    setTempCustomProp((prev) => ({ ...prev, [prop]: event.target.value }));
  };
  const handlePersonalInfoSave = (event) => {
    //callApiToSaveCustomProp will update userInfo
    callApiToSaveCustomProp(userAttr);
  };
  const handleOpenAddCustomPropDialog = (event) => {
    if (Object.values(isPersonalInfoInputError).includes(true)) {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("profile.custom_property.custom_property_error_message"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
    } else {
      setIsAddCustomProp(true);
      setTempCustomProp({});
      setOpenAddEditCustomPropDialog(true);
      setIsAddEditCustomPropNameError(false);
      setIsAddEditCustomPropValueError(false);
      setIsDisableAddEditCustomPropSave(true);
    }
  };
  const handleOpenEditCustomPropDialog = (row) => {
    if (Object.values(isPersonalInfoInputError).includes(true)) {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("profile.custom_property.custom_property_error_message"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
    } else {
      setIsAddCustomProp(false);
      setTempCustomProp({
        propertyName: row.toLowerCase().replace(/_/g, " "),
        propertyValue: customProp[row],
      });
      setEditedCustomProp(row);
      setIsAddEditCustomPropNameError(false);
      setIsAddEditCustomPropValueError(false);
      setOpenAddEditCustomPropDialog(true);
    }
  };
  const handleOpenDeleteCustomPropDialog = (row) => {
    if (Object.values(isPersonalInfoInputError).includes(true)) {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("profile.custom_property.custom_property_error_message"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
    } else {
      setEditedCustomProp(row);
      setOpenDeleteCustomPropDialog(true);
    }
  };
  const handleSaveCustomProp = (event) => {
    setIsLoadingCustomPropSave(true);
    let propName = tempCustomProp.propertyName.toUpperCase();
    if (nonCustomProp.includes(propName)) {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content:
          tempCustomProp.propertyName +
          t("profile.custom_property.please_do_modification_error"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
      setIsLoadingCustomPropSave(false);
    } else if (
      (isAddCustomProp && customProp.hasOwnProperty(propName)) ||
      (!isAddCustomProp &&
        customProp.hasOwnProperty(propName) &&
        propName !== editedCustomProp)
    ) {
      showAlertMessage({
        title: "",
        content:
          tempCustomProp.propertyName +
          t("profile.custom_property.is_already_exist"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
      setIsLoadingCustomPropSave(false);
    } else {
      if (!isAddCustomProp) {
        Object.keys(userAttr).forEach((u) => {
          if (u === editedCustomProp) {
            delete userAttr[u];
          }
        });
      }
      setUserAttr((prevState) => ({
        ...prevState,
        [propName.replace(/ /g, "_")]: tempCustomProp.propertyValue,
      }));
      //Setting customPropSave to be true and useEffect will callAPI to save customProps change
      setIsAddEditCustomPropSave(true);
    }
  };
  const handleDeleteCustomProp = (event) => {
    setIsLoadingCustomPropDelete(true);
    setIsLoadingCustomPropDeleteDialog(true);
    Object.keys(userAttr).forEach((u) => {
      if (u === editedCustomProp) {
        delete userAttr[u];
      }
    });
    callApiToSaveCustomProp(userAttr);
  };
  const handleCloseAddEditCustomPropDialog = (event) => {
    setOpenAddEditCustomPropDialog(false);
  };
  const handleCloseDeleteCustomPropDialog = (event) => {
    setOpenDeleteCustomPropDialog(false);
  };
  const handleSaveCustomSetting = (event) => {
    callApiToSaveCustomProp(userAttr);
  };
  const handleOpenSaveCustomSettingDialog = (event) => {
    setOpenSaveCustomSettingDialog(true);
  };
  const handleCloseSaveCustomSettingDialog = (event) => {
    setOpenSaveCustomSettingDialog(false);
  };
  const handleCustomSettingChange = (prop) => (event) => {
    setCustomSetting({ ...customSetting, [prop]: event.target.checked });
  };
  const createDataByElement = (element) => {
    return {
      proxyUser: element.userName,
      effectiveFrom: element.vaildDateFrom,
      effectiveTo: element.vaildDateTo,
      specifiedWorkflow: element.tplName,
      type: element.type,
      rowId: element.id,
      userId: element.userID,
    };
  };
  const getUserInfo = () => {
    setImageMessage(null);
    setRelease(true);
    setIsLoadingCustomPropTable(true);
    setIsLoadingPersonalInfo(true);
    setIsPersonalInfoInputError({
      MOBILE: false,
      PHONE_HOME: false,
      PHONE_OFFICE: false,
      EMAIL: false,
      FAX: false,
      FIRSTNAME: false,
      LASTNAME: false,
      OTHERNAME: false,
    });

    const url = urlJoin(resServerBaseUrl, "/User/GetUserProfile");

    CallApiWithContext(url, authenticationContext, null)
      .then((json) => {
        if (json != null) {
          setProfile(json);

          if (json.attr) {
            var attr = JSON.parse(json.attr);
            setUserAttr(attr);
            loadCustomPropTable(attr);
          }
          if (json.companyAttr) setCompanyAttr(json.companyAttr);

          setUserInfo(json);
          setUserUUID({
            UUID: json.uuid,
          });

          if (json.security) setAdminConsoleSecurity(json.security);
          if (json.customSetting) {
            var customSetting = JSON.parse(json.customSetting);
            setCustomSetting(customSetting);
          }

          setIsLoadingCustomPropTable(false);
          setIsLoadingPersonalInfo(false);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoadingImg(false);
      });
  };
  const loadTable = () => {
    setIsLoadingProxyTable(true);
    setIsLoadingDelegationTable(true);
    const proxyList = [];
    const delegationList = [];
    const data = JSON.stringify({ IsAdministrator: true });
    const url_caseTplpPrmission = urlJoin(
      resServerBaseUrl,
      "/Permission/GetCaseTemplatePermissions"
    );
    const url_specwf = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates");

    CallApiWithContext(url_specwf, authenticationContext, data)
      .then((getCaseTemplatesResponse) => {
        if (getCaseTemplatesResponse) {
          CallApiWithContext(url_caseTplpPrmission, authenticationContext)
            .then((permissionResponse) => {
              const findWFNameByID = [];

              for (let i = 0; i < getCaseTemplatesResponse.length; i++) {
                var filteredTemplates = getCaseTemplatesResponse[
                  i
                ].templateInfos.filter((tInfo) =>
                  permissionResponse.some(
                    (permission) =>
                      permission.tplSID === tInfo.sid &&
                      ((permission.permissionType & 1) > 0 ||
                        (permission.permissionType & 2) > 0)
                  )
                );
                if (filteredTemplates && filteredTemplates.length > 0) {
                  findWFNameByID.push(
                    ...getCaseTemplatesResponse[i].templateInfos.map(
                      (info) => ({
                        ID: info.uuid,
                        label: info.name,
                      })
                    )
                  );
                }
              }

              findWFNameByID.push({
                ID: "00000000-0000-0000-0000-000000000000",
                label: "All Workflow Templates",
              });

              findWFNameByID.sort((a, b) => {
                const labelA = a.label.toUpperCase();
                const labelB = b.label.toUpperCase();
                if (labelA < labelB) {
                  return -1;
                }
                if (labelA > labelB) {
                  return 1;
                }
                return 0;
              });

              setCaseTplRecord(findWFNameByID);

              const getUserProxyDelegationListURL = urlJoin(
                resServerBaseUrl,
                "/Case/GetUserProxyDelegationList"
              );
              const userID = { UserID: null };
              CallApiWithContext(
                getUserProxyDelegationListURL,
                authenticationContext,
                JSON.stringify(userID)
              )
                .then((getUserProxyDelegationListResponse) => {
                  if (getUserProxyDelegationListResponse) {
                    getUserProxyDelegationListResponse.forEach((element) => {
                      element = {
                        ...element,
                        tplName: findWFNameByID.find(
                          (x) => x.ID === element.caseTplUUID
                        ).label,
                      };
                      if (typeof element.tplName === "undefined") {
                        element.tplName = "Case Template not found!";
                      }
                      setOriginalUserId(element.originalUserID);

                      element.vaildDateFrom = ConvertUTCDateToLocal(
                        new Date(element.vaildDateFrom)
                      );
                      element.vaildDateTo = ConvertUTCDateToLocal(
                        new Date(element.vaildDateTo)
                      );
                      let dateTemp = element.vaildDateFrom.split(" ")[0];
                      dateTemp =
                        dateTemp.split("/")[2] +
                        "-" +
                        dateTemp.split("/")[1] +
                        "-" +
                        dateTemp.split("/")[0];
                      element.vaildDateFrom =
                        dateTemp + "T" + element.vaildDateFrom.split(" ")[1];
                      dateTemp = element.vaildDateTo.split(" ")[0];
                      dateTemp =
                        dateTemp.split("/")[2] +
                        "-" +
                        dateTemp.split("/")[1] +
                        "-" +
                        dateTemp.split("/")[0];
                      element.vaildDateTo =
                        dateTemp + "T" + element.vaildDateTo.split(" ")[1];

                      if (element.type === 0)
                        proxyList.push(createDataByElement(element));
                      else delegationList.push(createDataByElement(element));
                    });
                    setProxyTableRows(proxyList);
                    setDelegationTableRows(delegationList);
                    setIsLoadingProxyTable(false);
                    setIsLoadingDelegationTable(false);
                  } else {
                    setProxyTableRows(proxyList);
                    setDelegationTableRows(delegationList);
                    setIsLoadingProxyTable(false);
                    setIsLoadingDelegationTable(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setIsLoadingProxyTable(false);
                  setIsLoadingDelegationTable(false);
                });
            })
            .catch((error) => {
              console.log(error);
              setIsLoadingProxyTable(false);
              setIsLoadingDelegationTable(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingProxyTable(false);
        setIsLoadingDelegationTable(false);
      });
  };
  const loadCustomPropTable = (userAttr) => {
    let tempNoCustomProp = true;
    setCustomProp({});
    Object.keys(userAttr).forEach((u) => {
      if (nonCustomProp.includes(u)) {
      } else {
        setCustomProp((prevState) => ({ ...prevState, [u]: userAttr[u] }));
        setNoCustomProp(false);
        tempNoCustomProp = false;
      }
    });
    if (tempNoCustomProp) {
      setNoCustomProp(true);
    }
  };
  const callApiToSaveCustomProp = (userAttr) => {
    setIsLoadingPersonalInfo(true);
    setIsLoadingCustomPropTable(true);
    setIsLoadingPersonalInfoSave(true);
    setIsAddEditCustomPropSave(false);
    setIsLoadingCustomSettingSave(true);

    // if (fullName !== "") {
    //   userAttr = { ...userAttr, FULLNAME: fullName };
    // }

    // Object.keys(userAttr).forEach((u) => {
    //   if (userAttr[u] === "") {
    //     delete userAttr[u];
    //   }
    // });

    let u = userEditorRef.current.getEditingUser();

    var saveData = {
      ID: u.id,
      UUID: u.uuid,
      Attr: JSON.stringify(u.attr),
    };

    const updateUserURL = urlJoin(resServerBaseUrl, "User/UpdateUser");

    CallApiWithContext(
      updateUserURL,
      authenticationContext,
      JSON.stringify(saveData)
    )
      .then((response) => {
        if (response) {
          if (response.message === "Position cannot be found") {
            showAlertMessage({
              title: t(
                "administrative_console.user_page.edit_user.dialog_title"
              ),
              content: "Updated but " + response.message,
              buttons: [
                {
                  text: t("report.exist_dialog.close"),
                  action: () => {
                    closeAlertMessage();
                    setIsDisablePersonalInfoSaveButton(true);
                    GetAvailablePositions();
                  },
                },
              ],
            });
            resetLoading();
            return;
          }

          if (typeof response === "object") {
            showAlertMessage({
              title: t(
                "administrative_console.user_page.edit_user.dialog_title"
              ),
              content: t("profile.user_profile_updated"),
              buttons: [
                {
                  text: t("report.exist_dialog.close"),
                  action: () => {
                    closeAlertMessage();
                    setIsDisablePersonalInfoSaveButton(true);
                    GetAvailablePositions();
                  },
                },
              ],
            });
          } else {
            showAlertMessage({
              title: t(
                "administrative_console.user_page.edit_user.dialog_title"
              ),
              content: response.message,
              buttons: [
                {
                  text: t("report.exist_dialog.close"),
                  action: () => {
                    closeAlertMessage();
                    setIsDisablePersonalInfoSaveButton(false);
                    GetAvailablePositions();
                  },
                },
              ],
            });
          }
        } else {
          console.log(response);
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: response.message,
            buttons: [
              {
                text: t("report.exist_dialog.close"),
                action: () => {
                  closeAlertMessage();
                  setIsDisablePersonalInfoSaveButton(false);
                  GetAvailablePositions();
                },
              },
            ],
          });
        }
        resetLoading();
      })
      .catch((error) => {
        resetLoading();
        setIsDisablePersonalInfoSaveButton(true);
        GetAvailablePositions();
        console.log(error);
      });
  };
  const resetLoading = () => {
    setOpenAddEditCustomPropDialog(false);
    setOpenDeleteCustomPropDialog(false);
    setOpenSaveCustomSettingDialog(false);
    setIsLoadingPersonalInfo(false);
    setIsLoadingPersonalInfoSave(false);
    setIsLoadingCustomPropTable(false);
    setIsLoadingCustomPropSave(false);
    setIsLoadingCustomPropDelete(false);
    setIsLoadingCustomPropDeleteDialog(false);
    setIsLoadingCustomSettingSave(false);
    loadCustomPropTable(userAttr);
  };
  const handleChangePasswordDialogOpen = () => {
    changePasswordRef.current.openChangePasswordDialog();
    console.log(userInfo);
  };
  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };
  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Checking if the file type is allowed or not
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

      if (!allowedTypes.includes(selectedFile?.type)) {
        // setImageMessage("Warming: This is not a image!!!!");
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: "Uploading is not image",
          buttons: [
            {
              text: t("report.exist_dialog.close"),
              action: () => {
                closeAlertMessage();
              },
            },
          ],
        });
        event.target.value = "";
      } else {
        const url = urlJoin(resServerBaseUrl, "/User/UploadProfileImage");
        let file = new FormData();

        file.append("image", selectedFile);

        setLoadingImg(true);

        CallApiWithUploadFile(url, authenticationContext, file)
          .then((response) => {
            if (response) {
              getUserInfo();
            }
          })
          .finally(() => {
            setLoadingImg(false);
          });
      }
    }
  };
  const getPasswordRule = () => {
    const url = urlJoin(resServerBaseUrl, "/User/GetPasswordRule");
    CallApiWithContext(url, authenticationContext, null).then((response) => {
      if (response) {
        setRules(response);
      }
    });
  };

  const handlePrimaryPositionChanged = (e, chartSID) => {
    let tempObj = primaryPos;
    tempObj[chartSID] = e.target.value;
    setPrimaryPos({
      ...primaryPos,
      [chartSID]: e.target.value,
    });
  };

  const GetAvailablePositions = () => {
    setAvailablePosLoading(true);

    const url = urlJoin(resServerBaseUrl, "/User/GetAvailablePositions");
    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify({ ID: userInfo.id })
    )
      .then((response) => {
        if (response) {
          setAvailablePos(response);
          let primarypos = {};
          response.map((r) => {
            if (r.Positions.find((p) => p.IsPrimaryPosition == true).UUID) {
              primarypos[r.SID] = r.Positions.find(
                (p) => p.IsPrimaryPosition == true
              ).UUID;
              console.log(primarypos);

              setPrimaryPos(primarypos);
            }
          });
        }
      })
      .catch((ex) => {})
      .finally(() => {
        setAvailablePosLoading(false);
      });
  };

  const handleTransferDialogOpen = () => {
    setTransferDialogOpen(true);
    setUserAutocompleteLoading(true);
    const url = urlJoin(resServerBaseUrl, "/User/GetAllUserByCompany");
    CallApiWithContext(url, authenticationContext)
      .then((response) => {
        if (response) {
          let standardUserObj = response.userList.filter(
            (u) =>
              u.accountType === 0 &&
              u.accountDeleted === false &&
              u.accountDisabled === false
          );
          let standardUserOption = standardUserObj.map((u) => {
            let name =
              (JSON.parse(u.attr)?.FULLNAME ?? "") === ""
                ? JSON.parse(u.attr)?.EMAIL
                : JSON.parse(u.attr)?.FULLNAME;
            return {
              label: name,
              value: u.id,
            };
          });
          setUserListForTransfer(standardUserOption);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setUserAutocompleteLoading(false);
      });
  };

  const UpdateUserPrimaryPosition = () => {
    setTransferBtnLoading(true);
    const data = { UserID: newAdminUserID };
    const url = urlJoin(resServerBaseUrl, "/User/TransferAdminRight"); //update
    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (response.successed) {
          showAlertMessage({
            title: t("profile.transfer_admin_right"),
            content: t("profile.transfer_success"),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  closeAlertMessage();
                  setTransferDialogOpen(false);
                  SignOut();
                },
              },
            ],
          });
        } else {
          showAlertMessage({
            title: t("profile.transfer_admin_right"),
            content: t("profile.proxy_and_delegation.error"),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  closeAlertMessage();
                },
              },
            ],
          });
        }
      })
      .catch((error) => {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: t("profile.custom_property.custom_property_error_message"),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
              },
            },
          ],
        });
      })
      .finally(() => {
        setTransferBtnLoading(false);
      });
  };

  const handleUserEditorValueChange = () => {
    if (userEditorRef.current) {
      setIsDisablePersonalInfoSaveButton(
        userEditorRef.current.hasError() ||
          !userEditorRef.current.isUserInfoChanged()
      );
    }
  };

  const SignOut = () => {
    const url = urlJoin(authServerBaseUrl, "/sign-out");
    CallApiWithContext(url, [isAuthenticated, setIsAuthenticated], null)
      .then((response) => {
        setProfile((prev) => ({ ...prev, profileImage: null }));
        setIsAuthenticated(
          false,
          "sign_out_dialog.sign_out_succeeded",
          MessageType.Success
        );
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    //setIsInitStage(true);
    if (!security && !adminConsoleSecurity) {
      console.log("security is null");
      getUserInfo();
      getPasswordRule();
      setTabValue("profile");
    } else {
      setTabValue("admin-console");
      setAdminConsoleSecurity(security);
      setAdminConsole(true);
    }

    // eslint-disable-next-line
  }, [security]);
  React.useEffect(() => {
    if (adminConsoleSecurity) {
      if (
        adminConsoleSecurity["AdminConsole"] !== undefined &&
        adminConsoleSecurity["AdminConsole"] !== "No_Access"
      ) {
        setAdminConsole(true);
      }
    }
  }, [adminConsoleSecurity]);
  React.useEffect(() => {
    if (!security) loadTable();
    // eslint-disable-next-line
  }, [tabValue]);
  React.useEffect(() => {
    GetAvailablePositions();
  }, [userInfo]);
  React.useEffect(() => {
    setTitle(t("top_right_corner_menu.profile"));
    if (!security) {
      loadTable();
      loadCustomPropTable(userAttr);
    }
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    let okText = /^[a-zA-Z0-9+-_ ]+$/;
    if (
      (tempCustomProp.propertyName === "" &&
        tempCustomProp.propertyValue === "") ||
      (tempCustomProp.propertyName === null &&
        tempCustomProp.propertyValue === null)
    ) {
      setIsAddEditCustomPropNameError(false);
      setIsAddEditCustomPropValueError(false);
      setIsDisableAddEditCustomPropSave(true);
    } else if (
      tempCustomProp.propertyName !== "" &&
      tempCustomProp.propertyName !== null
    ) {
      if (!okText.test(tempCustomProp.propertyName)) {
        setIsAddEditCustomPropNameError(true);
        setIsDisableAddEditCustomPropSave(true);
      } else {
        setIsAddEditCustomPropNameError(false);
        if (
          tempCustomProp.propertyValue === "" ||
          tempCustomProp.propertyValue === null
        ) {
          setIsAddEditCustomPropValueError(true);
          setIsDisableAddEditCustomPropSave(true);
        } else {
          setIsAddEditCustomPropValueError(false);
          setIsDisableAddEditCustomPropSave(false);
        }
      }
    } else {
      setIsAddEditCustomPropNameError(true);
      setIsDisableAddEditCustomPropSave(true);
    }
  }, [tempCustomProp]);
  React.useEffect(() => {
    if (isAddEditCustomPropSave) {
      //For checking it is not initiating the page
      callApiToSaveCustomProp(userAttr);
    }
    // eslint-disable-next-line
  }, [isAddEditCustomPropSave]);

  return (
    <Stack sx={{ height: "100%" }}>
      <CssBaseline />
      <TabContext value={tabValue}>
        <Grid container>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            {
              <Tab
                label={t("profile.profile")}
                component={Link}
                to="/profile"
                value="profile"
                onClick={() => {
                  // getUserInfo();
                  getPasswordRule();
                }}
              />
            }
            {adminConsole ? (
              <Tab
                label={t("profile.administrative_console")}
                component={Link}
                to="/admin-console"
                value="administrativeConsole"
              />
            ) : null}
          </Tabs>
        </Grid>
        {(!security || release) && (
          <TabPanel
            value="profile"
            sx={{
              overflow: "auto",
              height: "100%",
              width: "100%",
              p: { xs: "10px", sm: "20px" },
            }}
          >
            <Grid
              container
              sx={{
                bgcolor: `secondaryContainer.${themeColorContext[0]}`,
              }}
            >
              <Grid
                item
                container
                direction="row"
                xs={12}
                sx={{
                  height: "40%",
                  display: "flex",
                  flexWrap: "wrap",
                  p: { xs: "5px", sm: "20px" },
                }}
                className="profile-ProxyDelegation"
              >
                <Grid
                  item
                  container
                  xs={12}
                  md={3}
                  direction="column"
                  className="Profile-container"
                  sx={{ pt: "50px", pb: "50px" }}
                >
                  <Grid
                    item
                    container
                    className="profile-Icon"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      //sm={6}
                      justifyContent="center"
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <AccountCircleIcon />
                        </Grid>
                        <Grid item>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              width={isLoadingPersonalInfo ? 100 : "100%"}
                            >
                              {isLoadingPersonalInfo ? (
                                <Skeleton variant="rounded" />
                              ) : (
                                userAttr?.FULLNAME ??
                                userAttr?.EMAIL?.split("@")[0] ??
                                ""
                              )}
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Card
                          style={{
                            display: "block",
                            marginTop: "10px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                            maxHeight: "200px",
                            maxWidth: "200px",
                            backgroundColor: "#F8F8F8",
                          }}
                        >
                          {
                            <CardMedia
                              sx={{ display: loadingImg ? "none" : "block" }}
                              component="img"
                              image={profileImage}
                            />
                          }

                          {loadingImg && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "80px",
                                marginBottom: "80px",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "100px",
                              marginBottom: "100px",
                            }}
                          ></Box>
                        </Card>
                      </Grid>
                      <Grid item>
                        <Button
                          xs={12}
                          variant="contained"
                          component="label"
                          startIcon={<PhotoCamera />}
                          style={{ marginTop: "10px" }}
                        >
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            // onChange={(input) => setFileInput(input)}
                            onChange={handleFileChange}
                          />
                          {t("profile.upload")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          xs={12}
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                          variant="contained"
                          component="label"
                          onClick={handleChangePasswordDialogOpen}
                        >
                          {t("profile.change_password")}
                        </Button>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <Typography
                          variant="p"
                          width="100%"
                          style={{ marginTop: "10px", color: "red" }}
                        >
                          {imageMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={9}
                  direction="column"
                  className="Profile-container"
                  sx={{ p: "10px" }}
                >
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    display="flex"
                  >
                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        xs={12}
                        display="flex"
                        justifyContent={"space-between"}
                        style={{ textAlign: "left" }}
                      >
                        <Grid item xs={"auto"}>
                          <TitleWithIcon
                            icon={<BorderColorIcon />}
                            title={{
                              content: t("profile.personal_title"),
                              variant: "h6",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          />
                        </Grid>
                        {userInfo.accountType === 1 ? (
                          <Grid
                            container
                            xs={"auto"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <Grid item>
                              <Chip
                                color="primary"
                                size="small"
                                label={t("profile.organization_admin")}
                              />
                            </Grid>
                            <Grid item>
                              <Tooltip
                                title={t("profile.transfer_admin_right")}
                              >
                                <IconButton
                                  onClick={() => handleTransferDialogOpen()}
                                  disabled={isLoadingPersonalInfo}
                                >
                                  <SocialDistance />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} textAlign={"left"}>
                        <Typography
                          variant="p"
                          style={{
                            color: "gray",
                            fontSize: "14px",
                            textAlign: "start",
                          }}
                        >
                          {t("profile.personal_info")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="column"
                    className="profile-pannel"
                    sx={{ pl: "0px", pt: "20px", pr: "0px" }}
                  >
                    {!isLoadingPersonalInfo &&
                    userInfo != null &&
                    userAttr != null ? (
                      <Grid container>
                        <Grid item>
                          <UserEditor
                            ref={userEditorRef}
                            user={userUUID}
                            onValueChange={handleUserEditorValueChange}
                            moduleEditorVisible={false}
                            showIcon={true}
                            disablePositionField={true}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          style={{
                            paddingTop: "10px",
                            justifyContent: "right",
                            textAlign: "right",
                          }}
                        >
                          <LoadingButton
                            variant="contained"
                            disabled={isDisablePersonalInfoSaveButton}
                            loading={isLoadingPersonalInfoSave}
                            onClick={handlePersonalInfoSave}
                            type="submit"
                          >
                            {t("profile.proxy_and_delegation.save")}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        justifySelf="center"
                        sx={{ p: "3%" }}
                      >
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={6} sm={12}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={6} sm={12}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <PersonalInfoSkeleton />
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <PersonalInfoSkeleton />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Skeleton variant="rounded" height={60} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Typography
                            variant="p"
                            style={{ height: "10px" }}
                          ></Typography>
                        </Grid>

                        <Grid item xs={11} sm={11}></Grid>
                        <Grid item xs={1} sm={1}>
                          <Skeleton variant="rounded" height={30} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {profile?.accountType !== 2 && (
                <>
                  {/* available position */}
                  <Grid container p={2} direction={"row"} spacing={1}>
                    <Grid item container xs={12} md={6}>
                      <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        spacing={1}
                        sx={{ px: { xs: "5px", sm: "20px" } }}
                      >
                        <Grid container direction={"row"}>
                          <Grid item>
                            <TitleWithIcon
                              icon={<WorkIcon />}
                              title={{
                                content: t("profile.available_positions"),
                                variant: "h6",
                                alignItems: "center",
                                fontWeight: "bold",
                              }}
                            />

                            <Typography
                              variant="p"
                              sx={{
                                color: "gray",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                            >
                              {t("profile.available_positions_description")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <PrimaryPositionTable
                            PrimaryPositionObj={primaryPos}
                            onClickFunction={handlePrimaryPositionChanged}
                            AvailablePosLoading={availablePosLoading}
                            AvailableArr={availablePos}
                          />
                          {/* {PositonOrGroupTable(availablePos)} */}
                        </Grid>
                        <Grid item container justifySelf={"right"}>
                          <LoadingButton
                            variant="contained"
                            loading={isLoadingPersonalInfoSave}
                            disabled={
                              isPrimaryPositionChanged || !isPositionChangable
                            }
                            onClick={() => callApiToSaveCustomProp(userAttr)}
                          >
                            {t("profile.proxy_and_delegation.save")}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container md={6}>
                      {/* TODO: Add available group list
                  <Grid container direction={"column"}>
                    <Grid item>
                      <TitleWithIcon
                        icon={<WorkIcon />}
                        title={{
                          content: "Available position",
                          variant: "h6",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item>{PositonOrGroupTable(availablePos)}</Grid>
                  </Grid> */}
                    </Grid>
                  </Grid>

                  {/* proxy */}
                  <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    sx={{
                      height: "20%",
                      display: "flex",
                      px: { xs: "5px", sm: "20px" },
                    }}
                    className="profile-ProxyDelegation"
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      style={{ textAlign: "left" }}
                    >
                      <TitleWithIcon
                        icon={<PersonIcon />}
                        title={{
                          content: t("profile.proxy_and_delegation.proxy_user"),
                          variant: "h6",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item container>
                      <Typography
                        variant="p"
                        style={{
                          color: "gray",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {t("profile.proxy_and_delegation.proxy_description")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ProxyDelegationEditor
                        type={ProxyDelegateType.Proxy}
                        data={ProxyTableRows}
                        isLoading={isLoadingProxyTable}
                        originalUserID={originalUserId}
                        isAdmin={caseTplAuthen}
                        caseTplRecord={caseTplRecord}
                      />
                    </Grid>
                  </Grid>

                  {/* delegate */}
                  <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    sx={{
                      height: "20%",
                      display: "flex",
                      px: { xs: "5px", sm: "20px" },
                    }}
                    className="profile-ProxyDelegation"
                  >
                    <Grid
                      container
                      direction="row"
                      style={{ textAlign: "left" }}
                    >
                      <TitleWithIcon
                        icon={<AssignmentIndIcon />}
                        title={{
                          content: t("profile.proxy_and_delegation.delegation"),
                          variant: "h6",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid container>
                      <Typography
                        variant="p"
                        style={{
                          color: "gray",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {t(
                          "profile.proxy_and_delegation.delegation_description"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ProxyDelegationEditor
                        type={ProxyDelegateType.Delegation}
                        data={DelegationTableRows}
                        isLoading={isLoadingDelegationTable}
                        originalUserID={originalUserId}
                        isAdmin={caseTplAuthen}
                        caseTplRecord={caseTplRecord}
                      />
                    </Grid>
                  </Grid>

                  {/* custom property */}
                  <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    sx={{
                      height: "20%",
                      display: "flex",
                      px: { xs: "5px", sm: "20px" },
                    }}
                    className="profile-ProxyDelegation"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      style={{ textAlign: "left", display: "flex" }}
                    >
                      <TitleWithIcon
                        icon={<AccountBoxIcon />}
                        title={{
                          content: t("profile.custom_property.custom_property"),
                          variant: "h6",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      />
                      {/* <AccountBoxIcon />
                  <h3>{t("profile.custom_property.custom_property")}</h3> */}
                    </Grid>
                    <Grid container>
                      <Typography
                        variant="p"
                        style={{
                          color: "gray",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {t(
                          "profile.custom_property.custom_property_description"
                        )}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      style={{ justifyContent: "right", textAlign: "right" }}
                    >
                      {isLoadingCustomPropTable ? (
                        <Grid
                          style={{
                            justifyContent: "right",
                            textAlign: "right",
                          }}
                        >
                          <Skeleton variant="circular" />
                        </Grid>
                      ) : (
                        <Grid
                          container
                          style={{
                            justifyContent: "right",
                          }}
                        >
                          <IconButton
                            color="primary"
                            onClick={handleOpenAddCustomPropDialog}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      )}
                      <Dialog
                        open={openAddEditCustomPropDialog}
                        onClose={handleCloseAddEditCustomPropDialog}
                      >
                        <DialogTitle>
                          {isAddCustomProp
                            ? t("profile.custom_property.add_custom_property")
                            : t("profile.custom_property.edit_custom_property")}
                        </DialogTitle>
                        <DialogContent>
                          <Grid container direction="column">
                            <Grid container direction="row">
                              <Grid
                                container
                                item
                                xs={6}
                                sx={{
                                  justifyContent: "left",
                                  alignContent: "left",
                                  p: "10px",
                                }}
                              >
                                <Typography
                                  variant="p"
                                  style={{
                                    color: "gray",
                                    justifyContent: "left",
                                    alignContent: "left",
                                  }}
                                >
                                  {t("profile.custom_property.custom_property")}
                                </Typography>
                              </Grid>

                              <Grid
                                container
                                item
                                xs={6}
                                sx={{
                                  justifyContent: "left",
                                  alignContent: "left",
                                  p: "10px",
                                }}
                              >
                                <Typography
                                  variant="p"
                                  style={{ color: "gray" }}
                                >
                                  {t("profile.custom_property.dialog_value")}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container direction="row">
                              <Grid container item xs={6}>
                                <TextField
                                  value={tempCustomProp.propertyName}
                                  error={isAddEditCustomPropNameError}
                                  onChange={handleCustomPropertyChange(
                                    "propertyName"
                                  )}
                                  sx={{ p: "10px" }}
                                />
                              </Grid>
                              <Grid container item xs={6}>
                                <TextField
                                  value={tempCustomProp.propertyValue}
                                  error={isAddEditCustomPropValueError}
                                  onChange={handleCustomPropertyChange(
                                    "propertyValue"
                                  )}
                                  sx={{ p: "10px" }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <LoadingButton
                            variant="contained"
                            disabled={isDisableAddEditCustomPropSave}
                            loading={isLoadingCustomPropSave}
                            onClick={(event) => handleSaveCustomProp(event)}
                          >
                            {t("profile.proxy_and_delegation.save")}
                          </LoadingButton>
                          <Button
                            onClick={handleCloseAddEditCustomPropDialog}
                            color="error"
                          >
                            {t("profile.proxy_and_delegation.cancel")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>

                    <Grid item>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  padding: isSmallDevice ? "10px" : "16px",
                                },
                              }}
                            >
                              {customPropHeaders.map((title, index) => (
                                <TableCell key={`header_cell_${index}`}>
                                  {title}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          {isLoadingCustomPropTable ? (
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Skeleton
                                    variant="text"
                                    sx={{ width: "30%", height: "30px" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Skeleton
                                    variant="text"
                                    sx={{ width: "30%", height: "30px" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Grid container direction="row">
                                    <Grid>
                                      <Skeleton
                                        variant="circular"
                                        sx={{ width: "25px", height: "25px" }}
                                      />
                                    </Grid>
                                    <Grid sx={{ width: "20px" }}></Grid>
                                    <Grid>
                                      <Skeleton
                                        variant="circular"
                                        sx={{ width: "25px", height: "25px" }}
                                      />
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : noCustomProp ? (
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {t(
                                    "profile.custom_property.no_custom_property"
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : (
                            <TableBody>
                              {Object.keys(customProp).map((row, index) => {
                                return (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        padding: isSmallDevice
                                          ? "10px"
                                          : "16px",
                                      },
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ wordBreak: "break-word" }}
                                    >
                                      {row.toLowerCase().replace(/_/g, " ")}
                                    </TableCell>
                                    <TableCell>{customProp[row]}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        onClick={() =>
                                          handleOpenEditCustomPropDialog(row)
                                        }
                                        color="primary"
                                      >
                                        <BorderColorIcon fontSize="small" />
                                      </IconButton>
                                      {isLoadingCustomPropDelete ? (
                                        <LoadingButton loading />
                                      ) : (
                                        <IconButton
                                          onClick={() =>
                                            handleOpenDeleteCustomPropDialog(
                                              row
                                            )
                                          }
                                          color="primary"
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    </TableCell>

                                    <Dialog
                                      open={openDeleteCustomPropDialog}
                                      onClose={
                                        handleCloseDeleteCustomPropDialog
                                      }
                                    >
                                      <Grid>
                                        <DialogTitle>
                                          {t(
                                            "profile.proxy_and_delegation.confirm_delete"
                                          )}
                                        </DialogTitle>
                                      </Grid>
                                      <Grid>
                                        <DialogActions>
                                          <LoadingButton
                                            variant="contained"
                                            loading={
                                              isLoadingCustomPropDeleteDialog
                                            }
                                            onClick={(event) =>
                                              handleDeleteCustomProp(event)
                                            }
                                          >
                                            {t(
                                              "profile.proxy_and_delegation.delete"
                                            )}
                                          </LoadingButton>
                                          <Button
                                            onClick={
                                              handleCloseDeleteCustomPropDialog
                                            }
                                            color="error"
                                          >
                                            {t(
                                              "profile.proxy_and_delegation.cancel"
                                            )}
                                          </Button>
                                        </DialogActions>
                                      </Grid>
                                    </Dialog>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* custom settings */}
              <Grid
                container
                item
                xs={12}
                direction="column"
                sx={{ height: "20%", px: { xs: "5px", sm: "20px" } }}
                className="profile-ProxyDelegation"
              >
                <Grid container direction="row" style={{ textAlign: "left" }}>
                  <TitleWithIcon
                    icon={<SettingsIcon />}
                    title={{
                      content: t("profile.custom_setting.custom_setting"),
                      variant: "h6",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  />
                </Grid>
                <Grid container>
                  <Typography
                    variant="p"
                    style={{
                      color: "gray",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    {t("profile.custom_setting.custom_setting_description")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{ justifyContent: "right", textAlign: "right" }}
                >
                  {isLoadingCustomPropTable ? (
                    <Grid
                      style={{ justifyContent: "right", textAlign: "right" }}
                      sx={{ p: "5px" }}
                    >
                      <Skeleton
                        variant="circular"
                        sx={{ width: "25px", height: "25px" }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      style={{ justifyContent: "right", textAlign: "right" }}
                    >
                      <IconButton
                        color="primary"
                        onClick={handleOpenSaveCustomSettingDialog}
                      >
                        <SaveIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: isSmallDevice ? "10px" : "16px",
                            },
                          }}
                        >
                          {customSettingHeaders.map((title, index) => (
                            <TableCell key={`header_cell_${index}`}>
                              {title}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {isLoadingPersonalInfo ? (
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Skeleton
                                variant="text"
                                sx={{ width: "50%", height: "30px" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Skeleton
                                variant="text"
                                sx={{ width: "50%", height: "30px" }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow
                            sx={{
                              "& .MuiTableCell-root": {
                                padding: isSmallDevice ? "10px" : "16px",
                              },
                            }}
                          >
                            <TableCell>
                              {t(
                                "profile.custom_setting.setting_description.anti_virus_bypass"
                              )}
                            </TableCell>
                            <TableCell>
                              <FormGroup>
                                <FormControlLabel
                                  //sx={{ fontSize: { xs: "16px", sm: "14px" } }}
                                  control={
                                    <Checkbox
                                      checked={customSetting.AVSERVERBYPASS}
                                      onChange={handleCustomSettingChange(
                                        "AVSERVERBYPASS"
                                      )}
                                    />
                                  }
                                  label={
                                    <Box component="div" fontSize={14}>
                                      {t(
                                        "profile.custom_setting.setting_description.value_bypass"
                                      )}
                                    </Box>
                                  }
                                />
                              </FormGroup>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={customSetting.KEEPAVSERVERBYPASS}
                                      onChange={handleCustomSettingChange(
                                        "KEEPAVSERVERBYPASS"
                                      )}
                                    />
                                  }
                                  label={
                                    <Box component="div" fontSize={14}>
                                      {t("profile.custom_setting.remember")}
                                    </Box>
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
                <Dialog
                  open={openSaveCustomSettingDialog}
                  onClose={handleCloseSaveCustomSettingDialog}
                >
                  <DialogTitle
                    sx={{
                      color: "gray",
                    }}
                  >
                    {t("profile.custom_setting.confirm_setting")}
                  </DialogTitle>
                  <DialogActions
                    sx={{
                      color: "gray",
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      loading={isLoadingCustomSettingSave}
                      onClick={(event) => handleSaveCustomSetting(event)}
                    >
                      {t("profile.proxy_and_delegation.save")}
                    </LoadingButton>
                    <Button
                      onClick={handleCloseSaveCustomSettingDialog}
                      color="error"
                    >
                      {t("profile.proxy_and_delegation.cancel")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

              {/* Change password dialog */}

              <ChangePasswordDialog
                rules={rules}
                ref={changePasswordRef}
                userUUID={""}
                userLogin={userInfo?.login ?? ""}
                isAdminChange={false}
              />

              {/* Transfer admin right dialog */}
              <Dialog
                open={transferDialogOpen}
                onClose={() => {
                  setTransferDialogOpen(false);
                  setNewAdminUserID("");
                }}
              >
                <DialogTitle>{t("profile.transfer_admin_right")}</DialogTitle>
                <DialogContent>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="p">
                        {t("profile.transfer_admin_right_description")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {userAutocompleteLoading ? (
                        <Skeleton>
                          <TextField />
                        </Skeleton>
                      ) : (
                        <Autocomplete
                          options={userListForTransfer}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("profile.proxy_and_delegation.user")}
                            />
                          )}
                          onChange={(e, value) =>
                            setNewAdminUserID(value.value)
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <LoadingButton
                    variant="contained"
                    disabled={newAdminUserID === ""}
                    loading={transferBtnLoading}
                    onClick={() => UpdateUserPrimaryPosition()}
                  >
                    {t("profile.proxy_and_delegation.save")}
                  </LoadingButton>
                </DialogActions>
              </Dialog>

              <SharedDialog
                isOpen={showAlertDialog}
                onClose={() => setShowAlertDialog(false)}
                title={alertDialog.title}
                content={alertDialog.content}
                buttons={alertDialog.buttons}
              />
            </Grid>
          </TabPanel>
        )}
      </TabContext>
    </Stack>
  );
};

export default Profile;
