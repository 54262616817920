import * as React from "react";
import { Container, CssBaseline, Grid, Paper, TextField } from "@mui/material";
import urlJoin from "url-join";
import { CallApiWithoutContext } from "../../helpers/ApiHelper";
import { authServerBaseUrl } from "../../Config";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ResetPasswordSection } from "../../shared/PasswordRuleDisplay";
import SharedDialog from "../../shared/SharedDialog";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Logo from "../../content/img/logo-small.png";
import Box from "@mui/material/Box";

export default function ResetPassword(props) {
  const { token, login } = props;
  const { t } = useTranslation();
  const [rules, setRules] = React.useState();
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const resetPasswordSessionRef = React.useRef();

  React.useEffect(() => {
    GetPasswordRule();
  }, []);

  function GetPasswordRule() {
    resetPasswordSessionRef.current.ruleLoadingTrue();
    const url = urlJoin(authServerBaseUrl, "/getPasswordRule");
    const data = { Token: token };
    CallApiWithoutContext(url, data)
      .then((response) => {
        if (response) {
          setRules(response);
        }
      })
      .catch((ex) => {
        setRules(null);
      })
      .finally((e) => {
        resetPasswordSessionRef.current.ruleLoadingFalse();
      });
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box sx={{ marginTop: 8 }} component="img" src={Logo}></Box>
      <Grid
        container
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="p">
              {t("forgot_password_dialog.reset_password")}
            </Typography>
          </Grid>
          {
            <ResetPasswordSection
              ref={resetPasswordSessionRef}
              rules={rules}
              token={token}
              login={login}
            />
          }
        </Grid>

        <SharedDialog
          isOpen={showAlertDialog}
          onClose={() => {
            setShowAlertDialog(false);
            window.location.reload();
          }}
          title={alertDialog.title}
          content={alertDialog.content}
          buttons={alertDialog.buttons}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 8, mb: 4 }}
        >
          {t("login_page.copyright")}
          {" © "}
          {new Date().getFullYear()}{" "}
          <Link color="inherit" href="https://flexsystem.com/" target="_blank">
            FlexSystem Limited
          </Link>
          {"."}
        </Typography>
      </Grid>
    </Container>
  );
}

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
};
