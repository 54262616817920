const formSchema = `{
  "schemaVersion": 1,
  "exporter": {
    "name": "form-js",
    "version": "0.1.0"
  },
  "components": [
    
  ],
  "type": "default"
}`;

export default formSchema;
