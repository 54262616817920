import * as wijmo from "@grapecity/wijmo";
export class TotalDisplay extends wijmo.Control {
  constructor(element, owner) {
    super(element, null, false);
    //
    this._owner = owner;
    //
    this.applyTemplate(
      "total-amount-display",
      `
            <div wj-part="total-amount-container">
                <span wj-part="total_title">Total: </span>
                <span wj-part="total" class="total"></span>
            </div>
        `,
      {
        _totalAmountContainer: "total-amount-container",
        _total_title: "total_title",
        _total: "total",
      }
    );
  }
  initDisplay(total, total_title) {
    if (total) {
      this._total.innerText = parseInt(total).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this._total_title.innerText = total_title + ": ";
      this._total.style.display = "block";
      this._total_title.style.display = "block";
      this._totalAmountContainer.style.fontWeight = "bold";
    }

    let host = this.hostElement,
      parent = this._owner.hostElement;
    //
    host.style.height = 0;
    host.style.position = "relative";
    host.style.top =
      parent.offsetHeight / 2 - host.offsetHeight / 2 - 30 + "px";
    host.style.left = parent.offsetWidth / 2 - host.offsetWidth / 2 + "px";
  }
}
