// export const authServerBaseUrl = process.env.NODE_ENV === 'development' ? 'http://fwflite.flexworkflow.com/api/authServer/' : 'http://fwflite.flexworkflow.com/api/authServer/';
// export const resServerBaseUrl = process.env.NODE_ENV === 'development' ? 'http://fwflite.flexworkflow.com/api/resServer/' : 'http://fwflite.flexworkflow.com/api/resServer/';
export const authServerBaseUrl =
  process.env.NODE_ENV === "development"
    ? "http://172.23.12.2:51551"
    : "/api/authServer/";
export const resServerBaseUrl =
  process.env.NODE_ENV === "development"
    ? "http://172.23.12.2:55551"
    : "/api/resServer/";
export const testServerBaseUrl =
  process.env.NODE_ENV === "development"
    ? "http://172.23.12.72:55551"
    : "/api/resServer/";
export const firebaseConfig = {
  apiKey: "AIzaSyCtKPH7JPjKQUwaB1ZXKAqs6Q_Rqr1a2_w",
  authDomain: "flexworkflow-rx.firebaseapp.com",
  databaseURL: "https://flexworkflow-rx.firebaseio.com",
  projectId: "flexworkflow-rx",
  storageBucket: "flexworkflow-rx.appspot.com",
  messagingSenderId: "933964784976",
  appId: "1:933964784976:web:b7bab3974ca81c79fcf855",
};
