import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CallApiWithoutContext,
  CallApiWithContext,
} from "../../helpers/ApiHelper";
import { authServerBaseUrl, resServerBaseUrl } from "../../Config";
import { useTranslation } from "react-i18next";
import { Alert, Icon, Stack } from "@mui/material";
import LoadingSpinner from "../../shared/LoadingSpinner";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import urlJoin from "url-join";
import Logo from "../../content/img/FlexCASE logowithtext 223x43.png";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";
import axios, { AxiosError } from "axios";
import { MessageType } from "../../Constants";
import SharedDialog from "../../shared/SharedDialog";
import { ChangePasswordDialog } from "../../shared/PasswordRuleDisplay";

const Copyright = (props) => {
  const { t } = useTranslation();
  const { children, ...others } = props;

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...others}
    >
      {`${t("login_page.copyright")} © ${new Date().getFullYear()} `}
      {children}
      {"."}
    </Typography>
  );
};

export default function Login(props) {
  const { t } = useTranslation();

  const [isAuthenticated, setIsAuthenticated, message, messageType] =
    useAuthentication();
  const [username, setUsername] = React.useState(
    localStorage.getItem("rememberMe") &&
      localStorage.getItem("rememberMe") === "true" &&
      localStorage.getItem("username")
      ? localStorage.getItem("username")
      : ""
  );
  const [password, setPassword] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [dialogMessage, setDialogMessage] = React.useState(null);
  const [alertMsg, setAlertMsg] = React.useState(message ?? "");
  const [alertMsgType, setAlertMsgType] = React.useState(messageType);
  const [rememberMe, setRememberMe] = React.useState(
    localStorage.getItem("rememberMe")
      ? localStorage.getItem("rememberMe") === "true"
      : false
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpen] = React.useState(false);
  const [userID, setUserID] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [rules, setRules] = React.useState();
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const changePasswordRef = React.useRef();
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };

  const passwordRef = React.useRef();
  const onUsernameChanged = (event) => {
    setUsername(event.target.value);
  };

  const onPasswordChanged = (event) => {
    setPassword(event.target.value);
  };

  const onRememberMeChanged = (event) => {
    setRememberMe(event.target.checked);
  };

  const onEmailAddressChanged = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogMessage(null);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const resetPassword = () => {
    //Send em
    setDialogMessage(t("forgot_password_dialog.please_wait"));
    const url = urlJoin(authServerBaseUrl, "/requestResetPassword");
    const data = { EmailAddress: emailAddress };
    CallApiWithoutContext(url, data)
      .then((response) => {
        if (response) {
          setDialogMessage(t("forgot_password_dialog.ok_message"));
        }
      })
      .catch((error) => {
        setDialogMessage(t("forgot_password_dialog.failed_message"));
      });
  };

  const handleSubmit = (event, newPassword) => {
    event.preventDefault();

    setIsLoading(true);

    const url = urlJoin(authServerBaseUrl, "/login");
    const data = {
      Login: username,
      Password: newPassword || password,
    };

    CallApiWithoutContext(url, data)
      .then((response) => {
        console.log(response);
        if (response.token) {
          localStorage.setItem("rememberMe", rememberMe);
          // if (rememberMe) {
          localStorage.setItem("username", username);
          // } else {
          //     localStorage.removeItem("username");
          // }

          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("accessToken", response.token);
            localStorage.setItem("refreshToken", response.refreshToken);
          }
          setUserID(response.id);
          if (!response.changePasswordNextLogon) {
            setIsAuthenticated(true);
            // if(!event){
            // window.location.reload(false);
            // }
          } else {
            changePasswordRef.current.openChangePasswordDialog();
          }
          //setChangePasswordNextLogon(true);
          // setAccessToken(response.token);
        }
      })
      .catch((error) => {
        if (error?.response?.status ?? 0 === 400) {
          if (error.response.data.errorCode === "0x1003") {
            setAlertMsg("login_page.license_not_found");
          } else if (error.response.data.errorCode === "0x1004") {
            setAlertMsg("login_page.license_expired");
          } else if (error.response.data.errorCode == "0x1005") {
            setAlertMsg({
              message: "login_page.session_full",
              maxValue: error.response.data.maxSession,
            });
          } else if (error.response.data.errorCode == "0x1006") {
            setAlertMsg(
              "Multiple organization admin was found. Please find system admin for more information."
            );
          } else {
            setAlertMsg("login_page.invalid_login");
          }
          setAlertMsgType(MessageType.Error);
        } else if (error?.response?.status ?? 0 === 0) {
          setAlertMsg("profile.proxy_and_delegation.server_no_response");
          setAlertMsgType(MessageType.Error);
        }
      })
      .finally(() => {
        setIsLoading(false);
        getPasswordRule();
      });
  };

  const getPasswordRule = () => {
    const url = urlJoin(resServerBaseUrl, "/User/GetPasswordRule");
    CallApiWithContext(url, isAuthenticated)
      .then((response) => {
        if (response) {
          setRules(response);
        }
      })
      .catch((ex) => {});
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
        <Box component="img" src={Logo} width={"40%"}></Box>
        {/* <LockOutlinedIcon /> */}
        {/* </Avatar> */}
        <Typography component="h1" variant="h5">
          {t("login_page.sign_in")}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "90%" }}
        >
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              label={t("login_page.user_name")}
              autoFocus
              value={username}
              autoComplete="off"
              onChange={onUsernameChanged}
            />
            <TextField
              required
              fullWidth
              label={t("login_page.password")}
              type={showPassword ? "text" : "password"}
              onChange={onPasswordChanged}
              inputRef={passwordRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {alertMsg && (
            <Alert
              severity={
                alertMsgType === MessageType.Success ? "success" : "error"
              }
              sx={{ mt: 1, textAlign: "left" }}
            >
              {/*
               * because of ApiHelper cannot use translation
               * therefore, translation key was passed from ApiHelper.
               */}
              {typeof alertMsg == "string"
                ? t(alertMsg)
                : t(alertMsg.message, alertMsg)}
            </Alert>
          )}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={rememberMe}
                  onChange={onRememberMeChanged}
                />
              }
              label={t("login_page.remember_me")}
            />
            <Link href="#" variant="body2" onClick={handleClickOpen}>
              {t("login_page.forgot_password_q")}
            </Link>
          </Stack>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            {t("login_page.sign_in")}
          </Button>

          {openDialog ? (
            <Dialog open={openDialog} onClose={handleClose}>
              <DialogTitle>
                {t("forgot_password_dialog.forgot_password")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("forgot_password_dialog.forgot_context")}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={t("forgot_password_dialog.email_address")}
                  type="name"
                  fullWidth
                  variant="standard"
                  onChange={onEmailAddressChanged}
                />
                {dialogMessage ? (
                  <DialogContentText>{dialogMessage}</DialogContentText>
                ) : null}
              </DialogContent>
              <DialogActions>
                <Button
                  type="resetPassword"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={resetPassword}
                >
                  {t("forgot_password_dialog.reset_password")}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
          <ChangePasswordDialog
            ref={changePasswordRef}
            rules={rules}
            userUUID={""}
            userLogin={username ?? ""}
            isAdminChange={false}
            LoginFunction={handleSubmit}
          />
        </Box>
      </Box>

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />

      <Copyright sx={{ mt: 8, mb: 4 }}>
        <Link color="inherit" href="https://flexsystem.com/" target="_blank">
          FlexSystem Limited
        </Link>
      </Copyright>
      <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
    </Container>
  );
}
