import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LastPageIcon from "@mui/icons-material/LastPage";
import RefreshIcon from "@mui/icons-material/Refresh";
import urlJoin from "url-join";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { Stack, TablePagination } from "@mui/material";
import { resServerBaseUrl } from "../../Config";
import SearchBar from "../SearchBar/SearchBar";
import "../../MailBox.css";
import SharedDialog from "../../shared/SharedDialog";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import { useProfile } from "../../providers/ProfileProvider";
import CustomToolbar from "../../shared/CustomToolbar";
import InOutboxList from "../../shared/InOutboxList";

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, parseInt(page) - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, parseInt(page) + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Grid sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Grid>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Inbox = (props) => {
  const { settitle: setTitle, isMdUp } = props;
  const { pages } = useParams();
  const nav = useNavigate();
  const [totalRecordNum, setTotalRecordNum] = React.useState(0);
  const [page, setPage] = React.useState(pages && !isNaN(pages) ? pages : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchData, setSearchData] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [allSelected, setAllSelected] = React.useState([]);
  const { t } = useTranslation();
  const [inboxObjects, setInboxObjects] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const authenticationContext = useAuthentication();
  const [profile] = useProfile();
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [noPermission, setNoPermission] = React.useState(false);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const themeColorContext = useThemeColor();

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const SelectAllCheckBox = (props) => {
    const { onSelectAllClick, numSelected, rowCount, disabled = false } = props;

    return (
      <Checkbox
        disabled={disabled}
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          "aria-label": t("inbox_outbox.select_all"),
        }}
      />
    );
  };

  const MarkMailAsRead = () => {
    selected.forEach((selectedSID) => {
      inboxObjects.find((item, index, array) => {
        if (item.planItemSID === selectedSID) {
          array[index].asRead = true;
          var url = urlJoin(resServerBaseUrl, "/Inbox/MarkSelectedInboxAsRead");
          var data = JSON.stringify({ PlanItemSID: selectedSID });
          CallApiWithContext(url, authenticationContext, data);
        }
      });
    });
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > 0) {
      nav({
        pathname: `/inbox/${newPage + 1}`,
        search: searchParams.toString(),
      });
    } else {
      nav({ pathname: "/inbox", search: searchParams.toString() });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(Math.floor((page * rowsPerPage) / event.target.value));
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inboxObjects.map((n) => n.planItemSID);
      setSelected(newSelecteds);
      setAllSelected(newSelecteds);
      return;
    }
    setAllSelected([]);
    setSelected([]);
  };

  const GetInboxObject = React.useCallback(async () => {
    if (noPermission) {
      return;
    }
    setIsLoading(true);
    const url = urlJoin(resServerBaseUrl, "/Inbox/GetInbox");
    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify({
        SearchCrtiria: searchData,
        StartIndex: rowsPerPage * page ? rowsPerPage * page : 0,
        PageSize: rowsPerPage,
      })
    )
      .then((respon) => {
        return respon ? respon : null;
      })
      .then((json) => {
        if (json !== null && typeof json.message !== "undefined") {
          if (json.message == "No permission to get Inbox") {
            setNoPermission(true);
          } else if (json.message == "Cannot Get Inbox") {
            setAlertContent(t("inbox_outbox.cannot_get_inbox"));
          }
          setInboxObjects([]);
          setTotalRecordNum(0);
        } else if (
          json !== null &&
          json !== "unauthorized" &&
          json.message !== "No permission to get Inbox"
        ) {
          setNoPermission(false);
          var data = [];
          setTotalRecordNum(json.totalRecordNum);
          json.inboxObjects.forEach((element) => {
            var inboxObject = {
              id: element.id,
              asRead: element.asRead,
              initiatorName: element.initiatorName,
              initiatorID: element.initiatorID,
              planItemName: element.planItemName,
              planItemID: element.planItemID,
              caseSID: element.caseSID,
              caseNumber: element.caseNumber,
              startDate: element.startDate,
              planItemTplID: element.planItemTplID,
              planItemSID: element.planItemSID,
            };
            data.push(inboxObject);
          });
          setInboxObjects(
            data.sort((a, b) => {
              return new Date(b.startDate) - new Date(a.startDate);
            })
          );
        } else {
          setInboxObjects([]);
          setTotalRecordNum(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setInboxObjects([]);
        setTotalRecordNum(0);
      });
  });

  const setSearchBoxItem = React.useCallback((item) => {
    item.SearchByFromDate = item.SearchByFromDate
      ? item.SearchByFromDate.toJSON()
      : null;
    item.SearchByEndDate = item.SearchByEndDate
      ? item.SearchByEndDate.toJSON()
      : null;
    setSearchData(JSON.stringify(item));
    setPage(0); //reset to first page
  }, []);

  React.useEffect(() => setTitle(t("left_menu.inbox")), []);

  React.useEffect(() => {
    if (profile) {
      if (profile.licenseState == 1) {
        GetInboxObject(authenticationContext, inboxObjects);
      } else {
        setNoPermission(true);
      }
    }
  }, [searchData, rowsPerPage, page, profile]);

  React.useEffect(() => {
    var data = "";
    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        data += `"${key}":"${value}",`;
        setSearchData(searchParams ? `{${data}}` : "");
      });
    } else {
      setSearchData("");
    }
    setPage(pages ? pages - 1 : 0);
  }, [location]);

  return (
    <Stack spacing={1}>
      <CustomToolbar
        componentArr={[
          {
            size: "auto",
            component: (
              <>
                <SelectAllCheckBox
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={inboxObjects.length}
                  disabled={noPermission}
                />
                <Tooltip title={t("inbox_outbox.refresh")}>
                  <IconButton
                    disabled={noPermission}
                    onClick={() => {
                      GetInboxObject(authenticationContext, inboxObjects);
                    }}
                  >
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("inbox_outbox.read")}>
                  <IconButton
                    onClick={(event) => {
                      MarkMailAsRead();
                    }}
                    disabled={noPermission}
                  >
                    <MarkEmailReadIcon
                      color="inherit"
                      sx={{ display: "block" }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            size: isSmallDevice ? 12 : 7,
            component: (
              <>
                {selected.length > 0 ? (
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {selected.length} {t("inbox_outbox.selected")}
                  </Typography>
                ) : (
                  //  null
                  <SearchBar disabled={noPermission} ref={setSearchBoxItem} />
                )}
              </>
            ),
          },
          {
            size: "auto",
            component: <></>,
          },
        ]}
      />
      <InOutboxList
        isInbox={true}
        objects={inboxObjects}
        isLoading={isLoading}
        noPermission={noPermission}
        setIsSelected={setSelected}
        allSelected={allSelected}
      />
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 15, 20, 25, 30]}
        labelRowsPerPage={t("inbox_outbox.display_row")}
        count={totalRecordNum}
        rowsPerPage={rowsPerPage}
        page={page}
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          overflow: "hidden",
          width: "100%",
          bgcolor: `mainContainer.${themeColorContext[0]}`,
          justifyContent: "right",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </Stack>
  );
};

Inbox.propTypes = {
  settitle: PropTypes.func.isRequired,
  isMdUp: PropTypes.bool.isRequired,
};

export default Inbox;
