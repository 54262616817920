import { Grid } from "@mui/material";
import { useThemeColor } from "../providers/ThemeColorProvider";
import PropTypes from "prop-types";

const CustomToolbar = (props) => {
  const { componentArr } = props;
  const themeColorContext = useThemeColor();

  return (
    <Grid
      container
      xs={12}
      className="paper"
      sx={{
        zIndex: 1,
        position: "sticky",
        top: "60px",
        bgcolor: `mainContainer.${themeColorContext[0]}`,
        borderRadius: 0,
        py: 0.5,
        justifyContent: "space-between",
      }}
      justifyContent={"space-evenly"}
      alignItems={"center"}
    >
      {componentArr.map((b) => (
        <Grid item xs={b.size}>
          {b.component}
        </Grid>
      ))}
    </Grid>
  );
};

CustomToolbar.propTypes = {
  componentArr: PropTypes.array,
};

export default CustomToolbar;
