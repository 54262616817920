import * as React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import SharedDialog from "../../shared/SharedDialog";
import LoadingSpinner from "../../shared/LoadingSpinner";
import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../helpers/ApiHelper";
import { useProfile } from "../../providers/ProfileProvider";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../Config";
import { useAuthentication } from "../../providers/AuthenticationProvider";

const PublishTemplate = (props) => {
  const { setTitle } = props;
  const { t } = useTranslation();
  const themeColorContext = useThemeColor();
  const [profile, setProfile] = useProfile();
  const authenticationContext = useAuthentication();

  const [files, setFiles] = React.useState([]);
  const [result, setResult] = React.useState(null);
  const [isUseLoadingSpinner, setIsUseLoadingSpinner] = React.useState(false);
  const [serverBypassDialog, setServerBypassDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    show: false,
    content: "",
    title: "",
  });

  const updateFiles = (incommingFiles) => {
    //do something with the files
    console.log("incomming files", incommingFiles);
    setFiles(incommingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleCancel = (id) => {
    setFiles(
      files.map((f) => {
        if (f.id === id) {
          return { ...f, uploadStatus: undefined };
        } else return { ...f };
      })
    );
  };

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };
  const handleServerBypassDialogClose = () => {
    const customSetting = profile.customSetting;

    customSetting.AVSERVERBYPASS = false;

    updateCustomSetting(customSetting);
    setServerBypassDialog(false);
    setResult(t("administrative_console.import_page.import_fail"));
  };
  const handleServerBypassConfirm = () => {
    const customSetting = profile.customSetting || {};

    customSetting.AVSERVERBYPASS = true;

    updateCustomSetting(customSetting);

    setServerBypassDialog(false);
    importDataSkipedVirusCheck();
  };
  const handleDoNotShowAgainChange = (event, prop) => {
    const customSetting = profile.customSetting || {};

    customSetting[prop] = event.target.checked;

    setProfile((prev) => ({ ...prev, customSetting: customSetting }));
  };
  const updateCustomSetting = (customSetting) => {
    var saveData = {
      ID: profile.id,
      Login: profile.login,
      CustomSetting: JSON.stringify(customSetting),
    };
    const updateUserURL = urlJoin(
      resServerBaseUrl,
      "User/UpdateUserCustomSetting"
    );
    CallApiWithContext(
      updateUserURL,
      authenticationContext,
      JSON.stringify(saveData)
    )
      .then((response) => {
        console.log(response.message);
        setProfile((prev) => ({ ...prev, customSetting: customSetting }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClickFn = () => {
    setIsUseLoadingSpinner(true);
    let formData = new FormData();

    if (files.length == 0) {
      setIsUseLoadingSpinner(false);
      setAlertDialog({
        show: true,
        content: t("administrative_console.import_page.please_select_a_file"),
        title: "",
      });
    } else {
      // file.append("file", fileInput.target.files[0]);
      files.map((f) => formData.append("file", f.file));
      const url = urlJoin(resServerBaseUrl, "/Import/ImportData");

      for (let value of formData.values()) {
        let upfile = new FormData();
        upfile.append("file", value);

        CallApiWithUploadFile(url, authenticationContext, upfile)
          .then((response) => {
            if (response && response.data) {
              var byteString = atob(response.data);
              var arrayBuffer = new ArrayBuffer(byteString.length);
              var intArray = new Uint8Array(arrayBuffer);

              for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
              }
              const blob = new Blob([intArray], {
                type: "application/vnd.ms-excel",
              });
              saveAs(blob, response.fileName);
              setAlertDialog({
                show: true,
                content: t(
                  "administrative_console.import_page.upload_succeed_message"
                ),
                title: t("administrative_console.import_page.upload_succeeded"),
              });
            } else if (response.message == "Inactive") {
              var customSetting = profile.customSetting;

              if (
                customSetting.KEEPAVSERVERBYPASS == true &&
                customSetting.AVSERVERBYPASS == true
              ) {
                importDataSkipedVirusCheck();
              } else if (
                customSetting.KEEPAVSERVERBYPASS == true &&
                customSetting.AVSERVERBYPASS == false
              ) {
                setResult(t("administrative_console.import_page.import_fail"));
              } else {
                setServerBypassDialog(true);
              }
            } else if (response) {
              setResult(response.message);
            } else {
              setResult(t("administrative_console.import_page.import_fail"));
            }
          })
          .catch((error) => {
            setAlertDialog({
              show: true,
              content: error,
              title: "Error",
            });
          })
          .finally(() => {
            setIsUseLoadingSpinner(false);
          });
      }
    }
  };
  const importDataSkipedVirusCheck = () => {
    setIsUseLoadingSpinner(true);
    let file = new FormData();

    files.map((f) => file.append("file", f.file));

    const url = urlJoin(resServerBaseUrl, "/Import/ImportDataSkipedVirusCheck");

    for (let value of file.values()) {
      let upfile = new FormData();

      upfile.append("file", value);

      CallApiWithUploadFile(url, authenticationContext, upfile)
        .then((response) => {
          if (response && response.data) {
            var byteString = atob(response.data);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
              intArray[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([intArray], {
              type: "application/vnd.ms-excel",
            });

            saveAs(blob, response.fileName);
            setResult(t("administrative_console.import_page.successed"));
          } else if (response) {
            setResult(response.message);
          } else {
            setResult(t("administrative_console.import_page.import_fail"));
          }
        })
        .catch((error) => {
          setAlertDialog({
            show: true,
            title: t("report.error"),
            content: t("report.error"),
          });
        })
        .finally(() => {
          setIsUseLoadingSpinner(false);
        });
    }
  };

  React.useEffect(() => {
    if (setTitle) {
      setTitle(t("left_menu.publish_template"));
    }
  }, []);

  return (
    <div>
      <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
        <Grid item style={{ textAlign: "left" }}>
          <p>{t("administrative_console.import_page.description")}</p>
          <h5>{t("administrative_console.import_page.support")}</h5>
        </Grid>
        <Grid item>
          <Dropzone
            background={
              themeColorContext[0] == "light"
                ? "radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);"
                : "radial-gradient(circle at 18.7% 37.8%, rgb(33 33 33) 0%, rgb(33 33 33) 0%)"
            }
            accept=".zip,.docx,application/zip,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.cmmn"
            headerConfig={{ deleteFiles: files.length == 0 ? false : true }}
            onChange={updateFiles}
            value={files}
            label={
              <div>
                <CloudUploadOutlinedIcon sx={{ fontSize: 55 }} />
                <Typography variant="h6">
                  {t("administrative_console.import_page.dropzoneText")}
                </Typography>
              </div>
            }
            autoClean
            actionButtons={{
              position: "after",
              uploadButton: {
                children: t("administrative_console.import_page.submit"),
                onClick: () => {
                  onClickFn();
                },
                style: {
                  margin: "auto",
                  backgroundColor:
                    themeColorContext[0] == "light" ? "#3a6015" : "#599121",
                },
              },
            }}
            footerConfig={{
              customMessage: (
                <h5>{t("administrative_console.import_page.support")}</h5>
              ),
            }}
          >
            {files.map((file) => (
              <FileMosaic
                key={file.id}
                {...file}
                onDelete={removeFile}
                onCancel={handleCancel}
                valid={undefined}
                resultOnTooltip
                alwaysActive
                info
              />
            ))}
          </Dropzone>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          {result ? <h4>{result}</h4> : null}
        </Grid>
        <SharedDialog
          isOpen={alertDialog.show}
          onClose={handleAlertDialogClose}
          title={alertDialog.title}
          content={alertDialog.content}
          buttons={[
            {
              text: t("administrative_console.report_page.confirm"),
              action: handleAlertDialogClose,
            },
          ]}
        ></SharedDialog>
        <Dialog
          open={serverBypassDialog}
          onClose={handleServerBypassDialogClose}
        >
          <Grid sx={{ bgcolor: "white", color: "grey" }}>
            <DialogTitle>
              {t("administrative_console.import_page.confirm_bypass_server")}
            </DialogTitle>
          </Grid>
          <Grid item style={{ textAlign: "left", marginLeft: 25 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profile?.customSetting?.KEEPAVSERVERBYPASS}
                    onChange={(e) =>
                      handleDoNotShowAgainChange(e, "KEEPAVSERVERBYPASS")
                    }
                  />
                }
                label={t("administrative_console.import_page.remember")}
              />
            </FormGroup>
          </Grid>
          <Grid>
            <DialogActions>
              <Button onClick={handleServerBypassConfirm}>
                {t("administrative_console.import_page.yes")}
              </Button>
              <Button onClick={handleServerBypassDialogClose}>
                {t("administrative_console.import_page.no")}
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
        <LoadingSpinner isOpen={isUseLoadingSpinner}></LoadingSpinner>
      </Grid>
    </div>
  );
};

export default PublishTemplate;
