import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../Config";
import urlJoin from "url-join";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import Skeleton from "@mui/material/Skeleton";
import {
  ConvertUTCDateToLocal,
  ConvertLocalDateToUTC,
} from "../../shared/Utils";
import useSize from "@react-hook/size";
import SharedDialog from "../../shared/SharedDialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemSecondaryAction, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const ProxyDelegateType = {
  Proxy: 0,
  Delegation: 1,
};

const ProxyDelegationEditor = (props) => {
  const authenticationContext = useAuthentication();
  const { type, data, isLoading, originalUserID, isAdmin, caseTplRecord } =
    props;

  function callApiToGetCaseTplName() {
    let caseTplArr = new Array();
    caseTplRecord.forEach((element) => {
      caseTplArr.push(element.label);
    });
    setCaseTplOptions(caseTplArr);
    setIsLoadingAutoComSPWF(false);
  }

  function refresh() {
    setIsLoadingTable(true);
    let userID = { UserID: parseInt(originalUserID) };

    const getUserProxyDelegationListURL = urlJoin(
      resServerBaseUrl,
      "/Case/GetUserProxyDelegationList"
    );
    setIgnoreFetch(true);
    CallApiWithContext(
      getUserProxyDelegationListURL,
      authenticationContext,
      JSON.stringify(userID)
    )
      .then((getListResponse) => {
        if (getListResponse) {
          const loadList = new Array();
          getListResponse.forEach((element) => {
            element = {
              ...element,
              tplName: caseTplRecord.find((x) => x.ID == element.caseTplUUID)
                .label,
            };
            if (typeof element.tplName == "undefined") {
              element.tplName = "Case Template not found!";
            }

            element.vaildDateFrom = ConvertUTCDateToLocal(
              new Date(element.vaildDateFrom)
            );
            element.vaildDateTo = ConvertUTCDateToLocal(
              new Date(element.vaildDateTo)
            );
            let dateTemp = element.vaildDateFrom.split(" ")[0];
            dateTemp =
              dateTemp.split("/")[2] +
              "-" +
              dateTemp.split("/")[1] +
              "-" +
              dateTemp.split("/")[0];
            element.vaildDateFrom =
              dateTemp + "T" + element.vaildDateFrom.split(" ")[1];
            dateTemp = element.vaildDateTo.split(" ")[0];
            dateTemp =
              dateTemp.split("/")[2] +
              "-" +
              dateTemp.split("/")[1] +
              "-" +
              dateTemp.split("/")[0];
            element.vaildDateTo =
              dateTemp + "T" + element.vaildDateTo.split(" ")[1];

            if (element.type == type) {
              loadList.push(createDataByElement(element));
            }
          });
          setNoRecord(loadList.length == 0);
          setLoadTable(loadList);
          setIsLoadingTable(false);
        } else {
          setNoRecord(true);
          setIsLoadingTable(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingTable(false);
      });
  }

  //const authenticationContext = useAuthentication();
  //const { type, data, isLoading, originalUserID, isAdmin, caseTplRecord } =
  //  props;
  const [loadTable, setLoadTable] = React.useState(data);
  const { t } = useTranslation();
  const proxyHeaders = [
    t("profile.proxy_and_delegation.big_proxy_user"),
    t("profile.proxy_and_delegation.big_effective_from"),
    t("profile.proxy_and_delegation.big_effective_to"),
    t("profile.proxy_and_delegation.big_specified_workflow"),
    t("profile.proxy_and_delegation.big_actions"),
  ];
  const delegationHeaders = [
    t("profile.proxy_and_delegation.big_delegation_user"),
    t("profile.proxy_and_delegation.big_effective_from"),
    t("profile.proxy_and_delegation.big_effective_to"),
    t("profile.proxy_and_delegation.big_specified_workflow"),
    t("profile.proxy_and_delegation.big_actions"),
  ];
  const headers =
    type === ProxyDelegateType.Proxy ? proxyHeaders : delegationHeaders;
  const [noRecord, setNoRecord] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [isDateIncorrect, setIsDateIncorrect] = React.useState(true);
  const [isLoadingTable, setIsLoadingTable] = React.useState(isLoading);
  const [isLoadingAddSave, setIsLoadingAddSave] = React.useState(false);
  const [isLoadingOpenDeletedialog, setIsLoadingOpenDeletedialog] =
    React.useState(false);
  const [isLoadingReallyDelete, setIsLoadingReallyDelete] =
    React.useState(false);
  const [isLoadingAutoComUserName, setIsLoadingAutoComUserName] =
    React.useState(false);
  const [isLoadingAutoComSPWF, setIsLoadingAutoComSPWF] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    React.useState(false);
  const GUIDpattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const [proxyUserOptions, setProxyUserOptions] = React.useState([]);
  const [caseTplOptions, setCaseTplOptions] = React.useState([
    "All Workflow Templates",
  ]);
  const [theEditingRowID, setTheEditingRowID] = React.useState(0);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [ignoreFetch, setIgnoreFetch] = React.useState(false);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const [editedRows, setEditedRows] = React.useState({
    proxyUser: "",
    effectiveFromDate: "",
    // effectiveFromTime: "",
    effectiveToDate: "",
    // effectiveToTime: "",
    specifiedWorkflow: "",
    type: "",
    rowId: "",
    userId: "",
  });
  const [deleteRows, setDeleteRows] = React.useState({
    ID: "",
    OriginalUserID: "",
    UserID: "",
    VaildDateFrom: "",
    VaildDateTo: "",
    CaseTplUUID: "",
    Type: "",
  });

  const createDataByElement = (element) => {
    return {
      proxyUser: element.userName,
      effectiveFrom: element.vaildDateFrom,
      effectiveTo: element.vaildDateTo,
      specifiedWorkflow: element.tplName,
      type: element.type,
      rowId: element.id,
      userId: element.userID,
    };
  };
  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };
  const handleOpenConfirmDeleteDialog = (row) => {
    setIsLoadingOpenDeletedialog(true);
    let caseTpl = "";

    // const caseTplURL = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates")
    // CallApiWithContext(caseTplURL, authenticationContext, isAdmin)
    //     .then(response => {
    //         if (response) {

    // response.forEach(element => {
    //     caseTpl = element.templateInfos.find(info => {
    //         if (info.name === row.specifiedWorkflow) {
    //             return info;
    //         }
    //     })
    //     if (row.specifiedWorkflow === "All Workflow Templates") {
    //         caseTpl = { uuid: "00000000-0000-0000-0000-000000000000" };
    //     }
    // })

    caseTplRecord.forEach((element) => {
      if (element.label == row.specifiedWorkflow) {
        caseTpl = element.ID;
      }
    });

    setDeleteRows({
      Id: row.rowId,
      OriginalUserID: originalUserID,
      UserID: row.userId,
      VaildDateFrom: row.effectiveFrom,
      VaildDateTo: row.effectiveTo,
      // CaseTplUUID: caseTpl.uuid,
      CaseTplUUID: caseTpl,
      Type: type,
    });

    setIsLoadingOpenDeletedialog(false);
    setOpenConfirmDeleteDialog(true);
    //     }
    // }).catch(error => {
    //     console.log(error);
    // });
  };
  const handleCloseConfirmDeleteDialog = (event) => {
    setOpenConfirmDeleteDialog(false);
  };
  const handleDataChange = (event, type) => {
    switch (type) {
      case "autoComplete":
        setEditedRows({
          ...editedRows,
          [event.target.id.split("-")[0]]: event.target.textContent,
        });
        break;
      case "effectiveFromDate":
        setEditedRows({ ...editedRows, effectiveFromDate: event.$d });
        break;
      // case "effectiveFromTime":
      //   setEditedRows({ ...editedRows, effectiveFromTime: event.$d });
      //   break;
      case "effectiveToDate":
        setEditedRows({ ...editedRows, effectiveToDate: event.$d });
        break;
      // case "effectiveToTime":
      //   setEditedRows({ ...editedRows, effectiveToTime: event.$d });
      //   break;
    }
  };
  const handleAdd = (event) => {
    /* if (
      originalUserID == null ||
      originalUserID == "undefined" ||
      originalUserID == ""
    ) {
      setAlertDialog(true);
      setAlertContent(t("profile.proxy_and_delegation.please_select_user"));
      setAlertTitle(t("profile.proxy_and_delegation.error"));
      return;
    }*/

    console.log(caseTplRecord);
    //setIsLoadingAutoComUserName(true);
    // setIsLoadingAutoComSPWF(true);
    setIsAdd(true);
    setOpenDialog(true);
    setTheEditingRowID("");
    setEditedRows({
      ...editedRows,
      proxyUser: "",
      effectiveFromDate: dayjs(),
      // effectiveFromTime: new dayjs(Date("2022-01-01T00:00:00")),
      effectiveToDate: dayjs(),
      // effectiveToTime: new dayjs(Date("2022-01-01T23:59:00")),
      specifiedWorkflow: "All Workflow Templates",
      type: type,
    });
    callApiToGetCaseTplName();
  };
  const handleEdit = (row) => {
    setIsLoadingAutoComUserName(true);
    setIsLoadingAutoComSPWF(true);
    setIsAdd(false);
    setOpenDialog(true);
    setTheEditingRowID(row.rowId);
    setEditedRows({
      ...editedRows,
      proxyUser: row.proxyUser,
      effectiveFromDate: row.effectiveFrom,
      effectiveFromTime: row.effectiveFrom,
      effectiveToDate: row.effectiveTo,
      effectiveToTime: row.effectiveTo,
      specifiedWorkflow: row.specifiedWorkflow,
      type: type,
    });
    callApiToGetCaseTplName();
  };
  const handleDelete = (event) => {
    setIsLoadingReallyDelete(true);
    //let delData = deleteRows;
    setDeleteRows((prevState) => ({ ...prevState, deleteRows }));

    const urlDelData = urlJoin(
      resServerBaseUrl,
      "/Case/DeleteProxyDelegationUser"
    );
    CallApiWithContext(
      urlDelData,
      authenticationContext,
      JSON.stringify(deleteRows)
    )
      .then((response) => {
        setOpenConfirmDeleteDialog(false);
        setIsLoadingReallyDelete(false);
        refresh();
      })
      .catch((error) => {
        console.log(error);
        setOpenConfirmDeleteDialog(false);
        setIsLoadingReallyDelete(false);
      });
  };
  const handleSave = (event) => {
    setIsLoadingAddSave(true);

    const userNameURL = urlJoin(
      resServerBaseUrl,
      "/User/GetUsersByPermissions"
    );
    CallApiWithContext(userNameURL, authenticationContext, null)
      .then((response) => {
        if (response) {
          let getUserNameArr = new Array();
          getUserNameArr = Object.keys(response).map((key) => {
            return { id: response[key].id, name: response[key].login };
          });

          var saveData = {
            OriginalUserID: originalUserID,
            UserID: getUserNameArr.find((n) => {
              return n.name == editedRows.proxyUser;
            }).id,
            VaildDateFrom: dayjs(editedRows.effectiveFromDate),
            // dayjs(
            //   ConvertLocalDateToUTC(
            //     new Date(
            //       dayjs(editedRows.effectiveFromDate).format("YYYY-MM-DDTHH:mm:ss") +
            //         "T" +
            //         dayjs(editedRows.effectiveFromTime).format("HH:mm:ss")
            //     )
            //   )
            // ).format("YYYY-MM-DDTHH:mm:ss")
            VaildDateTo: dayjs(editedRows.effectiveToDate),
            // dayjs(
            //   ConvertLocalDateToUTC(
            //     new Date(
            //       dayjs(editedRows.effectiveToDate).format("YYYY-MM-DD") +
            //         "T" +
            //         dayjs(editedRows.effectiveToTime).format("HH:mm:ss")
            //     )
            //   )
            // ).format("YYYY-MM-DDTHH:mm:ss")
            CaseTplUUID: caseTplRecord.find((c) => {
              return c.label == editedRows.specifiedWorkflow;
            }).ID,
            Type: editedRows.type,
          };

          if (isAdd) {
            const urlAddSave = urlJoin(
              resServerBaseUrl,
              "/Case/AddProxyDelegationUser"
            );
            CallApiWithContext(
              urlAddSave,
              authenticationContext,
              JSON.stringify(saveData)
            )
              .then((response) => {
                if (response.Status == "OK") {
                  setIsLoadingAddSave(false);
                  setOpenDialog(false);
                  refresh();
                } else if (response.Status == "Deadlock error") {
                  let wrongData = JSON.parse(response.OverlapData);
                  setAlertDialog(true);
                  setAlertContent(
                    t("profile.proxy_and_delegation.delegation_overlaped") +
                      ": " +
                      "\n" +
                      "\n" +
                      t("profile.proxy_and_delegation.user") +
                      ": " +
                      getUserNameArr.find((n) => {
                        return n.id == wrongData.crashedOriginalID;
                      }).name +
                      "\n" +
                      t("profile.proxy_and_delegation.delegation_user") +
                      ": " +
                      getUserNameArr.find((n) => {
                        return n.id == wrongData.crashedID;
                      }).name +
                      "\n" +
                      t("profile.proxy_and_delegation.effective_from") +
                      ": " +
                      ConvertUTCDateToLocal(
                        new Date(wrongData.crashedVaildDateFrom)
                      ) +
                      "\n" +
                      t("profile.proxy_and_delegation.effective_to") +
                      ": " +
                      ConvertUTCDateToLocal(
                        new Date(wrongData.crashedVaildDateTo)
                      ) +
                      "\n" +
                      t("administrative_console.report_page.case_template") +
                      ": " +
                      caseTplRecord.find((c) => {
                        return c.ID == wrongData.crashedCaseTplUUID;
                      }).label +
                      "\n" +
                      "\n" +
                      t("profile.proxy_and_delegation.please_check_the_form")
                  );
                  setAlertTitle(t("profile.proxy_and_delegation.error"));
                  setIsLoadingAddSave(false);
                  setOpenDialog(true);
                } else {
                  setIsLoadingAddSave(false);
                  setAlertDialog(true);
                  setAlertContent("Bad Request Error");
                  setAlertTitle(t("profile.proxy_and_delegation.error"));
                }
              })
              .catch((error) => {
                setAlertDialog(true);
                setAlertContent(error);
                setAlertTitle(t("profile.proxy_and_delegation.error"));
                console.log(error);
                setIsLoadingAddSave(false);
              });
          } else {
            let editedData = Object.assign({}, saveData, {
              Id: theEditingRowID,
            });
            const urlEditSave = urlJoin(
              resServerBaseUrl,
              "/Case/UpdateProxyDelegationUser"
            );
            CallApiWithContext(
              urlEditSave,
              authenticationContext,
              JSON.stringify(editedData)
            )
              .then((response) => {
                if (response.Status == "OK") {
                  setIsLoadingAddSave(false);
                  setOpenDialog(false);
                  refresh();
                } else if (response.Status == "Deadlock error") {
                  let wrongData = JSON.parse(response.OverlapData);
                  setAlertDialog(true);
                  setAlertContent(
                    t("profile.proxy_and_delegation.delegation_overlaped") +
                      ": " +
                      "\n" +
                      "\n" +
                      t("profile.proxy_and_delegation.user") +
                      ": " +
                      getUserNameArr.find((n) => {
                        return n.id == wrongData.crashedOriginalID;
                      }).name +
                      "\n" +
                      t("profile.proxy_and_delegation.delegation_user") +
                      ": " +
                      getUserNameArr.find((n) => {
                        return n.id == wrongData.crashedID;
                      }).name +
                      "\n" +
                      t("profile.proxy_and_delegation.effective_from") +
                      ": " +
                      ConvertUTCDateToLocal(
                        new Date(wrongData.crashedVaildDateFrom)
                      ) +
                      "\n" +
                      t("profile.proxy_and_delegation.effective_to") +
                      ": " +
                      ConvertUTCDateToLocal(
                        new Date(wrongData.crashedVaildDateTo)
                      ) +
                      "\n" +
                      t("administrative_console.report_page.case_template") +
                      ": " +
                      caseTplRecord.find((c) => {
                        return c.ID == wrongData.crashedCaseTplUUID;
                      }).label +
                      "\n" +
                      "\n" +
                      t("profile.proxy_and_delegation.please_check_the_form")
                  );
                  setAlertTitle(t("profile.proxy_and_delegation.error"));
                  setIsLoadingAddSave(false);
                  setOpenDialog(true);
                }
              })
              .catch((error) => {
                setAlertDialog(true);
                setAlertContent(error);
                setAlertTitle(t("profile.proxy_and_delegation.error"));
                console.log(error);
                setIsLoadingAddSave(false);
              });
          }
        } else {
          setAlertDialog(true);
          setAlertContent("No permission");
          setAlertTitle(t("profile.proxy_and_delegation.error"));
          setIsLoadingAddSave(false);
        }
      })
      .catch((error) => {
        setIsLoadingAddSave(false);
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (
      editedRows.effectiveFromDate !== "" &&
      editedRows.effectiveToDate !== ""
      // &&
      // editedRows.effectiveFromTime !== "" &&
      // editedRows.effectiveToTime !== ""
    ) {
      if (
        dayjs(editedRows.effectiveToDate) >= dayjs(editedRows.effectiveFromDate)
      ) {
        setIsDateIncorrect(false);
        if (
          editedRows.proxyUser !== "" &&
          editedRows.specifiedWorkflow !== ""
        ) {
          setDisableSave(false);
        }
      } else {
        setIsDateIncorrect(true);
        setDisableSave(true);
      }
    } else {
      setIsDateIncorrect(false);
      setDisableSave(true);
    }
  }, [editedRows]);

  React.useEffect(() => {
    setIsLoadingTable(isLoading);
    setLoadTable(data);

    if (isLoading) {
      setNoRecord(false);
    } else {
      setNoRecord(!data || data.length == 0);
    }
  }, [isLoading, data]);

  const callapi = React.useMemo(() => {
    const userNameURL = urlJoin(
      resServerBaseUrl,
      "/User/GetUsersByPermissionsByID"
    );
    let userID;
    if (
      originalUserID != null &&
      originalUserID != "undefined" &&
      originalUserID != ""
    ) {
      userID = { UserId: parseInt(originalUserID) };
      setIsLoadingAutoComUserName(true);
      CallApiWithContext(
        userNameURL,
        authenticationContext,
        JSON.stringify(userID)
      )
        .then((response) => {
          if (response) {
            let userNameArr = new Array();
            let getUserNameArr = new Array();
            getUserNameArr = Object.keys(response).map((key) => {
              return { id: response[key].id, name: response[key].login };
            });
            getUserNameArr.forEach((element) => {
              let forPushUserIDName = { ID: element.id, label: element.name };
              if (GUIDpattern.test(forPushUserIDName.label) === true) {
              } else {
                userNameArr.push(forPushUserIDName);
              }
            });
            userNameArr = userNameArr.filter(
              (L) =>
                L.label != "Robot" &&
                L.label != "00000000-0000-0000-0000-000000000000"
            );
            userNameArr.sort((a, b) => {
              const labelA = a.label.toUpperCase();
              const labelB = b.label.toUpperCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });
            setProxyUserOptions(userNameArr);
            setIsLoadingAutoComUserName(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [originalUserID]);

  return (
    <Grid>
      <Grid container sx={{ justifyContent: "right" }}>
        {isLoadingTable ? (
          <Grid sx={{ p: "5px" }}>
            <Skeleton
              variant="circular"
              sx={{ width: "25px", height: "25px" }}
            />
          </Grid>
        ) : (
          <IconButton onClick={handleAdd} color="primary">
            <PersonAddAlt1Icon fontSize="small" />
          </IconButton>
        )}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullScreen={isSmallDevice}
        >
          <DialogTitle>
            {isAdd
              ? t("profile.proxy_and_delegation.add")
              : t("profile.proxy_and_delegation.edit")}
            {type === ProxyDelegateType.Proxy
              ? t("profile.proxy_and_delegation.proxy_user")
              : t("profile.proxy_and_delegation.delegation_user")}
          </DialogTitle>
          <DialogContent style={{ overflow: "auto" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid
                container
                direction="column"
                spacing={2}
                p={1}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <DateTimePicker
                    sx={{ width: "350px" }}
                    label={t("profile.proxy_and_delegation.effective_from")}
                    ampm={false}
                    value={dayjs(editedRows.effectiveFromDate)}
                    renderInput={(params) => (
                      <TextField {...params} error={isDateIncorrect} />
                    )}
                    onChange={(event) =>
                      handleDataChange(event, "effectiveFromDate")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    sx={{ width: "350px" }}
                    label={t("profile.proxy_and_delegation.effective_to")}
                    ampm={false}
                    value={dayjs(editedRows.effectiveToDate)}
                    renderInput={(params) => (
                      <TextField {...params} error={isDateIncorrect} />
                    )}
                    onChange={(event) =>
                      handleDataChange(event, "effectiveToDate")
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ width: "350px" }}
                    loading={isLoadingAutoComUserName}
                    loadingText={"Loading..."}
                    id="proxyUser"
                    name="proxyUser"
                    defaultValue={editedRows.proxyUser}
                    onChange={(event) =>
                      handleDataChange(event, "autoComplete")
                    }
                    options={proxyUserOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          type === ProxyDelegateType.Proxy
                            ? t("profile.proxy_and_delegation.proxy_user")
                            : t("profile.proxy_and_delegation.delegation_user")
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ width: "350px" }}
                    loading={isLoadingAutoComSPWF}
                    loadingText={"Loading..."}
                    id="specifiedWorkflow"
                    name="specifiedWorkflow"
                    defaultValue={editedRows.specifiedWorkflow}
                    onChange={(event) =>
                      handleDataChange(event, "autoComplete")
                    }
                    options={caseTplOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(
                          "profile.proxy_and_delegation.specified_workflow"
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              disabled={disableSave}
              loading={isLoadingAddSave}
              onClick={(event) => handleSave(event)}
            >
              {t("profile.proxy_and_delegation.save")}
            </LoadingButton>
            <Button onClick={handleCloseDialog} color="error">
              {t("profile.proxy_and_delegation.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      {isSmallDevice ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{
                "& .MuiTableCell-root": {
                  padding: isSmallDevice ? "10px" : "16px",
                },
              }}
            >
              <TableCell>{headers[0]}</TableCell>
              <TableCell>{headers[3]}</TableCell>
              <TableCell>{headers[4]}</TableCell>
            </TableHead>
            {isLoadingTable ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Grid container direction="row">
                      <Grid>
                        <Skeleton
                          variant="circular"
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Grid>
                      <Grid sx={{ width: "20px" }}></Grid>
                      <Grid>
                        <Skeleton
                          variant="circular"
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : noRecord ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    {type === ProxyDelegateType.Proxy
                      ? t("profile.proxy_and_delegation.no_info_proxy")
                      : t("profile.proxy_and_delegation.no_info_delegation")}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {loadTable.map((row, rowIndex) => {
                  var currentRowIndex = rowIndex;
                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: isSmallDevice ? "10px" : "16px",
                        },

                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.proxyUser}
                      </TableCell>
                      <TableCell>
                        {row.specifiedWorkflow == "Case Template not found!" ? (
                          <i>
                            {t("profile.proxy_and_delegation.casetpl_notfound")}
                          </i>
                        ) : (
                          row.specifiedWorkflow
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          id={currentRowIndex}
                          onClick={() => handleEdit(row)}
                          color="primary"
                        >
                          <BorderColorIcon fontSize="small" />
                        </IconButton>
                        {isLoadingOpenDeletedialog ? (
                          <LoadingButton loading />
                        ) : (
                          <IconButton
                            id={currentRowIndex}
                            onClick={() => handleOpenConfirmDeleteDialog(row)}
                            color="primary"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>

                      <Dialog
                        open={openConfirmDeleteDialog}
                        onClose={handleCloseConfirmDeleteDialog}
                      >
                        <Grid>
                          <DialogTitle>
                            {t("profile.proxy_and_delegation.confirm_delete")}
                          </DialogTitle>
                        </Grid>
                        <Grid>
                          <DialogActions>
                            <LoadingButton
                              variant="contained"
                              loading={isLoadingReallyDelete}
                              onClick={(event) => handleDelete(event)}
                            >
                              {t("profile.proxy_and_delegation.delete")}
                            </LoadingButton>
                            <Button
                              onClick={handleCloseConfirmDeleteDialog}
                              color="error"
                            >
                              {t("profile.proxy_and_delegation.cancel")}
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Dialog>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((title) => (
                  <TableCell>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoadingTable ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Grid container direction="row">
                      <Grid>
                        <Skeleton
                          variant="circular"
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Grid>
                      <Grid sx={{ width: "20px" }}></Grid>
                      <Grid>
                        <Skeleton
                          variant="circular"
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : noRecord ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    {type === ProxyDelegateType.Proxy
                      ? t("profile.proxy_and_delegation.no_info_proxy")
                      : t("profile.proxy_and_delegation.no_info_delegation")}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {loadTable.map((row, rowIndex) => {
                  var currentRowIndex = rowIndex;
                  return (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.proxyUser}
                      </TableCell>
                      <TableCell>
                        {dayjs(row.effectiveFrom).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>
                        {dayjs(row.effectiveTo).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>
                        {row.specifiedWorkflow == "Case Template not found!" ? (
                          <i>
                            {t("profile.proxy_and_delegation.casetpl_notfound")}
                          </i>
                        ) : (
                          row.specifiedWorkflow
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          id={currentRowIndex}
                          onClick={() => handleEdit(row)}
                          color="primary"
                        >
                          <BorderColorIcon fontSize="small" />
                        </IconButton>
                        {isLoadingOpenDeletedialog ? (
                          <LoadingButton loading />
                        ) : (
                          <IconButton
                            id={currentRowIndex}
                            onClick={() => handleOpenConfirmDeleteDialog(row)}
                            color="primary"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>

                      <Dialog
                        open={openConfirmDeleteDialog}
                        onClose={handleCloseConfirmDeleteDialog}
                      >
                        <Grid>
                          <DialogTitle>
                            {t("profile.proxy_and_delegation.confirm_delete")}
                          </DialogTitle>
                        </Grid>
                        <Grid>
                          <DialogActions>
                            <LoadingButton
                              variant="contained"
                              loading={isLoadingReallyDelete}
                              onClick={(event) => handleDelete(event)}
                            >
                              {t("profile.proxy_and_delegation.delete")}
                            </LoadingButton>
                            <Button
                              onClick={handleCloseConfirmDeleteDialog}
                              color="error"
                            >
                              {t("profile.proxy_and_delegation.cancel")}
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Dialog>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}

      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </Grid>
  );
};

export default ProxyDelegationEditor;
