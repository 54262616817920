import React from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar,
  Tooltip,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SaveIcon from "@mui/icons-material/Save";

import {
  FlexGrid,
  FlexGridColumn,
  FlexGridCellTemplate,
} from "@grapecity/wijmo.react.grid";
import * as wjCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { ComboBox } from "@grapecity/wijmo.input";
import { useWindowSize } from "react-use-size";
import { FormEditor } from "@bpmn-io/form-js-editor";
import FileSaver from "file-saver";

import FeedbackButtonRenderExtension from "./extension/render";
import FeedbackButtonPropertiesPanelExtension from "./extension/propertiesPanel";

import formSchema from "./schema";
import "./FormDesigner.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";

const FormDesigner = (props) => {
  const { setTitle } = props;

  const editor = React.useRef();
  const inputFile = React.useRef();
  const selectFormGrid = React.useRef();
  const [openFileDialog, setOpenFileDialog] = React.useState(false);
  const [formUpdated, setFormUpdated] = React.useState(false);
  const [forms, setForms] = React.useState([]);

  const { height: bodyHeight } = useWindowSize();
  const defautSchema = JSON.parse(formSchema);

  const initFormJs = async () => {
    const container = document.querySelector("#form-designer");

    const formEditor = new FormEditor({
      container: container,
      keyboard: { bindTo: container },
      data: {},
      // additionalModules: [
      //   FeedbackButtonRenderExtension,
      //   FeedbackButtonPropertiesPanelExtension,
      // ],
      // editorAdditionalModules: [FeedbackButtonPropertiesPanelExtension],
    });

    formEditor.on("commandStack.changed", () => setFormUpdated(true));

    editor.current = formEditor;

    await formEditor.importSchema(defautSchema);
  };

  const loadSchema = async (json) => {
    if (editor.current) {
      await editor.current.importSchema(json);
    }
  };

  const handleNewClick = async () => {
    if (editor.current) {
      await editor.current.importSchema(defautSchema);
    }
  };

  const handleSaveClick = () => {
    if (editor.current) {
      const schema = editor.current.saveSchema();
      const blob = new Blob([JSON.stringify(schema, null, 2)]);

      FileSaver.saveAs(blob, "form.json");

      console.log(schema);
    }
  };

  const handleOpenFile = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (ev) => {
      loadSchema(JSON.parse(ev.target.result));
      if (inputFile.current) {
        inputFile.current.value = null;
      }
    };
    reader.readAsText(file);
  };

  const handleOpenFileFromServer = (e) => {
    setOpenFileDialog(true);
  };

  const getPublishedDateList = () => {
    const dates = forms.flatMap((f) => f.createdDates);
    const map = new wjcGrid.DataMap(dates, "id", "formattedDate");

    map.getDisplayValues = (dataItem) => {
      const filtered = dates.filter((d) =>
        dataItem.createdDates.some((dd) => dd.id == d.id)
      );

      return filtered.map((d) => d.formattedDate);
    };

    return map;
  };

  React.useEffect(() => {
    setTitle("Form Designer");

    initFormJs();

    setFormUpdated(false);
    setForms([
      {
        id: 1127,
        tplName: "Sample Flow",
        formName: "需求建議書招標書",
        language: "zh-hk",
        selectedVersion: 1127,
        createdDates: [
          {
            id: 1127,
            formattedDate: "2024-07-15 17:06",
          },
          {
            id: 1121,
            formattedDate: "2024-07-14 21:40",
          },
          {
            id: 1117,
            formattedDate: "2024-07-14 21:35",
          },
          {
            id: 1111,
            formattedDate: "2024-07-14 20:43",
          },
          {
            id: 1107,
            formattedDate: "2024-07-14 20:35",
          },
        ],
      },
      {
        id: 1126,
        tplName: "Sample Flow",
        formName: "RFPBidsOverview",
        language: null,
        selectedVersion: 1126,
        createdDates: [
          {
            id: 1126,
            formattedDate: "2024-07-15 17:06",
          },
          {
            id: 1120,
            formattedDate: "2024-07-14 21:40",
          },
          {
            id: 1116,
            formattedDate: "2024-07-14 21:35",
          },
          {
            id: 1110,
            formattedDate: "2024-07-14 20:43",
          },
          {
            id: 1106,
            formattedDate: "2024-07-14 20:35",
          },
        ],
      },
      {
        id: 1125,
        tplName: "Sample Child Case",
        formName: "建議書招標書",
        language: "zh-hk",
        selectedVersion: 1125,
        createdDates: [
          {
            id: 1125,
            formattedDate: "2024-07-15 17:06",
          },
          {
            id: 1123,
            formattedDate: "2024-07-14 21:40",
          },
          {
            id: 1119,
            formattedDate: "2024-07-14 21:35",
          },
          {
            id: 1115,
            formattedDate: "2024-07-14 21:34",
          },
          {
            id: 1113,
            formattedDate: "2024-07-14 20:43",
          },
        ],
      },
      {
        id: 1124,
        tplName: "Sample Child Case",
        formName: "RFPBidsForm",
        language: null,
        selectedVersion: 1124,
        createdDates: [
          {
            id: 1124,
            formattedDate: "2024-07-15 17:06",
          },
          {
            id: 1123,
            formattedDate: "2024-07-14 21:40",
          },
          {
            id: 1119,
            formattedDate: "2024-07-14 21:35",
          },
          {
            id: 1115,
            formattedDate: "2024-07-14 21:34",
          },
          {
            id: 1113,
            formattedDate: "2024-07-14 20:43",
          },
        ],
      },
      {
        id: 1097,
        tplName: "Supplier Registration",
        formName: "SupplierRegistration",
        language: null,
        selectedVersion: 1097,
        createdDates: [
          {
            id: 1097,
            formattedDate: "2024-07-15 17:06",
          },
          {
            id: 1123,
            formattedDate: "2024-07-14 21:40",
          },
          {
            id: 1119,
            formattedDate: "2024-07-14 21:35",
          },
          {
            id: 1115,
            formattedDate: "2024-07-14 21:34",
          },
          {
            id: 1113,
            formattedDate: "2024-07-14 20:43",
          },
        ],
      },
    ]);

    const onbeforeunload = (e) => {
      e.preventDefault();
      e.returnValue = false;
    };

    window.addEventListener("beforeunload", onbeforeunload);

    return () => {
      if (editor.current) editor.current.destroy();
      window.removeEventListener("beforeunload", onbeforeunload);
    };
  }, []);

  return (
    <div className="form-designer">
      <div
        id="form-designer"
        style={{
          maxWidth: "100%",
          height: `${bodyHeight - 116}px`,
          padding: null,
          margin: null,
          textAlign: "left",
          backgroundColor: "white",
        }}
      ></div>
      <Toolbar>
        <ul className="buttons">
          <li>
            <ButtonGroup>
              <Tooltip title="Open from local">
                <Button
                  component={"label"}
                  role={undefined}
                  variant={"outlined"}
                  tabIndex={-1}
                >
                  <FolderOpenIcon />
                  <input
                    ref={inputFile}
                    type="file"
                    style={{
                      clip: "rect(0,0,0,0)",
                      clipPath: "inset(50%)",
                      width: 1,
                      height: 1,
                      overflow: "hidden",
                    }}
                    onChange={handleOpenFile}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Open from server">
                <Button disabled={true} onClick={handleOpenFileFromServer}>
                  <CloudDownloadIcon />
                </Button>
              </Tooltip>
              <Tooltip title="New">
                <Button onClick={handleNewClick}>
                  <AddCircleIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </li>
          <li>
            <ButtonGroup>
              <Tooltip title="Save to local">
                <Button disabled={!formUpdated} onClick={handleSaveClick}>
                  <SaveIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Publish to server">
                <Button disabled={true} onClick={handleSaveClick}>
                  <CloudUploadIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </li>
        </ul>
      </Toolbar>
      <Dialog open={openFileDialog} maxWidth={"md"}>
        <DialogTitle>Open from server</DialogTitle>
        <DialogContent>
          <FlexGrid
            ref={selectFormGrid}
            itemsSource={forms}
            selectionMode={wjcGrid.SelectionMode.Row}
            headersVisibility={wjcGrid.HeadersVisibility.Column}
          >
            <FlexGridColumn
              binding={"tplName"}
              header="Template Name"
              width={250}
              isReadOnly={true}
            ></FlexGridColumn>
            <FlexGridColumn
              binding={"formName"}
              header="Form Name"
              width={200}
              isReadOnly={true}
            ></FlexGridColumn>
            <FlexGridColumn
              binding={"language"}
              header="Language"
              isReadOnly={true}
            >
              <FlexGridCellTemplate
                cellType="Cell"
                template={(context) => {
                  return context.item.language
                    ? context.item.language
                    : "<default>";
                }}
              ></FlexGridCellTemplate>
            </FlexGridColumn>
            <FlexGridColumn
              binding={"selectedVersion"}
              header="Published Date"
              width={200}
              dataMap={getPublishedDateList()}
            ></FlexGridColumn>
          </FlexGrid>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"}>Open</Button>
          <Button
            variant={"outlined"}
            color="error"
            onClick={() => setOpenFileDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDesigner;
