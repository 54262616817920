import * as React from "react";
import * as wijmo from "@grapecity/wijmo";
import * as wjChart from "@grapecity/wijmo.react.chart";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowCircleUp, Close, Refresh, Settings } from "@mui/icons-material";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../../Config";
import { CallApiWithContext } from "../../../helpers/ApiHelper";
import { useAuthentication } from "../../../providers/AuthenticationProvider";
import "../Dashboard.css";
import SharedDialog from "../../../shared/SharedDialog";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import { Color } from "@grapecity/wijmo";
import PropTypes from "prop-types";
import utc from "dayjs/plugin/utc";
import { BarChart, BarPlot, ChartsOnAxisClickHandler } from "@mui/x-charts";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";

dayjs.extend(utc);

const BarCharts = React.forwardRef((props, ref) => {
  const { settings, settingsSID, editFunction, isMidDevice } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const authenticationContext = useAuthentication();
  const [dashboardSetting, setDashboardSetting] = React.useState({});
  const [chartDatas, setChartDatas] = React.useState({});
  const [chartRequests, setChartRequests] = React.useState({});
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [request, setRequest] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [lastSelectedSection, setLastSelectedSection] = React.useState({});
  const [lastSelectedFunction, setLastSelectedFunction] = React.useState();
  const [xAxisLabel, setXAxisLabel] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [axisList, setAxisList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const levelRef = React.useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const quaterEnd = (month) => {
    switch (month + 1) {
      case 1:
      case 2:
      case 3:
        return { quater: 1, end: dayjs().month(2) };
      case 4:
      case 5:
      case 6:
        return { quater: 2, end: dayjs().month(5) };
      case 7:
      case 8:
      case 9:
        return { quater: 3, end: dayjs().month(8) };
      case 10:
      case 11:
      case 12:
        return { quater: 4, end: dayjs().month(11) };
    }
  };

  const itemFormatter = React.useCallback((engine, ht, defRender) => {
    let clr = new Color(engine.fill);
    clr.a = 0.8; // use opaque color
    engine.fill = clr.toString();
    engine.stroke = theme.palette.mainContainer[theme.palette.mode];
    defRender();
  });

  const handleDrillDown = (p) => {
    if (
      levelRef.current + 1 <
      Object.keys(JSON.parse(settings.settings).x).length
    ) {
      if (open) {
        setOpen(false);
      }
      setLoading(true);
      levelRef.current = levelRef.current + 1;
      let selectedFunction = p.seriesId;
      let sectionObj = lastSelectedSection;
      sectionObj[levelRef.current] = xAxisLabel[p.dataIndex];
      setLastSelectedFunction(selectedFunction);
      setLastSelectedSection(sectionObj);
      GenerateDashboardData(
        levelRef.current,
        sectionObj[levelRef.current],
        selectedFunction
      );
    }
  };

  const palette = (start, end, length) => {
    const startColor = parseInt("FF" + start, 16);
    const endColor = parseInt("FF" + end, 16);
    let colorArr = [];

    for (let count = 0; count < length; count++) {
      let color;
      if (count === 0) {
        color = "#" + start;
      } else {
        color =
          "#" +
          Math.round(startColor + (endColor - (startColor / length) * count))
            .toString(16)
            .slice(-6);
      }

      colorArr.push(color);
    }
    return colorArr;
  };

  const seriesGenerator = () => {
    let p = palette("283d17", "3a6015", chartRequests?.Function?.length ?? 0);
    if ((chartRequests?.Function?.length ?? 0) > 1) {
      return chartRequests.Function.map((f, i) => {
        return {
          dataKey: Object.keys(chartDatas?.y ?? {})[0] + `(${f})`,
          label: Object.values(chartDatas?.y ?? {})[0] + `(${f})`,
          id: Object.values(chartDatas?.y ?? {})[0] + `(${f})`,
          color: p[i],
        };
      });
    } else if ((chartRequests?.Function?.length ?? 0) > 0) {
      return [
        {
          dataKey: Object.keys(chartDatas?.y ?? {})[0],
          label: Object.values(chartDatas?.y ?? {})[0],
          id: Object.values(chartDatas?.y ?? {})[0],
          color: p[0],
        },
      ];
    } else {
      return [];
    }
  };

  const GenerateDashboardData = (level, selectedSection, selectedFunction) => {
    const url = urlJoin(resServerBaseUrl, "Report/GenerateDashboardData");
    const data = {
      DashboardSettingSID: settingsSID,
      Level: level ?? 0,
      SelectedSection: selectedSection ?? null,
      Function: selectedFunction,
    };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (!Object.keys(response).includes("message")) {
          GenerateChartData(response, selectedFunction);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GenerateChartData = (dataArr, selectedFunction) => {
    let xAxis = [];
    let series = [];
    let maxNameLength = 7;
    let functions = selectedFunction
      ? [selectedFunction]
      : JSON.parse(settings.settings).chartRequest.Function;
    let colors = palette("283d17", "3a6015", functions?.length ?? 0);
    if (dataArr.length > 0) {
      xAxis = dataArr[0].Data.map((d) => {
        return d.label;
      });
      functions.map((f, i) => {
        series.push({
          id: f,
          data: dataArr.find((d) => d.Function === f).Data.map((d) => d.value),
          label: f,
          color: colors[i],
        });
      });
    }

    setXAxisLabel(xAxis);
    setSeries(series);

    let setting = {
      sid: settingsSID,
      chartName: JSON.parse(settings.settings).chartName,
      chartType: JSON.parse(settings.settings).chartType,
      chartRow: JSON.parse(settings.settings).chartRow,
      chartColumn: JSON.parse(settings.settings).chartColumn,
      chartOrder: JSON.parse(settings.settings).chartOrder,
      x: JSON.parse(settings.settings).x,
      y: JSON.parse(settings.settings).y,
    };
    let request = {
      CaseTplUUID: JSON.parse(settings.settings).chartRequest.CaseTplUUID,
      TplSID: JSON.parse(settings.settings).chartRequest.TplSID,
      FilterCriteria: JSON.parse(settings.settings).chartRequest.FilterCriteria,
      IsPreviewReport: JSON.parse(settings.settings).chartRequest
        .IsPreviewReport,
      IsDownload: JSON.parse(settings.settings).chartRequest.IsDownload,
      X: JSON.parse(settings.settings).chartRequest.X,
      Y: JSON.parse(settings.settings).chartRequest.Y,
      Function: JSON.parse(settings.settings).chartRequest.Function,
      Level: JSON.parse(settings.settings).chartRequest.Level,
    };
    setChartDatas(setting);
    setChartRequests(request);
    setLoading(false);
  };

  const handleDrillUp = (event) => {
    setLoading(true);
    let lastSelecSec;
    let lastSelecFun;

    if (levelRef.current - 1 > 0) {
      lastSelecSec = lastSelectedSection[levelRef.current - 1];
      lastSelecFun = lastSelectedFunction;
    } else if (levelRef.current - 1 == 0) {
      lastSelecSec = null;
      lastSelecFun = null;
    }
    levelRef.current = levelRef.current - 1;
    GenerateDashboardData(levelRef.current, lastSelecSec, lastSelecFun);
    // let settingObj = JSON.parse(settings.settings);
    // let xArr = settingObj.x;

    // if (levelRef.current - 1 >= 0) {
    //   setLoading(true);
    //   settingObj.chartRequest.x = Object.keys(xArr)[levelRef.current - 1];
    //   if (levelRef.current - 1 == 0) {
    //     settingObj.chartRequest.Function = request.Function;
    //     settingObj.chartRequest.Level = request.Level;
    //   } else {
    //     settingObj.chartRequest.Function = chartRequests.Function;
    //     settingObj.chartRequest.Level = lastLevelObj;
    //   }
    //   let s = {
    //     sid: settings.sid,
    //     chartOrder: settings.chartOrder,
    //     settings: JSON.stringify(settingObj),
    //   };
    //   levelRef.current = levelRef.current - 1;
    //   GenerateDashboardData(s);
    // }
  };

  const handleRefresh = () => {
    setLoading(true);
    if (levelRef.current === 0) {
      GenerateDashboardData(0, null, null);
    } else {
      GenerateDashboardData(
        levelRef.current,
        lastSelectedSection[levelRef.current],
        lastSelectedFunction
      );
    }
  };

  const CustomYAxis = () => {
    return (
      <Box
        component={"text"}
        sx={{
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          textAlign: "center",
        }}
      >
        {Object.values(JSON.parse(settings.settings)?.y)[0]}
      </Box>
    );
  };

  const boxWidth = (cols) => {
    switch (cols) {
      case 1:
        return 401;
      case 2:
        return 813;
      case 3:
        return 1227;
      case 4:
        return 1621;
      default:
        return 401;
    }
  };

  const handlePopoverOpen = (event, seriesInfo) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    let list = series
      .find((s) => s.id === seriesInfo.seriesId)
      .data.map((s, i) => {
        return {
          label: xAxisLabel[i],
          value: s,
          seriesId: seriesInfo.seriesId,
          dataIndex: i,
        };
      });
    setAxisList(list);
  };

  const handlePopoverClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setAxisList([]);
  };

  React.useEffect(() => {
    setLoading(true);
    GenerateDashboardData(0, null, null);
    setDashboardSetting(JSON.parse(settings.settings));
    setRequest(JSON.parse(settings.settings).chartRequest);
    levelRef.current = 0;
    setOpen(false);
  }, [settings]);

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        container
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
        overflow={"auto"}
      >
        <Grid item xs={"auto"}>
          {levelRef.current === 0 ? null : (
            <IconButton
              disabled={loading}
              onClick={(e) => {
                //add function to handle setting with last level of criteria
                handleDrillUp(e, chartDatas.data, settings, true);
              }}
            >
              <ArrowCircleUp />
            </IconButton>
          )}
        </Grid>
        <Grid item width={150} height={35} overflow={"auto"}>
          <Typography variant="h6">
            {JSON.parse(settings.settings).chartName.split("").length > 25
              ? JSON.parse(settings.settings).chartName.slice(0, 25) + "..."
              : JSON.parse(settings.settings).chartName}
          </Typography>
        </Grid>
        <Grid container item xs={"auto"}>
          {JSON.parse(settings.settings)?.DynamicDate ??
          []
            .map((d) => d.range[1] === "now" || d.range[1] === 0)
            .includes(true) ? (
            <Grid item>
              <IconButton
                disabled={loading}
                onClick={() => {
                  handleRefresh();
                }}
              >
                <Refresh />
              </IconButton>
            </Grid>
          ) : null}

          <Grid item>
            <IconButton
              onClick={() =>
                editFunction(JSON.parse(settings.settings), settings.sid)
              }
              disabled={loading}
            >
              <Settings />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {loading || series.length === 0 ? (
        <Box
          width={boxWidth(JSON.parse(settings.settings)?.chartColumn ?? 1)}
          height={350 * (JSON.parse(settings.settings)?.chartRow ?? 1)}
          alignContent={"center"}
        >
          {loading ? t("dashboard.loading") : t("dashboard.no_data")}
        </Box>
      ) : (
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          sx={{
            overflowX: "auto",

            width: boxWidth(JSON.parse(settings.settings)?.chartColumn ?? 1),
          }}
        >
          <Grid item xs={"auto"} alignSelf={"center"}>
            {loading || series.length == 0 ? null : CustomYAxis()}
          </Grid>

          <Grid item xs={10}>
            <BarChart
              margin={{ left: 100, right: 100 }}
              slotProps={{
                loadingOverlay: { message: t("dashboard.loading") },
                legend: {
                  onItemClick: (e, l, i) => {
                    handlePopoverOpen(e, l);
                  },
                },
              }}
              loading={loading}
              xAxis={[
                {
                  scaleType: "band",
                  data: xAxisLabel,
                  barGapRatio:
                    (xAxisLabel?.length ?? 0 < 7) && (series?.count ?? 0 < 2)
                      ? 1
                      : 0.1,
                  categoryGapRatio:
                    (xAxisLabel?.length ?? 0 < 7) && (series?.count ?? 0 < 2)
                      ? 0.8
                      : 0,
                  tickPlacement: "middle",
                  label: Object.values(JSON.parse(settings.settings)?.x)[
                    levelRef.current
                  ],
                  valueFormatter: (label, context) =>
                    label.split("").length > 7 && context.location === "tick"
                      ? `${label.slice(
                          0,
                          label.split("").length / 2
                        )} \n${label.slice(
                          label.split("").length / 2,
                          label.split("").length > 30
                            ? 7
                            : label.split("").length
                        )}
                          ${label.split("").length > 30 ? "..." : ""}
                          `
                      : `${label}`,
                  tickLabelStyle: {
                    fontSize: 8,
                  },
                },
              ]}
              series={series} //this is the latest setting
              width={
                (xAxisLabel?.length ?? 0) > 12
                  ? 100 * xAxisLabel.length
                  : 300 * (JSON.parse(settings.settings)?.chartColumn ?? 1)
              }
              height={350 * (JSON.parse(settings.settings)?.chartRow ?? 1)}
              onItemClick={(e, p) => {
                handleDrillDown(p);
              }}
              tooltip={
                xAxisLabel?.length ?? 0 > 0
                  ? { trigger: "axis" }
                  : { trigger: "none" }
              }
            />
          </Grid>
        </Grid>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List
          sx={{
            height: "150px",
            maxHeight: "150px",
            overflow: "auto",
            backgroundColor: theme.palette.primary[theme.palette.mode],
          }}
        >
          {axisList.map((a) => (
            <ListItemButton
              onClick={() => handleDrillDown(a)}
              disabled={
                Object.keys(JSON.parse(settings.settings).x).length <= 1
              }
              sx={{
                "&.MuiListItemButton-gutters": {
                  backgroundColor: theme.palette.primary[theme.palette.mode],
                },
              }}
            >{`${a.label}: ${a.value}`}</ListItemButton>
          ))}
        </List>
      </Popover>

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
});

BarCharts.propTypes = {
  settings: PropTypes.object,
  settingSID: PropTypes.string,
  editFunction: PropTypes.func,
  isMidDevice: PropTypes.bool,
};

export default BarCharts;
