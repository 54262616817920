import {
  CometChatConversationsWithMessages,
  CometChatLocalize,
  CometChatThemeContext,
  ConversationsConfiguration,
} from "@cometchat/chat-uikit-react";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { useThemeColor } from "../../providers/ThemeColorProvider";

const Messages = (props) => {
  const { setTitle } = props;
  const { t, i18n } = useTranslation();
  const { theme } = React.useContext(CometChatThemeContext);
  const [themeColor] = useThemeColor();

  theme.palette.setMode(themeColor);
  setTitle(t("messages.title"));

  if (i18n.language.startsWith("zh")) {
    if (i18n.language.toLocaleLowerCase().endsWith("cn")) {
      CometChatLocalize.setLocale("zh");
    } else {
      CometChatLocalize.setLocale("zh-tw");
    }
  } else {
    CometChatLocalize.setLocale("en");
  }

  return (
    <CometChatThemeContext.Provider value={{ theme }}>
      <CometChatConversationsWithMessages />
    </CometChatThemeContext.Provider>
  );
};

export default Messages;
