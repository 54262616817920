import * as React from "react";

const HtmlLabel = (props) => {
  const { control } = props;
  const uniqueId = React.useId();

  const regex = new RegExp("((#|\\.)\\w+\\s*{)", "g");
  const content = control.content.replace(
    regex,
    `#${uniqueId.replace(new RegExp(":", "g"), "\\:")} $1`
  );

  return (
    <div
      id={uniqueId}
      style={{ textAlign: "left" }}
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export default HtmlLabel;
