import * as React from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import DialogContentText from "@mui/material/DialogContentText";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Select,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Tooltip,
  Typography,
  ToggleButton,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { debounce } from "@mui/material/utils";
import { LoadingButton } from "@mui/lab";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncLock from "@mui/icons-material/SyncLock";
import SearchIcon from "@mui/icons-material/Search";
import urlJoin from "url-join";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";

import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../../../Config";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import HightlightWords from "../../../../shared/HighlightWords";
import { ChangePasswordDialog } from "../../../../shared/PasswordRuleDisplay";
import PrimaryPositionTable from "../../../../shared/PrimaryPositionTable";
import SharedDialog from "../../../../shared/SharedDialog";
import TablePaginationActions from "../../../../shared/TablePaginationActions";
import TitleWithIcon from "../../../../shared/TitleWithIcon";
import UserAddEditor from "./UserAddEditor";
import UserEditor from "./UserEditor";

dayjs.extend(tz);

export default function UserSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [changePage, setChangePage] = React.useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = React.useState(false);
  const [disableGoNextPage, setDisableGoNextPage] = React.useState(true);
  const [isLoadingSave, setIsLoadingSave] = React.useState(false);

  const [users, setUsers] = React.useState([]);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = React.useState(false);
  const [userTablePage, setUserTablePage] = React.useState(0);
  const [rowsPerUserTablePage, setRowsPerUserTablePage] = React.useState(10);
  const [selectedUser, setSelectedUser] = React.useState();
  const [openEditUserDialog, setOpenEditUserDialog] = React.useState(false);
  const [openDisableUserDialog, setOpenDisableUserDialog] =
    React.useState(false);

  const [isUserInfoChanged, setIsUserInfoChanged] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);

  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });

  const [isChangePwBtnLoading, setIsChangePwBtnLoading] = React.useState(false);
  const [isLoadingUserList, setIsLoadingUserList] = React.useState(false);
  const [isAddUserPanelOpen, setIsAddUserPanelOpen] = React.useState(false);

  const [selectedUserRows, setSelectedUserRows] = React.useState([]);
  const [currentUserListPage, setCurrentUserListPage] =
    React.useState(userTablePage);
  const [lastSelectedState, setLastSelectedState] = React.useState(false);
  const [isShowAdvanceSearch, setIsShowAdvanceSearch] = React.useState(false);
  const [alignment, setAlignment] = React.useState(-1);
  const [availablePos, setAvailablePos] = React.useState([]);
  const [primaryPos, setPrimaryPos] = React.useState({});
  const [availablePosLoading, setAvailablePosLoading] = React.useState(false);
  const [modifyingUser, setModifyingUser] = React.useState();
  const [rules, setRules] = React.useState();

  const anchor = React.useRef();
  const userSearchBarRef = React.useRef();
  const advanceUserSearchBarFullNameRef = React.useRef();
  const advanceUserSearchBarEmailRef = React.useRef();
  const advanceUserSearchBarDepartmentRef = React.useRef();
  const customPropSearchBarRef = React.useRef();
  const accountDisabledSearchRef = React.useRef();
  const accountTypeSearchRef = React.useRef();
  const userEditor = React.useRef();
  const userAddEditor = React.useRef();
  const changePasswordRef = React.useRef();

  const indeterminate = React.useMemo(() => {
    return (
      selectedUserRows.length > 0 && selectedUserRows.length < users.length
    );
  }, [selectedUserRows, users]);

  const handleAlignment = (event, newAlignment) => {
    accountDisabledSearchRef.current = event.target;
    setAlignment(newAlignment);
  };

  const handleSelectAllClick = (event, startSlice, stopSlice) => {
    const allUserCells = users;
    setCurrentUserListPage(userTablePage);
    const newSelected = allUserCells
      .slice(startSlice, stopSlice)
      .map((n) => n.Id);

    if (lastSelectedState === false || userTablePage !== currentUserListPage) {
      for (let count = newSelected.length - 1; count >= 0; count--) {
        const isDulplicated = selectedUserRows.includes(newSelected[count]);
        if (isDulplicated === true) {
          newSelected.splice(count, 1);
        }
        setSelectedUserRows(selectedUserRows.concat(newSelected));
      }
      setLastSelectedState(true);
    } else if (event.target.checked === false) {
      setSelectedUserRows([]);
      setLastSelectedState(false);
    } else {
      setSelectedUserRows([]);
      setLastSelectedState(false);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selectedUserRows.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUserRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUserRows.slice(1));
    } else if (selectedIndex === selectedUserRows.length - 1) {
      newSelected = newSelected.concat(selectedUserRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUserRows.slice(0, selectedIndex),
        selectedUserRows.slice(selectedIndex + 1)
      );
    }

    setSelectedUserRows(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentUserListPage(userTablePage);
    setUserTablePage(newPage);
  };

  const handleChangeRowPerPage = (e) => {
    setRowsPerUserTablePage(parseInt(e.target.value, 10));
    setUserTablePage(0);
  };

  const handleCloseEditUserDialog = () => {
    setOpenEditUserDialog(false);
    setIsUserInfoChanged(false);
    setSelectedUser({});
  };

  const handleCloseDisableUserDialog = () => {
    setOpenDisableUserDialog(false);
    setIsUserInfoChanged(false);
  };

  const handleDisableUsersDialog = () => {
    showAlertMessage({
      title: t(
        "administrative_console.user_page.enable_disable_user.disable_user_title"
      ),
      content: t(
        "administrative_console.user_page.enable_disable_user.disable_user_description"
      ),
      buttons: [
        {
          text: t(
            "administrative_console.user_page.enable_disable_user.disable_user_title"
          ),
          action: () => {
            DisableUsers();
            closeAlertMessage();
          },
          variant: "contained",
        },
        {
          text: t("profile.proxy_and_delegation.cancel"),
          color: "error",
          action: () => {
            closeAlertMessage();
          },
          variant: "outlined",
        },
      ],
    });
  };
  const handleEnableUsersDialog = () => {
    showAlertMessage({
      title: t(
        "administrative_console.user_page.enable_disable_user.enable_user_title"
      ),
      content: t(
        "administrative_console.user_page.enable_disable_user.enable_user_description"
      ),
      buttons: [
        {
          text: t(
            "administrative_console.user_page.enable_disable_user.enable_user_title"
          ),
          action: () => {
            EnableUsers();
            closeAlertMessage();
          },
          variant: "contained",
        },
        {
          text: t("profile.proxy_and_delegation.cancel"),
          color: "error",
          action: () => {
            closeAlertMessage();
          },
          variant: "outlined",
        },
      ],
    });
  };
  const handleDeleteUsersDialog = () => {
    let userName = [];
    for (var i = 0; i < selectedUserRows.length; i++) {
      users.find((user) => {
        if (user.Id === selectedUserRows[i]) {
          userName.push(user.Name);
          return;
        }
      });
    }
    /*let userName = selectedUserRows.map((user, index) => {
      if (user === userCell[index].Id) {
        return userCell[index].Name;
      }
    });*/
    console.log(userName);
    showAlertMessage({
      title: t("administrative_console.user_page.delete_user"),
      content:
        t("administrative_console.user_page.delete_user_description") +
        "<br>" +
        t("administrative_console.user_page.delete_warning") +
        "<br>" +
        t("inbox_outbox.selected") +
        ": " +
        selectedUserRows.length +
        "<br>" +
        "<br>" +
        userName.filter((w) => !!w).join("<br>"),
      buttons: [
        {
          text: t("administrative_console.user_page.delete_user"),
          action: () => {
            DeleteUsers();
            closeAlertMessage();
            setIsUserInfoChanged(true);
          },
          variant: "contained",
        },
        {
          text: t("profile.proxy_and_delegation.cancel"),
          color: "error",
          action: () => {
            closeAlertMessage();
          },
          variant: "outlined",
        },
      ],
    });
    userName = [];
  };
  const positionOptionColumns = [
    {
      field: "position",
      headerName: t("administrative_console.user_page.position"),
      width: 350,
      editable: false,
    },
  ];

  // save new add user information to server.
  const handlePageChange = (event) => {
    setIsLoadingNextPage(true);

    const addingUser = userAddEditor.current.getAddingUser();

    if (changePage) {
      setChangePage(false);
      setIsLoadingNextPage(false);
      setIsUserInfoChanged(true);
    } else {
      Object.keys(addingUser).forEach((a) => {
        if (addingUser[a] === "") {
          delete addingUser[a];
        }
      });
      const userAttr = {
        FIRSTNAME: addingUser.firstName,
        MIDDLENAME: addingUser.middleName,
        LASTNAME: addingUser.lastName,
        FULLNAME: addingUser.fullName,
        TITLE: addingUser.title,
        JOBTITLE: addingUser.jobTitle,
        DEPARTMENT: addingUser.department,
        MOBILE: addingUser.mobile,
        EMAIL: addingUser.email,
        ADDRESS_OFFICE: addingUser.address_office,
      };
      const customSetting = {
        AVSERVERBYPASS: false,
      };
      const saveData = {
        Login: addingUser.email,
        AccountType: addingUser.accountType,
        Attr: JSON.stringify(userAttr),
        CustomSetting: JSON.stringify(customSetting),
        Password: addingUser.password,
      };
      const addUserURL = urlJoin(resServerBaseUrl, "User/AddUser");
      CallApiWithContext(
        addUserURL,
        authenticationContext,
        JSON.stringify(saveData)
      )
        .then((response) => {
          if (response?.succeed) {
            let refresh = true;

            setModifyingUser({
              ...saveData,
              ID: response.userId,
              UUID: response.userUUID,
            });

            Object.keys(response.positionResult).map((ps) => {
              if (response.positionResult[ps].length > 1) {
                setChangePage(true);
                refresh = false;
              }
            });
            if ((addingUser?.password ?? "") == "") {
              showAlertMessage({
                title: t("administrative_console.user_page.add_user"),
                content:
                  t(
                    "administrative_console.user_page.alert_password_reminder_a"
                  ) +
                  (response?.message ?? "") +
                  t(
                    "administrative_console.user_page.alert_password_reminder_b"
                  ),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      closeAlertMessage();
                      if (refresh) {
                        setIsUserInfoChanged(true);
                      }
                    },
                  },
                ],
              });
            } else {
              showAlertMessage({
                title: t("administrative_console.user_page.add_user"),
                content: t(
                  "administrative_console.user_page.alert_success_add_user"
                ),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      closeAlertMessage();
                      if (refresh) {
                        setIsUserInfoChanged(true);
                      }
                    },
                  },
                ],
              });
            }
            setIsLoadingNextPage(false);
          } else {
            if ((response?.message ?? "") == "Unauthorized") {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: t(
                  "administrative_console.user_page.alert_login_expired"
                ),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
              setIsLoadingNextPage(false);
            } else if (
              (response?.message ?? "") == "This user is already exsited"
            ) {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: t("administrative_console.user_page.alert_user_exist"),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
              setDisableGoNextPage(true);
              setIsLoadingNextPage(false);
            } else if (
              (response?.message ?? "") == "Password did not meet requirement"
            ) {
              showAlertMessage({
                title: t("administrative_console.user_page.add_user"),
                content: t(
                  "administrative_console.user_page.alert_password_invalid"
                ),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      setIsLoadingNextPage(false);
                      closeAlertMessage();
                    },
                  },
                ],
              });
            } else {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: t(
                  "administrative_console.user_page.alert_server_side_error"
                ),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      setIsLoadingNextPage(false);
                      closeAlertMessage();
                    },
                  },
                ],
              });
            }
          }
        })
        .catch((error) => {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: error,
            buttons: [
              {
                text: t("report.exist_dialog.close"),
                action: () => {
                  closeAlertMessage();
                },
              },
            ],
          });
          setIsLoadingNextPage(false);
        });
    }
  };
  const handleAddUserPropertyChange = () => {
    if (userAddEditor.current?.isDataIncorrect()) {
      setDisableGoNextPage(true);
    } else {
      setDisableGoNextPage(false);
    }
  };

  // save user primary position to server.
  const handleSave = (event) => {
    setIsLoadingSave(true);

    const currentUser = modifyingUser;

    Object.keys(currentUser).find((a) => {
      if (typeof currentUser[a] === "undefined") {
        delete currentUser[a];
      }
    });

    const saveData = {
      ...currentUser,
      PrimaryPositionUUID: JSON.stringify(primaryPos),
    };

    if (Object.keys(saveData).includes("ID")) {
      const updateUserURL = urlJoin(resServerBaseUrl, "User/UpdateUser");

      CallApiWithContext(
        updateUserURL,
        authenticationContext,
        JSON.stringify(saveData)
      )
        .then((response) => {
          if (response) {
            if ((response?.message ?? "") == "User successfully updated") {
              showAlertMessage({
                title: t("administrative_console.user_page.add_user"),
                content: t(
                  "administrative_console.user_page.alert_success_selected_role"
                ),
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
            } else {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: response?.message ?? "",
                buttons: [
                  {
                    text: t("report.exist_dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
            }
            setChangePage(false);
          } else {
            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: t(
                "administrative_console.user_page.alert_server_side_error"
              ),
              buttons: [
                {
                  text: t("report.exist_dialog.close"),
                  action: () => {
                    closeAlertMessage();
                  },
                },
              ],
            });
          }
          setIsUserInfoChanged(!isUserInfoChanged);
          handleCloseEditUserDialog();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingSave(false);
        });
    } else {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("administrative_console.user_page.alert_ID_match_error"),
        buttons: [
          {
            text: t("report.exist_dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
      setIsLoadingSave(false);
    }
  };

  React.useEffect(() => {
    GetPasswordRule();
    setIsLoadingUserList(true);
    setLastSelectedState(false);
    setIsUserInfoChanged(false);
    setUserTablePage(0);
    setSelectedUserRows([]);
    setIsAddUserPanelOpen(false);
    setChangePage(false);

    //fetch api to get users and store them by useState
    const url = urlJoin(resServerBaseUrl, "/User/GetAllUserByCompany");

    CallApiWithContext(url, authenticationContext, null)
      .then((res) => {
        const data = res.userList
          .filter((u) => !u.accountDeleted && !u.isRobot && !u.isSystem)
          .map((u) => {
            return {
              Id: u.id,
              UUID: u.uuid,
              Name: u.name,
              Attr: u.attr,
              AccountDeleted: u.accountDeleted,
              AccountDisabled: u.accountDisabled,
              AccountType: u.accountType,
            };
          });

        setUsers(data);
      })
      .catch(console.log)
      .finally(() => setIsLoadingUserList(false));
  }, [isUserInfoChanged]);

  const handleSelectedUser = (event, dialogType) => {
    const data = {
      UserId: event.Id,
      UUID: event.UUID,
      UserLogin: event.Name,
      UserAttr: event.Attr,
      UserAccountDeleted: event.AccountDeleted,
      UserAccountDisabled: event.AccountDisabled,
      UserAccountType: event.AccountType,
    };

    setSelectedUser(data);

    switch (dialogType) {
      case "ChangePassword":
        changePasswordRef.current.openChangePasswordDialog();
        break;
      case "EditUser":
        setOpenEditUserDialog(true);
        break;
      case "DisableUser":
        console.log(event.AccountDisabled);
        if (event.AccountDisabled === true) {
          userAccountDisable.current = true;
        } else {
          userAccountDisable.current = false;
        }
        setOpenDisableUserDialog(true);
        break;
      case "DeleteUser":
        setOpenDeleteUserDialog(true);
        break;
      default:
        break;
    }
  };

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };

  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  //Edit user
  const [isDisablePersonalInfoSaveButton, setIsDisablePersonalInfoSaveButton] =
    React.useState(true);

  const handleUserEditorValueChange = () => {
    if (userEditor.current)
      setIsDisablePersonalInfoSaveButton(userEditor.current.hasError);
  };
  const handlePersonalInfoSave = () => {
    //callApiToSaveCustomProp will update userInfo
    EditUserInfo(
      userEditor.current.getEditingUser(),
      userEditor.current.isUpdateUserPosition()
    );
  };

  const isSelected = (name) => selectedUserRows.indexOf(name) !== -1;

  // save the editing user to server.
  const EditUserInfo = (editingUser, isUpdateUserPosition) => {
    const saveData = {
      ID: editingUser.id,
      UUID: editingUser.uuid,
      Attr: JSON.stringify(editingUser.attr),
      ModulePermissions: editingUser.modulePermissions,
    };

    const updateUserURL = urlJoin(resServerBaseUrl, "User/UpdateUser");
    setIsChangePwBtnLoading(true);
    CallApiWithContext(
      updateUserURL,
      authenticationContext,
      JSON.stringify(saveData)
    ).then((response) => {
      setIsChangePwBtnLoading(false);

      if ((response?.message ?? "") == "User successfully updated") {
        if (isUpdateUserPosition) {
          let refresh = true;

          Object.keys(response.positionResult).map((ps) => {
            if (response.positionResult[ps].length > 1) {
              GetPrimaryPositionByUserID(saveData.ID);
              setChangePage(true);
              refresh = false;
            }
          });

          showAlertMessage({
            title: t("administrative_console.user_page.edit_user.edit"),
            content: t(
              "administrative_console.user_page.alert_success_edit_user"
            ),
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  if (refresh) {
                    handleCloseEditUserDialog();
                    setIsUserInfoChanged(true);
                  }
                },
              },
            ],
          });
        } else {
          showAlertMessage({
            title: t("administrative_console.user_page.edit_user.edit"),
            content: t(
              "administrative_console.user_page.alert_success_edit_user"
            ),
            buttons: [
              {
                text: "OK",
                action: () => {
                  setIsLoadingSave(false);
                  handleCloseEditUserDialog();
                  setIsUserInfoChanged(true);
                  closeAlertMessage();
                },
              },
            ],
          });
        }
      } else {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: t("profile.proxy_and_delegation.error"),
          buttons: [
            {
              text: "OK",
              action: () => {
                closeAlertMessage();
                handleCloseEditUserDialog();
              },
            },
          ],
        });
      }
    });
  };

  //Disable user function
  const userAccountDisable = React.useRef(false);
  const DisableUser = () => {
    setOpenDisableUserDialog(false);
    const disableUserUrl = urlJoin(resServerBaseUrl, "User/DisableUser");
    const user = {
      UserId: selectedUser.UserId,
    };
    CallApiWithContext(
      disableUserUrl,
      authenticationContext,
      JSON.stringify(user)
    ).then((response) => {
      if ((response?.message ?? "") === "Cannot disable user") {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: response?.message ?? "",
          buttons: [
            {
              text: "OK",
              action: () => {
                closeAlertMessage();
                setIsUserInfoChanged(true);
                handleCloseEditUserDialog();
              },
            },
          ],
        });
      } else if (
        (response?.message ?? "") ===
        "Unauthorized User cannot execute this action"
      ) {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: response?.message ?? "",
          buttons: [
            {
              text: "OK",
              action: () => {
                closeAlertMessage();
                handleCloseEditUserDialog();
              },
            },
          ],
        });
      } else {
        setIsUserInfoChanged(true);
      }
    });
    setIsUserInfoChanged(true);
  };
  //Enable user function
  const EnableUser = () => {
    setIsUserInfoChanged(true);
    setOpenDisableUserDialog(false);
    const disableUserUrl = urlJoin(resServerBaseUrl, "User/EnableUser");
    const user = {
      UserId: selectedUser.UserId,
    };
    CallApiWithContext(
      disableUserUrl,
      authenticationContext,
      JSON.stringify(user)
    ).then((response) => {
      setIsUserInfoChanged(true);
      if ((response?.message ?? "") === "Cannot enable user") {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: response?.message ?? "",
          buttons: [
            {
              text: "OK",
              action: () => {
                closeAlertMessage();
                handleCloseEditUserDialog();
              },
            },
          ],
        });
      } else if (
        (response?.message ?? "") ===
        "Unauthorized User cannot execute this action"
      ) {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: response?.message ?? "",
          buttons: [
            {
              text: "OK",
              action: () => {
                closeAlertMessage();
                handleCloseEditUserDialog();
              },
            },
          ],
        });
      }
    });
  };
  //Delete User function
  const DeleteUser = () => {
    if (selectedUser.UserId) {
      console.log(selectedUser.UserId);
      const user = {
        UserId: selectedUser.UserId,
      };
      const deleteUserUrl = urlJoin(resServerBaseUrl, "User/RemoveUser");
      CallApiWithContext(
        deleteUserUrl,
        authenticationContext,
        JSON.stringify(user)
      ).then((response) => {
        if ((response?.message ?? "") === "Cannot remove user") {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: response?.message ?? "",
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  handleCloseEditUserDialog();
                },
              },
            ],
          });
        } else if (
          (response?.message ?? "") ===
          "Unauthorized User cannot execute this action"
        ) {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: response?.message ?? "",
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  handleCloseEditUserDialog();
                },
              },
            ],
          });
        }
      });
      setOpenDeleteUserDialog(false);
      setIsUserInfoChanged(true);
    }
  };

  const EnableUsers = () => {
    setIsUserInfoChanged(true);
    if (selectedUserRows.length !== 0) {
      const enableUsersUrl = urlJoin(resServerBaseUrl, "User/EnableUsers");
      const users = {
        UserIds: selectedUserRows,
      };
      console.log(JSON.stringify(users));
      CallApiWithContext(
        enableUsersUrl,
        authenticationContext,
        JSON.stringify(users)
      ).then((response) => {
        setIsUserInfoChanged(true);
        if ((response?.message ?? "") === "Cannot enable users") {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: response?.message ?? "",
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  handleCloseEditUserDialog();
                },
              },
            ],
          });
        }
      });
    }
  };
  const DisableUsers = () => {
    setIsUserInfoChanged(true);
    if (selectedUserRows.length !== 0) {
      const disableUsersUrl = urlJoin(resServerBaseUrl, "User/DisableUsers");
      const users = {
        UserIds: selectedUserRows,
      };
      console.log(JSON.stringify(users));
      CallApiWithContext(
        disableUsersUrl,
        authenticationContext,
        JSON.stringify(users)
      ).then((response) => {
        if ((response?.message ?? "") === "Cannot disable users") {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: response?.message ?? "",
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  handleCloseEditUserDialog();
                  setIsUserInfoChanged(true);
                },
              },
            ],
          });
        }
      });
    }
  };
  const DeleteUsers = () => {
    setIsUserInfoChanged(true);
    if (selectedUserRows.length !== 0) {
      const disableUsersUrl = urlJoin(resServerBaseUrl, "User/RemoveUsers");
      const users = {
        UserIds: selectedUserRows,
      };
      console.log(JSON.stringify(users));
      CallApiWithContext(
        disableUsersUrl,
        authenticationContext,
        JSON.stringify(users)
      ).then((response) => {
        if ((response?.message ?? "") === "Cannot disable users") {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: response?.message ?? "",
            buttons: [
              {
                text: "OK",
                action: () => {
                  closeAlertMessage();
                  handleCloseEditUserDialog();
                  setIsUserInfoChanged(true);
                },
              },
            ],
          });
        }
      });
    }
  };

  const AdvancedSearch = () => {
    setIsLoadingUserList(true);
    const data = [];
    let searchCritiria = {};

    const url = urlJoin(resServerBaseUrl, "User/AdvanceUserSearch");

    searchCritiria = {
      Fullname: advanceUserSearchBarFullNameRef?.current.value ?? null,
      Department: advanceUserSearchBarDepartmentRef?.current.value ?? null,
      Email: advanceUserSearchBarEmailRef?.current.value ?? null,
      AccountDisabled: accountDisabledSearchRef?.current.value ?? null,
      AccountType:
        accountTypeSearchRef?.current.value == ""
          ? null
          : accountTypeSearchRef?.current.value,
    };

    console.log(
      accountDisabledSearchRef.current.value,
      " ",
      accountTypeSearchRef.current.value
    );

    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify(searchCritiria)
    ).then((json) => {
      if (json.status != "undefined") {
        if (json.status !== 400) {
          setIsLoadingUserList(false);
          json.userList.forEach((element) => {
            if (element.accountDeleted !== true) {
              const userData = {
                Id: element.id,
                Name: element.name,
                Attr: element.attr,
                AccountDeleted: element.accountDeleted,
                AccountDisabled: element.accountDisabled,
                AccountType: element.accountType,
              };
              data.push(userData);
            }
          });
          setUsers(data);
          setIsLoadingUserList(false);
        } else {
          setUsers([]);
        }
      }
    });
    setIsShowAdvanceSearch(false);
    setIsLoadingSave(false);
    setAlignment(null);
  };

  const FuzzyUserSearch = debounce(() => {
    setIsLoadingUserList(true);
    const data = [];
    let searchCritiria = {};
    if (userSearchBarRef.current.value !== null) {
      const url = urlJoin(resServerBaseUrl, "User/FuzzyUserSearch");

      searchCritiria = {
        SearchItem: userSearchBarRef.current.value,
      };

      CallApiWithContext(
        url,
        authenticationContext,
        JSON.stringify(searchCritiria)
      ).then((json) => {
        if (json.status != "undefined") {
          if (json.status !== 400) {
            setIsLoadingUserList(false);
            json.userList.forEach((element) => {
              if (element.accountDeleted !== true) {
                const userData = {
                  Id: element.id,
                  UUID: element.uuid,
                  Name: element.name,
                  Attr: element.attr,
                  AccountDeleted: element.accountDeleted,
                  AccountDisabled: element.accountDisabled,
                  AccountType: element.accountType,
                };
                data.push(userData);
              }
            });
            setUsers(data);
            setIsLoadingUserList(false);
          } else {
            setUsers([]);
          }
        }
      });
    }
    setIsLoadingSave(false);
  }, 1500);

  React.useEffect(() => {
    setIsAddUserPanelOpen(false);
  }, []);

  const GetPrimaryPositionByUserID = (id) => {
    setAvailablePosLoading(true);

    const url = urlJoin(resServerBaseUrl, "/User/GetAvailablePositions");
    CallApiWithContext(url, authenticationContext, JSON.stringify({ ID: id }))
      .then((response) => {
        if (response) {
          let availablePos = response.filter((r) => r.Positions.length > 1);
          setAvailablePos(availablePos);
          let primarypos = {};
          availablePos.map((r) => {
            primarypos[r.SID] = r.Positions.find(
              (p) => p.IsPrimaryPosition == true
            ).UUID;
          });
          setPrimaryPos(primarypos);
        }
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setAvailablePosLoading(false);
      });
  };

  const handlePrimaryPositionChanged = (e, chartSID) => {
    // let tempObj = primaryPos;
    // tempObj[chartName] = e.target.value;
    setPrimaryPos({
      ...primaryPos,
      [chartSID]: e.target.value,
    });
  };

  const GetPasswordRule = () => {
    const url = urlJoin(resServerBaseUrl, "/User/GetPasswordRule");
    CallApiWithContext(url, authenticationContext).then((response) => {
      if (response) {
        setRules(response);
      }
    });
  };

  return (
    <Grid>
      {isSmallDevice && changePage === false ? (
        <Fab
          sx={{ right: "5%", bottom: "5%", position: "fixed" }}
          onClick={() => {
            setIsAddUserPanelOpen(!isAddUserPanelOpen);
          }}
        >
          {isAddUserPanelOpen ? <CloseIcon /> : <AddIcon />}
        </Fab>
      ) : null}

      {changePage ? (
        <Grid
          container
          item
          xs={12}
          md={12}
          direction="column"
          sx={{ p: "20px" }}
        >
          <Typography variant="h5">
            {t("administrative_console.user_page.select_primary_role")}
          </Typography>
          <Grid
            item
            sx={{
              height: 500,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <PrimaryPositionTable
              PrimaryPositionObj={primaryPos}
              onClickFunction={handlePrimaryPositionChanged}
              AvailablePosLoading={availablePosLoading}
              AvailableArr={availablePos}
            />
            <Grid container direction="row" sx={{ height: "30px" }}></Grid>
            <Grid
              container
              direction="row"
              justifyContent="right"
              alignItems="right"
            >
              <Grid item>
                <LoadingButton
                  variant="contained"
                  loading={isLoadingSave}
                  onClick={handleSave}
                >
                  {t("profile.proxy_and_delegation.save")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          md={12}
          direction="column"
          sx={{ p: { xs: "10px", sm: "20px" } }}
        >
          <Grid container>
            <Grid container item direction="row" style={{ textalign: "left" }}>
              <TitleWithIcon
                icon={<PersonIcon />}
                title={{
                  content: t("administrative_console.user_page.add_user"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container item>
              <Typography variant="p">
                {t("administrative_console.user_page.add_user_description")}
              </Typography>
            </Grid>
          </Grid>

          {/* delete user comfirmation dialog */}
          <Dialog
            open={openDeleteUserDialog}
            onClose={() => {
              setOpenDeleteUserDialog(false);
            }}
          >
            <DialogTitle textalign={"center"}>
              {t("profile.proxy_and_delegation.confirm_delete")}
            </DialogTitle>
            <DialogContent sx={{ textalign: "center" }}>
              <Typography color="error">
                <Box sx={{ fontWeight: "bold" }}>
                  {t("administrative_console.user_page.delete_warning")}
                </Box>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => DeleteUser()}
              >
                {t("administrative_console.report_page.confirm")}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setOpenDeleteUserDialog(false);
                }}
                sx={{ m: 2 }}
              >
                {t("administrative_console.report_page.cancel")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* change Password dialog */}
          <ChangePasswordDialog
            rules={rules}
            ref={changePasswordRef}
            userUUID={selectedUser?.UUID ?? ""}
            userLogin={selectedUser?.UserLogin ?? ""}
            isAdminChange={true}
          />
          {/* edit user information dialog */}
          <Dialog
            open={openEditUserDialog}
            onClose={handleCloseEditUserDialog}
            maxWidth={"md"}
            fullScreen={isSmallDevice}
          >
            <DialogTitle>
              {t("administrative_console.user_page.edit_user.dialog_title")}
            </DialogTitle>
            <DialogContent sx={{ overflow: "auto" }} textalign="center">
              <UserEditor
                ref={userEditor}
                user={selectedUser}
                onValueChange={handleUserEditorValueChange}
                moduleEditorVisible={true}
                showIcon={false}
                disablePositionField={false}
              />
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={isChangePwBtnLoading}
                disabled={isDisablePersonalInfoSaveButton}
                variant="contained"
                onClick={handlePersonalInfoSave}
              >
                {t("profile.proxy_and_delegation.save")}
              </LoadingButton>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenEditUserDialog(false)}
              >
                {t("administrative_console.report_page.cancel")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* disable user dialog */}
          {userAccountDisable.current ? (
            <Dialog
              open={openDisableUserDialog}
              onClose={handleCloseDisableUserDialog}
            >
              <DialogTitle textalign={"center"}>
                {t(
                  "administrative_console.user_page.enable_disable_user.enable_user_title"
                )}
              </DialogTitle>

              <DialogContent sx={{ textalign: "center" }}>
                <Grid
                  container
                  justifyContent={"center"}
                  textAlign={"center"}
                  display={"contents"}
                >
                  <Grid item>
                    <Typography variant="p">
                      {t(
                        "administrative_console.user_page.enable_disable_user.enable_user_description"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ alignContent: "center" }}>
                <Button variant="contained" onClick={EnableUser}>
                  {t("administrative_console.report_page.confirm")}
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseDisableUserDialog}
                >
                  {t("administrative_console.report_page.cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDisableUserDialog}
              onClose={handleCloseDisableUserDialog}
            >
              <DialogTitle textalign={"center"}>
                {t(
                  "administrative_console.user_page.enable_disable_user.disable_user_title"
                )}
              </DialogTitle>

              <DialogContent sx={{ textalign: "center" }}>
                <Grid
                  container
                  justifyContent={"center"}
                  textAlign={"center"}
                  display={"contents"}
                >
                  <Grid item>
                    <Typography variant="p">
                      {t(
                        "administrative_console.user_page.enable_disable_user.disable_user_description"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={DisableUser}>
                  {t("administrative_console.report_page.confirm")}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseDisableUserDialog}
                >
                  {t("administrative_console.report_page.cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {/* user listing table */}
          <Grid container direction="row" my={2}>
            {/* search textfield */}
            <Grid
              item
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={isSmallDevice ? 12 : "auto"}>
                <TextField
                  inputRef={userSearchBarRef}
                  fullWidth={isSmallDevice}
                  size="small"
                  sx={{ display: "contents" }}
                  variant="outlined"
                  autoComplete="off"
                  disabled={isLoadingUserList}
                  onChange={() => {
                    FuzzyUserSearch();
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      FuzzyUserSearch();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <React.Fragment key={"reset"}>
                        <Tooltip title={t("inbox_outbox.reset")}>
                          <IconButton
                            onClick={() => {
                              setIsUserInfoChanged(true);
                              userSearchBarRef.current.value = "";
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={t(
                            "administrative_console.user_page.advance_searching"
                          )}
                        >
                          <IconButton
                            ref={anchor}
                            onClick={() => {
                              setIsShowAdvanceSearch(!isShowAdvanceSearch);
                            }}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                  }}
                ></TextField>
              </Grid>

              {isSmallDevice ? null : (
                <>
                  <Grid item>
                    {selectedUserRows.length > 0 ? (
                      <Grid item>
                        <Typography>
                          {`${selectedUserRows.length} ${t(
                            "inbox_outbox.selected"
                          )}`}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      ></Grid>
                    )}
                  </Grid>
                  <Grid item display={"inline-flex"}>
                    {selectedUserRows.length > 0 ? (
                      <Box>
                        <IconButton
                          color={"primary"}
                          onClick={handleEnableUsersDialog}
                        >
                          <PersonIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={handleDisableUsersDialog}
                        >
                          <PersonOffIcon />
                        </IconButton>
                        <IconButton
                          sx={{ color: red[700] }}
                          onClick={handleDeleteUsersDialog}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Grid item></Grid>
                    )}
                    <Tooltip
                      title={t("administrative_console.user_page.add_user")}
                    >
                      <IconButton
                        onClick={() => {
                          setIsAddUserPanelOpen(!isAddUserPanelOpen);
                        }}
                      >
                        {isAddUserPanelOpen ? <CloseIcon /> : <AddIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              )}
            </Grid>

            {/* advance search dialog */}
            <Dialog
              open={isShowAdvanceSearch}
              onClose={() => {
                setIsShowAdvanceSearch(false);
              }}
              maxWidth={"md"}
            >
              <DialogTitle>
                {t("administrative_console.user_page.advance_searching")}
                <Tooltip title={t("inbox_outbox.reset")}>
                  <IconButton
                    textalign={"end"}
                    onClick={() => {
                      setAlignment(-1);
                      advanceUserSearchBarFullNameRef.current.value = "";
                      advanceUserSearchBarDepartmentRef.current.value = "";
                      advanceUserSearchBarEmailRef.current.value = "";
                      customPropSearchBarRef.current.value = "";
                      accountTypeSearchRef.current.value = "";
                      accountDisabledSearchRef.current = "";
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </DialogTitle>
              <DialogContent>
                <Grid container direction={"row"} spacing={1}>
                  <Grid item xs={isSmallDevice ? 12 : 6}>
                    <TextField
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          setIsShowAdvanceSearch(false);
                          AdvancedSearch();
                        }
                      }}
                      fullWidth
                      inputRef={advanceUserSearchBarFullNameRef}
                      label={t("administrative_console.user_page.full_name")}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={isSmallDevice ? 12 : 6}>
                    <TextField
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          setIsShowAdvanceSearch(false);
                          AdvancedSearch();
                        }
                      }}
                      fullWidth
                      inputRef={advanceUserSearchBarDepartmentRef}
                      label={t("administrative_console.user_page.department")}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item></Grid>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={isSmallDevice ? 12 : 6}>
                    <TextField
                      fullWidth
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          setIsShowAdvanceSearch(false);
                          AdvancedSearch();
                        }
                      }}
                      autoComplete="off"
                      inputRef={advanceUserSearchBarEmailRef}
                      label={t("administrative_console.user_page.email")}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={isSmallDevice ? 12 : 6}>
                    <FormControl fullWidth>
                      <InputLabel id="accountType">
                        {t("administrative_console.user_page.account_type")}
                      </InputLabel>
                      <Select
                        inputRef={accountTypeSearchRef}
                        labelId="accountType"
                        label="Account Type"
                        type="string"
                        defaultValue={""}
                        autoWidth
                      >
                        <MenuItem value={1}>
                          {t(
                            "administrative_console.user_page.organization_admin"
                          )}
                        </MenuItem>
                        <MenuItem value={0}>
                          {t(
                            "administrative_console.license_page.standard_users"
                          )}
                        </MenuItem>
                        <MenuItem value={2}>
                          {t(
                            "administrative_console.license_page.restricted_users"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction={"row"} spacing={2} pt={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      inputRef={customPropSearchBarRef}
                      label={t("profile.custom_property.custom_property")}
                      type="string"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  pt={1}
                  xs={12}
                  textalign={"center"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                    textalign={isSmallDevice ? "center" : "start"}
                    xs={isSmallDevice ? 12 : 6}
                  >
                    <DialogContentText>
                      {t("administrative_console.user_page.account_state")}
                    </DialogContentText>
                  </Grid>
                  <Grid
                    item
                    textalign={isSmallDevice ? "center" : "start"}
                    xs={isSmallDevice ? 12 : 6}
                  >
                    <ToggleButtonGroup
                      ref={accountDisabledSearchRef}
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={1}>
                        {t(
                          "administrative_console.user_page.enable_disable_user.disable"
                        )}
                      </ToggleButton>
                      <ToggleButton value={-1}>
                        {t(
                          "administrative_console.user_page.enable_disable_user.any"
                        )}
                      </ToggleButton>
                      <ToggleButton value={0}>
                        {t(
                          "administrative_console.user_page.enable_disable_user.enable"
                        )}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  loading={isLoadingSave}
                  onClick={() => {
                    setIsLoadingSave(true);
                    AdvancedSearch();
                  }}
                >
                  {t("inbox_outbox.search")}
                </LoadingButton>
                <Button
                  color="error"
                  onClick={() => {
                    setIsShowAdvanceSearch(false);
                  }}
                >
                  {t("formruntime.cancel")}
                </Button>
              </DialogActions>
            </Dialog>

            <Grid
              item
              xs={12}
              justifyContent={"center"}
              mt={1}
              component={Paper}
            >
              <TableContainer>
                <>
                  {isSmallDevice ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              indeterminate={indeterminate}
                              checked={
                                users.length > 0 &&
                                selectedUserRows.length === users.length
                              }
                              onChange={(event) =>
                                handleSelectAllClick(
                                  event,
                                  userTablePage * rowsPerUserTablePage,
                                  userTablePage * rowsPerUserTablePage +
                                    rowsPerUserTablePage
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {selectedUserRows.length > 0 ? (
                              <Typography>
                                {`${selectedUserRows.length} ${t(
                                  "inbox_outbox.selected"
                                )}`}
                              </Typography>
                            ) : (
                              <Grid container direction={"column"}>
                                <Grid item>
                                  <Typography variant="p" xs={12}>
                                    {t("profile.full_name")}
                                  </Typography>
                                </Grid>
                                <Grid item container direction={"column"}>
                                  <Typography variant={"p"}>
                                    {t("profile.email")}
                                  </Typography>
                                  <Typography variant="p">
                                    {t(
                                      "administrative_console.user_page.department"
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {selectedUserRows.length > 0 ? (
                              <span>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={handleEnableUsersDialog}
                                >
                                  <PersonIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={handleDisableUsersDialog}
                                >
                                  <PersonOffIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  sx={{ color: red[700] }}
                                  onClick={handleDeleteUsersDialog}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </span>
                            ) : (
                              <Grid container direction={"column"}>
                                <Grid item>
                                  <Typography variant="p">
                                    {t(
                                      "administrative_console.report_page.action"
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {isLoadingUserList ? (
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {users ? (
                            users
                              .slice(
                                userTablePage * rowsPerUserTablePage,
                                userTablePage * rowsPerUserTablePage +
                                  rowsPerUserTablePage
                              )
                              .map((user, index) => {
                                const isItemSelected = isSelected(user.Id);
                                return (
                                  <TableRow
                                    key={index}
                                    aria-checked={isItemSelected}
                                    selected={isItemSelected}
                                    id="userRecord"
                                  >
                                    <TableCell
                                      padding="checkbox"
                                      key={"table_checkbox" + index}
                                    >
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={(event) =>
                                          handleClick(event, user.Id)
                                        }
                                      />
                                    </TableCell>

                                    <TableCell
                                      key={"fullname" + index}
                                      onClick={(event) =>
                                        handleClick(event, user.Id)
                                      }
                                      sx={{ wordBreak: "break-word" }}
                                    >
                                      {userSearchBarRef.current?.value ? (
                                        <React.Fragment
                                          key={"userList_highLight"}
                                        >
                                          <HightlightWords
                                            originalWord={
                                              JSON.parse(user.Attr)?.FULLNAME ??
                                              "--"
                                            }
                                            highlightWord={
                                              userSearchBarRef.current.value
                                            }
                                          />
                                          <Grid item direction={"column"}>
                                            <Grid
                                              item
                                              sx={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <HightlightWords
                                                originalWord={
                                                  JSON.parse(user.Attr)
                                                    ?.EMAIL ?? "--"
                                                }
                                                highlightWord={
                                                  userSearchBarRef.current.value
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              sx={{ display: "inline-flex" }}
                                            >
                                              <HightlightWords
                                                originalWord={
                                                  JSON.parse(user.Attr)
                                                    ?.DEPARTMENT ?? "--"
                                                }
                                                highlightWord={
                                                  userSearchBarRef.current.value
                                                }
                                              />
                                            </Grid>
                                          </Grid>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment
                                          key={"userList_noHighlight"}
                                        >
                                          <Typography variant="p" xs={12}>
                                            {JSON.parse(user.Attr)?.FULLNAME ??
                                              "--"}
                                          </Typography>
                                          <Grid
                                            item
                                            container
                                            direction={"column"}
                                          >
                                            <Typography
                                              variant="p"
                                              style={{
                                                display:
                                                  JSON.parse(user.Attr)
                                                    ?.FULLNAME ?? "--" === "--"
                                                    ? "flex"
                                                    : "inline-block",
                                              }}
                                            >
                                              {JSON.parse(user.Attr)?.EMAIL ??
                                                "--"}
                                            </Typography>
                                            <Typography
                                              variant="p"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              {JSON.parse(user.Attr)
                                                ?.DEPARTMENT ?? "--"}
                                            </Typography>
                                          </Grid>
                                        </React.Fragment>
                                      )}
                                    </TableCell>

                                    <TableCell
                                      align="right"
                                      key={"action" + index}
                                    >
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <IconButton
                                            onClick={() =>
                                              handleSelectedUser(
                                                user,
                                                "EditUser"
                                              )
                                            }
                                            disabled={isItemSelected === true}
                                          >
                                            <Tooltip
                                              title={t(
                                                "administrative_console.user_page.edit_user.edit"
                                              )}
                                            >
                                              <BorderColorIcon
                                                disabled={
                                                  isItemSelected === true
                                                }
                                                fontSize="small"
                                                color={
                                                  isItemSelected === true
                                                    ? "disabled"
                                                    : "primary"
                                                }
                                              />
                                            </Tooltip>
                                          </IconButton>
                                          <IconButton
                                            onClick={() =>
                                              handleSelectedUser(
                                                user,
                                                "ChangePassword"
                                              )
                                            }
                                            disabled={isItemSelected === true}
                                          >
                                            <Tooltip
                                              title={t(
                                                "reset_password_dialog.change_password"
                                              )}
                                            >
                                              <SyncLock
                                                disabled={
                                                  isItemSelected === true
                                                }
                                                fontSize="small"
                                                color={
                                                  isItemSelected === true
                                                    ? "disabled"
                                                    : "primary"
                                                }
                                              ></SyncLock>
                                            </Tooltip>
                                          </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <IconButton
                                            disabled={isItemSelected === true}
                                          >
                                            {user.AccountDisabled ? (
                                              <Tooltip
                                                title={t(
                                                  "administrative_console.user_page.enable_disable_user.enable_user_title"
                                                )}
                                              >
                                                <PersonOffIcon
                                                  disabled={
                                                    isItemSelected === true
                                                  }
                                                  fontSize="small"
                                                  color={
                                                    isItemSelected === true
                                                      ? "disabled"
                                                      : "secondary"
                                                  }
                                                  onClick={() =>
                                                    handleSelectedUser(
                                                      user,
                                                      "DisableUser"
                                                    )
                                                  }
                                                ></PersonOffIcon>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title={t(
                                                  "administrative_console.user_page.enable_disable_user.disable_user_title"
                                                )}
                                              >
                                                <PersonIcon
                                                  disabled={
                                                    isItemSelected === true
                                                  }
                                                  fontSize="small"
                                                  color={
                                                    isItemSelected === true
                                                      ? "disabled"
                                                      : "primary"
                                                  }
                                                  onClick={() =>
                                                    handleSelectedUser(
                                                      user,
                                                      "DisableUser"
                                                    )
                                                  }
                                                ></PersonIcon>
                                              </Tooltip>
                                            )}
                                          </IconButton>
                                          <IconButton
                                            disabled={isItemSelected === true}
                                          >
                                            <Tooltip
                                              title={t(
                                                "administrative_console.user_page.delete_user"
                                              )}
                                            >
                                              <DeleteIcon
                                                onClick={() =>
                                                  handleSelectedUser(
                                                    user,
                                                    "DeleteUser"
                                                  )
                                                }
                                                fontSize="small"
                                                color={
                                                  isItemSelected === true
                                                    ? "disabled"
                                                    : "error"
                                                }
                                              />
                                            </Tooltip>
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell>--</TableCell>
                              <TableCell>--</TableCell>
                              <TableCell>--</TableCell>
                              <TableCell>--</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    <Table size="small">
                      <TableHead>
                        <TableRow key={"tableHead"}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              indeterminate={indeterminate}
                              checked={
                                users.length > 0 &&
                                selectedUserRows.length === users.length
                              }
                              onChange={(event) =>
                                handleSelectAllClick(
                                  event,
                                  userTablePage * rowsPerUserTablePage,
                                  userTablePage * rowsPerUserTablePage +
                                    rowsPerUserTablePage
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{t("profile.full_name")}</TableCell>
                          <TableCell>{t("profile.email")}</TableCell>
                          <TableCell>
                            {t("administrative_console.user_page.department")}
                          </TableCell>
                          <TableCell align="right">
                            {t("administrative_console.report_page.action")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {isLoadingUserList ? (
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rounded" />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {users
                            ? users
                                .slice(
                                  userTablePage * rowsPerUserTablePage,
                                  userTablePage * rowsPerUserTablePage +
                                    rowsPerUserTablePage
                                )
                                .map((user, index) => {
                                  const isItemSelected = isSelected(user.Id);
                                  return (
                                    <TableRow
                                      key={index}
                                      aria-checked={isItemSelected}
                                      selected={isItemSelected}
                                      id="userRecord"
                                    >
                                      <TableCell
                                        padding="checkbox"
                                        key={"checkbox" + index}
                                      >
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          onClick={(event) =>
                                            handleClick(event, user.Id)
                                          }
                                        />
                                      </TableCell>

                                      {["FULLNAME", "EMAIL", "DEPARTMENT"].map(
                                        (type) => (
                                          <TableCell
                                            key={type}
                                            onClick={(event) =>
                                              handleClick(event, user.Id)
                                            }
                                          >
                                            {userSearchBarRef.current?.value ? (
                                              <HightlightWords
                                                originalWord={
                                                  JSON.parse(user.Attr)?.[
                                                    type
                                                  ] ?? "--"
                                                }
                                                highlightWord={
                                                  userSearchBarRef.current.value
                                                }
                                              />
                                            ) : (
                                              <Typography variant="p">
                                                {JSON.parse(user.Attr)?.[
                                                  type
                                                ] ?? "--"}
                                              </Typography>
                                            )}
                                          </TableCell>
                                        )
                                      )}

                                      <TableCell
                                        align="right"
                                        key={"action" + index}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleSelectedUser(user, "EditUser")
                                          }
                                          disabled={isItemSelected === true}
                                        >
                                          <Tooltip
                                            title={t(
                                              "administrative_console.user_page.edit_user.edit"
                                            )}
                                          >
                                            <BorderColorIcon
                                              disabled={isItemSelected === true}
                                              fontSize="small"
                                              color={
                                                isItemSelected === true
                                                  ? "disabled"
                                                  : "primary"
                                              }
                                            />
                                          </Tooltip>
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleSelectedUser(
                                              user,
                                              "ChangePassword"
                                            )
                                          }
                                          disabled={isItemSelected === true}
                                        >
                                          <Tooltip
                                            title={t(
                                              "reset_password_dialog.change_password"
                                            )}
                                          >
                                            <SyncLock
                                              disabled={isItemSelected === true}
                                              fontSize="small"
                                              color={
                                                isItemSelected === true
                                                  ? "disabled"
                                                  : "primary"
                                              }
                                            ></SyncLock>
                                          </Tooltip>
                                        </IconButton>
                                        <IconButton
                                          disabled={isItemSelected === true}
                                        >
                                          {user.AccountDisabled ? (
                                            <Tooltip
                                              title={t(
                                                "administrative_console.user_page.enable_disable_user.enable_user_title"
                                              )}
                                            >
                                              <PersonOffIcon
                                                disabled={
                                                  isItemSelected === true
                                                }
                                                fontSize="small"
                                                color={
                                                  isItemSelected === true
                                                    ? "disabled"
                                                    : "secondary"
                                                }
                                                onClick={() =>
                                                  handleSelectedUser(
                                                    user,
                                                    "DisableUser"
                                                  )
                                                }
                                              ></PersonOffIcon>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title={t(
                                                "administrative_console.user_page.enable_disable_user.disable_user_title"
                                              )}
                                            >
                                              <PersonIcon
                                                disabled={
                                                  isItemSelected === true
                                                }
                                                fontSize="small"
                                                color={
                                                  isItemSelected === true
                                                    ? "disabled"
                                                    : "primary"
                                                }
                                                onClick={() =>
                                                  handleSelectedUser(
                                                    user,
                                                    "DisableUser"
                                                  )
                                                }
                                              ></PersonIcon>
                                            </Tooltip>
                                          )}
                                        </IconButton>
                                        <IconButton
                                          disabled={isItemSelected === true}
                                        >
                                          <Tooltip
                                            title={t(
                                              "administrative_console.user_page.delete_user"
                                            )}
                                          >
                                            <DeleteIcon
                                              onClick={() =>
                                                handleSelectedUser(
                                                  user,
                                                  "DeleteUser"
                                                )
                                              }
                                              fontSize="small"
                                              color={
                                                isItemSelected === true
                                                  ? "disabled"
                                                  : "error"
                                              }
                                            />
                                          </Tooltip>
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                            : null}
                        </TableBody>
                      )}
                    </Table>
                  )}
                </>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  labelRowsPerPage={t("inbox_outbox.display_row")}
                  component="div"
                  count={users.length}
                  rowsPerPage={rowsPerUserTablePage}
                  page={userTablePage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowPerPage}
                  ActionsComponent={TablePaginationActions}
                ></TablePagination>
              </TableContainer>
            </Grid>
          </Grid>

          {/* add new user Table */}
          <Grid container direction="row" sx={{ height: "30px" }}></Grid>
          {isAddUserPanelOpen && isSmallDevice === false ? (
            <Grid container>
              {/* add new user editor */}
              <UserAddEditor
                ref={userAddEditor}
                rules={rules}
                onPropertyChange={handleAddUserPropertyChange}
              />
              <Grid container direction="row" sx={{ height: "30px" }}></Grid>
              {/* submit button */}
              <Grid
                container
                direction="row"
                justifyContent="right"
                sx={{ pr: "20px" }}
              >
                <LoadingButton
                  loading={isLoadingNextPage}
                  disabled={disableGoNextPage}
                  onClick={handlePageChange}
                >
                  {t("administrative_console.user_page.next_page")}
                </LoadingButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" sx={{ height: "30px" }}></Grid>
          )}

          {/* add user dialog */}
          <Dialog
            open={isAddUserPanelOpen && isSmallDevice}
            fullScreen={isSmallDevice}
            maxWidth={"md"}
          >
            <DialogTitle>
              <Typography variant="p">
                {t("administrative_console.user_page.add_user")}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container sx={{ pt: 1 }}>
                <UserAddEditor
                  ref={userAddEditor}
                  rules={rules}
                  onPropertyChange={handleAddUserPropertyChange}
                />
                <Grid container direction="row" sx={{ height: "30px" }}></Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {/**Submit button */}
              <LoadingButton
                loading={isLoadingNextPage}
                disabled={disableGoNextPage}
                onClick={handlePageChange}
              >
                {t("administrative_console.user_page.next_page")}
              </LoadingButton>
              <Button
                onClick={() => {
                  setIsAddUserPanelOpen(false);
                }}
                color="error"
              >
                {t("administrative_console.report_page.cancel")}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
}
