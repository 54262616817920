import * as React from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import PinIcon from "@mui/icons-material/Pin";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import urlJoin from "url-join";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../../../Config";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import SharedDialog from "../../../../shared/SharedDialog";
import Skeleton from "@mui/material/Skeleton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TitleWithIcon from "../../../../shared/TitleWithIcon";

export default function PasswordPolicySetting() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [policies, setPolicies] = React.useState({});
  const [rules, setRules] = React.useState({});
  const authenticationContext = useAuthentication();
  const [disabled, setUseDefault] = React.useState();
  const [loading, setLoading] = React.useState({ reset: false, submit: false });
  const [reload, setReload] = React.useState(true);
  const [isAdd, setIsAdd] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });

  React.useEffect(() => {
    GetPasswordPolicy();
  }, [reload]);

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };

  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const handleResetDialogOpen = () => {
    showAlertMessage({
      title: t(
        "administrative_console.password_policy_page.password_policy_setting"
      ),
      content: t(
        "administrative_console.password_policy_page.reset_policy_setting_confirm"
      ),
      buttons: [
        {
          text: t("administrative_console.report_page.confirm"),
          action: () => {
            closeAlertMessage();
            ResetPasswordPolicy();
          },
        },
        {
          text: t("administrative_console.group_page.dialog.close"),
          action: () => {
            closeAlertMessage();
          },
        },
      ],
    });
  };

  const ResetPasswordPolicy = () => {
    setLoading({
      ...loading,
      reset: true,
    });

    const url = urlJoin(resServerBaseUrl, "/User/ResetPasswordPolicy");

    CallApiWithContext(url, authenticationContext)
      .then((response) => {
        //dialog
        showAlertMessage({
          title: t(
            "administrative_console.password_policy_page.password_policy_setting"
          ),
          content: t(
            "administrative_console.password_policy_page.reset_policy_setting_success"
          ),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
                setLoading({
                  ...loading,
                  reset: false,
                });
                setReload(true);
              },
            },
          ],
        });
      })
      .catch((ex) => {
        showAlertMessage({
          title: t(
            "administrative_console.password_policy_page.password_policy_setting"
          ),
          content: t(
            " administrative_console.password_policy_page.reset_policy_setting_failed"
          ),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
                setLoading({
                  ...loading,
                  reset: false,
                });
                setReload(true);
              },
            },
          ],
        });
        console.log(ex);
      });
  };

  function UpdatePasswordPolicy() {
    policies.passwordRules = JSON.stringify(rules);
    setLoading({
      loading,
      submit: true,
    });
    console.log(policies);
    const url = urlJoin(
      resServerBaseUrl,
      isAdd ? "/User/AddPasswordPolicy" : "/User/UpdatePasswordPolicy"
    );

    const data = {
      PasswordRule: JSON.stringify(rules),
      PasswordFailedAttempt: policies.passwordFailedAttempt,
      PasswordLockoutDuration: policies.passwordLockoutDuration,
      PasswordAttemptResetDuration: policies.passwordAttemptResetDuration,
      PasswordMaximumAge: policies.passwordMaximumAge,
      PasswordMinimumAge: policies.passwordMinimumAge,
      PasswordResetTokenAge: policies.passwordResetTokenAge,
    };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        //dialog
        showAlertMessage({
          title: t(
            "administrative_console.password_policy_page.password_policy_setting"
          ),
          content: t(
            `administrative_console.password_policy_page.${
              isAdd
                ? "add_policy_setting_success"
                : "update_policy_setting_success"
            }`
          ),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
                setLoading({
                  ...loading,
                  submit: false,
                });
                setReload(true);
              },
            },
          ],
        });
      })
      .catch((ex) => {
        showAlertMessage({
          title: t(
            "administrative_console.password_policy_page.password_policy_setting"
          ),
          content: t(
            `administrative_console.password_policy_page.${
              isAdd
                ? "add_policy_setting_failed"
                : "update_policy_setting_failed"
            }`
          ),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
                setLoading({
                  ...loading,
                  submit: false,
                });
                setReload(true);
              },
            },
          ],
        });
        console.log(ex);
      });
  }

  function GetPasswordPolicy() {
    const url = urlJoin(resServerBaseUrl, "/User/GetPasswordPolicy");
    CallApiWithContext(url, authenticationContext)
      .then((response) => {
        if (response) {
          setReload(false);
          if (response.message === "Get preset") {
            setIsAdd(true);
          } else {
            setIsAdd(false);
          }
          // console.log(response.policies.passwordRule.split(","));
          setRules(JSON.parse(response.policies.passwordRule));
          setPolicies(response.policies);
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  }

  //add 0 before a single digit num. => String(value).padStart(totalLength, '0')
  function handleDataChange(event, key, policyType) {
    switch (policyType) {
      case "policy":
        if (key.includes("_")) {
          const time = [];
          const unit = key.split("_")[1];
          const type = key.split("_")[0];
          var result = "";

          time[0] =
            unit === "day"
              ? event?.target?.value ?? 0
              : policies?.[type]?.split(/[.:]/)[0];

          time[1] =
            unit === "hour"
              ? event.target.value.split().length === 1
                ? String(event.target.value).padStart(2, "0")
                : event.target.value > 60
              : policies?.[type]?.split(/[.:]/)[1];

          time[2] =
            unit === "minute"
              ? event.target.value.split().length === 1
                ? String(event.target.value).padStart(2, "0")
                : event.target.value
              : policies?.[type]?.split(/[.:]/)[2];

          time[3] = String(0).padStart(2, "0");

          if (parseInt(time[1]) >= 24) {
            time[0] = parseInt(time[0]) + Math.floor(parseInt(time[1]) / 24);
            time[1] =
              parseInt(time[1]) - 24 * time[0] < 0 ? 0 : time[1] - 24 * time[0];
          }

          if (parseInt(time[2]) >= 60) {
            time[1] = parseInt(time[1]) + Math.floor(parseInt(time[2]) / 60);
            time[2] =
              parseInt(time[2]) - 60 * time[1] < 0 ? 0 : time[2] - 60 * time[1];
          }

          result = time[0] + "." + time[1] + ":" + time[2] + ":" + time[3];
          console.log(result);
          setPolicies({
            ...policies,
            [type]: result,
          });
        } else {
          setPolicies({
            ...policies,
            [key]: event.target.value === "" ? 0 : event.target.value,
          });
        }

        break;
      case "rule":
        if (key !== "passwordMinLength" && key !== "passwordHistoryRule") {
          setRules({
            ...rules,
            [key]: event.target.checked,
          });
        } else {
          setRules({
            ...rules,
            [key]: event.target.value === "" ? 0 : event.target.value,
          });
        }

        break;
      default:
        break;
    }
  }

  function RuleRearrange(oriPosition, newPosition) {
    let objectArr = [];
    let newObj = {};
    Object.keys(rules).forEach((rule) => {
      if (rules[rule] === true || rules[rule] > 0) {
        objectArr.push(rule);
      }
    });
    objectArr.splice(newPosition, 0, objectArr.splice(oriPosition, 1)[0]);

    for (let x = 0; x < objectArr.length; x++) {
      newObj[objectArr[x]] = rules[objectArr[x]];
    }

    Object.keys(rules).forEach((rule) => {
      if (!newObj.hasOwnProperty(rule)) {
        newObj[rule] = rules[rule];
      }
    });

    if (
      !(
        (oriPosition === 0 && newPosition < 0) ||
        newPosition > objectArr.length
      )
    ) {
      setRules(newObj);
    }

    console.log(JSON.stringify(rules));
  }

  return (
    <Grid container padding={2} direction={"column"}>
      <Grid
        item
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        xs={12}
      >
        <Grid item direction={"row"} display={"flex"} alignItems={"center"}>
          <TitleWithIcon
            icon={<PinIcon />}
            title={{
              content: t(
                "administrative_console.password_policy_page.password_policy_setting"
              ),
              variant: "h6",
              alignItems: "center",
              fontWeight: "bold",
            }}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={loading.reset}
            variant={"outlined"}
            disabled={isAdd}
            onClick={() => handleResetDialogOpen()}
          >
            {t("inbox_outbox.reset")}
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        pt={2}
        spacing={2}
        display={"flex"}
      >
        {[
          { key: "password_min_length", value: "passwordMinLength" },
          { key: "password_history_rule", value: "passwordHistoryRule" },
          { key: "password_failed_attempt", value: "passwordFailedAttempt" },
        ].map((k) => {
          return (
            <Grid item xs={12} sm={4} key={k.key}>
              {reload ? (
                <Skeleton>
                  <TextField fullWidth />
                </Skeleton>
              ) : (
                <TextField
                  value={
                    k.value === "passwordFailedAttempt"
                      ? policies?.[k.value] ?? ""
                      : rules?.[k.value] ?? ""
                  }
                  type="number"
                  inputProps={{
                    min: 0,
                    max: k.key === "password_min_length" ? 14 : 999,
                    step: "1",
                  }}
                  label={t(
                    `administrative_console.password_policy_page.${k.key}`
                  )}
                  onChange={(event) => {
                    handleDataChange(
                      event,
                      k.value,
                      k.value === "passwordFailedAttempt" ? "policy" : "rule"
                    );
                  }}
                  fullWidth
                />
              )}
            </Grid>
          );
        })}
      </Grid>

      <Grid item container xs={12} alignItems={"space-between"}>
        {[
          { key: "account_lockout_duration", value: "passwordLockoutDuration" },
          {
            key: "password_failed_attempt_reset_duration",
            value: "passwordAttemptResetDuration",
          },
          { key: "password_maximum_age", value: "passwordMaximumAge" },
          { key: "password_minimum_age", value: "passwordMinimumAge" },
          { key: "password_reset_token_age", value: "passwordResetTokenAge" },
        ].map((k) => (
          <Grid item container direction={"column"} key={k.key} sm={6}>
            <Grid item pt={2} textAlign={"start"}>
              {t(`administrative_console.password_policy_page.${k.key}`)}
            </Grid>
            <Grid item container textAlign={"center"} spacing={2} pt={2}>
              {[
                { parent: k.value, dataType: "day" },
                { parent: k.value, dataType: "hour" },
                { parent: k.value, dataType: "minute" },
              ].map((unit, index) => (
                <Grid item xs={3} key={unit.parent + "_" + unit.dataType}>
                  {reload ? (
                    <Skeleton xs={6}>
                      <TextField fullWidth />
                    </Skeleton>
                  ) : (
                    <TextField
                      value={Number(
                        policies?.[unit.parent]?.split(/[.:]/)[index]
                      )}
                      type="number"
                      fullWidth
                      label={t(
                        `administrative_console.password_policy_page.${unit.dataType}`
                      )}
                      inputProps={{
                        min: 0,
                        max: 999,
                        step: "1",
                      }}
                      onChange={(event) => {
                        handleDataChange(
                          event,
                          unit.parent + "_" + unit.dataType,
                          "policy"
                        );
                      }}
                    ></TextField>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item container direction={"row"} pt={2}>
        {reload ? (
          <Grid
            item
            container
            direction={"row"}
            alignItems={"center"}
            spacing={1}
          >
            <Grid item>
              <Skeleton>
                <Checkbox />
              </Skeleton>
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={100}></Skeleton>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <FormGroup>
              {[
                {
                  key: "password_alphabetic_rule",
                  value: "passwordAlphabeticRule",
                },
                {
                  key: "password_upper_lower_case_rule",
                  value: "passwordUpperLowerCaseRule",
                },
                { key: "password_numeric_rule", value: "passwordNumericRule" },
                { key: "password_login_rule", value: "passwordLoginRule" },
                { key: "password_symbols_rule", value: "passwordSymbolsRule" },
              ].map((k, index) => {
                return (
                  <FormControlLabel
                    key={k.key}
                    checked={rules?.[k.value] ?? false}
                    control={<Checkbox />}
                    label={
                      reload
                        ? ""
                        : t(
                            `administrative_console.password_policy_page.${k.key}`
                          )
                    }
                    onChange={(event) => {
                      handleDataChange(event, k.value, "rule");
                    }}
                  />
                );
              })}
            </FormGroup>
          </Grid>
        )}
      </Grid>

      <Grid item container pt={2} xs={6}>
        {reload ? (
          <Grid item container direction={"column"}>
            <Grid item>
              <Skeleton>
                <Typography height={50}>Rule display arrangement</Typography>
              </Skeleton>
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={250}></Skeleton>
            </Grid>
          </Grid>
        ) : (
          <Grid item container>
            <Grid item xs={12} textAlign={"start"}>
              <h3>
                {t(
                  "administrative_console.password_policy_page.rule_display_arrangement"
                )}
              </h3>
            </Grid>
            <Grid item sm={4} xs={12}>
              <List>
                {Object.keys(rules).map((r, index) => {
                  if (rules[r] === true || rules[r] > 0) {
                    return (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                RuleRearrange(index, index - 1);
                              }}
                            >
                              <ExpandLessIcon />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                RuleRearrange(index, index + 1);
                              }}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </>
                        }
                      >
                        <ListItemText
                          id={index}
                          primary={t(
                            `administrative_console.password_policy_page.${r
                              .split(/(?=[A-Z])/)
                              .join("_")
                              .toLocaleLowerCase()}`
                          )}
                        />
                      </ListItem>
                    );
                  }

                  return null;
                })}
              </List>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item pt={2} textAlign={"end"}>
        <LoadingButton
          loading={loading.submit}
          variant={"contained"}
          disabled={disabled}
          onClick={() => UpdatePasswordPolicy()}
        >
          {t("formruntime.submit")}
        </LoadingButton>
      </Grid>

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
}
