import { Button, useTemplateEvaluation } from "@bpmn-io/form-js";

import { html, useCallback } from "diagram-js/lib/ui";

import FeedbackIcon from "./feedback.svg";

export const type = "feedbackButton";

export function FeedbackButtonRenderer(props) {
  const { disabled, readonly, field } = props;

  const { endpoint, message } = field;

  const evaluatedMessage = useTemplateEvaluation(message, {
    debug: true,
    strict: true,
  });

  const onClick = useCallback(() => {
    if (disabled || readonly) {
      return;
    }

    // send the message to the configured endpoint
    alert(`Send message to ${endpoint}:\n\n${evaluatedMessage}`);
  }, [disabled, readonly, endpoint, evaluatedMessage]);

  return html`<div class="feedback-button-container" onClick=${onClick}>
    <${Button}
      {...props}
      field=${{
        ...field,
        acton: "feedback",
      }}></${Button}>
    </div>`;
}

FeedbackButtonRenderer.config = {
  ...Button.config,
  type: type,
  label: "Feedback",
  iconUrl: `data:image/svg+xml,${encodeURIComponent(
    "<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='800' height='800' baseProfile='tiny' viewBox='0 0 42 42'><path d='M6.5 25.5v4c.016 2.812 1.344 2.375 2.328 1.531L14.5 25.91v2.59c0 2.43.56 3 3 3h9s5.209 6.125 5.25 6.084c.75.916 2.781.604 2.75-1.084v-5h3c2.45 0 3-.609 3-3v-15c0-2.4-.59-3-3-3h-10v-2c0-2.47-.46-3-3-3h-21c-2.36 0-3 .51-3 3v13c0 2.439.55 4 3 4h3zm25 3v4.721l-4-4.721h-9c-.75 0-1-.27-1-1v-13c0-.67.31-1 1-1h18c.689 0 1 .37 1 .94V27.5c0 .721-.359 1-1 1h-5z'/></svg>"
  )}`,
  propertiesPanelEntries: ["label"],
};
