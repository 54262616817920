import * as React from "react";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TextField from "@mui/material/TextField";
import { Autocomplete, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import ProxyDelegationEditor, {
  ProxyDelegateType,
} from "../../ProxyDelegationEditor";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../../../Config";
import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../../../helpers/ApiHelper";
import {
  ConvertUTCDateToLocal,
  ConvertLocalDateToUTC,
} from "../../../../shared/Utils";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import SharedDialog from "../../../../shared/SharedDialog";
import TitleWithIcon from "../../../../shared/TitleWithIcon";

export default function ProxyDelegateSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const GUIDpattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const [proxyTableRows, setProxyTableRows] = React.useState([]);
  const [delegationTableRows, setDelegationTableRows] = React.useState([]);
  const [isLoadingProxyTable, setIsLoadingProxyTable] = React.useState(false);
  const [isLoadingDelegationTable, setIsLoadingDelegationTable] =
    React.useState(false);
  const [userOption, setUserOption] = React.useState([]);
  const [isLoadingAutocom, setIsLoadingAutocom] = React.useState(false);
  const [originalUserId, setOriginalUserId] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState("");
  const [inputedUser, setInputedUser] = React.useState("");
  const [isAdministrator, setIsAdministrator] = React.useState(true);
  const [caseTplRecord, setCaseTplRecord] = React.useState([]);
  const [isUseLoadingSpinner, setIsUseLoadingSpinner] = React.useState(false);
  var caseTplAuthen = JSON.stringify({ IsAdministrator: isAdministrator });
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  const createDataByElement = (element) => {
    return {
      proxyUser: element.userName,
      effectiveFrom: element.vaildDateFrom,
      effectiveTo: element.vaildDateTo,
      specifiedWorkflow: element.tplName,
      type: element.type,
      rowId: element.id,
      userId: element.userID,
    };
  };
  const callApi = (name) => {
    setIsLoadingProxyTable(true);
    setIsLoadingDelegationTable(true);
    if (name == null || name == "") {
      setIsLoadingProxyTable(false);
      setIsLoadingDelegationTable(false);
    } else {
      console.log(userOption);
      let getUserNameArr = new Array();
      let originalUserID = "";

      userOption.map((element) => {
        if (element.label == name) {
          originalUserID = element.ID;
          setOriginalUserId(originalUserID);
        }
      });
      if (originalUserID != "" && originalUserID != "undefined") {
        /*    const getAllProxyDelegationListURL = urlJoin(
              resServerBaseUrl,
              "/Case/GetAllProxyDelegetionList"
            );
            CallApiWithContext(
              getAllProxyDelegationListURL,
              authenticationContext,
              null
            )*/ /* original get all proxyDelegetionList */

        let userID = { UserID: parseInt(originalUserID) };

        const getUserProxyDelegationListURL = urlJoin(
          resServerBaseUrl,
          "/Case/GetUserProxyDelegationList"
        );
        CallApiWithContext(
          getUserProxyDelegationListURL,
          authenticationContext,
          JSON.stringify(userID)
        )
          .then((response) => {
            if (response) {
              const proxyList = new Array();
              const delegationList = new Array();
              response.forEach((element) => {
                element = {
                  ...element,
                  tplName: caseTplRecord.find(
                    (x) => x.ID == element.caseTplUUID
                  ).label,
                };
                if (typeof element.tplName == "undefined") {
                  element.tplName = "Case Template not found!";
                }
                element.vaildDateFrom = ConvertUTCDateToLocal(
                  new Date(element.vaildDateFrom)
                );
                element.vaildDateTo = ConvertUTCDateToLocal(
                  new Date(element.vaildDateTo)
                );
                let dateTemp = element.vaildDateFrom.split(" ")[0];
                dateTemp =
                  dateTemp.split("/")[2] +
                  "-" +
                  dateTemp.split("/")[1] +
                  "-" +
                  dateTemp.split("/")[0];
                element.vaildDateFrom =
                  dateTemp + "T" + element.vaildDateFrom.split(" ")[1];
                dateTemp = element.vaildDateTo.split(" ")[0];
                dateTemp =
                  dateTemp.split("/")[2] +
                  "-" +
                  dateTemp.split("/")[1] +
                  "-" +
                  dateTemp.split("/")[0];
                element.vaildDateTo =
                  dateTemp + "T" + element.vaildDateTo.split(" ")[1];
                if (element.originalUserID == parseInt(originalUserID)) {
                  if (element.type == 0)
                    proxyList.push(createDataByElement(element));
                  else delegationList.push(createDataByElement(element));
                }
              });
              setProxyTableRows(proxyList);
              setDelegationTableRows(delegationList);
            } else {
              //alert("Cannot get response from server, please check");
              setAlertDialog(true);
              setAlertContent(
                t("profile.proxy_and_delegation.server_no_response")
              );
              setAlertTitle(t("profile.proxy_and_delegation.error"));
            }
            setIsLoadingProxyTable(false);
            setIsLoadingDelegationTable(false);
          })
          .catch((error) => {
            //alert(error);
            setAlertDialog(true);
            setAlertContent(error);
            setAlertTitle(t("profile.proxy_and_delegation.error"));
            console.log(error);
            setIsLoadingProxyTable(false);
            setIsLoadingDelegationTable(false);
          });
      } else {
        setIsLoadingProxyTable(false);
        setIsLoadingDelegationTable(false);
      }
    }
  };

  React.useEffect(() => {
    if (inputedUser != null) {
      callApi(inputedUser);
    } else if (selectedUser != null) {
      callApi(selectedUser);
    }
  }, [inputedUser, selectedUser]);

  const handleUserInput = (value) => {
    //handleUserOptionSelect(value);
    setSelectedUser(value);
    callApi(value);
  };

  const handleUserOptionSelect = (event) => {
    if (event != null) {
      callApi(event.target.textContent);
    }
  };
  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };
  React.useEffect(() => {
    setIsLoadingAutocom(true);
    setIsLoadingProxyTable(true);
    setIsLoadingDelegationTable(true);
    setIsUseLoadingSpinner(true);

    const url_username = urlJoin(
      resServerBaseUrl,
      "/User/GetUsersByPermissions"
    );
    CallApiWithContext(url_username, authenticationContext, null)
      .then((response) => {
        if (response) {
          var getUserNameArr = new Array();
          var userNameArr = new Array();
          /*return {
            id: key,
            name: JSON.parse(response[key].attr)?.FULLNAME ?? "null",
          };*/
          getUserNameArr = Object.keys(response).map((key) => {
            return { id: response[key].id, name: response[key].login };
          });
          getUserNameArr.forEach((element) => {
            var forPushUserIDName = { ID: element.id, label: element.name };
            if (GUIDpattern.test(forPushUserIDName.label) === true) {
            } else {
              userNameArr.push(forPushUserIDName);
            }
          });
          userNameArr = userNameArr.filter(
            (L) =>
              L.label != "Robot" &&
              L.label != "00000000-0000-0000-0000-000000000000"
          );
          userNameArr.sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          });

          setUserOption(userNameArr);
          setIsLoadingAutocom(false);

          const url_specwf = urlJoin(
            resServerBaseUrl,
            "/Case/GetCaseTemplates"
          );
          CallApiWithContext(url_specwf, authenticationContext, caseTplAuthen)
            .then((getCaseTemplatesResponse) => {
              if (getCaseTemplatesResponse) {
                const FindWFNameByID = new Array();

                for (let i = 0; i < getCaseTemplatesResponse.length; i++) {
                  FindWFNameByID.push(
                    ...getCaseTemplatesResponse[i].templateInfos.map(
                      (info) => ({ ID: info.uuid, label: info.name })
                    )
                  );
                }
                FindWFNameByID.push({
                  ID: "00000000-0000-0000-0000-000000000000",
                  label: "All Workflow Templates",
                });
                FindWFNameByID.sort((a, b) => {
                  const labelA = a.label.toUpperCase();
                  const labelB = b.label.toUpperCase();
                  if (labelA < labelB) {
                    return -1;
                  }
                  if (labelA > labelB) {
                    return 1;
                  }
                  return 0;
                });
                setCaseTplRecord(FindWFNameByID);
                setIsLoadingProxyTable(false);
                setIsLoadingDelegationTable(false);
                //    }
                //);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoadingProxyTable(false);
              setIsLoadingDelegationTable(false);
            });
        }
        setIsUseLoadingSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingAutocom(false);
        setIsLoadingProxyTable(false);
        setIsLoadingDelegationTable(false);
        setIsUseLoadingSpinner(false);
      });
  }, []);

  const cleanTable = () => {
    setSelectedUser("");
    setInputedUser("");
    setOriginalUserId(null);
    setProxyTableRows(null);
    setDelegationTableRows(null);
  };

  return (
    <Box>
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
      {!isUseLoadingSpinner ? (
        <Grid xs={12} md={12}>
          <Grid
            xs={12}
            md={12}
            container
            direction="row"
            justifyContent={{ xs: "center", sm: "start" }}
            sx={{ p: { xs: "5px", sm: "20px" } }}
          >
            <Grid container direction="row">
              <TitleWithIcon
                icon={<GroupIcon />}
                title={{
                  content: t("profile.proxy_and_delegation.select_user"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container>
              <Typography
                variant="p"
                style={{ color: "gray", fontSize: "14px" }}
              >
                {t("profile.proxy_and_delegation.autocomplete_description")}
              </Typography>
            </Grid>
            <Grid item>
              {isLoadingProxyTable ? (
                <Grid sx={{ pt: "5px" }}>
                  <Skeleton sx={{ width: "280px", height: "60px" }}></Skeleton>
                </Grid>
              ) : (
                <Autocomplete
                  size="small"
                  loading={isLoadingAutocom}
                  loadingText={t("profile.proxy_and_delegation.loading_text")}
                  defaultValue={selectedUser}
                  value={selectedUser}
                  onChange={(event, value) => {
                    if (value != null && value != "undefined" && value != "") {
                      setSelectedUser(value.label);
                    } else {
                      cleanTable();
                    }
                  }}
                  onInputChange={(event, value) => {
                    setInputedUser(value);
                  }}
                  inputValue={inputedUser}
                  options={userOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ pt: "20px", width: "280px" }}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            xs={12}
            md={12}
            container
            direction="column"
            sx={{
              height: "20%",
              width: "100%",
              px: { xs: "5px", sm: "20px" },
              pt: "20px",
            }}
          >
            <Grid container direction="row" style={{ textAlign: "left" }}>
              <TitleWithIcon
                icon={<PersonIcon />}
                title={{
                  content: t("profile.proxy_and_delegation.proxy_user"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container>
              <Typography
                variant="p"
                style={{ color: "gray", fontSize: "14px" }}
              >
                {t("profile.proxy_and_delegation.proxy_description")}
              </Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <ProxyDelegationEditor
                type={ProxyDelegateType.Proxy}
                data={proxyTableRows}
                isLoading={isLoadingProxyTable}
                originalUserID={originalUserId}
                isAdmin={caseTplAuthen}
                caseTplRecord={caseTplRecord}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ height: "0px" }}></Grid>

          <Grid
            xs={12}
            md={12}
            container
            direction="column"
            sx={{
              height: "20%",
              width: "100%",
              px: { xs: "5px", sm: "20px" },
              py: "20px",
            }}
          >
            <Grid container direction="row" style={{ textAlign: "left" }}>
              <TitleWithIcon
                icon={<AssignmentIndIcon />}
                title={{
                  content: t("profile.proxy_and_delegation.delegation"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container>
              <Typography
                variant="p"
                style={{ color: "gray", fontSize: "14px" }}
              >
                {t("profile.proxy_and_delegation.delegation_description")}
              </Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <ProxyDelegationEditor
                type={ProxyDelegateType.Delegation}
                data={delegationTableRows}
                isLoading={isLoadingDelegationTable}
                originalUserID={originalUserId}
                isAdmin={caseTplAuthen}
                caseTplRecord={caseTplRecord}
              />
            </Grid>
          </Grid>
          <SharedDialog
            isOpen={alertDialog}
            onClose={handleAlertDialogClose}
            title={alertTitle}
            content={alertContent}
            buttons={[
              {
                text: t("administrative_console.report_page.confirm"),
                action: handleAlertDialogClose,
              },
            ]}
          ></SharedDialog>
        </Grid>
      ) : (
        <LoadingSpinner isOpen={true}></LoadingSpinner>
      )}
    </Box>
  );
}
