import * as React from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import SharedDialog from "../../shared/SharedDialog";
import { use } from "i18next";
import { size } from "min-dash";

const SearchBar = React.forwardRef((props, ref) => {
  const [searchByFromDate, setSearchByFromDate] = React.useState(null);
  const [searchByEndDate, setSearchByEndDate] = React.useState(
    dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      )
    )
  );
  const [searchInfo, setSearchInfo] = React.useState("");
  const [isShowAdvanceSearch, setIsShowAdvanceSearch] = React.useState(false);
  const [delay, setDelay] = React.useState(3000);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchor = React.useRef();
  const { t } = useTranslation();
  const { loading: isLoading, disabled = false } = props;
  const [loadingBtn, setLoadingBtn] = React.useState("");
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [error, setError] = React.useState(null);
  const [endDayError, setEndDayError] = React.useState(null);
  const [resetBtn, setResetBtn] = React.useState(false);
  const [isDeleteSearchCriteria, setIsDeleteSearchCriteria] =
    React.useState(false);
  const nav = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const location = useLocation();
  const { pages } = useParams();
  const [searchCriteria, setSearchCriteria] = React.useState([]);
  const [searchCriteriaKey, setSearchCriteriaKey] = React.useState([]);

  const useDebounce = (callback, delay) => {
    const latestCallback = React.useRef();
    const [lastCalledAt, setLastCalledAt] = React.useState(null);

    React.useEffect(() => {
      latestCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
      if (lastCalledAt) {
        const fire = () => {
          setLastCalledAt(null);
          latestCallback.current();
        };

        const fireAt = lastCalledAt + delay;
        const id = setTimeout(fire, fireAt - Date.now());
        return () => clearTimeout(id);
      }
    }, [lastCalledAt, delay]);

    return () => setLastCalledAt(Date.now());
  };

  const handler = (btn) => {
    if (isLoading && loadingBtn === btn) {
      return true;
    } else {
      return false;
    }
  };

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const errorHandle = (error) => {
    var message;
    switch (error) {
      case "invalidDate":
        message = "Your date is not valid";
        break;
      case "minDate":
        message = "Please select the correct date";
        break;
      case "maxDate":
        message = "Please select the correct date";
        break;
      default:
        break;
    }
    setAlertDialog(true);
    setAlertTitle("Warming");
    setAlertContent(message);
  };

  const handleSearchingBtnClick = () => {
    //alert('searchbtnclick');
    //var searchItem = { SearchBoxInfo: searchInfo, SearchByFromDate: searchByFromDate, SearchByEndDate: searchByEndDate };
    //ref(searchItem);
    //setKeyTime("fast");
    setLoadingBtn("save");
    setDelay(500);
    if (!searchByFromDate) {
      setAlertDialog(true);
      setAlertTitle("Warning");
      setAlertContent("Please choose start day");
      return;
    }
    if (!searchByEndDate) {
      setAlertDialog(true);
      setAlertTitle("Warning");
      setAlertContent("Please choose End day");
      return;
    }
    if (error) {
      errorHandle(error);
      return;
    }
    if (endDayError) {
      errorHandle(endDayError);
      return;
    }
    if (searchByFromDate <= searchByEndDate) {
      handleClick();
      setIsShowAdvanceSearch(false);
    } else {
      setAlertDialog(true);
      setAlertContent("Start Date should be early then End Date");
    }
  };

  const handleAdvancedSearchArrowOnClick = (event) => {
    setAnchorEl(anchor.current);
    setSearchByFromDate(null);
    if (isShowAdvanceSearch) {
      setIsShowAdvanceSearch(false);
    } else {
      setIsShowAdvanceSearch(true);
    }
  };

  const handleResetBtnClick = () => {
    setLoadingBtn("reset");
    setSearchInfo("");
    setSearchByFromDate(null);
    setSearchByEndDate(dayjs(new Date()));
    //var searchItem = { SearchBoxInfo: searchInfo, SearchByFromDate: searchByFromDate, SearchByEndDate: searchByEndDate };
    //ref(searchItem);
    setDelay(500);
    setResetBtn(true);
    handleClick();
    setIsShowAdvanceSearch(false);
  };

  const handleTextBoxInput = (event) => {
    setSearchInfo(event.target.value);
    setDelay(2000);
    handleClick();
  };

  const refreshSearch = () => {
    var searchItem = {
      SearchBoxInfo: searchInfo,
      SearchByFromDate: searchByFromDate,
      SearchByEndDate: searchByEndDate,
      SearchError: error,
      SearchEndError: endDayError,
      SearchReset: resetBtn,
    };
    handleSearchCriteria(searchItem);
    ref(searchItem);
    if (resetBtn) {
      const keys = [...searchParams.keys()];
      for (const key of keys) {
        searchParams.delete(key);
      }
    }
    if (!isDeleteSearchCriteria) {
      pages
        ? nav({
            pathname: location.pathname.slice(
              0,
              location.pathname.lastIndexOf("/")
            ),
            search: searchParams.toString(),
          })
        : nav({ search: searchParams.toString() });
    }
    setIsDeleteSearchCriteria(false);
    setResetBtn(false);
  };

  const handleClick = useDebounce(refreshSearch, delay);

  /*
    React.useEffect(() => {
      var time=2000;
      if(keyTime=="slow"){
        time=2000;
      }
      else{
        time=500;
      }

      
  
      const delayDebonceFn = setTimeout(() => {
        if (!isFirstInitial) {
          var searchItem = { SearchBoxInfo: searchInfo, SearchByFromDate: searchByFromDate, SearchByEndDate: searchByEndDate };
          ref(searchItem);
        }
        else {
          setIsFirstInitial(false);
        }
      }, 2000);
      return () => clearTimeout(delayDebonceFn);
    }, [searchInfo,  searchByFromDate, searchByEndDate,rerender]);
    */
  //}, [searchInfo, isFirstInitial, ref, searchByFromDate, searchByEndDate]);
  const open = Boolean(isShowAdvanceSearch);
  const id = open ? "simple-popover" : undefined;

  const deleteSearchCriteria = (key) => {
    searchParams.delete(searchCriteriaKey[key]);
    switch (searchCriteriaKey[key]) {
      case "SearchBoxInfo":
        setSearchInfo("");
        break;
      case "SearchByFromDate":
        setSearchByFromDate(null);
        break;
      case "SearchByEndDate":
        setSearchByEndDate(dayjs(new Date()));
        break;
    }
    nav({
      pathname:
        location.pathname.lastIndexOf("/") == pages
          ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
          : location.pathname,
      search: searchParams.toString(),
    });
    setIsDeleteSearchCriteria(true);
    setDelay(0);
    handleClick();
  };

  const handleSearchCriteria = (searchItem) => {
    if (searchInfo) {
      searchParams.set("SearchBoxInfo", searchInfo);
    }
    if (searchByFromDate != null) {
      searchParams.set("SearchByFromDate", searchByFromDate.toJSON());
    }
    if (
      searchByEndDate?.format("DD/MM/YYYY") !=
      dayjs(new Date()).format("DD/MM/YYYY")
    ) {
      searchParams.set("SearchByEndDate", searchByEndDate.toJSON());
    }
    var searchItemArray = Object.values({
      SearchBoxInfo: searchInfo,
      SearchByFromDate: searchByFromDate?.format("DD/MM/YYYY"),
      SearchByEndDate:
        searchByEndDate?.format("DD/MM/YYYY") !=
        dayjs(new Date()).format("DD/MM/YYYY")
          ? searchByEndDate?.format("DD/MM/YYYY")
          : null,
    });
    setSearchCriteria(searchItemArray);
    setSearchCriteriaKey(Object.keys(searchItem));
  };

  //Apply search tag after reload the page and to the previous
  React.useEffect(() => {
    const searchByEndDate = searchParams.get("SearchByEndDate")
      ? dayjs(searchParams.get("SearchByEndDate"))
      : dayjs(new Date());
    const searchByFromDate = searchParams.get("SearchByFromDate")
      ? dayjs(searchParams.get("SearchByFromDate"))
      : null;
    const searchBoxInfo = searchParams.get("SearchBoxInfo");
    var searchItem = {
      SearchBoxInfo: searchBoxInfo ? searchBoxInfo : null,
      SearchByFromDate: searchByFromDate
        ? searchByFromDate.format("DD/MM/YYYY")
        : null,
      SearchByEndDate:
        searchByEndDate?.format("DD/MM/YYYY") !=
        dayjs(new Date()).format("DD/MM/YYYY")
          ? searchByEndDate.format("DD/MM/YYYY")
          : null,
    };
    setSearchCriteria(Object.values(searchItem));
    setSearchCriteriaKey(Object.keys(searchItem));
    searchTag(searchCriteria);

    // searchTag(searchCriteria);
  }, [searchParams]);

  const searchTag = (searchCriteria) => {
    return searchCriteria
      ? searchCriteria.map((value, key) => {
          if (value) {
            return (
              <Grid backgroundColor={"grey"} display={"flex"} borderRadius={50}>
                <IconButton>
                  <HighlightOffIcon
                    color="inherit"
                    sx={{ display: "block" }}
                    onClick={() => {
                      deleteSearchCriteria(key);
                    }}
                  />
                </IconButton>
                <Grid
                  sx={{
                    alignContent: "center",
                    paddingRight: "10px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      maxWidth: 200,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value}
                  </span>
                </Grid>
              </Grid>
            );
          }

          return null;
        })
      : null;
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      <Toolbar
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <TextField
          fullWidth
          id="searchBar"
          disabled={disabled}
          placeholder={
            searchParams.get("SearchBoxInfo")
              ? searchParams.get("SearchBoxInfo")
              : t("inbox_outbox.search")
          }
          InputProps={{
            disableUnderline: true,
            sx: { fontSize: "default" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-describedby={id}
                disabled={disabled}
                onClick={() => {
                  handleAdvancedSearchArrowOnClick();
                }}
              >
                {isShowAdvanceSearch ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </IconButton>
            ),
          }}
          onChange={handleTextBoxInput}
          sm={3}
          xs={3}
          variant="standard"
          value={searchInfo}
          ref={anchor}
        />
        <Grid item>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setIsShowAdvanceSearch(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              width: { xs: "75%", md: "100%", sm: "80%", lg: "100%" },
            }}
          >
            <Grid
              container
              direction="column"
              style={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                container
                // xs={7}
                md={12}
                lg={12}
                alignItems={"center"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item md={2} xs={12} sm={2}>
                    <Box>
                      <Typography
                        variant="p"
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "5px",
                          color: "gray",
                        }}
                      >
                        {t("search_bar.searchby_date")}:
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4.7} md={4.7} sm={5}>
                    <DesktopDatePicker
                      label={t("search_bar.from")}
                      value={searchByFromDate}
                      onChange={(newValue) => setSearchByFromDate(newValue)}
                      inputFormat="yyyy/MM/dd"
                      renderInput={(params) => <TextField {...params} />}
                      sx={{ width: { xs: "100%" } }}
                      onError={(newError) => setError(newError)}
                    />
                  </Grid>
                  <Grid item display={{ xs: "none", sm: "none", md: "block" }}>
                    <HorizontalRuleIcon
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg="auto"
                    md={4.7}
                    sm={5}
                    sx={{
                      marginTop: { xs: "10px", sm: "0px", md: "0px" },
                    }}
                  >
                    <DesktopDatePicker
                      label={t("search_bar.to")}
                      value={searchByEndDate}
                      onChange={(newValue) => setSearchByEndDate(newValue)}
                      inputFormat="yyyy/MM/dd"
                      renderInput={(params) => <TextField {...params} />}
                      sx={{ width: { xs: "100%" } }}
                      onError={(newError) => setEndDayError(newError)}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={12} lg={3} textAlign={"center"}>
                <Tooltip title={t("inbox_outbox.search")}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    loading={handler("save")}
                    className="ActionButton"
                    onClick={() => handleSearchingBtnClick()}
                    sx={{ width: "64px", height: "36px" }}
                  >
                    {isLoading && loadingBtn === "save"
                      ? ""
                      : t("inbox_outbox.search")}
                  </LoadingButton>
                </Tooltip>

                <Tooltip title={t("inbox_outbox.reset")}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    loading={handler("reset")}
                    className="ActionButton"
                    onClick={() => handleResetBtnClick()}
                    sx={{ width: "64px", height: "36px" }}
                  >
                    {isLoading && loadingBtn === "reset"
                      ? ""
                      : t("inbox_outbox.reset")}
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Popover>
          <SharedDialog
            isOpen={alertDialog}
            onClose={handleAlertDialogClose}
            title={alertTitle}
            content={alertContent}
            buttons={[
              {
                // text: t("administrative_console.report_page.confirm"),
                action: handleAlertDialogClose,
              },
            ]}
          ></SharedDialog>
        </Grid>
        {searchCriteria.length > 0 ? (
          <Grid sx={{ display: "flex", flexWrap: "wrap" }}>
            {searchTag(searchCriteria)}
          </Grid>
        ) : null}
      </Toolbar>
    </AppBar>
  );
});

export default SearchBar;
