import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";

import "./localization/i18n";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationProvider } from "./providers/AuthenticationProvider";
import { ProfileProvider } from "./providers/ProfileProvider";
import { ThemeColorProvider } from "./providers/ThemeColorProvider";
import { CountryProvider } from "./providers/CountryProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CountryProvider>
    <AuthenticationProvider>
      <ProfileProvider>
        <ThemeColorProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeColorProvider>
      </ProfileProvider>
    </AuthenticationProvider>
  </CountryProvider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
