import * as React from "react";
import { Button, ButtonGroup, debounce, Tooltip } from "@mui/material";

import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import ImageIcon from "@mui/icons-material/Image";
import FileSaver from "file-saver";

import CmmnModeler from "cmmn-js/lib/Modeler";

import PropertiesPanelModule from "cmmn-js-properties-panel";
import PropertiesProviderModule from "cmmn-js-properties-panel/lib/provider/cmmn";
import camundaModdleDescriptor from "camunda-cmmn-moddle/resources/camunda";
import cmmnSample from "./cmmnSample";
import newDiagramXML from "./cmmnNewDiagram";

import "cmmn-js/dist/assets/cmmn-font/css/cmmn.css";
import "cmmn-js/dist/assets/cmmn-font/css/cmmn-embedded.css";
import "cmmn-js/dist/assets/diagram-js.css";
import "cmmn-js-properties-panel/dist/assets/cmmn-js-properties-panel.css";
import "./CaseDesigner.css";

const CaseDesigner = (props) => {
  const { setTitle } = props;
  const cmmnModeler = React.useRef();
  const inputFile = React.useRef();
  const [hasChange, setHasChange] = React.useState(false);

  const handleNewClick = async () => {
    if (cmmnModeler.current) {
      openDiagram(newDiagramXML);
    }
  };

  const handleSaveClick = () => {
    if (cmmnModeler.current) {
      saveDiagram((err, xml) => {
        if (err) {
          alert(err);
        } else {
          const blob = new Blob([xml]);

          FileSaver.saveAs(blob, "diagram.cmmn");
        }
      });
    }
  };

  const handleOpenFile = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (ev) => {
      openDiagram(ev.target.result);
      if (inputFile.current) {
        inputFile.current.value = null;
      }
    };
    reader.readAsText(file);
  };

  const handleDownloadImage = () => {
    if (cmmnModeler.current) {
      saveSVG((err, svg) => {
        if (err) {
          alert(err);
        } else {
          const blob = new Blob([svg]);

          FileSaver.saveAs(blob, "diagram.svg");
        }
      });
    }
  };

  const openDiagram = (cmmnXML) => {
    if (cmmnModeler.current) {
      cmmnModeler.current.importXML(cmmnXML, (err) => {
        if (err) {
          return console.error("Cannot import CMMN diagram.", err);
        }
      });

      const canvas = cmmnModeler.current.get("canvas");

      canvas.zoom("fit-viewport");
    }
  };

  const saveDiagram = (done) => {
    cmmnModeler.current.saveXML({ format: true }, (err, xml) => {
      done(err, xml);
    });
  };

  const saveSVG = (done) => {
    cmmnModeler.current.saveSVG(done);
  };

  React.useEffect(() => {
    if (setTitle) {
      setTitle("Case Designer");
    }

    cmmnModeler.current = new CmmnModeler({
      container: "#case-designer",
      keyboard: { bindTo: window },
      propertiesPanel: {
        parent: "#case-designer-properties",
      },
      additionalModules: [PropertiesPanelModule, PropertiesProviderModule],
      moddleExtensions: {
        camunda: camundaModdleDescriptor,
      },
    });

    const handleChanged = debounce(() => {
      setHasChange(true);
    }, 500);

    setHasChange(false);

    openDiagram(newDiagramXML);

    cmmnModeler.current.on("commandStack.changed", handleChanged);

    return () => {
      if (cmmnModeler.current != null) {
        cmmnModeler.current.destroy();
      }
    };
  }, []);

  return (
    <div class="case-designer-container">
      <div
        style={{
          height: "100%",
          padding: 0,
          margin: 0,
          textAlign: "left",
          backgroundColor: "white",
        }}
      >
        <div
          id="case-designer"
          style={{ height: "100%", padding: 0, margin: 0 }}
        ></div>
        <div id="case-designer-properties"></div>
      </div>
      <ul class="buttons">
        <li>
          <ButtonGroup sx={{ bgcolor: "white" }}>
            <Tooltip title="Open">
              <Button
                component={"label"}
                role={undefined}
                variant={"outlined"}
                tabIndex={-1}
              >
                <FolderOpenIcon />
                <input
                  ref={inputFile}
                  type="file"
                  style={{
                    clip: "rect(0,0,0,0)",
                    clipPath: "inset(50%)",
                    width: 1,
                    height: 1,
                    overflow: "hidden",
                  }}
                  onChange={handleOpenFile}
                />
              </Button>
            </Tooltip>
            <Tooltip title="New">
              <Button onClick={handleNewClick}>
                <AddCircleIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </li>
        <li>
          <ButtonGroup sx={{ bgcolor: "white" }}>
            <Tooltip title="Save">
              <Button disabled={!hasChange} onClick={handleSaveClick}>
                <SaveIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Export as svg">
              <Button disabled={!hasChange} onClick={handleDownloadImage}>
                <ImageIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </li>
      </ul>
    </div>
  );
};

export default CaseDesigner;
