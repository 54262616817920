import * as React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WebIcon from "@mui/icons-material/Web";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Facebook, Instagram, WhatsApp, X, YouTube } from "@mui/icons-material";
import urlJoin from "url-join";
import { useTranslation } from "react-i18next";

import { authServerBaseUrl } from "../../../../Config";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";

const LicenseSetting = (props) => {
  const [license, setLicense] = React.useState({});
  const [usage, setUsage] = React.useState({});
  const [manufacturer, setManufacturer] = React.useState({});
  const authenticationContext = useAuthentication();
  const { t } = useTranslation();

  const isExpired = (date) => {
    return dayjs(date) < dayjs().hour(0).minute(0).second(0).millisecond(0);
  };

  const isOverflow = (cur, max) => {
    return cur > max;
  };

  const TickCrossIcon = (props) => {
    const { check } = props;

    return (
      <>{check ? <DoneIcon color="success" /> : <CloseIcon color="error" />}</>
    );
  };

  const ManufacturerButton = (props) => {
    const { url, icon, label, disableNewTab = false } = props;

    return (
      <Link
        to={url}
        target={disableNewTab ? "_self" : "_blank"}
        style={{ color: "inherit" }}
      >
        <div style={{ display: "inline-block" }}>
          <IconButton>{icon}</IconButton>
          {label ? <Typography component={"span"}> {label}</Typography> : null}
        </div>
      </Link>
    );
  };

  const phoneNumber = (phone) => {
    if (phone) {
      return Object.assign([], phone)
        .filter((c) => /[0-9+]/gi.test(c))
        .join("");
    }

    return phone;
  };

  React.useEffect(() => {
    const url = urlJoin(authServerBaseUrl, "getLicense");
    CallApiWithContext(url, authenticationContext)
      .then((res) => {
        if (res.succeed) {
          setLicense(res.license);
          setUsage(res.usage);
        }
      })
      .catch((error) => console.log(error));
    setManufacturer({
      companyName: "FlexSystem Limited",
      website: "https://www.flexworkflow.com",
      contactUs: "https://www.flexworkflow.com/about-us/contact-us",
      email: "support@flexworkflow.com",
      map: "https://maps.app.goo.gl/3JhK7FBGKmoScwBj7",
      phone: "+852 3950-6300",
      youtube: "https://www.youtube.com/@FlexWorkflow",
      facebook: "https://www.facebook.com/FlexWorkflow/",
      whatsapp: "https://wa.me/+85239506300",
      instagram: "https://www.instagram.com/flexsystem_limited",
      twitter: "https://twitter.com/FlexSystem",
    });
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Table>
        <TableBody>
          {/* company id */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.company_id")}
            </TableCell>
            <TableCell>
              <Typography>{`${license.companyUUID}`}</Typography>
            </TableCell>
          </TableRow>
          {/* company name */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.company_name")}
            </TableCell>
            <TableCell>
              <Typography>{`${license.companyName}`}</Typography>
            </TableCell>
          </TableRow>
          {/* standard users */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.standard_users")}
            </TableCell>
            <TableCell>
              <Typography
                component={"span"}
                sx={{
                  color: isOverflow(usage.standardUsers, license.standardUsers)
                    ? "red"
                    : "inherit",
                  fontWeight: isOverflow(
                    usage.standardUsers,
                    license.standardUsers
                  )
                    ? "bold"
                    : "normal",
                }}
              >{`${usage.standardUsers}`}</Typography>
              <Typography
                component={"span"}
              >{` / ${license.standardUsers}`}</Typography>
            </TableCell>
          </TableRow>
          {/* restricted users */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.restricted_users")}
            </TableCell>
            <TableCell>
              <Typography
                component={"span"}
                sx={{
                  color: isOverflow(
                    usage.restrictedUsers,
                    license.restrictedUsers
                  )
                    ? "red"
                    : "inherit",
                  fontWeight: isOverflow(
                    usage.restrictedUsers,
                    license.restrictedUsers
                  )
                    ? "bold"
                    : "normal",
                }}
              >{`${usage.restrictedUsers}`}</Typography>
              <Typography
                component={"span"}
              >{` / ${license.restrictedUsers}`}</Typography>
            </TableCell>
          </TableRow>
          {/* concurrent users */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.concurrent_users")}
            </TableCell>
            <TableCell>
              <Typography
                component={"span"}
              >{`${usage.concurrentUsers} / ${license.concurrentUsers}`}</Typography>
            </TableCell>
          </TableRow>
          {/* valid until */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.valid_until")}
            </TableCell>
            <TableCell>
              <Typography
                sx={{
                  color: isExpired(license.dueDateTime) ? "red" : "inherit",
                  fontWeight: isExpired(license.dueDateTime)
                    ? "bold"
                    : "normal",
                }}
                component={"span"}
              >
                {dayjs(license.dueDateTime).format("DD/MM/YYYY")}
              </Typography>
            </TableCell>
          </TableRow>
          {/* modules */}
          <TableRow>
            <TableCell width={"200px"}>
              {t("administrative_console.license_page.modules")}
            </TableCell>
            <TableCell padding="none">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: "200px" }}>
                      {t("left_menu.dashboard")}
                    </TableCell>
                    <TableCell>
                      <TickCrossIcon
                        check={license.modulePermissions?.Dashboard === true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px" }}>
                      {t("left_menu.designer")}
                    </TableCell>
                    <TableCell>
                      <TickCrossIcon
                        check={license.modulePermissions?.Designer === true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px" }}>
                      {t("left_menu.documanlink")}
                    </TableCell>
                    <TableCell>
                      <TickCrossIcon
                        check={license.modulePermissions?.DMS === true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px" }}>
                      {t("left_menu.messages")}
                    </TableCell>
                    <TableCell>
                      <TickCrossIcon
                        check={license.modulePermissions?.Messages === true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none", width: "200px" }}>
                      {t("left_menu.report")}
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      <TickCrossIcon
                        check={license.modulePermissions?.Report === true}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
          {/* manufacturer */}
          <TableRow>
            <TableCell sx={{ width: "200px" }}>
              {t("administrative_console.license_page.manufacturer")}
            </TableCell>
            <TableCell padding={"none"}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <ManufacturerButton
                        url={manufacturer.map}
                        icon={<BusinessIcon />}
                        label={manufacturer.companyName}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <ManufacturerButton
                        url={`mailto:${manufacturer.email}`}
                        icon={<EmailIcon />}
                        label={manufacturer.email}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <ManufacturerButton
                        url={manufacturer.contactUs}
                        icon={<WebIcon />}
                        label={manufacturer.website}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <ManufacturerButton
                        url={`tel:${phoneNumber(manufacturer.phone)}`}
                        icon={<PhoneIcon />}
                        label={manufacturer.phone}
                        disableNewTab
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <ManufacturerButton
                        url={manufacturer.whatsapp}
                        icon={<WhatsApp />}
                      />
                      {/* <ManufacturerButton
                        url={"https://line.me/R/nv/chat/"}
                        icon={
                          <svg
                            fill="#FFFFFF"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12,2.641c-5.523,0-10,3.585-10,8.007,0,3.942,3.557,7.219,8.242,7.884h0c.11.015.221.029.332.042,1.595.332.212,2.328.735,2.736.6.47,5.152-2.574,7.918-5.13A8.638,8.638,0,0,0,20.316,15.1l.006-.006a2.071,2.071,0,0,0,.185-.232A6.844,6.844,0,0,0,22,10.648C22,6.226,17.523,2.641,12,2.641ZM8.736,12.681a.522.522,0,0,1-.521.521H6.2a.523.523,0,0,1-.522-.521V8.813A.524.524,0,0,1,6.2,8.291h.043a.524.524,0,0,1,.522.522v3.3H8.215a.522.522,0,0,1,.521.521Zm1.508,0a.523.523,0,0,1-.522.521H9.679a.523.523,0,0,1-.522-.521V8.813a.524.524,0,0,1,.522-.522h.043a.524.524,0,0,1,.522.522Zm4.724,0a.524.524,0,0,1-.151.366h0a.354.354,0,0,1-.127.094.508.508,0,0,1-.244.061H14.4a.52.52,0,0,1-.2-.039.431.431,0,0,1-.2-.15h0a.5.5,0,0,1-.039-.052l-1.9-2.593v2.313a.523.523,0,0,1-.522.521H11.5a.523.523,0,0,1-.522-.521V8.813a.524.524,0,0,1,.522-.522h.043A.524.524,0,0,1,12,8.555l1.885,2.528V8.813a.524.524,0,0,1,.522-.522h.043a.524.524,0,0,1,.522.522ZM18.7,8.865a.523.523,0,0,1-.521.522H16.722v.826h1.452a.522.522,0,0,1,.521.521v.044a.522.522,0,0,1-.521.521H16.722v.826h1.452a.523.523,0,0,1,.521.521v.044a.523.523,0,0,1-.521.522H16.157a.524.524,0,0,1-.522-.522V8.822a.524.524,0,0,1,.522-.522h2.017a.523.523,0,0,1,.521.522Z" />
                          </svg>
                        }
                      /> */}
                      <ManufacturerButton
                        url={manufacturer.youtube}
                        icon={<YouTube />}
                      />
                      <ManufacturerButton
                        url={manufacturer.facebook}
                        icon={<Facebook />}
                      />
                      <ManufacturerButton
                        url={manufacturer.instagram}
                        icon={<Instagram />}
                      />
                      <ManufacturerButton
                        url={manufacturer.twitter}
                        icon={<X />}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default LicenseSetting;
