import * as React from "react";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  TextField,
  LinearProgress,
  FormHelperText,
} from "@mui/material";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { debounce } from "@mui/material/utils";
import PropTypes from "prop-types";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/zh-hk";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import urlJoin from "url-join";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import { date, evaluate } from "feelin";
import { useTranslation } from "react-i18next";
import MarkdownIt from "markdown-it";
import { zhHK, zhCN, enUS } from "@mui/x-date-pickers/locales";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import dayjs from "dayjs";

import { resServerBaseUrl } from "../../Config";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useLocation, useParams } from "react-router-dom";
import { groupBy } from "../../shared/Utils";
import ControlsGroup from "./ControlsGroup";
import ComboBox from "./ComboBox";
import NumericBox from "./NumericBox";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import InlineDataGrid from "./InlineDataGrid";
import HtmlLabel from "./HtmlLabel";
import MultiColumnComboBox from "./MultiColumnComboBox";
import LoadingSpinner from "../../shared/LoadingSpinner";
import "./JsonFormViewer.css";

const JsonFormViewer = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  const {
    caseTplUUID,
    isOutbox,
    isReadOnly = false,
    isNewWorkOrder = true,
    planItemSID = null,
    alertMessage = null,
    caseSID = null,
  } = props;
  const { PlanItemTplSID } = useParams();
  const authenticationContext = useAuthentication();
  const location = useLocation();
  const isCopyAsNew = React.useMemo(() => {
    return location.pathname.includes("triggered");
  }, [location]);

  const [controlValues, setControlValues] = React.useState(new Map());
  const [updatedDataSources, setUpdatedDataSources] = React.useState();
  const [formTemplates, setFormTemplates] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedFormIndex, setSelectedFormIndex] = React.useState(0);
  const [updatedControls, setUpdatedControls] = React.useState([]);
  const [calculateTimeout, setCalculateTimeout] = React.useState();
  const [pageMode, setPageMode] = React.useState(true);
  const themeColorContext = useThemeColor();
  const readonlyColor = "#AAA";
  const markdownit = MarkdownIt({ html: true });

  const controlTypes = React.useMemo(() => {
    const getControlType = (c) => {
      switch (c.type) {
        case "fws_table":
          return {
            type: c.type,
            columns: Object.fromEntries(
              c.components.map((cc) => [cc.key, getControlType(cc)])
            ),
          };
        case "dynamiclist":
          return {
            type: c.type,
            columns: Object.fromEntries(
              c.components.map((cc) => [cc.key, getControlType(cc)])
            ),
          };
        case "group":
          return getControlTypes(c.components);
        default:
          return { type: c.type };
      }
    };
    const getControlTypes = (components) => {
      let obj = {};

      components
        .filter((c) => !!c.key || Array.isArray(c.components))
        .forEach((c) => {
          const result = getControlType(c);

          if (Array.isArray(result)) {
            const sublist = Object.fromEntries(result);

            obj = { ...obj, ...sublist };
          } else if (c.key) {
            obj[c.key] = result;
          } else if (c.path) {
            if (c.components) {
              if (c.type === "group") {
                obj = {
                  ...obj,
                  ...getControlTypes(c.components),
                };
              } else {
                obj[c.path] = result;
              }
            }
          } else {
            obj = { ...obj, ...result };
          }
        });

      return obj;
    };

    if (Array.isArray(formTemplates) && formTemplates?.length > 0) {
      let dic = {};

      formTemplates.forEach((t) => {
        if (t.formTemplate?.components) {
          dic = { ...dic, ...getControlTypes(t.formTemplate.components) };
        }
      });

      return dic;
    } else if (formTemplates?.components) {
      return getControlTypes(formTemplates.components);
    }

    return undefined;
  }, [formTemplates]);

  const calculate = React.useCallback(
    (curControlValues, controlTypes) => {
      if (calculateTimeout) {
        window.clearTimeout(calculateTimeout);
      }

      setCalculateTimeout(
        window.setTimeout(() => {
          setCalculateTimeout(null);
          console.log("start calculate");

          const url = urlJoin(resServerBaseUrl, "/JsonForm/CalculateJsonForm");
          const formData = JSON.stringify({
            CaseTplUUID: caseTplUUID,
            IsOutbox: isOutbox,
            JsonData: JSON.stringify(curControlValues),
            UpdatedControls: JSON.stringify(updatedControls),
          });

          CallApiWithContext(url, authenticationContext, formData)
            .then((res) => {
              const updatedControlValues = convertValues(
                JSON.parse(res.jsonFormData),
                controlTypes
              );

              if (res.updatedControls) {
                const updatedControlNames = JSON.parse(res.updatedControls);

                if (updatedControlNames?.length > 0) {
                  setControlValues((prev) => ({
                    ...prev,
                    ...updatedControlValues,
                  }));
                }
              } else {
                setControlValues((prev) => ({
                  ...prev,
                  ...updatedControlValues,
                }));
              }

              if (res.updatedDataSources) {
                const updatedDataSources = JSON.parse(res.updatedDataSources);

                setUpdatedDataSources((prev) => ({
                  ...prev,
                  ...updatedDataSources,
                }));
              } else {
                setUpdatedDataSources({});
              }
            })
            .catch((error) => console.log(error));

          setUpdatedControls([]);
        }, 500)
      );
    },
    [updatedControls]
  );
  const addUpdatedControl = (controlName) => {
    if (!updatedControls.some((c) => c === controlName)) {
      setUpdatedControls((prev) => [...prev, controlName]);
    } else {
      setUpdatedControls((prev) => [...prev]);
    }
  };
  const convertValues = (newValues, controlTypes) => {
    if (controlTypes) {
      for (let k in newValues) {
        if (Object.hasOwn(controlTypes, k)) {
          switch (controlTypes[k].type) {
            case "datetime":
              const newDate = dayjs(newValues[k]);

              if (newDate.isValid()) {
                newValues[k] = newDate;
              } else {
                newValues[k] = null;
              }
              break;
            case "fws_table":
              {
                const columns = controlTypes[k].columns;

                newValues[k]?.forEach((r) => {
                  for (let c in columns) {
                    switch (columns[c].type) {
                      case "datetime":
                        const newDate = dayjs(r[c]);

                        if (newDate.isValid()) {
                          r[c] = newDate;
                        } else {
                          r[c] = null;
                        }
                    }
                  }
                });
              }
              break;
            case "dynamiclist":
              {
                const columns = controlTypes[k].columns;

                newValues[k]?.forEach((r) => {
                  for (let c in columns) {
                    switch (columns[c].type) {
                      case "datetime":
                        if (r[c]) {
                          const newDate = new Date(r[c]);

                          if (newDate !== "Invalid Date") {
                            r[c] = newDate;
                          } else {
                            r[c] = null;
                          }
                        }
                    }
                  }
                });
              }
              break;
          }
        }
      }

      return newValues;
    }

    return {};
  };
  const massageFormTemplate = (template) => {
    // if (template?.components) {
    //   const controls = template.components;
    //   const filteredControls = controls.filter((c) => c.properties?.group);

    //   if (filteredControls?.length > 0) {
    //     // has grid columns
    //     const groupedControls = groupBy(
    //       filteredControls,
    //       (c) => c.properties.group
    //     );

    //     [...groupedControls.keys()].forEach((k) => {
    //       const labelAndColumns = groupedControls.get(k);
    //       const label = labelAndColumns.find((c) => !c.key);
    //       const columns = labelAndColumns.filter((c) => c.key);
    //       const firstColumnControl = columns[0];
    //       const firstColumnIndex = controls.indexOf(firstColumnControl);
    //       const gridControl = {
    //         label: firstColumnControl.properties.groupLabel,
    //         type: "fws_table",
    //         layout: {
    //           row: firstColumnControl.layout.row,
    //           columns: null,
    //         },
    //         validate: {
    //           required: label?.properties?.groupRequired,
    //         },
    //         readonly: label?.properties?.groupReadOnly,
    //         id: `Field_${uuid}`,
    //         key: firstColumnControl.properties.group,
    //         components: columns,
    //         properties: {
    //           groupTitle: label?.properties?.groupTitle,
    //         },
    //       };

    //       controls.splice(firstColumnIndex, columns.length, gridControl);
    //     });
    //   }
    // }

    return template;
  };
  const generateForm = (controls) => {
    const getAdapterLocale = () => {
      switch (localStorage.getItem("i18nextLng")?.toLocaleUpperCase()) {
        case "ZH-HK":
          return "zh-hk";
        case "ZH-CN":
          return "zh-CN";
        default:
          return undefined;
      }
    };
    const getLocale = () => {
      switch (localStorage.getItem("i18nextLng")?.toLocaleUpperCase()) {
        case "ZH-HK":
          return zhHK.components.MuiLocalizationProvider.defaultProps
            .localeText;
        case "ZH-CN":
          return zhCN.components.MuiLocalizationProvider.defaultProps
            .localeText;
        default:
          return enUS.components.MuiLocalizationProvider.defaultProps
            .localeText;
      }
    };
    const getControlledValue = (key, emptyValue = null) => {
      return controlValues[key] == undefined ? emptyValue : controlValues[key];
    };
    const getEvaluatedLabel = (label) => {
      if (controlValues && label?.startsWith("=")) {
        return evaluate(label.substring(1), controlValues);
      }

      return label;
    };
    const getHideCondition = (c) => {
      if (c.conditional?.hide) {
        try {
          const result = evaluate(
            c.conditional.hide.substring(1).replace("<>", "!="),
            controlValues
          );

          return result;
        } catch (ex) {
          console.log(ex);
        }
      }

      return false;
    };
    const getBoolCondition = (condition, defaultValue = false) => {
      if (typeof condition === "boolean") {
        return condition;
      }

      if (condition && new String(condition).startsWith("=")) {
        try {
          const result = evaluate(
            new String(condition).substring(1).replace("<>", "!="),
            controlValues
          );

          if (typeof result === "boolean") {
            return result;
          }
        } catch (ex) {
          console.log(ex);
        }
      }

      return defaultValue;
    };
    const generateControls = (list) => {
      const groupedByRow = groupBy(list, (c) => c.layout.row);

      return [...groupedByRow.keys()].map((key) => (
        <Grid
          container
          key={key}
          columns={{ xs: 16, sm: 16 }}
          alignItems={"stretch"}
        >
          {groupedByRow.get(key).map((c, index) =>
            c.layout?.columns ? (
              <>
                <Grid
                  item
                  key={`${c.layout.row}_${index}`}
                  xs={Math.min(
                    16,
                    c.layout.columns * 2 > 8 ? 16 : c.layout.columns * 2
                  )}
                  sm={c.layout.columns}
                  p={{ sm: 1, xs: 0.5 }}
                  textAlign={"left"}
                  display={getHideCondition(c) ? "hide" : "block"}
                >
                  {getHideCondition(c) ? null : generateControl(c)}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  key={`${c.layout.row}_${index}`}
                  sm
                  sx={{ width: "100%" }}
                  p={{ sm: 1, xs: 0.5 }}
                  textAlign={"left"}
                  display={getHideCondition(c) ? "none" : "block"}
                >
                  {getHideCondition(c) ? null : generateControl(c)}
                </Grid>
              </>
            )
          )}
        </Grid>
      ));
    };
    const generateControl = (control) => {
      let ui;

      const handleChangeDateType = (d) => {
        for (let i = 0; i < d?.length; i++) {
          const dateKey = Object.keys(d[i])
            .filter((key) => key.toLocaleLowerCase().includes("date"))
            .map((key) => key);

          for (let k = 0; k < dateKey.length; k++) {
            const curValue = d[i][dateKey[k]];

            if (curValue === null) {
              continue;
            }

            if (isSmUp) {
              if (Date(curValue) != undefined) {
                d[i][dateKey[k]] = new Date(curValue);
              } else {
                d[i][dateKey[k]] = null;
              }
            } else {
              d[i][dateKey[k]] = dayjs(curValue);
            }
          }
        }
        return d;
      };
      const generateLabel = (control) => {
        return (
          <div
            key={control.id}
            style={{
              height: "100%",
            }}
            className="label-view"
            dangerouslySetInnerHTML={{
              __html: markdownit.render(control.text),
            }}
          />
        );
      };
      const generateHtml = (control) => {
        return <HtmlLabel key={control.id} control={control} />;
      };
      const generateTextBox = (control) => {
        const handleValueChange = (e) => {
          // setControlValues((prev) => ({
          //   ...prev,
          //   [control.key]: e.target.value,
          // }));
        };
        const handleTextBoxOnFocus = (e) => {
          e.target.oldValue = e.target.value;
        };
        const handleTextBoxOnBlur = (e) => {
          if (e.target.oldValue !== e.target.value) {
            setControlValues((prev) => ({
              ...prev,
              [control.key]: e.target.value,
            }));
            addUpdatedControl(control.key);
            e.target.oldValue = undefined;
          }
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        return (
          <TextField
            key={`${control.id}${getControlledValue(control.key, "")}`}
            name={`${control.key}`}
            autoComplete="off"
            label={getEvaluatedLabel(control.label)}
            helperText={control.description}
            sx={{ width: "100%" }}
            required={control.validate?.required}
            multiline={control.type === "textarea"}
            defaultValue={getControlledValue(control.key, "")}
            onChange={handleValueChange}
            onBlur={handleTextBoxOnBlur}
            onFocus={handleTextBoxOnFocus}
            InputProps={{
              readOnly: readonly,
              maxRows: 5,
              inputProps: { maxLength: control.validate?.maxLength },
            }}
            variant={readonly ? "filled" : "outlined"}
          ></TextField>
        );
      };
      const generateDateTime = (control) => {
        const handleValueChange = (e) => {
          setControlValues((prev) => ({
            ...prev,
            [control.key]: e,
          }));
          addUpdatedControl(control.key);
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        switch (control.subtype) {
          case "date":
            return (
              <DatePicker
                key={control.id}
                label={`${getEvaluatedLabel(control.dateLabel)}${
                  control.validate?.required ? " *" : ""
                }`}
                sx={{ width: "100%" }}
                format={control.properties?.format ?? "DD/MM/YYYY"}
                value={getControlledValue(control.key)}
                onChange={handleValueChange}
                readOnly={readonly}
                slotProps={{
                  textField: {
                    id: control.id,
                    variant: readonly ? "filled" : "outlined",
                    autoComplete: "off",
                    spellCheck: false,
                  },
                }}
              />
            );
          case "time":
            return (
              <TimePicker
                key={control.id}
                label={`${getEvaluatedLabel(control.dateLabel)}${
                  control.validate?.required ? " *" : ""
                }`}
                sx={{ width: "100%" }}
                readOnly={isReadOnly || getBoolCondition(control.readonly)}
                value={getControlledValue(control.key)}
                slotProps={{
                  textField: {
                    id: control.id,
                    autoComplete: "off",
                    variant: readonly ? "filled" : "outlined",
                    spellCheck: false,
                  },
                }}
                onChange={handleValueChange}
              />
            );
          default:
            return (
              <DateTimePicker
                key={control.id}
                label={`${getEvaluatedLabel(control.dateLabel)}${
                  control.validate?.required ? " *" : ""
                }`}
                sx={{ width: "100%" }}
                format={control.properties?.format ?? "DD/MM/YYYY hh:mm A"}
                readOnly={isReadOnly || getBoolCondition(control.readonly)}
                value={getControlledValue(control.key)}
                slotProps={{
                  textField: {
                    id: control.id,
                    autoComplete: "off",
                    variant: readonly ? "filled" : "outlined",
                    spellCheck: false,
                  },
                }}
                onChange={handleValueChange}
              />
            );
        }
      };
      const generateNumericInput = (control) => {
        const handleValueChange = (e) => {
          // setControlValues((prev) => ({
          //   ...prev,
          //   [control.key]: e.target.value,
          // }));
        };
        const handleNumericBoxBlur = (e) => {
          if (e.target.valueChanged) {
            setControlValues((prev) => ({
              ...prev,
              [control.key]: e.target.value,
            }));
            addUpdatedControl(control.key);
          }
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        return (
          <NumericBox
            key={`${control.id}${getControlledValue(control.key)}`}
            label={getEvaluatedLabel(control.label)}
            helperText={control.description}
            required={control.validate?.required}
            defaultValue={
              getControlledValue(control.key) || control.defaultValue
            }
            decimalDigits={
              control.label != "undefined" &&
              control.label != "" &&
              control.label != null
                ? control.label.toLocaleLowerCase().includes("total")
                  ? 2
                  : control.decimalDigits
                : control.decimalDigits
            }
            variant={readonly ? "filled" : "outlined"}
            serializeToString={control.serializeToString}
            min={control.validate?.min}
            max={control.validate?.max}
            InputProps={{
              readOnly: readonly,
              inputProps: {
                prefix: control.properties?.prefix,
                suffix: control.properties?.suffix,
                style: { textAlign: "right" },
              },
            }}
            onChange={handleValueChange}
            onBlur={handleNumericBoxBlur}
            fullWidth
          />
        );
      };
      const generateSelect = (control) => {
        const isMultiColumnComboBox = () => {
          if (control.properties?.columns) {
            return (
              JSON.parse(control.properties.columns).filter((c) => c.visible)
                .length > 1
            );
          } else if (control.itemsSource?.length > 0) {
            return Object.keys(control.itemsSource[0]).length > 1;
            //
          }

          return false;
        };
        const handleSelectChange = (e) => {
          setControlValues((prev) => ({
            ...prev,
            [control.key]: e.target.value,
          }));
          addUpdatedControl(control.key);
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        if (
          updatedDataSources &&
          Object.keys(updatedDataSources).some((k) => k === control.key)
        ) {
          control.itemsSource = updatedDataSources[control.key];
        }

        const itemsSource = control.itemsSource;

        if (
          itemsSource?.length > 0 &&
          (Object.keys(itemsSource[0]).length == 1 ||
            (control.properties?.columns &&
              JSON.parse(control.properties?.columns).filter(
                (c) => c.visible == true
              ).length == 1))
        ) {
          let displayMember = control.properties?.displayMember;
          let valueMember = control.properties?.valueMember;
          const columnNames = Object.keys(itemsSource[0]);

          if (!columnNames.some((k) => k === displayMember))
            displayMember = columnNames[0];
          if (!columnNames.some((k) => k === valueMember))
            valueMember = columnNames[0];

          control.values = itemsSource.map((r) => ({
            value: r[valueMember],
            label: r[displayMember],
          }));
        }

        let menuItems = control.values || control.valuesKey;

        if (typeof menuItems === "string") {
          menuItems = JSON.parse(menuItems);
        }

        return isMultiColumnComboBox() ? (
          <MultiColumnComboBox
            key={control.id}
            controlName={control.key}
            label={getEvaluatedLabel(control.label)}
            value={getControlledValue(control.key)}
            onChange={handleSelectChange}
            readOnly={readonly}
            required={control.validate?.requried}
            itemsSource={control.itemsSource}
            displayMember={control.properties?.displayMember}
            valueMember={control.properties?.valueMember}
            multiple={control.properties?.multiple}
            columns={
              control.properties?.columns
                ? JSON.parse(control.properties?.columns)
                : undefined
            }
            description={control.description}
          />
        ) : (
          <ComboBox
            key={control.id}
            label={getEvaluatedLabel(control.label)}
            value={getControlledValue(control.key, "")}
            onChange={handleSelectChange}
            multiple={control.properties?.multiple}
            readOnly={readonly}
            required={control.validate?.required}
            menuItems={menuItems}
            description={control.description}
          />
        );
      };
      const generateButton = (control) => {
        const handleButtonClick = () => {
          console.log(controlValues);
        };

        return (
          <Button
            key={control.id}
            fullWidth
            variant={"contained"}
            disalbed={isReadOnly}
            onClick={handleButtonClick}
          >
            {control.label}
          </Button>
        );
      };
      const generateRadio = (control) => {
        const handleValueChange = (e) => {
          setControlValues((prev) => ({
            ...prev,
            [control.key]: e.target.value,
          }));
          addUpdatedControl(control.key);
        };
        const handleOnClick = (e) => {
          if (isReadOnly) {
            e.preventDefault();
          }
        };
        const ButtonIcon = (props) => {
          const { checked } = props;
          const checkBoxLayout = control.properties?.type === "checkbox";

          return checked ? (
            checkBoxLayout ? (
              <CheckBoxIcon />
            ) : (
              <RadioButtonCheckedIcon />
            )
          ) : checkBoxLayout ? (
            <CheckBoxOutlineBlankIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          );
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        return (
          <FormControl key={control.id} required={control.validate?.required}>
            <FormLabel>{getEvaluatedLabel(control.label)}</FormLabel>
            <RadioGroup
              defaultValue={getControlledValue(control.key)}
              row={control.properties?.direction === "row"}
              onChange={handleValueChange}
            >
              {control.values.map((v, index) => (
                <FormControlLabel
                  key={`${control.key}_${index}`}
                  value={v.value}
                  control={
                    <Radio
                      icon={<ButtonIcon />}
                      checkedIcon={<ButtonIcon checked />}
                      disableRipple={readonly}
                      readOnly={readonly}
                      checked={controlValues[control.key] === v.value}
                      style={{ color: readonly ? readonlyColor : null }}
                      onClick={(e) => handleOnClick(e)}
                    />
                  }
                  label={v.label}
                />
              ))}
            </RadioGroup>
            {control.description ? (
              <FormHelperText>{control.description}</FormHelperText>
            ) : null}
          </FormControl>
        );
      };
      const generateCheckBox = (control) => {
        const handleChange = (e) => {
          setControlValues((prev) => ({
            ...prev,
            [control.key]: e.target.checked,
          }));
          addUpdatedControl(control.key);
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        return (
          <FormGroup>
            <FormControlLabel
              required={control.validate?.required}
              control={
                <Checkbox
                  readOnly={readonly}
                  disableRipple={readonly}
                  checked={controlValues[control.key] === true}
                  onChange={readonly ? null : handleChange}
                  style={{ color: readonly ? readonlyColor : null }}
                />
              }
              label={getEvaluatedLabel(control.label)}
            />
          </FormGroup>
        );
      };
      const generateCheckList = (control) => {
        const isChecked = (targetValue) => {
          const value = controlValues[control.key];

          if (value) {
            return value.split(",").some((t) => t === targetValue);
          }

          return false;
        };
        const handleChange = (checked, targetValue) => {
          const value = controlValues[control.key];
          let ary = [];

          if (value) {
            ary = value.split(",");

            if (checked) {
              if (!ary.some((t) => t === targetValue)) {
                ary.push(targetValue);
              }
            } else {
              const index = ary.findIndex((t) => t === targetValue);

              if (index >= 0) {
                ary.splice(index, 1);
              }
            }
          } else {
            if (checked) {
              ary.push(targetValue);
            }
          }

          setControlValues((prev) => ({
            ...prev,
            [control.key]: ary.join(","),
          }));
          addUpdatedControl(control.key);
        };
        const readonly = isReadOnly || getBoolCondition(control.readonly);

        return (
          <FormControl
            key={control.id}
            required={control.validate?.required}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">
              {getEvaluatedLabel(control.label)}
            </FormLabel>
            <FormGroup row={control.properties?.direction === "row"}>
              {control.values.map((item, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: readonly ? readonlyColor : null }}
                      readOnly={readonly}
                      checked={isChecked(item.value)}
                      disableRipple={readonly}
                      onChange={(e) =>
                        readonly
                          ? null
                          : handleChange(e.target.checked, item.value)
                      }
                      name={`${control.key}_${index}`}
                    />
                  }
                  label={item.label}
                  key={`${control.key}_${index}`}
                />
              ))}
            </FormGroup>
            {control.description ? (
              <FormHelperText>{control.description}</FormHelperText>
            ) : null}
          </FormControl>
        );
      };
      const generateTable = (control) => {
        const handleValueChange = (e) => {
          console.log(e.target.values);
          setControlValues((prev) => ({
            ...prev,
            [control.key]: e.target.values,
          }));
        };

        return isSmUp ? (
          <InlineDataGrid
            key={control.id}
            name={control.key}
            // title={control.label}
            readonly={control.readonly || isReadOnly}
            required={control.validate?.required}
            columns={control.components}
            value={handleChangeDateType(controlValues[control.key])}
            allowAddRemove={control.allowAddRemove}
            onChange={handleValueChange}
            getBoolCondition={getBoolCondition}
          />
        ) : (
          <ControlsGroup
            key={control.id}
            name={control.key}
            // title={control.label}
            readonly={control.readonly || isReadOnly}
            required={control.validate?.required}
            columns={control.components}
            value={handleChangeDateType(controlValues[control.key])}
            onChange={handleValueChange}
          >
            {control.components.map((c) => generateControl(c))}
          </ControlsGroup>
        );
      };
      const generateDynamicList = (control) => {
        const handleValueChange = (e) => {
          const qualifiedName =
            `${control.path}` +
            (e.target.columnName ? `.${e.target.columnName}` : ``);

          setControlValues((prev) => ({
            ...prev,
            [control.path]: e.target.values,
          }));
          addUpdatedControl(qualifiedName);
        };
        const updateHideCondition = (components) => {
          components.forEach((c) => {
            c.hide = getHideCondition(c);
          });

          return components;
        };
        const readonly =
          isReadOnly || getBoolCondition(control.properties?.readonly);

        if (updatedDataSources) {
          if (
            Object.keys(updatedDataSources).some(
              (k) => k.split(".")[0] === control.path
            )
          ) {
            const columns = Object.keys(updatedDataSources).filter(
              (k) => k.split(".")[0] === control.path
            );

            columns.forEach((c) => {
              const columnName = c.split(".")[1];
              const column = control.components.find(
                (c) => c.key === columnName
              );

              if (column) {
                column.itemsSource = updatedDataSources[c];
              }
            });
          }
        }

        const visibleComponents = control.components.filter(
          (c) => !getHideCondition(c)
        );

        return isSmUp ? (
          <InlineDataGrid
            key={control.id}
            name={control.path}
            // title={control.label}
            readonly={readonly}
            allowAddRemove={control.allowAddRemove}
            required={control.validate?.required}
            columns={updateHideCondition(control.components)}
            value={handleChangeDateType(controlValues[control.path])}
            onChange={handleValueChange}
            getBoolCondition={getBoolCondition}
          />
        ) : (
          <ControlsGroup
            key={control.id}
            name={control.path}
            // title={control.label}
            readonly={readonly}
            allowAddRemove={control.allowAddRemove}
            required={control.validate?.required}
            columns={visibleComponents}
            value={handleChangeDateType(controlValues[control.path])}
            onChange={handleValueChange}
          >
            {visibleComponents.map((c) => generateControl(c))}
          </ControlsGroup>
        );
      };
      const generateGroup = (control) => {
        return (
          <Paper
            key={control.id}
            elevation={control.showOutline === true ? 3 : 0}
            sx={{ p: control.showOutline ? 2 : 0 }}
          >
            {control.label ? (
              <div
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                dangerouslySetInnerHTML={{
                  __html: getEvaluatedLabel(control.label),
                }}
              ></div>
            ) : null}
            {generateControls(control.components)}
          </Paper>
        );
      };
      const generateSpacer = (control) => {
        if (isSmUp) {
          return (
            <div
              key={control.id}
              style={{ height: `${control.height}px` }}
            ></div>
          );
        }

        return null;
      };
      const generateSeparator = (control) => {
        const getAlignment = () => {
          switch (control.properties?.alignment) {
            case "top":
              return "flex-start";
            case "bottom":
              return "flex-end";
            default:
              return "center";
          }
        };

        return (
          <div
            key={control.id}
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: getAlignment(),
            }}
          >
            <Divider sx={{ width: "100%" }} />
          </div>
        );
      };

      switch (control.type) {
        case "text":
          ui = generateLabel(control);
          break;
        case "html":
          ui = generateHtml(control);
          break;
        case "textfield":
        case "textarea":
          ui = generateTextBox(control);
          break;
        case "number":
          ui = generateNumericInput(control);
          break;
        case "datetime":
          ui = generateDateTime(control);
          break;
        case "button":
          ui = generateButton(control);
          break;
        case "radio":
          ui = generateRadio(control);
          break;
        case "select":
          ui = generateSelect(control);
          break;
        case "fws_table":
          ui = generateTable(control);
          break;
        case "dynamiclist":
          ui = generateDynamicList(control);
          break;
        case "group":
          ui = generateGroup(control);
          break;
        case "spacer":
          ui = generateSpacer(control);
          break;
        case "separator":
          ui = generateSeparator(control);
          break;
        case "checkbox":
          ui = generateCheckBox(control);
          break;
        case "checklist":
          ui = generateCheckList(control);
          break;
        default:
          ui = generateTextBox(control);
          break;
      }

      return ui;
    };

    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={getAdapterLocale()}
        localeText={getLocale()}
      >
        <Grid
          container
          gap={{ xs: 0, sm: 1 }}
          sx={{ p: { sm: 3, xs: 1 } }}
          component={"div"}
          id="jsonForm"
        >
          {generateControls(controls)}
        </Grid>
      </LocalizationProvider>
    );
  };
  const validateForm = (controls) => {
    const validateTable = (control, rows) => {
      if (rows?.length > 0) {
        return rows.some((r) => {
          return control.components.some(
            (c) => c.validate?.required && !r[c.key] && r[c.key] !== 0
          );
        });
      }

      return false;
    };

    const getEmptyControl = (c) => {
      switch (c.type) {
        case "fws_table":
          const rows = controlValues[c.key];

          if (c.validate?.required && (rows?.length ? rows.length : 0) === 0) {
            return c.label;
          } else {
            const emptyRow = rows.find((r) =>
              c.components.some(
                (cc) => cc.validate?.required && !r[cc.key] && r[cc.key] !== 0
              )
            );

            if (emptyRow) {
              const rowIndex = rows.indexOf(emptyRow);
              const emptyColumn = c.components.find(
                (cc) =>
                  cc.validate?.required &&
                  !emptyRow[cc.key] &&
                  emptyRow[cc.key] !== 0
              );

              return `${c.label} > Line ${rowIndex + 1} > ${
                emptyColumn.dateLabel || emptyColumn.label
              }`;
            }
          }
          break;
        default:
          return c.dateLabel || c.label;
      }
    };

    if (controls) {
      return controls
        .filter((c) => {
          if (c.key) {
            switch (c.type) {
              case "fws_table":
                return (
                  (c.validate?.required &&
                    (!controlValues[c.key] ||
                      controlValues[c.key].length === 0)) ||
                  validateTable(c, controlValues[c.key])
                );
              default:
                return (
                  c.validate?.required &&
                  !controlValues[c.key] &&
                  controlValues[c.key] !== 0
                );
            }
          }

          return false;
        })
        .map((c) => getEmptyControl(c));
    }

    return [];
  };
  const handleActionButtonClick = (action) => {
    return new Promise((resolve, rejected) => {
      if (action === "_Close_" || action === "_Trigger_") {
        // nothing to do...
      } else {
        if (isLoading) {
          rejected(
            t(
              "formruntime.please_wait_for_the_form_load_complete_before_submit"
            )
          );
        } else {
          // check whether controls are fulliled all requirement.
          const result = validateForm(formTemplates?.components);

          if (result.length > 0) {
            rejected(
              `${result.join("\n")} \n` + t("formruntime.cannot_be_empty")
            );
          }
        }
      }

      resolve({ canProcess: true });
    });
  };
  const submitTask = (data) => {
    return new Promise((resolve, rejected) => {
      const url = urlJoin(resServerBaseUrl, "/JsonForm/SaveDocumentToServer");
      const formData = JSON.stringify({
        ...data,
        JsonData: JSON.stringify(controlValues),
      });

      CallApiWithContext(url, authenticationContext, formData)
        .then((res) => {
          resolve({ result: t("formruntime.task_submitted") });
        })
        .catch((error) => {
          rejected(error);
        });
    });
  };
  const handleFormTabChange = (event, newValue) => {
    setSelectedFormIndex(newValue);

    if (newValue === 0) {
    }
  };

  /* expose method to integrate with FormRuntime */
  React.useImperativeHandle(ref, () => ({
    actionButtonClick: (action) => {
      return handleActionButtonClick(action);
    },
    submitTask: (data) => {
      return submitTask(data);
    },
    onFormClose: () => {
      // nothing to do.
    },
  }));
  /* load form */
  React.useEffect(() => {
    const loadForm = (url, data) => {
      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {
          if (res.jsonFormData) {
            const controlValues = {};

            const retrieveControlValues = (control) => {
              if (control.components) {
                control.components.forEach((c) => {
                  if (c.key || c.path) {
                    switch (c.type) {
                      case "datetime":
                        if (c.defaultValue) {
                          const dateValue = dayjs(c.defaultValue);

                          if (dateValue.isValid()) {
                            controlValues[c.key] = dateValue;
                          } else {
                            controlValues[c.key] = null;
                          }
                        }
                        break;
                      case "number":
                        const digits =
                          c.decimalDigits === undefined ? 2 : c.decimalDigits;

                        if (digits >= 0) {
                          if (!isNaN(c.defaultValue)) {
                            const value = parseFloat(c.defaultValue).toFixed(
                              digits
                            );

                            controlValues[c.key] = value;
                          } else {
                            controlValues[c.key] = null;
                          }
                        }
                        break;
                      case "fws_table":
                        break;
                      case "dynamiclist":
                        // ignore
                        if (c.path && c.defaultValue) {
                          controlValues[c.path] = c.defaultValue;
                        }
                        break;
                      default:
                        if (c.key) {
                          controlValues[c.key] =
                            c.defaultValue === undefined ? "" : c.defaultValue;
                        }
                        break;
                    }
                  }

                  if (c.components) {
                    retrieveControlValues(c);
                  }
                });
              }
            };

            if (Array.isArray(res.jsonFormData)) {
              const formTemplates = res.jsonFormData.map((d) => ({
                name: d.name,
                formTemplate: JSON.parse(d.data),
              }));

              setFormTemplates(formTemplates);

              formTemplates.forEach((t) =>
                retrieveControlValues(t.formTemplate)
              );

              setControlValues(controlValues);
            } else {
              const formTemplate = JSON.parse(res.jsonFormData);

              // update json
              massageFormTemplate(formTemplate);

              setFormTemplates(formTemplate);

              // convert date to dayjs
              retrieveControlValues(formTemplate);

              setControlValues(controlValues);
            }
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    setIsLoading(true);

    const url = urlJoin(resServerBaseUrl, `/JsonForm/GetJsonFormTemplate`);
    const data = JSON.stringify({
      uuid: caseTplUUID,
      planItemSID: planItemSID,
      caseSID: caseSID,
      copyAsNew: isCopyAsNew,
      language: localStorage.getItem("i18nextLng"),
    });

    /* load form ui */
    loadForm(url, data);
  }, [isNewWorkOrder, caseTplUUID, caseSID, planItemSID]);
  React.useEffect(() => {
    const loadFormData = (url, data) => {
      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {
          if (res.jsonFormData) {
            const result = JSON.parse(res.jsonFormData);

            console.log("%cJsonForm Data", "color: green ");
            console.log(result);

            const convertedValues = convertValues(result, controlTypes);
            // for (let k in result) {
            //   if (Object.hasOwn(controlTypes, k)) {
            //     switch (controlTypes[k].type) {
            //       case "datetime":
            //         const newDate = dayjs(result[k]);

            //         if (newDate.isValid()) {
            //           result[k] = newDate;
            //         } else {
            //           result[k] = null;
            //         }
            //         break;
            //       case "fws_table":
            //         const columns = controlTypes[k].columns;

            //         result[k]?.forEach((r) => {
            //           for (let c in columns) {
            //             switch (columns[c].type) {
            //               case "datetime":
            //                 const newDate = dayjs(r[c]);

            //                 if (newDate.isValid()) {
            //                   r[c] = isSmUp ? new Date(newDate) : newDate;
            //                 } else {
            //                   r[c] = null;
            //                 }
            //             }
            //           }
            //         });
            //         break;
            //     }
            //   }
            // }

            // merge default values and form data.
            setControlValues((prev) => ({ ...prev, ...convertedValues }));
          }

          if (res.updatedDataSources) {
            setUpdatedDataSources(JSON.parse(res.updatedDataSources));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    /* load form data */
    if ((caseSID && formTemplates && controlTypes) || isCopyAsNew) {
      const getFormDataUrl = urlJoin(resServerBaseUrl, `/JsonForm/GetJsonForm`);
      const getFormDataParams = JSON.stringify({
        CaseSID: caseSID,
        PlanItemSID: planItemSID,
        PlanItemTplSID: PlanItemTplSID,
        IsOutbox: isOutbox,
        CaseTplUUID: caseTplUUID,
        CopyAsNew: isCopyAsNew,
      });

      setIsLoading(true);
      loadFormData(getFormDataUrl, getFormDataParams);
    }
  }, [controlTypes]);
  React.useEffect(() => {
    // if (
    //   controlValues.DataGroup != "undefined" &&
    //   controlValues.DataGroup != null
    // ) {
    //   const result = Object.keys(controlValues.DataGroup).map(
    //     (key) => controlValues.DataGroup[key]
    //   );
    //   var subTotal = result.reduce(
    //     (a, v) => (a = a + (v.nb_Amount ? parseFloat(v.nb_Amount) : 0)),
    //     0
    //   );
    //   setTotal(subTotal);

    //   if (controlValues["nb_TotalAmount"] !== subTotal) {
    //     setControlValues((prev) => ({
    //       ...prev,
    //       ["nb_TotalAmount"]: subTotal,
    //     }));
    //   }
    // } else {
    //   //  for (var [key, value] of Object.entries(controlValues)) console.log(key);
    //   var sum = 0;
    //   Object.entries(controlValues).map((key, value) => {
    //     if (
    //       !key[0].toLocaleLowerCase().includes("lamount") &&
    //       !key[0].toLocaleLowerCase().includes("l amount") &&
    //       !key[0].toLocaleLowerCase().includes("l_amount")
    //     ) {
    //       if (key[0].toLocaleLowerCase().includes("amount")) {
    //         sum += parseFloat(key[1]);
    //       }
    //     }
    //   });
    //   setTotal(sum);
    // }

    // returnValue used to prevent calculation update control values
    // makes the loop calculation.
    // if (returnValue) {
    //   setReturnValue(false);
    // } else if (controlTypes) {
    //   calculate(controlValues, controlTypes);
    // }

    if (updatedControls.length > 0 && controlTypes) {
      calculate(controlValues, controlTypes);
    }

    //setCounters(nextCounters);
    /*    if (controlValues != "undefined" && controlValues != "") {
      if (
        controlValues.DataGroup != "undefined" &&
        controlValues.DataGroup != ""
      ) {
        console.log(
          controlValues.DataGroup.reduce((a, v) => (a = a + v.nb_Amount), 0)
        );
      }
    }*/
  }, [updatedControls]);
  React.useEffect(() => {
    const onbeforeunload = (e) => {
      e.preventDefault();
      e.returnValue = false;
    };

    window.addEventListener("beforeunload", onbeforeunload);

    return () => {
      window.removeEventListener("beforeunload", onbeforeunload);
    };
  }, []);

  return (
    <div>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        sx={{
          p: isSmUp ? 5 : 0,
          pt: formTemplates?.length > 1 ? 0 : { xs: 0, sm: 5 },
          maxWidth: pageMode ? `1080px` : null,
        }}
      >
        {formTemplates?.length > 1 ? (
          <Grid item sx={{ width: "100%", mt: { xs: 0, sm: 5 } }}>
            <AppBar position="static">
              <Tabs
                value={selectedFormIndex}
                onChange={handleFormTabChange}
                textColor="#fff"
                variant={"scrollable"}
                scrollButtons="auto"
                sx={{ pr: 1 }}
              >
                {formTemplates
                  ? formTemplates.map((item, index) => (
                      <Tab key={index} label={item.name} />
                    ))
                  : Array.from(new Array(1)).map((_, index) => (
                      <Tab key={index} label={<LinearProgress />} />
                    ))}
              </Tabs>
            </AppBar>
          </Grid>
        ) : null}
        <Grid
          item
          sx={{
            bgcolor: `formMainContainer.${themeColorContext[0]}`,
            minWidth: { xs: "0px", md: "400px" }, //isSmUp ? "400px" : "0px",
            width: "100%",
          }}
        >
          {formTemplates?.length > 0 ? (
            <Paper sx={{ borderRadius: 0 }} elevation={12}>
              {Array.isArray(formTemplates)
                ? generateForm(
                    formTemplates[selectedFormIndex].formTemplate.components,
                    controlValues
                  )
                : generateForm(formTemplates.components, controlValues)}
            </Paper>
          ) : (
            <Grid
              xs
              container
              item
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ p: 1, height: "300px" }}
            >
              <CircularProgress color="secondary" />
            </Grid>
          )}
        </Grid>
      </Grid>
      <LoadingSpinner isOpen={isLoading} />
    </div>
  );
});

JsonFormViewer.propTypes = {
  caseTplUUID: PropTypes.string,
  isOutbox: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isNewWorkOrder: PropTypes.bool,
};

export default JsonFormViewer;
