import * as React from "react";

import {
  Autocomplete,
  ClickAwayListener,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popper,
  TextField,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MuiPhoneNumber from "mui-phone-number";
import { useTranslation } from "react-i18next";

import UserModulePermission from "./UserModulePermission";
import { useCountry } from "../../../../providers/CountryProvider";
import { useThemeColor } from "../../../../providers/ThemeColorProvider";
import { MODULE_PERMISSIONS } from "../../../../Constants";
import { AddUserPasswordField } from "../../../../shared/PasswordRuleDisplay";

const UserAddEditor = React.forwardRef((props, ref) => {
  const { rules, onPropertyChange } = props;
  const { t } = useTranslation();
  const [countryCode] = useCountry();

  const [addingUser, setAddingUser] = React.useState({
    jobTitle: "",
    department: "",
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    mobile: "",
    accountType: "",
    modulePermissions:
      MODULE_PERMISSIONS.Inbox |
      MODULE_PERMISSIONS.Outbox |
      MODULE_PERMISSIONS.Workspace,
  });
  const [isDataIncorrect, setIsDataIncorrect] = React.useState({});
  const addingUserPasswordField = React.useRef();

  const accTypeOption = [
    {
      label: t("administrative_console.license_page.standard_users"),
      value: 0,
    },
    {
      label: t("administrative_console.license_page.restricted_users"),
      value: 2,
    },
  ];

  const getFullName = (addingUser) => {
    const isChinese = /[^\u4e00-\u9fa5]/;

    if (
      isChinese.test(addingUser.firstName) == true &&
      isChinese.test(addingUser.lastName) == true
    ) {
      return [addingUser.firstName, addingUser.middleName, addingUser.lastName]
        .filter((w) => !!w)
        .join(" ");
    } else {
      return [addingUser.lastName, addingUser.middleName, addingUser.firstName]
        .filter((w) => !!w)
        .join("");
    }
  };

  const handleDataChange = (property, event, newValue) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    const noRegex = /^\+?[0-9()-]+$/;
    const errorInputObject = {};
    const userInfo = { ...addingUser };

    Object.keys(userInfo).forEach((a) => {
      if (
        (a === "jobTitle" ||
          a === "department" ||
          a === "firstName" ||
          a === "lastName") &&
        userInfo[a] === ""
      ) {
        errorInputObject[a] = true;
      } else if (
        a === "email" &&
        !emailRegex.test(userInfo[a].replace(/\s/g, "")) &&
        userInfo[a] === ""
      ) {
        errorInputObject[a] = true;
      } else if (
        a === "mobile" &&
        !noRegex.test(userInfo[a]) &&
        userInfo[a] != ""
      ) {
        errorInputObject[a] = true;
      } else if (a === "accountType" && userInfo[a] === "") {
        errorInputObject[a] = true;
      }
    });

    switch (property) {
      case "firstName":
      case "lastName":
      case "middleName":
      case "jobTitle":
      case "department":
        if (!event.target.value && property != "middleName") {
          errorInputObject[property] = true;
        } else {
          errorInputObject[property] = false;
        }

        userInfo[property] = event.target.value;
        // update full name.
        userInfo.fullName = getFullName(userInfo);
        break;
      case "accountType":
        if (event.target.textContent == "") {
          errorInputObject[property] = true;
          userInfo[property] = "";
        } else {
          errorInputObject[property] = false;
          userInfo[property] = newValue.value;
        }
        break;
      case "email":
        errorInputObject[property] =
          event.target.value != "" &&
          !emailRegex.test(event.target.value.replace(/\s/g, ""));
        userInfo[property] = event.target.value;
        break;
      case "mobile":
        errorInputObject[property] = event.target.value === "+";
        userInfo[property] = event.target.value;
        break;
      default:
        userInfo[property] = event.target.value;
        break;
    }

    setAddingUser((prev) => ({ ...prev, ...userInfo }));
    setIsDataIncorrect((prev) => ({ ...prev, ...errorInputObject }));

    if (onPropertyChange) {
      // prevent get isDataIncorrect too fast.
      window.setTimeout(onPropertyChange, 10);
    }
  };
  const handleUserPermissionChange = (value) => {
    setAddingUser((prev) => ({
      ...prev,
      modulePermissions: value,
    }));
  };
  const phoneNumber = (number) => {
    let length = number
      ? number.split("").filter((e) => parseInt(e) !== "NaN").length
      : null;
    switch (length) {
      case 8:
        return `+852 ${number}`;
      case 11:
        return `+86 ${number}`;
      case 12:
        return `+886 ${number}`;
      default:
        return `+852 ${number}`;
    }
  };

  /* expose method to integrate with user setting page */
  React.useImperativeHandle(ref, () => ({
    getAddingUser: () => {
      return addingUser;
    },
    isDataIncorrect: () => {
      return (
        Object.values(isDataIncorrect).includes(true) &&
        addingUserPasswordField.current.getPasswordInvalidaty()
      );
    },
  }));

  return (
    <Grid container direction="row" spacing={1}>
      {/* first name, middle name, last name */}
      {[
        { key: "firstName", label: t("profile.first_name") },
        { key: "middleName", label: t("profile.middle_name") },
        { key: "lastName", label: t("profile.last_name") },
      ].map((k) => (
        <Grid key={k.key} item xs={12} md={4}>
          <TextField
            fullWidth
            autoComplete="off"
            label={k.label}
            value={addingUser[k.key]}
            error={isDataIncorrect[k.key]}
            onChange={(e) => handleDataChange(k.key, e)}
          />
        </Grid>
      ))}
      {/* email */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          autoFocus
          autoComplete="off"
          label={t("profile.email")}
          value={addingUser.email}
          error={isDataIncorrect.email}
          onChange={(e) => handleDataChange("email", e)}
        />
      </Grid>
      {/* password */}
      <Grid container item xs={12} md={6}>
        <AddUserPasswordField
          ref={addingUserPasswordField}
          password={addingUser.password}
          rules={rules}
          userLogin={addingUser.email}
          onChange={handleDataChange}
        />
      </Grid>
      {/* job title */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label={t("administrative_console.user_page.job_title")}
          value={addingUser.jobTitle}
          autoComplete="off"
          error={isDataIncorrect.jobTitle}
          onChange={(e) => handleDataChange("jobTitle", e)}
        />
      </Grid>
      {/* department */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label={t("administrative_console.user_page.department")}
          value={addingUser.department}
          autoComplete="off"
          error={isDataIncorrect.department}
          onChange={(e) => handleDataChange("department", e)}
        />
      </Grid>
      {/* account type */}
      <Grid item xs={12} md={6}>
        <Autocomplete
          defaultValue={addingUser.accountType}
          options={accTypeOption}
          onChange={(e, value) => handleDataChange("accountType", e, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t("administrative_console.user_page.account_type")}
              autoComplete="off"
            />
          )}
        />
      </Grid>
      {/* mobile */}
      <Grid item xs={12} md={6}>
        <MuiPhoneNumber
          fullWidth
          defaultCountry={countryCode ?? "hk"}
          variant={"outlined"}
          label={t("profile.mobile")}
          defaultValue={
            (addingUser?.mobile ?? "").includes("+")
              ? addingUser?.mobile ?? ""
              : phoneNumber(addingUser?.mobile)
          }
          onBlur={(e) => handleDataChange("mobile", e)}
        />
      </Grid>
      {/* title */}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          label={t("administrative_console.user_page.title")}
          autoComplete="off"
          value={addingUser.title}
          onChange={(e) => handleDataChange("title", e)}
        />
      </Grid>
      {/* office address */}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          label={t("profile.address_office")}
          autoComplete="off"
          value={addingUser.address_office}
          onChange={(e) => handleDataChange("address_office", e)}
        />
      </Grid>
      {/* module permission */}
      <Grid item xs={12}>
        <UserModulePermission
          userAccountType={addingUser.accountType}
          modulePermissions={addingUser.modulePermissions}
          onPermissionChange={handleUserPermissionChange}
        />
      </Grid>
    </Grid>
  );
});

export default UserAddEditor;
