import * as React from "react";
import { Grid, IconButton, Pagination, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const ControlsGroup = (props) => {
  const {
    name,
    value = null,
    columns,
    required = false,
    allowAddRemove = true,
    readonly = false,
    onChange = null,
    children,
  } = props;
  const [rows, setRows] = React.useState();
  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(5.0);
  const columnNames = React.useMemo(() => {
    return columns.map((c) => c.key);
  }, [columns]);
  const pageCount = React.useMemo(
    () => Math.max(Math.ceil((rows?.length ?? 0) / itemsPerPage), 1),
    [rows]
  );

  const generateRow = (rowIndex, row) => {
    const focusRow = (toRow) => {
      window.setTimeout(() => {
        const row = document.querySelector(
          `#${name}${toRow.id.replace("-", "")}`
        );

        row?.scrollIntoView();
      }, 10);
    };
    const createNewRow = () => {
      const newRow = {
        ...Object.fromEntries(columnNames.map((c) => [c, null])),
        id: uuidv4(),
      };

      return newRow;
    };
    const handleAddRowClick = (i) => {
      const newPage = Math.floor((i + 1) / itemsPerPage) + 1;
      const newRow = createNewRow();
      const newRows = rows.toSpliced(i + 1, 0, newRow);

      setRows(newRows);
      focusRow(newRow);

      if (page !== newPage) {
        setPage(newPage);
      }

      if (onChange) {
        onChange({
          target: {
            row: newRow,
            columnName: "",
            value: newRow,
            values: newRows,
          },
        });
      }
    };
    const handleDeleteRowClick = (rowIndex) => {
      const newRows =
        rows.length > 1
          ? rows.toSpliced(rowIndex, 1)
          : rows.toSpliced(rowIndex, 1, createNewRow());
      const newPage =
        Math.floor(Math.min(rowIndex, newRows.length - 1) / itemsPerPage) + 1;

      setRows(newRows);
      focusRow(
        rowIndex < newRows.length
          ? newRows[rowIndex]
          : newRows[newRows.length - 1]
      );

      if (page !== newPage) {
        setPage(newPage);
      }

      if (onChange) {
        onChange({
          target: {
            row: null,
            columnName: "",
            value: null,
            values: newRows,
          },
        });
      }
    };
    const handleValueChange = (e, row, columnName) => {
      const column = columns.find((c) => c.key === columnName);

      switch (column.type) {
        case "datetime":
          row[columnName] = e;
          break;
        default:
          row[columnName] = e.target.value;
          break;
      }

      setRows((prev) => [...prev]);

      if (onChange) {
        onChange({
          target: {
            row: rows.indexOf(row),
            columnName: columnName,
            value: row[columnName],
            values: rows,
          },
        });
      }
    };

    return (
      <Grid
        id={`${name}${row.id.replace("-", "")}`}
        container
        alignItems={"center"}
        columnGap={0.5}
        rowGap={1}
        columns={16}
      >
        <Grid item xs={16} sm="auto">
          <Typography textAlign={"right"} sx={{ width: "25px" }}>
            {rowIndex + 1}.
          </Typography>
        </Grid>
        {React.Children.map(children, (child, colIndex) => (
          <Grid item xs={16} sm key={`${row.id}_${colIndex}`}>
            {child.type.name === "NumericBox" &&
            !child.props.InputProps.readOnly
              ? React.cloneElement(child, {
                  defaultValue: row[columnNames[colIndex]],
                  onBlur: (e) => {
                    if (e.target.valueChanged) {
                      handleValueChange(e, row, columnNames[colIndex]);
                    }
                  },
                })
              : child.type.render?.name === "TextField" &&
                !child.props.InputProps.readOnly
              ? React.cloneElement(child, {
                  defaultValue: row[columnNames[colIndex]],
                  onBlur: (e) => {
                    if (e.target.oldValue !== e.target.value) {
                      handleValueChange(e, row, columnNames[colIndex]);
                    }
                  },
                })
              : React.cloneElement(child, {
                  value: row[columnNames[colIndex]],
                  onBlur: undefined,
                  onChange: (e) =>
                    handleValueChange(e, row, columnNames[colIndex]),
                })}
          </Grid>
        ))}
        {readonly || !allowAddRemove ? null : (
          <Grid item>
            <IconButton onClick={() => handleAddRowClick(rowIndex)}>
              <AddIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteRowClick(rowIndex)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  React.useEffect(() => {
    const pagination = document.querySelector(`#${name}_pagination`);

    pagination?.scrollIntoView(false);
  }, [page]);
  React.useEffect(() => {
    const oldValue = value?.length > 0 ? value : Array.from(new Array(1));
    const newValue = oldValue.map((r) => ({
      ...Object.fromEntries(columnNames.map((c) => [c, null])),
      id: uuidv4(),
      ...r,
    }));

    setRows(newValue);
  }, [value, columnNames]);

  return (
    <Grid container rowGap={1.5}>
      {rows?.map((row, i) => {
        if (i < page * itemsPerPage && i >= (page - 1) * itemsPerPage)
          return <React.Fragment key={i}>{generateRow(i, row)}</React.Fragment>;
      })}
      {pageCount > 1 ? (
        <Grid
          id={`${name}_pagination`}
          container
          item
          xs={12}
          justifyContent="flex-end"
        >
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

ControlsGroup.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  readonly: PropTypes.bool,
  required: PropTypes.bool,
};

export default ControlsGroup;
