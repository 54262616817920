import React, { useContext, createContext, useState } from "react";
import urlJoin from "url-join";
import { authServerBaseUrl } from "../Config";
import { CallApiWithContext } from "../helpers/ApiHelper";
import { MessageType } from "../Constants";

// type AccessTokenContext = [string, React.Dispatch<React.SetStateAction<string>>]

const AuthenticationProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState(MessageType.Error);
  const url = urlJoin(authServerBaseUrl, "/validate-token");
  const setIsAuthenticatedWithMessage = (
    isAuthen,
    message = null,
    messageType = MessageType.Error
  ) => {
    setIsAuthenticated(isAuthen);
    setMessage(message);
    setMessageType(messageType);
  };

  CallApiWithContext(url, [isAuthenticated, setIsAuthenticated])
    .then((response) => {
      setIsAuthenticated(response.isValid);

      // if (response.isValid) {
      //     setAccessToken(response.isValid);
      //     navigation.current("/");
      // }
    })
    .catch((error) => {
      setIsAuthenticated(false);
      console.log(error);
      // navigation.current("/");
    });

  return (
    <AuthenticationContext.Provider
      value={[
        isAuthenticated,
        setIsAuthenticatedWithMessage,
        message,
        messageType,
      ]}
      {...props}
    />
  );
};

const AuthenticationContext = createContext(null);

const useAuthentication = () => useContext(AuthenticationContext);

export { AuthenticationProvider, useAuthentication };
