import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import StorageIcon from "@mui/icons-material/Storage";
import PinIcon from "@mui/icons-material/Pin";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PasswordIcon from "@mui/icons-material/Password";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../../../Config";
import { useTranslation } from "react-i18next";
import urlJoin from "url-join";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LinkIcon from "@mui/icons-material/Link";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import SharedDialog from "../../../../shared/SharedDialog";
import { LoadingButton } from "@mui/lab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Add, Delete } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function EmailSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [loading, setLoading] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [senderProtocol, setSenderProtocol] = React.useState("smtp");
  const [recieverProtocol, setRecieverProtocol] = React.useState("imap");
  const [
    emailServerSenderCredentialSetting,
    setEmailSenderServerCredentialSetting,
  ] = React.useState({});
  const [
    emailReceiverServerCredentialSetting,
    setEmailReceiverServerCredentialSetting,
  ] = React.useState({});
  const [emailServerSenderSetting, setEmailServerSenderSetting] =
    React.useState({});
  const [emailServerReceiverSetting, setEmailServerReceiverSetting] =
    React.useState({});
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = React.useState(true);
  const [isSyncPreferenceToReceiver, setIsSyncPreferenceToReceiver] =
    React.useState(false);
  const [isSyncCredentialToReceiver, setIsSyncCredentialToReceiver] =
    React.useState(false);
  const [isSenderSettingTypeError, setIsSenderSettingTypeError] =
    React.useState({
      Host: false,
      Port: false,
      SenderAddress: false,
      HostPassword: false,
      URL: false,
      SenderName: false,
      HostPassword: false,
      TanentID: false,
      ClientID: false,
      AuthPassword: false,
      ClientSecrect: false,
      HostName: false,
      PortName: false,
    });
  const [isSenderCredentialTypeError, setIsSenderCredentialTypeError] =
    React.useState({
      userName: false,
      password: false,
    });
  const [isReceiverSettingTypeError, setIsReceiverSettingTypeError] =
    React.useState({
      Name: false,
      Address: false,
      Host: false,
      Port: false,
      ReceiverAddress: false,
      HostPassword: false,
      URL: false,
      ReceiverName: false,
      TanentID: false,
      ClientID: false,
      AuthPassword: false,
      ClientSecrect: false,
      HostName: false,
      CheckFrequency: false,
    });
  const [isReceiverCredentialTypeError, setIsReceiverCredentialTypeError] =
    React.useState({
      userName: false,
      password: false,
    });
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [response, setResponse] = React.useState();
  const [isUpdateSetting, setIsUpdateSetting] = React.useState();
  const [openAddPatternDialog, setOpenAddPatternDialog] = React.useState(false);
  const [select, setSelect] = React.useState({});
  const patternRef = React.useRef();
  const senderRadio = React.useRef();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    //Get Existing setting
    setLoading(true);
    setIsSubmitBtnDisabled(true);
    const url = urlJoin(resServerBaseUrl, "/Email/GetEmailPreference");
    CallApiWithContext(url, authenticationContext)
      .then((response) => {
        if (response.message !== "No setting") {
          setIsUpdateSetting(true);
          setResponse(response);

          setEmailReceiverServerCredentialSetting({
            userName:
              response.incomingCredentialName == ""
                ? ""
                : response.incomingCredentialName,
          });
          if (response?.incomingPreference) {
            setEmailServerReceiverSetting(
              JSON.parse(response.incomingPreference)
            );
          }
          if (response?.outgoingCredentialName != "") {
            setEmailSenderServerCredentialSetting({
              userName: response.outgoingCredentialName,
            });
          }

          setSenderProtocol(
            response.incomingPreferenceID === response.outgoingPreferenceID
              ? "ews_sender"
              : response.outgoingPreferenceType
              ? response.outgoingPreferenceType
              : "smtp"
          );
          setRecieverProtocol(
            response.incomingPreferenceID === response.outgoingPreferenceID
              ? "ews_receiver"
              : response.incomingPreferenceType
              ? response.incomingPreferenceType
              : "imap"
          );
          if (response?.outgoingPreference) {
            setEmailServerSenderSetting(
              JSON.parse(response?.outgoingPreference ?? "{}")
            );
          }

          setLoading(false);
          if (
            response.outgoingCredentialID != 0 &&
            response.incomingCredentialID != 0 &&
            response.outgoingCredentialID === response.incomingCredentialID
          ) {
            setIsSyncCredentialToReceiver(true);
          }
          if (response.outgoingPreferenceID === response.incomingPreferenceID) {
            setIsSyncPreferenceToReceiver(true);
          }
        } else if (response.message == "No setting") {
          setIsUpdateSetting(false);
          setLoading(false);
        }

        let checkObj = {};
        if (
          response.outgoingPreferenceID === response.incomingPreferenceID &&
          JSON.parse(response.outgoingPreference).ExcludeSubject
        ) {
          JSON.parse(response.outgoingPreference).ExcludeSubject.map(
            (subject) => {
              checkObj[subject] = false;
            }
          );
        } else {
          JSON.parse(response.incomingPreference).ExcludeSubject.map(
            (subject) => {
              checkObj[subject] = false;
            }
          );
        }
        setSelect(checkObj);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reload]);

  function handleSchemaChange() {
    let arr = emailServerReceiverSetting?.ExcludeSubject ?? [];
    arr.push(patternRef.current.value);

    if (isSyncPreferenceToReceiver) {
      setEmailServerSenderSetting({
        ...emailServerSenderSetting,
        ExcludeSubject: arr,
      });
    } else {
      setEmailServerReceiverSetting({
        ...emailServerReceiverSetting,
        ExcludeSubject: arr,
      });
    }

    patternRef.current.value = "";
    setIsSubmitBtnDisabled(false);
    setOpenAddPatternDialog(false);
  }

  function handlePatternDelete() {
    if (Object.values(select).includes(true)) {
      let originalArr = emailServerReceiverSetting.ExcludeSubject;
      let selectedArr = Object.keys(select).filter(
        (key) => select[key] === true
      );
      for (let x = 0; x < selectedArr.length; x++) {
        let item = "";
        item = originalArr.findIndex((element) => element === selectedArr[x]);
        originalArr.splice(item, 1);
      }

      if (isSyncPreferenceToReceiver) {
        setEmailServerSenderSetting({
          ...emailServerSenderSetting,
          ExcludeSubject: originalArr,
        });
      } else {
        setEmailServerReceiverSetting({
          ...emailServerReceiverSetting,
          ExcludeSubject: originalArr,
        });
      }

      setSelect(
        originalArr.map((element) => {
          return { [element]: false };
        })
      );
      console.log(originalArr);
      setIsSubmitBtnDisabled(false);
    }
  }

  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };

  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const EmailFormDataVariant = () => {
    if (isUpdateSetting) {
      return JSON.stringify({
        OutgoingPreferenceID: response.outgoingPreferenceID,
        OutgoingCredentialID: response.outgoingCredentialID,
        IncomingPreferenceID: response.incomingPreferenceID,
        IncomingCredentialID: response.incomingCredentialID,
        IsSyncCredential: isSyncCredentialToReceiver,
        IsSyncPreference: isSyncPreferenceToReceiver,
        SenderType: senderProtocol === "ews" ? "ews_sender" : senderProtocol,
        SenderUserName: emailServerSenderCredentialSetting.userName,
        SenderPassword: emailServerSenderCredentialSetting.password,
        SenderPreferences: JSON.stringify(emailServerSenderSetting),
        SenderPurpose: 1,
        ReceiverType:
          recieverProtocol === "ews" ? "ews_receiver" : recieverProtocol,
        ReceiverUserName: isSyncCredentialToReceiver
          ? emailServerSenderCredentialSetting.userName
          : emailReceiverServerCredentialSetting.userName,
        ReceiverPassword: isSyncCredentialToReceiver
          ? emailServerSenderCredentialSetting.password
          : emailReceiverServerCredentialSetting.password,
        ReceiverPreferences: isSyncPreferenceToReceiver
          ? JSON.stringify(emailServerSenderSetting)
          : JSON.stringify(emailServerReceiverSetting),
        RecevierPurpose: 4,
      });
    } else {
      return JSON.stringify({
        IsSyncCredential: isSyncCredentialToReceiver,
        IsSyncPreference: isSyncPreferenceToReceiver,
        SenderType: senderProtocol === "ews" ? "ews_sender" : senderProtocol,
        SenderUserName: emailServerSenderCredentialSetting.userName,
        SenderPassword: emailServerSenderCredentialSetting.password,
        SenderPreferences: JSON.stringify(emailServerSenderSetting),
        SenderPurpose: 1,
        ReceiverType:
          recieverProtocol === "ews" ? "ews_receiver" : recieverProtocol,
        ReceiverUserName: isSyncCredentialToReceiver
          ? emailServerSenderCredentialSetting.userName
          : emailReceiverServerCredentialSetting.userName,
        ReceiverPassword: isSyncCredentialToReceiver
          ? emailServerSenderCredentialSetting.password
          : emailReceiverServerCredentialSetting.password,
        ReceiverPreferences: isSyncPreferenceToReceiver
          ? emailServerSenderSetting == {}
            ? null
            : JSON.stringify(emailServerSenderSetting)
          : emailServerReceiverSetting == {}
          ? null
          : JSON.stringify(emailServerReceiverSetting),
        RecevierPurpose: 4,
      });
    }
  };

  const ApplyOnClick = () => {
    const url = urlJoin(
      resServerBaseUrl,
      isUpdateSetting === true
        ? "/Email/UpdateEmailPreference"
        : "/Email/InsertEmailPreference"
    );
    Object.keys(emailServerSenderSetting).forEach((u) => {
      if (emailServerSenderSetting[u] === "") {
        delete emailServerSenderSetting[u];
      }
    });
    Object.keys(emailServerReceiverSetting).forEach((u) => {
      if (emailServerReceiverSetting[u] === "") {
        delete emailServerReceiverSetting[u];
      }
    });

    CallApiWithContext(url, authenticationContext, EmailFormDataVariant())
      .then((response) => {
        if (response.message === "Email preference cannot update") {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: t("administrative_console.email_page.update_failed"),
            buttons: [
              {
                text: "Ok",
                action: () => {
                  closeAlertMessage();
                  setReload(!reload);
                  setBtnLoading(false);
                },
              },
            ],
          });
        } else if (
          response.message === "Email preference successfully uploaded"
        ) {
          showAlertMessage({
            title: t("administrative_console.email_page.applied"),
            content: t("administrative_console.email_page.upload_success"),
            onClose: setReload(!reload),
            buttons: [
              {
                text: "Ok",
                action: () => {
                  closeAlertMessage();
                  setReload(!reload);
                  setBtnLoading(false);
                },
              },
            ],
          });
        } else if (
          response.message === "Email preference successfully update"
        ) {
          showAlertMessage({
            title: t("administrative_console.email_page.applied"),
            content: t("administrative_console.email_page.update_success"),
            onClose: setReload(!reload),
            buttons: [
              {
                text: "Ok",
                action: () => {
                  closeAlertMessage();
                  setReload(!reload);
                  setBtnLoading(false);
                },
              },
            ],
          });
        } else if (
          response.message === "Please insert credential information"
        ) {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: t("administrative_console.email_page.update_failed"),
            onClose: setReload(!reload),
            buttons: [
              {
                text: "Ok",
                action: () => {
                  closeAlertMessage();
                  setReload(!reload);
                  setBtnLoading(false);
                },
              },
            ],
          });
        }
      })
      .catch((error) => {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: t("administrative_console.email_page.update_failed"),
          onClose: setReload(!reload),
          buttons: [
            {
              text: "Ok",
              action: () => {
                closeAlertMessage();
                setReload(!reload);
                setBtnLoading(false);
              },
            },
          ],
        });
      });
  };

  const handleSenderProtocolRadioChange = (event) => {
    setSenderProtocol(event.target.value);
    setEmailServerSenderSetting({});
    if (isSyncPreferenceToReceiver === true) {
      setIsSyncPreferenceToReceiver(false);
    }
    if (event.target.value == "smtp") {
      setIsSyncCredentialToReceiver(false);
    }
  };

  const handleReceiverProtocolRadioChange = (event) => {
    setRecieverProtocol(event.target.value);
    setEmailServerReceiverSetting({});
    if (isSyncPreferenceToReceiver === true) {
      setIsSyncPreferenceToReceiver(false);
    }
  };

  const UserSettingSkeleton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "8px 8px 8px",
        }}
      >
        <Skeleton width="100%" height={48}></Skeleton>
      </Box>
    );
  };

  const buttonDisableToggle = (list) => {
    if (Object.values(list).includes(true)) {
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false);
    }
  };

  const handleSenderSettingsChange = (prop) => (event) => {
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let numberRegex = /^\+?[0-9()-]+$/;
    let webRegex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    setIsSubmitBtnDisabled(false);
    if (prop === "userName" || prop === "password") {
      setEmailSenderServerCredentialSetting({
        ...emailServerSenderCredentialSetting,
        [prop]: event.target.value,
      });
    } else if (prop === "SSL") {
      if (Object.keys(emailServerSenderSetting).length > 1) {
        setEmailServerSenderSetting({
          ...emailServerSenderSetting,
          [prop]: event.target.checked,
        });
      } else {
        if (event.target.checked) {
          setEmailServerSenderSetting({
            ...emailServerSenderSetting,
            [prop]: event.target.checked,
          });
        } else {
          delete emailServerSenderSetting.SSL;
        }
      }
    } else {
      setEmailServerSenderSetting({
        ...emailServerSenderSetting,
        [prop]: event.target.value,
      });
    }

    if (
      (prop === "Port" && !numberRegex.test(event.target.value)) ||
      (prop === "Address" && !emailRegex.test(event.target.value)) ||
      (prop === "URL" &&
        !webRegex.test(event.target.value) &&
        senderProtocol != "smtp")
    ) {
      setIsSenderSettingTypeError({
        ...isSenderSettingTypeError,
        [prop]: true,
      });
    } else {
      setIsSenderSettingTypeError({
        ...isSenderSettingTypeError,
        [prop]: false,
      });
    }

    buttonDisableToggle(isSenderSettingTypeError);
  };

  const handleReceiverSettingsChange = (prop) => (event) => {
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let numberRegex = /[+-]?([0-9]*[.])?[0-9]+/g;
    let webRegex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    setIsSubmitBtnDisabled(false);
    if (prop === "userName" || prop === "password") {
      setEmailReceiverServerCredentialSetting({
        ...emailReceiverServerCredentialSetting,
        [prop]: event.target.value,
      });
      setIsSubmitBtnDisabled(false);
    } else if (prop === "SSL") {
      if (Object.keys(emailServerReceiverSetting).length > 1) {
        setEmailServerReceiverSetting({
          ...emailServerReceiverSetting,
          [prop]: event.target.checked,
        });
      } else {
        if (event.target.checked) {
          setEmailServerReceiverSetting({
            ...emailServerReceiverSetting,
            [prop]: event.target.checked,
          });
        } else {
          delete emailServerReceiverSetting.SSL;
        }
      }
    } else {
      setEmailServerReceiverSetting({
        ...emailServerReceiverSetting,
        [prop]: event.target.value,
      });
    }

    if (
      (prop === "Port" && !numberRegex.test(event.target.value)) ||
      (prop === "CheckFrequency" &&
        (event.target.value < 0.5 ||
          event.target.value > 120 ||
          !numberRegex.test(event.target.value))) ||
      (prop === "Address" && !emailRegex.test(event.target.value)) ||
      (prop === "URL" && !webRegex.test(event.target.value))
    ) {
      setIsSubmitBtnDisabled(true);
      setIsReceiverSettingTypeError({
        ...isReceiverSettingTypeError,
        [prop]: true,
      });
    } else {
      setIsSubmitBtnDisabled(false);
      setIsReceiverSettingTypeError({
        ...isReceiverSettingTypeError,
        [prop]: false,
      });
    }

    buttonDisableToggle(isReceiverSettingTypeError);
  };

  const handleSyncPreferenceToReceiverChange = () => {
    setIsSyncPreferenceToReceiver(!isSyncPreferenceToReceiver);
    setEmailServerReceiverSetting(emailServerSenderSetting);
    setIsSubmitBtnDisabled(!isSubmitBtnDisabled);
  };

  const handleSyncCredentialToReceiverChange = () => {
    setIsSyncCredentialToReceiver(!isSyncCredentialToReceiver);
    setEmailReceiverServerCredentialSetting(emailServerSenderCredentialSetting);
    setIsSubmitBtnDisabled(!isSubmitBtnDisabled);
    if (
      response !== undefined &&
      response.outgoingCredentialID == response.incomingCredentialID &&
      isSyncCredentialToReceiver == true
    ) {
      setIsReceiverCredentialTypeError({
        ...isReceiverCredentialTypeError,
        password: true,
      });
    }
  };

  return (
    <Grid container sx={{ p: 1 }}>
      <Grid
        item
        container
        sx={{
          textAlign: "start",
        }}
        key={"senderForm"}
      >
        <Grid item container justifyContent={"space-between"}>
          <Grid item>
            <FormControl>
              <FormLabel id="mail-server">
                {t("administrative_console.email_page.choose_outgoing")}
              </FormLabel>
              <RadioGroup
                ref={senderRadio}
                value={senderProtocol ? senderProtocol : "smtp"}
                row
                aria-labelledby="mail-server"
                name="senderinfo"
                onChange={handleSenderProtocolRadioChange}
              >
                <FormControlLabel
                  disabled={loading}
                  value="smtp"
                  control={<Radio />}
                  label="SMTP"
                />
                <FormControlLabel
                  disabled={loading}
                  value="ews_sender"
                  control={<Radio />}
                  label="EWS"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            {t("administrative_console.email_page.credential_setting")}
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                disabled={loading || senderProtocol == "smtp"}
                checked={isSyncCredentialToReceiver}
                control={<Checkbox />}
                label={t(
                  "administrative_console.email_page.apply_credential_setting_to_receiver"
                )}
                onChange={handleSyncCredentialToReceiverChange}
                sx={{ px: 1, py: 1 }}
              />
            </FormGroup>
          </Grid>
          {loading ? (
            <Grid item container>
              <Grid item xs={6} p={1}>
                <Skeleton height={48}></Skeleton>
              </Grid>
              <Grid item xs={6} p={1}>
                <Skeleton height={48}></Skeleton>
              </Grid>
            </Grid>
          ) : (
            <Grid item container pt={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="userName_sender"
                  label={t("administrative_console.email_page.userName")}
                  required
                  onChange={handleSenderSettingsChange("userName")}
                  value={emailServerSenderCredentialSetting.userName}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={isSenderCredentialTypeError.userName}
                  sx={{ px: 1, py: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="password_sender"
                  label={t("administrative_console.email_page.password")}
                  defaultValue={isUpdateSetting ? "xxxx" : ""}
                  value={emailServerSenderCredentialSetting.password}
                  required
                  onChange={handleSenderSettingsChange("password")}
                  onClick={(event) => {
                    if (event.target.defaultValue == "xxxx") {
                      event.target.value = "";
                    }
                  }}
                  fullWidth
                  type="password"
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={isSenderCredentialTypeError.password}
                  sx={{ px: 1, py: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    disabled={
                      senderProtocol === "ews_sender" &&
                      recieverProtocol === "ews_receiver"
                        ? false
                        : true
                    }
                    checked={isSyncPreferenceToReceiver}
                    control={<Checkbox />}
                    label={t(
                      "administrative_console.email_page.apply_setting_to_receiver"
                    )}
                    onChange={handleSyncPreferenceToReceiverChange}
                    sx={{ px: 1, py: 1 }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Grid container sx={{ textAlign: "center" }} direction="row">
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={1} md={1}>
              <UserSettingSkeleton />
            </Grid>
            <Grid container direction="column">
              <UserSettingSkeleton />
            </Grid>
          </Grid>
        ) : senderProtocol === "smtp" ? (
          <>
            <Grid container>
              <Grid item container>
                <Grid item>
                  <Typography variant="h5">SMTP</Typography>
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item container direction="row">
                  {[
                    { type: "Name", icon: <PersonIcon /> },
                    { type: "Address", icon: <ContactMailIcon /> },
                    { type: "Host", icon: <StorageIcon />, dataType: "string" },
                    { type: "Port", icon: <PinIcon />, dataType: "string" },
                  ].map((k, i) => (
                    <Grid item xs={12} sm={6} key={k.type + "smtp_sender"}>
                      <TextField
                        label={t(
                          `administrative_console.email_page.${k.type.toLowerCase()}`
                        )}
                        required
                        onChange={handleSenderSettingsChange(k.type)}
                        value={emailServerSenderSetting?.[k.type] ?? ""}
                        fullWidth
                        variant="standard"
                        type={k.type.includes("Password") ? "password" : null}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {k.icon}
                            </InputAdornment>
                          ),
                        }}
                        error={isSenderSettingTypeError?.[k.type]}
                        sx={{ px: 1, py: 1 }}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <FormGroup key={"sender_SSL"}>
                      <FormControlLabel
                        checked={emailServerSenderSetting?.SSL ?? false}
                        control={<Checkbox />}
                        label={t("administrative_console.email_page.ssl")}
                        onChange={handleSenderSettingsChange("SSL")}
                        sx={{ px: 1, py: 1 }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container>
            <Grid container item xs={12}>
              <Grid item>
                <Typography variant="h5">EWS</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item>
                <Grid item container direction="row">
                  {[
                    {
                      type: "Name",
                      icon: <DriveFileRenameOutlineIcon />,
                    },
                    { type: "Address", icon: <ContactMailIcon /> },
                    { type: "URL", icon: <LinkIcon /> },
                    { type: "ClientID", icon: <ContactPageOutlinedIcon /> },
                    { type: "ClientSecrect", icon: <LockPersonIcon /> },
                    { type: "TanentID", icon: <AccountCircleIcon /> },
                  ].map((k, i) => (
                    <Grid item xs={12} sm={6} key={k.type + "ewsSender"}>
                      <TextField
                        label={t(
                          `administrative_console.email_page.${k.type.toLowerCase()}`
                        )}
                        required
                        onChange={handleSenderSettingsChange(k.type)}
                        value={emailServerSenderSetting?.[k.type] ?? ""}
                        type={k.type.includes("Secrect") ? "password" : null}
                        fullWidth
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {k.icon}
                            </InputAdornment>
                          ),
                        }}
                        sx={{ px: 1, py: 1 }}
                        error={isSenderSettingTypeError?.[k.type]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        container
        sx={{
          textAlign: "start",
        }}
        key={"receiverForm"}
      >
        <Grid item>
          <FormControl>
            <FormLabel id="mail-server">
              {t("administrative_console.email_page.choose_incoming")}
            </FormLabel>
            <RadioGroup
              value={recieverProtocol ? recieverProtocol : "imap"}
              row
              aria-labelledby="mail-server"
              name="receiverInfo"
              onChange={handleReceiverProtocolRadioChange}
            >
              <FormControlLabel
                disabled={loading}
                value="imap"
                control={<Radio />}
                label="IMAP"
              />
              <FormControlLabel
                disabled={loading}
                value="ews_receiver"
                control={<Radio />}
                label="EWS"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {loading ? (
          <Grid item container>
            <Grid item xs={6} p={1}>
              <Skeleton height={48}></Skeleton>
            </Grid>
            <Grid item xs={6} p={1}>
              <Skeleton height={48}></Skeleton>
            </Grid>
          </Grid>
        ) : (
          <Grid item container>
            <Grid item>
              {t("administrative_console.email_page.credential_setting")}
            </Grid>
            <Grid item container pt={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={isSyncCredentialToReceiver ? true : false}
                  id="userName_receiver"
                  label={t("administrative_console.email_page.userName")}
                  required
                  onChange={
                    isSyncCredentialToReceiver
                      ? handleSenderSettingsChange("userName")
                      : handleReceiverSettingsChange("userName")
                  }
                  value={
                    isSyncCredentialToReceiver
                      ? emailServerSenderCredentialSetting.userName
                      : emailReceiverServerCredentialSetting.userName
                  }
                  error={isReceiverCredentialTypeError.userName}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ px: 1, py: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="password_receiver"
                  defaultValue={isUpdateSetting ? "xxxx" : ""}
                  disabled={isSyncCredentialToReceiver ? true : false}
                  label={t("administrative_console.email_page.password")}
                  error={isReceiverCredentialTypeError.password}
                  value={
                    isSyncCredentialToReceiver
                      ? emailServerSenderCredentialSetting.password
                      : emailReceiverServerCredentialSetting.password
                  }
                  required
                  onClick={(event) => {
                    if (event.target.value === "xxxx") {
                      event.target.value = "";
                    }
                  }}
                  onChange={
                    isSyncCredentialToReceiver
                      ? handleSenderSettingsChange("password")
                      : handleReceiverSettingsChange("password")
                  }
                  helperText={
                    isReceiverCredentialTypeError.password
                      ? t("profile.please_fill_in_this_field")
                      : ""
                  }
                  fullWidth
                  type="password"
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ px: 1, py: 1 }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {loading ? (
          <Grid container sx={{ textAlign: "center" }} direction="row">
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={6} md={6}>
              <UserSettingSkeleton />
            </Grid>
            <Grid item sm={6} xs={6} lg={1} md={1}>
              <UserSettingSkeleton />
            </Grid>
            <Grid container direction="column">
              <UserSettingSkeleton />
            </Grid>
          </Grid>
        ) : recieverProtocol === "imap" ? (
          <>
            <Grid container>
              <Grid item container>
                <Grid item>
                  <Typography variant="h5">IMAP</Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item container direction="row">
                  {[
                    { type: "Host", icon: <StorageIcon /> },
                    { type: "Port", icon: <PinIcon /> },
                  ].map((k, i) => (
                    <Grid item xs={12} sm={6} key={k.type + "_imapReceiver"}>
                      <TextField
                        label={t(
                          `administrative_console.email_page.${k.type.toLowerCase()}`
                        )}
                        required
                        type={k.type.includes("Password") ? "password" : null}
                        onChange={
                          isSyncPreferenceToReceiver
                            ? handleSenderSettingsChange(k.type)
                            : handleReceiverSettingsChange(k.type)
                        }
                        value={
                          isSyncPreferenceToReceiver
                            ? emailServerSenderSetting?.[k.type] ?? ""
                            : emailServerReceiverSetting?.[k.type] ?? ""
                        }
                        fullWidth
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {k.icon}
                            </InputAdornment>
                          ),
                        }}
                        sx={{ px: 1, py: 1 }}
                        error={isReceiverSettingTypeError?.[k.type]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={"CheckFrequency"}
                  label={t("administrative_console.email_page.check_frequency")}
                  type="number"
                  onChange={
                    isSyncPreferenceToReceiver
                      ? handleSenderSettingsChange("CheckFrequency")
                      : handleReceiverSettingsChange("CheckFrequency")
                  }
                  value={
                    isSyncPreferenceToReceiver
                      ? emailServerSenderSetting?.CheckFrequency ?? ""
                      : emailServerReceiverSetting?.CheckFrequency ?? ""
                  }
                  fullWidth
                  variant="standard"
                  inputProps={{
                    min: 0.5,
                    max: 120,
                    step: "1",
                    inputMode: "decimal",
                  }}
                  helperText={
                    isReceiverSettingTypeError?.CheckFrequency == true
                      ? t(
                          "administrative_console.email_page.check_frequency_range"
                        )
                      : ""
                  }
                  sx={{ px: 1, py: 1 }}
                  error={isReceiverSettingTypeError?.CheckFrequency}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <FormGroup key={"receiver_SSL"}>
                  <FormControlLabel
                    checked={
                      isSyncPreferenceToReceiver
                        ? emailServerSenderSetting?.SSL ?? false
                        : emailServerReceiverSetting?.SSL ?? false
                    }
                    control={<Checkbox />}
                    label={t("administrative_console.email_page.ssl")}
                    onChange={handleReceiverSettingsChange("SSL")}
                    sx={{ px: 1, py: 1 }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container>
            <Grid container item xs={12}>
              <Grid item>
                <Typography variant="h5">EWS</Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item container direction="row">
                {[
                  {
                    type: "Name",
                    icon: <DriveFileRenameOutlineIcon />,
                  },
                  { type: "Address", icon: <ContactMailIcon /> },
                  { type: "URL", icon: <LinkIcon /> },
                  { type: "ClientID", icon: <ContactPageOutlinedIcon /> },
                  { type: "ClientSecrect", icon: <LockPersonIcon /> },
                  { type: "TanentID", icon: <AccountCircleIcon /> },
                ].map((k, i) => (
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    lg={6}
                    md={6}
                    key={k.type + "ewsReceiver"}
                  >
                    <TextField
                      disabled={isSyncPreferenceToReceiver ? true : false}
                      label={t(
                        `administrative_console.email_page.${k.type.toLowerCase()}`
                      )}
                      required
                      onChange={
                        isSyncPreferenceToReceiver
                          ? handleSenderSettingsChange(k.type)
                          : handleReceiverSettingsChange(k.type)
                      }
                      value={
                        isSyncPreferenceToReceiver
                          ? emailServerSenderSetting?.[k.type] ?? ""
                          : emailServerReceiverSetting?.[k.type] ?? ""
                      }
                      fullWidth
                      variant="standard"
                      type={k.type.includes("Secrect") ? "password" : null}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {k.icon}
                          </InputAdornment>
                        ),
                      }}
                      error={isReceiverSettingTypeError?.[k.type]}
                      sx={{ px: 1, py: 1 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        {loading ? (
          <Skeleton>
            <Grid container>
              <Grid container item xs={12} sm={6}>
                <Grid
                  xs={12}
                  container
                  item
                  alignItems={"center"}
                  justifyItems={"space-between"}
                  direction={"row"}
                >
                  <Grid item xs={6}>
                    {t("administrative_console.email_page.exclude_subject")}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checked"></TableCell>
                          <TableCell>
                            {t("administrative_console.email_page.pattern")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Skeleton>
        ) : (
          <Grid container>
            <Grid container item xs={12} sm={6}>
              <Grid
                xs={12}
                container
                item
                alignItems={"center"}
                justifyItems={"space-between"}
                direction={"row"}
              >
                <Grid item xs={6}>
                  {t("administrative_console.email_page.exclude_subject")}
                </Grid>

                <Grid container item xs={6}>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setOpenAddPatternDialog(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => handlePatternDelete()}
                      disabled={!Object.values(select).includes(true)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checked"></TableCell>
                        <TableCell>
                          {t("administrative_console.email_page.pattern")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isSyncPreferenceToReceiver
                        ? emailServerSenderSetting?.ExcludeSubject ?? false
                          ? emailServerSenderSetting.ExcludeSubject.map(
                              (subject, index) => (
                                <TableRow key={index}>
                                  <TableCell padding="checked">
                                    <Checkbox
                                      variant="primary"
                                      checked={select[subject]}
                                      onChange={() => {
                                        setSelect({
                                          ...select,
                                          [subject]: !select[subject],
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>{subject}</TableCell>
                                </TableRow>
                              )
                            )
                          : null
                        : emailServerReceiverSetting?.ExcludeSubject ?? false
                        ? emailServerReceiverSetting.ExcludeSubject.map(
                            (subject, index) => (
                              <TableRow key={index}>
                                <TableCell padding="checked">
                                  <Checkbox
                                    variant="primary"
                                    checked={select[subject]}
                                    onChange={() => {
                                      setSelect({
                                        ...select,
                                        [subject]: !select[subject],
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell>{subject}</TableCell>
                              </TableRow>
                            )
                          )
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container direction="column">
          <LoadingButton
            loading={btnLoading}
            disabled={isSubmitBtnDisabled}
            type="btn1"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => {
              setBtnLoading(true);
              ApplyOnClick();
            }}
          >
            {t("administrative_console.email_page.apply")}
          </LoadingButton>
        </Grid>
      </Grid>
      <Dialog
        open={openAddPatternDialog}
        onClose={() => setOpenAddPatternDialog(false)}
        fullScreen={isSmallDevice}
      >
        <DialogTitle>
          {t("administrative_console.email_page.add_pattern")}
        </DialogTitle>
        <DialogContent>
          <Grid container pt={1}>
            <Grid item xs={12}>
              <TextField
                inputRef={patternRef}
                fullWidth={isSmallDevice}
                label={t("administrative_console.email_page.pattern")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSchemaChange()} variant={"contained"}>
            {t("formruntime.submit")}
          </Button>
          <Button
            onClick={() => {
              setOpenAddPatternDialog(false);
            }}
            color="error"
          >
            {t("formruntime.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
}
