import { initializeApp } from "firebase/app";
import { CallApiWithContext } from "./helpers/ApiHelper";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { resServerBaseUrl, firebaseConfig } from "./Config";
import urlJoin from "url-join";

const PUBLIC_KEY =
  "BBjYwiKQWws46OeW1mcVhxcSWy5eW8oUGvvFC4S4iiEkb0Zu67Nj-yBwqu4wAZ8KYkv7pVpnnwJAAw-SuQi7T5g";

export function InitServiceWorker(authenticationContext) {
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  navigator.serviceWorker
    .register(document.location.origin + "/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
      getToken(messaging, { vapidKey: PUBLIC_KEY })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            saveSubscription(currentToken, authenticationContext);
            receiveMsg();
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

function saveSubscription(subscription, authenticationContext) {
  let deviceType = "desktop";
  let provider = "fcm";
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  const url = urlJoin(resServerBaseUrl, "/PushNotification/RegisterToken");
  CallApiWithContext(
    url,
    authenticationContext,
    JSON.stringify({
      DeviceType: deviceType,
      Provider: provider,
      Token: subscription,
    })
  )
    .then((respon) => {
      console.log(respon);
    })
    .catch(() => {
      console.log("save to server fail");
    });
}

function receiveMsg() {
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  onMessage(messaging, (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };

    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      const redirectLink = `${document.location.origin}/case/${payload.data.CaseSID}/${payload.data.PlanItemSID}`;
      //open case after new case initiate
      notification.onclick = function (event) {
        event.preventDefault();
        console.log(redirectLink);
        window.open(redirectLink, "_blank");
      };
    }

    console.log("Message received. ", payload);
  });
}

function deleteSubscription(subscription, authenticationContext) {
  let deviceType = "desktop";
  let provider = "fcm";

  const url = urlJoin(resServerBaseUrl, "/PushNotification/Unregister");
  CallApiWithContext(
    url,
    authenticationContext,
    JSON.stringify({
      DeviceType: deviceType,
      Provider: provider,
      Token: subscription,
    })
  )
    .then((respon) => {
      console.log(respon);
    })
    .then(() => {
      console.log("save to server fail");
    });
}
