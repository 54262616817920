import React, { createContext, useContext } from "react";
import useGeoLocation from "react-ipgeolocation";

const CountryProvider = (props) => {
  const [countryCode, setCountryCode] = React.useState();
  const location = useGeoLocation();

  React.useEffect(() => {
    if (location.country) setCountryCode(() => location.country.toLowerCase());
  }, [location.country]);

  return <Context.Provider value={[countryCode]} {...props} />;
};

const Context = createContext(null);

const useCountry = () => useContext(Context);

export { CountryProvider, useCountry };
