import * as React from "react";
import urlJoin from "url-join";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import { resServerBaseUrl } from "../../../../Config";
import { useTranslation } from "react-i18next";
import SharedDialog from "../../../../shared/SharedDialog";

//try add
import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { GifOutlined, GifRounded } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
//

export default function CaseSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [caseTemplates, setCaseTemplates] = React.useState(null);
  const [caseTemplateSecurities, setCaseTemplateSecurities] =
    React.useState(null);
  const [filteredCaseTemplates, setFilteredCaseTemplateSecurities] =
    React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [type, setType] = React.useState(-2);
  const [typeID, setTypeID] = React.useState(null);
  const [isLoadingTypeName, setIsLoadingTypeName] = React.useState(true);
  const [isloadingAddsave, setisloadingAddsave] = React.useState(false);
  const [disableSave, setdisableSave] = React.useState(true);
  const [isTemplateNull, setIsTemplateNull] = React.useState(true);
  var test = [];
  const typeList = ["All", "Position", "Group"];
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  //trial add case tpl attachment security
  const GUIDpattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const [theSelectedCaseTpl, setTheSelectedCaseTpl] = React.useState(null);
  const [isLoadingPage, setIsLoadingPage] = React.useState(false);
  const [
    isLoadingGetCaseAttachmentSecurity,
    setIsLoadingGetCaseAttachmentSecurity,
  ] = React.useState(false);
  const [
    isLoadingRecipientMatrixColumnTable,
    setIsLoadingRecipientMatrixColumnTable,
  ] = React.useState(false);
  const [isNoRecipientMatrixColumnRecord, setIsNoRecipientMatrixColumnRecord] =
    React.useState(false);
  const [isLoadingAttachmentSecuritySave, setIsLoadingAttachmentSecuritySave] =
    React.useState(false);
  const [openAddAttachmentSecurityDialog, setOpenAddAttachmentSecurityDialog] =
    React.useState(false);
  const [noAttachmentSecurity, setNoAttachmentSecurity] = React.useState(true);
  const [caseTemplatesAttachmentSecurity, setCaseTemplatesAttachmentSecurity] =
    React.useState({
      canAdd: false,
      canDownload: false,
      canDelete: false,
    });
  const caseAttachmentSecurityHeader = [
    t("UserID"),
    t("Add"),
    t("Download"),
    t("Delete"),
  ];
  const [caseAttachmentSecurity, setCaseAttachmentSecurity] =
    React.useState(null);
  const [caseAttachmentSecurityTableData, setCaseAttachmentSecurityTableData] =
    React.useState(null);
  const createCaseAttachmentSecurityDataByElement = (element) => {
    return {
      tplID: element.tplID,
      caseTplUUID: element.caseTplUUID,
      userID: element.userID,
      add: element.add,
      download: element.download,
      fullControl: element.fullControl,
    };
  };
  const targetOption = ["user", "group", "recipientMatrixColumn"];
  const [userOption, setUserOption] = React.useState([]);
  const [groupOption, setGroupOption] = React.useState([]);
  const [recipientMatrixColumnOption, setRecipientMatrixColumnOption] =
    React.useState([]);
  const [theSelectedId, setTheSelectedId] = React.useState({
    userID: null,
    groupID: null,
  });
  const [
    theSelectedRecipientMatrixColumn,
    setTheSelectedRecipientMatrixColumn,
  ] = React.useState(null);
  const [theSelectedAttrToSetSecurity, setTheSelectedAttrToSetSecurity] =
    React.useState({ user: false, group: false, recipientMatrixColumn: false });
  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const handleOpenAddAttachmentSecurityDialog = (event) => {
    setCaseTemplatesAttachmentSecurity({
      canAdd: false,
      canDownload: false,
      canDelete: false,
    });
    setTheSelectedAttrToSetSecurity({
      user: false,
      group: false,
      recipientMatrixColumn: false,
    });
    setTheSelectedId({ userID: null, groupID: null });
    setTheSelectedRecipientMatrixColumn(null);
    if (theSelectedCaseTpl == null || theSelectedCaseTpl.id == -1) {
      setAlertDialog(true);
      setAlertContent(
        t(
          "administrative_console.case_page.cannot_add_caseAttachmentSecurity_for_all_templates"
        )
      );
      setAlertTitle(t("profile.proxy_and_delegation.error"));
    } else {
      setOpenAddAttachmentSecurityDialog(true);
    }
  };
  const handleCloseAddAttachmentSecurityDialog = (event) => {
    setOpenAddAttachmentSecurityDialog(false);
  };
  const handleChooseAttachmentSecurity = (event, target) => {
    setCaseTemplatesAttachmentSecurity({
      ...caseTemplatesAttachmentSecurity,
      [target]: event.target.checked,
    });
  };
  const handleAddAttachmentSecurityDialogSave = (event) => {
    setIsLoadingAttachmentSecuritySave(true);

    var url = urlJoin(
      resServerBaseUrl,
      "/Case/AddCaseTemplate_AttachmentSecurity"
    );
    var data = {
      UserID: theSelectedId.userID,
      TplID: theSelectedCaseTpl.id,
      GroupID: theSelectedId.groupID,
      CaseTplUUID:
        theSelectedCaseTpl == null || theSelectedCaseTpl.uuid == -1
          ? "00000000-0000-0000-0000-000000000000"
          : theSelectedCaseTpl.uuid,
      RecipientMatrixColumn: JSON.stringify(theSelectedRecipientMatrixColumn),
      FullControl: caseTemplatesAttachmentSecurity.canAdd
        ? caseTemplatesAttachmentSecurity.canDownload
          ? caseTemplatesAttachmentSecurity.canDelete
            ? true
            : false
          : false
        : false,
      Add: caseTemplatesAttachmentSecurity.canAdd,
      Download: caseTemplatesAttachmentSecurity.canDownload,
    };
    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (response.message) {
          setAlertDialog(true);
          setAlertContent(response.message);
          setAlertTitle("");
        } else {
          setAlertDialog(true);
          setAlertContent(response);
          setAlertTitle("");
        }
        setIsLoadingAttachmentSecuritySave(false);
        setOpenAddAttachmentSecurityDialog(false);
      })
      .catch((error) => {
        console.log(error);
        setAlertDialog(true);
        setAlertContent(error);
        setAlertTitle(t("profile.proxy_and_delegation.error"));
        setIsLoadingAttachmentSecuritySave(false);
        setOpenAddAttachmentSecurityDialog(false);
      });
  };
  const handleSelectionOnChange = (event, newValue, type) => {
    if (type == "TargetOption") {
      setTheSelectedAttrToSetSecurity({ [event.target.textContent]: true });
    }
    if (type == "UserID") {
      setTheSelectedId({ ...theSelectedId, userID: parseInt(newValue.id) });
    } else if (type == "GroupID") {
      setTheSelectedId({ ...theSelectedId, groupID: parseInt(newValue.id) });
    } else if (type == "RecipientMatrixColumn") {
      let recipientMatrixColumnArray =
        theSelectedRecipientMatrixColumn == null
          ? new Array()
          : theSelectedRecipientMatrixColumn;
      recipientMatrixColumnArray.push(newValue);
      setTheSelectedRecipientMatrixColumn(recipientMatrixColumnArray);
      //setTheSelectedRecipientMatrixColumn({ ...theSelectedRecipientMatrixColumn, newValue });
    }
  };
  //end of trial

  React.useEffect(() => {
    setIsLoadingPage(true);
    var url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates");
    const data = JSON.stringify({ IsAdministrator: true });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        console.log(response);
        if (response) {
          if (response.message != "Cannot Get Case Template") {
            var url_caseTplpPrmission = urlJoin(
              resServerBaseUrl,
              "/Permission/GetCaseTemplatePermissions"
            );

            CallApiWithContext(
              url_caseTplpPrmission,
              authenticationContext
            ).then((permissionResponse) => {
              var filteredResponses = [];
              response.forEach((element) => {
                var filteredTemplates = element.templateInfos.filter((tInfo) =>
                  permissionResponse.some(
                    (permission) =>
                      permission.tplSID === tInfo.sid &&
                      (permission.permissionType === 1 ||
                        permission.permissionType === 2)
                  )
                );
                if (filteredTemplates && filteredTemplates.length > 0) {
                  var toAddResponse = element;
                  toAddResponse.templateInfos = filteredTemplates;
                  filteredResponses.push(toAddResponse);
                }
              });
              setCaseTemplates(filteredResponses);
            });
          } else {
            setAlertDialog(true);
            if (response.message == "Cannot Get Case Template") {
              setAlertContent(
                t("administrative_console.case_page.cannot_get_case_template")
              );
            } else if (
              response.message == "No permission to get running case"
            ) {
              setAlertContent(
                t(
                  "administrative_console.case_page.no_permission_to_get_running_case"
                )
              );
            }
            setAlertTitle("");
            setCaseTemplates(null);
          }
        }
      })
      .catch((error) => {
        setIsLoadingPage(false);
        console.log(error);
      });
    url = urlJoin(resServerBaseUrl, "/Case/GetAllCaseTemplateSecurity");
    CallApiWithContext(url, authenticationContext, null)
      .then((response) => {
        console.log(response);
        if (response) {
          setCaseTemplateSecurities(response);
          setFilteredCaseTemplateSecurities(response);
          //setIsLoadingPage(false);
        }
      })
      .catch((error) => {
        setIsLoadingPage(false);
        console.log(error);
      });
    url = urlJoin(resServerBaseUrl, "/User/GetAllUsername");
    CallApiWithContext(url, authenticationContext, null)
      .then((response) => {
        if (response) {
          let userArray = new Array();
          Object.keys(response)
            .map((key) => {
              return { id: key, label: response[key] };
            })
            .forEach((element) => {
              if (
                !GUIDpattern.test(element.label) &&
                element.label != "00000000-0000-0000-0000-000000000000"
              ) {
                userArray.push(element);
              }
            });
          setUserOption(userArray);
          //setIsLoadingPage(false);
        }
      })
      .catch((error) => {
        setIsLoadingPage(false);
        console.log(error);
      });
    url = urlJoin(resServerBaseUrl, "/User/GetAllGroups");
    CallApiWithContext(url, authenticationContext, null)
      .then((response) => {
        if (response) {
          let groupArray = new Array();
          Object.keys(response)
            .map((key) => {
              return { id: key, label: response[key] };
            })
            .forEach((element) => {
              groupArray.push(element);
            });
          setGroupOption(groupArray);
          setIsLoadingPage(false);
        }
      })
      .catch((error) => {
        setIsLoadingPage(false);
        console.log(error);
      });
    //call API for getting all attachment security
    //getAllCaseAttachmentSecurity()
    setIsLoadingGetCaseAttachmentSecurity(true);
    url = urlJoin(resServerBaseUrl, "/Case/GetAllCaseAttachmentSecurity");
    CallApiWithContext(url, authenticationContext, null)
      .then((response) => {
        if (response) {
          const securityList = new Array();
          response.forEach((element) => {
            securityList.push(
              createCaseAttachmentSecurityDataByElement(element)
            );
          });
          setCaseAttachmentSecurity(securityList);
          setCaseAttachmentSecurityTableData(securityList);
          setIsLoadingGetCaseAttachmentSecurity(false);
        } else {
          setAlertDialog(true);
          setAlertContent(
            "Cannot get response of getting all CaseAttachmentSecurity"
          );
          setAlertTitle("Error");
        }
      })
      .catch((error) => {
        setIsLoadingGetCaseAttachmentSecurity(false);
        console.log(error);
      });
  }, [authenticationContext]);

  React.useEffect(() => {
    if (filteredCaseTemplates) {
      if (filteredCaseTemplates.length !== 0) {
        setIsTemplateNull(false);
      } else {
        setIsTemplateNull(true);
      }
    } else {
      setIsTemplateNull(true);
    }
  }, [filteredCaseTemplates]);

  function getAllCaseAttachmentSecurity() {
    setIsLoadingGetCaseAttachmentSecurity(true);
    var url = urlJoin(resServerBaseUrl, "/Case/GetAllCaseAttachmentSecurity");
    CallApiWithContext(url, authenticationContext, null)
      .then((response) => {
        if (response) {
          setCaseAttachmentSecurity(response);
          setIsLoadingGetCaseAttachmentSecurity(false);
        }
      })
      .catch((error) => {
        setIsLoadingGetCaseAttachmentSecurity(false);
        console.log(error);
      });
  }

  const ComboBox = (caseTemplates) => {
    if (caseTemplates) {
      test.push({
        firstLetter: "*",
        uuid: -1,
        categoryName: "*",
        templateName: "All Case Templates",
        id: -1,
      });
      caseTemplates.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        option.templateInfos.forEach((t) =>
          test.push({
            firstLetter: firstLetter,
            uuid: t.uuid,
            categoryName: option.name,
            templateName: t.name,
            id: t.id,
          })
        );
      });
      return (
        <Grid Container sx={{ p: "20px" }}>
          <Grid item style={{ textAlign: "left" }}>
            <h3>Filter Case Template Below:</h3>
            &nbsp;
          </Grid>
          <Grid item>
            <Autocomplete
              id="CaseTemplates"
              defaultValue={test[0]}
              options={test.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(test) => test.categoryName}
              getOptionLabel={(test) => test.templateName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Case Template" />
              )}
              onChange={(event, value) => handleTemplateOnChange(value)}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const handleTemplateOnChange = (template) => {
    console.log(template.templateName);
    if (caseTemplateSecurities) {
      if (template.uuid === -1) {
        setFilteredCaseTemplateSecurities(caseTemplateSecurities);
      } else {
        setFilteredCaseTemplateSecurities(
          caseTemplateSecurities.filter((c) => c.tplUUID === template.uuid)
        );
      }
    }

    //new trial add for getting the selected tpl and use for uploading attachment security
    setTheSelectedCaseTpl(template);
    if (template.id != -1) {
      setCaseAttachmentSecurityTableData(
        caseAttachmentSecurity.filter((c) => c.caseTplUUID === template.uuid)
      );
      //call Api to get recipient matrix column
      setIsLoadingRecipientMatrixColumnTable(true);
      var url = urlJoin(
        resServerBaseUrl,
        "/Case/GetRecipientMatrixColumnByCaseTemplate"
      );
      var data = { CaseTplUUID: template.uuid };
      CallApiWithContext(url, authenticationContext, JSON.stringify(data))
        .then((response) => {
          if (response) {
            const recipientMatrixColumnList = new Array();
            response.forEach((element) => {
              if (!recipientMatrixColumnList.includes(element)) {
                recipientMatrixColumnList.push(element);
              }
            });
            setRecipientMatrixColumnOption(recipientMatrixColumnList);
            setIsLoadingRecipientMatrixColumnTable(false);
          } else {
            setAlertDialog(true);
            setAlertContent(
              t(
                "administrative_console.case_page.cannot_get_response_of_getting_recipientMatrixColumn"
              )
            );
            setAlertTitle(t("profile.proxy_and_delegation.error"));
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertDialog(true);
          setAlertContent(error);
          setAlertTitle(t("profile.proxy_and_delegation.error"));
        });
    } else {
      setCaseAttachmentSecurityTableData(caseAttachmentSecurity);
    }
  };

  const handleTypeOnChange = (event) => {};

  const handleTypeNameOnChange = (event) => {};

  const handleDialogSave = (event) => {};

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleRecordDelete = (row, index) => {};

  const handleDeleteDialogDeleteOpen = () => {
    setDeleteDialog(true);
  };

  const handleDeleteDialogDeleteClose = () => {
    setDeleteDialog(false);
  };

  const ConvertType = (type) => {
    switch (type) {
      case 1:
        return "Position";
      case 3:
        return "Group";
      case -2:
        return "All";
    }
  };

  return (
    <Grid
      Container
      direction="row"
      justifyContent="space-around"
      spacing={4}
      sx={{ width: "100%" }}
    >
      {caseTemplates ? (
        <Grid item>
          {ComboBox(caseTemplates)}
          &nbsp;
          <Grid container direction="column" sx={{ p: "20px" }}>
            <Grid item style={{ textAlign: "left" }}>
              <h3>
                {t(
                  "administrative_console.case_page.existing_case_template_setting"
                )}
              </h3>
              <p style={{ color: "gray", fontSize: "14px" }}>{""}</p>
            </Grid>
            <Grid item style={{ textAlign: "right" }}>
              <Button
                onClick={handleDialogOpen}
                startIcon={<AddModeratorIcon />}
              ></Button>
              <Dialog open={dialog} onClose={handleDialogClose}>
                <DialogTitle sx={{ bgcolor: "lightgray", color: "gray" }}>
                  {t(
                    "administrative_console.case_page.add_case_template_security_setting"
                  )}
                </DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                  <Grid container direction="column" spacing={2} m="50px">
                    <Grid container direction="row" spacing={2}>
                      <Grid>
                        <p style={{ paddingTop: "15px", color: "gray" }}>
                          {t("administrative_console.case_page.type")}
                        </p>
                      </Grid>
                      <Grid pl="60px">
                        <Autocomplete
                          id="name"
                          defaultValue={typeList[0]}
                          onChange={(event, value) => handleTypeOnChange(value)}
                          options={typeList}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2} pt="50px">
                      <Grid>
                        <p
                          style={{
                            paddingTop: "15px",
                            color: "gray",
                            width: "50px",
                          }}
                        >
                          {t("administrative_console.case_page.type_name")}
                        </p>
                      </Grid>
                      <Grid pl="60px">
                        <Autocomplete
                          loading={isLoadingTypeName}
                          loadingText={"Loading..."}
                          id="combo-box-demo"
                          defaultValue={"specwf_op[0]"}
                          onChange={(event, value) =>
                            handleTypeNameOnChange(value)
                          }
                          options={typeList}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ bgcolor: "lightgray" }}>
                  <LoadingButton
                    loading={isloadingAddsave}
                    disabled={disableSave}
                    onClick={(event) => handleDialogSave(event)}
                  >
                    {t("profile.proxy_and_delegation.save")}
                  </LoadingButton>
                  <Button onClick={handleDialogClose}>
                    {t("profile.proxy_and_delegation.cancel")}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            {isTemplateNull ? (
              <p item style={{ textAlign: "left" }}>
                {t(
                  "administrative_console.case_page.no_existing_security_for_this_case_emplate"
                )}
              </p>
            ) : (
              <Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead sx={{ fontWeight: "600", bgcolor: "lightgray" }}>
                      <TableRow>
                        <TableCell>
                          {t("administrative_console.case_page.type")}
                        </TableCell>
                        <TableCell>
                          {t("administrative_console.case_page.type_name")}
                        </TableCell>
                        <TableCell>
                          {t("report.system_dialog.case_template_name")}
                        </TableCell>
                        <TableCell>{t("formruntime.actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredCaseTemplates.map((row, rowIndex) => {
                        var currentRowIndex = rowIndex;
                        return (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {ConvertType(row.type)}
                            </TableCell>
                            <TableCell>{row.typeName}</TableCell>
                            <TableCell>{row.caseTplName}</TableCell>
                            <TableCell>
                              <Button
                                id={currentRowIndex}
                                onClick={handleDeleteDialogDeleteOpen}
                                startIcon={<DeleteIcon />}
                              ></Button>
                            </TableCell>
                            <Dialog
                              open={deleteDialog}
                              onClose={handleDeleteDialogDeleteClose}
                            >
                              <Grid sx={{ bgcolor: "white", color: "gray" }}>
                                <DialogTitle>
                                  {t(
                                    "profile.proxy_and_delegation.confirm_delete"
                                  )}
                                </DialogTitle>
                              </Grid>
                              <Grid>
                                <DialogActions sx={{ bgcolor: "white" }}>
                                  <Button
                                    onClick={handleRecordDelete(row, rowIndex)}
                                  >
                                    {t("profile.proxy_and_delegation.delete")}
                                  </Button>
                                  <Button
                                    onClick={handleDeleteDialogDeleteClose}
                                  >
                                    {t("profile.proxy_and_delegation.cancel")}
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Dialog>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
          {theSelectedCaseTpl == null ||
          theSelectedCaseTpl.uuid == -1 ? null : (
            <Grid container direction="column" sx={{ p: "20px" }}>
              <Grid item style={{ textAlign: "left" }}>
                <h3>{"Case Template Attachment Security Setting"}</h3>
                <p style={{ color: "gray", fontSize: "14px" }}>{""}</p>
              </Grid>

              {/* Pending */}
              <Grid item style={{ textAlign: "right" }}>
                {/* <Button disable={(theSelectedCaseTpl == null || theSelectedCaseTpl.uuid == -1)} onClick={handleOpenAddAttachmentSecurityDialog} startIcon={<AddModeratorIcon />}></Button> */}
                <Dialog
                  open={openAddAttachmentSecurityDialog}
                  onClose={handleCloseAddAttachmentSecurityDialog}
                >
                  <DialogTitle sx={{ bgcolor: "lightgray", color: "gray" }}>
                    {t(
                      "administrative_console.case_page.case_template_attachment_security_setting"
                    )}
                  </DialogTitle>
                  <DialogContent>
                    <Grid sx={{ p: "10px" }}>
                      <Autocomplete
                        id="combo-box-selectAttr"
                        options={targetOption}
                        onChange={(event, newValue) =>
                          handleSelectionOnChange(
                            event,
                            newValue,
                            "TargetOption"
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t(
                              "administrative_console.case_page.select_the_target_of_setting_attachment_security"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    {theSelectedAttrToSetSecurity.user ? (
                      <Grid sx={{ p: "10px" }}>
                        <Autocomplete
                          id="combo-box-selectUser"
                          options={userOption}
                          onChange={(event, newValue) =>
                            handleSelectionOnChange(event, newValue, "UserID")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(
                                "profile.proxy_and_delegation.select_user"
                              )}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {theSelectedAttrToSetSecurity.group ? (
                      <Grid sx={{ p: "10px" }}>
                        <Autocomplete
                          id="combo-box-selectGroup"
                          options={groupOption}
                          onChange={(event, newValue) =>
                            handleSelectionOnChange(event, newValue, "GroupID")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(
                                "administrative_console.case_page.select_group"
                              )}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {theSelectedAttrToSetSecurity.recipientMatrixColumn ? (
                      <Grid sx={{ p: "10px" }}>
                        <TableContainer component={Paper}>
                          <Table aria-label="table">
                            <TableHead sx={{ bgcolor: "lightgray" }}>
                              <TableRow>
                                <TableCell>
                                  {t(
                                    "administrative_console.case_page.recipient_matrix_column"
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {isLoadingRecipientMatrixColumnTable ? (
                              <TableBody>
                                <TableRow>
                                  {/* <TableCell><Skeleton variant="text" sx={{ width: "50%", height: "30px" }} /></TableCell> */}
                                  <TableCell>
                                    {t("inbox_outbox.loading_text")}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : recipientMatrixColumnOption == null ? (
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    {t(
                                      "administrative_console.case_page.no_recipient_matrix_column_can_be_choose"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {recipientMatrixColumnOption.map(
                                  (row, rowIndex) => {
                                    return (
                                      <TableRow
                                        key={row}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onChange={(event) =>
                                                  handleSelectionOnChange(
                                                    event,
                                                    row,
                                                    "RecipientMatrixColumn"
                                                  )
                                                }
                                              />
                                            }
                                            label={row}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : null}
                    {/* <p>The attachment of this caseTemplates can be: </p> */}
                    <FormGroup sx={{ p: "20px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) =>
                              handleChooseAttachmentSecurity(event, "canAdd")
                            }
                          />
                        }
                        label={t("profile.proxy_and_delegation.add")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) =>
                              handleChooseAttachmentSecurity(
                                event,
                                "canDownload"
                              )
                            }
                          />
                        }
                        label={t("administrative_console.case_page.download")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) =>
                              handleChooseAttachmentSecurity(event, "canDelete")
                            }
                          />
                        }
                        label={t("profile.proxy_and_delegation.delete")}
                      />
                    </FormGroup>
                  </DialogContent>
                  <DialogActions sx={{ bgcolor: "lightgray" }}>
                    <LoadingButton
                      loading={isLoadingAttachmentSecuritySave}
                      onClick={(event) =>
                        handleAddAttachmentSecurityDialogSave(event)
                      }
                    >
                      {t("profile.proxy_and_delegation.save")}
                    </LoadingButton>
                    <Button onClick={handleCloseAddAttachmentSecurityDialog}>
                      {t("profile.proxy_and_delegation.cancel")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

              {caseAttachmentSecurityTableData == null ? (
                <p item style={{ textAlign: "left" }}>
                  {t(
                    "administrative_console.case_page.no_existing_atachment_security_for_this_case_template"
                  )}
                </p>
              ) : (
                <Grid sx={{ p: "2%" }}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead sx={{ bgcolor: "lightgray" }}>
                        <TableRow>
                          {caseAttachmentSecurityHeader.map((title) => (
                            <TableCell>{title}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {caseAttachmentSecurityTableData.map(
                          (row, rowIndex) => {
                            return (
                              <TableRow
                                key={row}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{row.userID}</TableCell>
                                <TableCell>
                                  {row.fullControl ? "true" : "false"}
                                </TableCell>
                                <TableCell>
                                  {row.add ? "true" : "false"}
                                </TableCell>
                                <TableCell>
                                  {row.download ? "true" : "false"}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      ) : null}
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </Grid>
  );
}
