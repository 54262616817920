import * as React from "react";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjcGridSearch from "@grapecity/wijmo.grid.search";
import * as wjInput from "@grapecity/wijmo.react.input";

import "@grapecity/wijmo.styles/wijmo.css";
import "./MultiColumnComboBox.css";
import {
  FilledInput,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import useEvent from "react-use-event-hook";

const MultiColumnComboBox = (props) => {
  const comboRef = React.useRef();
  const labelRef = React.useRef();
  const {
    controlName,
    label = "",
    itemsSource,
    displayMember,
    valueMember,
    value = "",
    inGrid = false,
    columns = [],
    onChange,
    readOnly = false,
    required = false,
    description = "",
  } = props;
  //const readOnly = true;
  //const required = true;
  const [controlFocused, setControlFocused] = React.useState(false);

  const isFocused = React.useMemo(() => {
    return controlFocused || !!value;
  }, [controlFocused, value]);

  const dropDownDisplayMember = React.useMemo(() => {
    return displayMember
      ? displayMember
      : columns
      ? columns.find((c) => c.visible)?.key
      : "";
  }, [displayMember, columns]);

  const initDropDownDataGrid = () => {
    const theCombo = comboRef.current.control;

    if (!theCombo.dropDownGrid) {
      const displayColumns =
        itemsSource?.length > 0
          ? Object.keys(itemsSource[0])
              .filter((k) =>
                columns?.length > 0
                  ? !!columns.find(
                      (c) =>
                        c.key.toLocaleUpperCase() === k.toLocaleUpperCase() &&
                        c.visible
                    )
                  : true
              )
              .map((k) => {
                const c =
                  columns?.length > 0
                    ? columns.find(
                        (c) =>
                          c.key.toLocaleUpperCase() === k.toLocaleUpperCase() &&
                          c.visible
                      )
                    : null;

                return {
                  binding: k,
                  header: c?.label || k,
                  width: c?.width || 120,
                };
              })
          : [];

      // it's multiple column combo box.
      const theGrid = new wjcGrid.FlexGrid(
        wjcCore.createElement(
          "<div style='max-height: 190px'></div>",
          theCombo.dropDown
        ),
        {
          stickyHeaders: true,
          headersVisibility: wjcGrid.HeadersVisibility.Column,
          selectionMode: wjcGrid.SelectionMode.Row,
          isReadOnly: true,
          autoGenerateColumns: displayColumns === null,
          columns: displayColumns,
          itemsSourceChanged: (s) => {
            theGrid.select(new wjcGrid.CellRange(-1, 0, -1, 0), true);
          },
        }
      );
      const handleHostElementMouseDown = (e) => {
        const hti = theGrid.hitTest(e);

        if (hti.panel && hti.panel.cellType === wjcGrid.CellType.Cell) {
          theCombo.text = hti.panel.getCellData(hti.row, dropDownDisplayMember);
          theCombo.isDroppedDown = false;
        }
      };
      const handleHostElementClick = (e) => {
        const hti = theGrid.hitTest(e);

        if (!hti.panel || hti.panel.cellType !== wjcGrid.CellType.Cell) {
          e.preventDefault();
          e.stopPropagation();
        }
      };

      theGrid.itemsSource = itemsSource;

      const searchBox = new wjcGridSearch.FlexGridSearch(
        document.createElement("div"),
        {
          placeholder: "",
          grid: theGrid,
        }
      );

      theGrid.hostElement.addEventListener(
        "mousedown",
        handleHostElementMouseDown
      );
      theGrid.hostElement.addEventListener("click", handleHostElementClick);

      theGrid.keepEditOnSelect = true;
      theCombo.dropDownGrid = theGrid;
      theCombo.searchBox = searchBox;
    }
  };

  const handleComboBoxGotFocus = (s, e) => {
    setControlFocused(true);
    // labelRef.current.style.position = "absolute";
    // labelRef.current.style.transform = "translate(-16px, -27px) scale(0.75)";
  };

  const handleComboBoxLostFocus = (s, e) => {
    setControlFocused(false);

    if (s.selectedIndex < 0) {
      // labelRef.current.style.position = "relative";
      // labelRef.current.style.transform = null;

      if (s.text) {
        s.text = "";
      }
    }
  };

  const handleComboBoxDroppedDownChanged = useEvent((s, e) => {
    if (s.isDroppedDown && !s.dropDownGrid) {
      initDropDownDataGrid();
    }

    if (s.isDroppedDown && s.dropDownGrid) {
      const theGrid = s.dropDownGrid;
      const theSearchBox = s.searchBox;

      theSearchBox.text = "";

      theGrid.selectedRows.forEach((row, index, array) => {
        row.isSelected = false;
      });

      if (s.selectedIndex >= 0) {
        const selectedRow = theGrid.rows.find((r) => {
          return (
            r.dataItem ===
            theGrid.collectionView.sourceCollection[s.selectedIndex]
          );
        });

        if (selectedRow) {
          theGrid.select(
            new wjcGrid.CellRange(
              selectedRow.dataIndex,
              0,
              selectedRow.dataIndex,
              0
            ),
            true
          );
        }
      } else {
        theGrid.scrollPosition = new wjcCore.Point(theGrid.scrollPosition.x, 0);
      }
    }
  });

  const handleComboBoxSelectedIndexChanged = (s, e) => {
    if (s.isDroppedDown && s.dropDownGrid) {
      const theGrid = s.dropDownGrid;

      theGrid.selectedRows.forEach((row, index, array) => {
        row.isSelected = false;
      });

      if (s.selectedIndex >= 0) {
        const selectedRow = theGrid.rows.find((r) => {
          return (
            r.dataItem ===
            theGrid.collectionView.sourceCollection[s.selectedIndex]
          );
        });

        if (selectedRow) {
          selectedRow.isSelected = true;

          const rc = theGrid.cells.getCellBoundingRect(
            selectedRow.index,
            0,
            true
          );
          theGrid.scrollPosition = new wjcCore.Point(
            theGrid.scrollPosition.x,
            -rc.top
          );
        }
      } else {
        theGrid.scrollPosition = new wjcCore.Point(theGrid.scrollPosition.x, 0);
      }

      if (onChange) {
        onChange({
          target: {
            value: s.selectedValue,
          },
        });
      }
    }
  };

  const handleSelectionChanged = (s, e) => {};

  React.useEffect(() => {
    const combo = comboRef.current.control;

    // const combo = new wjcInput.ComboBox(comboRef.current, {
    //   headerPath: displayMember,
    //   displayMemberPath: displayMember,
    //   selectedValuePath: valueMember,
    //   itemsSource: itemsSource,
    //   isRequired: false,
    //   isEditable: true,
    //   dropDownCssClass: "multicol-combobox",
    //   isAnimated: false,
    //   inputElement: <InputBase />,
    // });
    const raiseCustomEvent = (ele, eventName) => {
      let event;
      if (typeof Event === "function") {
        event = new Event(eventName);
      } else {
        event = document.createEvent("Event");
        event.initEvent(eventName, true, true);
      }
      ele.dispatchEvent(event);
    };
    const handleInputElementKeyDown = (e) => {
      const theGrid = combo.dropDownGrid;

      theGrid?.startEditing();

      if (!combo.isDroppedDown) {
        combo.isDroppedDown = true;
      }

      switch (e.key) {
        case "ArrowDown": {
          let rowIndex = -1;

          if (theGrid.selectedRows.length > 0) {
            rowIndex = theGrid.selectedRows[0].index;
          }
          if (theGrid.rows.length > rowIndex + 1) {
            rowIndex++;
            theGrid.select(
              new wjcGrid.CellRange(rowIndex, 0, rowIndex, 0),
              true
            );
          }
          if (theGrid.rows.length > rowIndex && rowIndex > -1) {
            combo.selectedIndex = combo.itemsSource.indexOf(
              theGrid.rows[rowIndex].dataItem
            );
          }
          e.returnValue = false;
          e.preventDefault();

          return false;
        }
        case "ArrowUp": {
          let rowIndex = theGrid.rows.length;

          if (theGrid.selectedRows.length > 0) {
            rowIndex = theGrid.selectedRows[0].index;
          }

          if (rowIndex > 0) {
            rowIndex -= 1;
            // ** modified the wijmo.grid.min.js code to support select drop down grid
            // and won't end the edit immediately.
            theGrid.select(
              new wjcGrid.CellRange(rowIndex, 0, rowIndex, 0),
              true
            );
          }

          if (theGrid.rows.length > rowIndex) {
            combo.selectedIndex = combo.itemsSource.indexOf(
              theGrid.rows[rowIndex].dataItem
            );
          }

          e.returnValue = false;
          e.preventDefault();

          return false;
        }
        case "Backspace": {
          const input = e.currentTarget;
          const text = input.value;

          if (input.selectionStart >= 0) {
            const startPos = input.selectionStart;
            const endPos = input.selectionEnd;

            if (startPos === endPos) {
              input.value =
                text.substring(0, startPos - 1) + text.substring(endPos);
            } else {
              input.value =
                text.substring(0, startPos) + text.substring(endPos);
            }

            input.selectionStart = startPos - 1;
            input.selectionEnd = input.selectionStart;
          }

          raiseCustomEvent(input, "search_input");

          e.returnValue = false;
          e.preventDefault();

          return false;
        }
        case "Delete": {
          const input = e.currentTarget;
          let text = input.value;

          if (input.selectionStart !== undefined) {
            const startPos = input.selectionStart;
            const endPos = input.selectionEnd;

            if (startPos === endPos) {
              input.value =
                text.substring(0, startPos) + text.substring(endPos + 1);
            } else {
              input.value =
                text.substring(0, startPos) + text.substring(endPos);
            }

            input.selectionStart = startPos;
            input.selectionEnd = input.selectionStart;
          } else {
            input.value = text.substring(0, text.length - 1);
          }

          raiseCustomEvent(input, "search_input");
          e.returnValue = false;
          e.preventDefault();

          return false;
        }
        default: {
          if (!e.ctrlKey && !e.shiftKey && e.key.length === 1) {
            const input = combo.inputElement;

            theGrid?.startEditing();

            if (input.selectionStart !== undefined) {
              const startPos = input.selectionStart;
              const endPos = input.selectionEnd;
              const text = input.value;

              input.value =
                text.substring(0, startPos) + e.key + text.substring(endPos);
              input.selectionStart = startPos + 1;
              input.selectionEnd = input.selectionStart;
            } else {
              input.value += e.key;
            }

            raiseCustomEvent(input, "search_input");
            e.returnValue = false;
            e.preventDefault();

            return false;
          }
          break;
        }
      }
    };
    const handleInputElementSearchInput = (e) => {
      const theGrid = combo.dropDownGrid;
      const theSearchBox = combo.searchBox;

      theSearchBox.text = combo.inputElement.value;

      if (combo.inputElement.value === "") {
        theGrid.select(new wjcGrid.CellRange(-1, 0, -1, 0), true);
        combo.selectedIndex = -1;
      }
    };

    combo.inputElement.addEventListener("keydown", handleInputElementKeyDown);
    combo.inputElement.addEventListener(
      "search_input",
      handleInputElementSearchInput
    );

    return () => {
      combo.inputElement.removeEventListener(
        "keydown",
        handleInputElementKeyDown
      );
      combo.inputElement.removeEventListener(
        "search_input",
        handleInputElementSearchInput
      );
      combo.dropDownGrid = undefined;
    };
  }, [itemsSource]);

  return (
    // <div className="wj-labeled-input wide">
    // <FormControl style={{ width: "100%" }}>
    //   <InputLabel>{label}</InputLabel>
    //   <Select ref={comboRef} label={label}>
    //     <wjGrid.FlexGrid
    //       itemsSource={itemsSource}
    //       stickyHeaders
    //       selectionChanged={handleSelectionChanged}
    //       selectionMode={"Row"}
    //       isReadOnly
    //     />
    //   </Select>
    // </FormControl>
    <div>
      {inGrid ? (
        <wjInput.ComboBox
          ref={comboRef}
          headerPath={displayMember}
          displayMemberPath={displayMember}
          selectedValuePath={valueMember}
          itemsSource={itemsSource}
          isEditable={true}
          isReadOnly={readOnly}
          isRequired={required}
          dropDownCssClass={"multicol-combobox-dropdown"}
          isAnimated={false}
          className={"multicol-combobox"}
          selectedIndexChanged={handleComboBoxSelectedIndexChanged}
        />
      ) : (
        <div style={{ position: "relative", height: "56px" }}>
          <FormControl
            fullWidth
            required={required}
            variant={readOnly ? "filled" : "outlined"}
            focused={readOnly ? undefined : controlFocused}
            sx={{ height: "100%" }}
          >
            <InputLabel
              shrink={isFocused}
              sx={{
                pr: readOnly || isFocused ? 0 : 4,
              }}
            >
              {label}
            </InputLabel>

            <wjInput.ComboBox
              ref={comboRef}
              headerPath={displayMember}
              displayMemberPath={displayMember}
              selectedValuePath={valueMember}
              itemsSource={itemsSource}
              isEditable={true}
              isReadOnly={readOnly}
              isRequired={required}
              dropDownCssClass={"multicol-combobox-dropdown"}
              isAnimated={false}
              className={"multicol-combobox"}
              selectedValue={value}
              style={{
                position: "absolute",
                width: "100%",
                border: "none",
                visibility: readOnly ? "hidden" : "visible",
              }}
              isDroppedDownChanged={handleComboBoxDroppedDownChanged}
              gotFocus={handleComboBoxGotFocus}
              lostFocus={handleComboBoxLostFocus}
              selectedIndexChanged={handleComboBoxSelectedIndexChanged}
            />
            {readOnly ? (
              <FilledInput readOnly={readOnly} value={value} />
            ) : (
              <OutlinedInput
                notched={isFocused}
                label={label}
                sx={{
                  pointerEvents: "none",
                }}
              />
            )}
          </FormControl>
          {/* <div style={{ display: "flex" }}>
            <label
              ref={labelRef}
              style={{
                position:
                  comboRef.current?.control.selectedIndex > 0
                    ? "absolute"
                    : "relative",
                left: "10px",
                top: "16px",
                color: "rgba(0,0,0,0.6)",
                backgroundColor: "white",
                transform:
                  comboRef.current?.control.selectedIndex > 0
                    ? "translate(-16px, -27px) scale(0.75)"
                    : null,
                padding: "0px 8px",
                marginRight: readOnly ? "0px" : "50px",
                maxWidth: "calc(100% -24px)",
                pointerEvents: "none",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {label}
              {requried ? " *" : ""}
            </label> 
          </div>*/}
        </div>
      )}
      {description ? (
        <Typography component={"div"} variant={"caption"}>
          {description}
        </Typography>
      ) : null}
    </div>

    //   <label>{label}</label>
    // </div>
    // <FormControl variant={"filled"} fullWidth>
    //   <InputLabel>{label}</InputLabel>
    //   <div ref={comboRef} id={`mcb_${controlName}`} style={{ width: "100%" }} />
    // </FormControl>
  );
};

export default MultiColumnComboBox;
