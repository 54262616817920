import * as React from "react";

import { GcPdfViewer } from "@grapecity/gcpdfviewer";
import urlJoin from "url-join";
import { useTranslation } from "react-i18next";

import { CallApiWithContext } from "../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../Config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuthentication } from "../../providers/AuthenticationProvider";

const C1PdfViewer = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authenticationContext = useAuthentication();
  const { t } = useTranslation();

  const {
    caseTplID,
    caseTplUUID,
    inboxId: inboxID,
    isOutbox,
    metadata,
    name,
    readonly,
    viewerHeight,
  } = props;
  const { CaseID, CaseSID, TaskID, uuid, PlanItemSID } = useParams();

  /* for pdf viewer */
  const _viewer = React.useRef(null);
  const [uri, setUri] = React.useState();
  const [pdfViwerReady, setPdfViewerReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  /* for optional task */
  const [optionalTask, setOptionalTask] = React.useState(props.optionalTask);
  const [optionalStage, setOptionalStage] = React.useState(props.optionalStage);
  const [taskID, setTaskID] = React.useState(props.taskID);
  const [stageID, setStageID] = React.useState(-1);
  const [orginaltaskID, setOrginalTaskID] = React.useState(props.taskID);
  const [firstTaskName, setFirstTaskName] = React.useState(props.firstTaskName);
  const [originalFirstTaskName, setOrginialFirstTaskName] = React.useState(
    props.firstTaskName
  );
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const action = React.useMemo(() => {
    return location.pathname.includes("new") ||
      location.pathname.includes("triggered")
      ? "new"
      : "";
  }, [location]);
  const isNewTrigger = React.useMemo(() => {
    return (
      location.pathname.includes("new") ||
      location.pathname.includes("triggered")
    );
  }, [location]);
  const isCopyAsNew = React.useMemo(() => {
    return location.pathname.includes("triggered");
  }, [location]);

  /* initialize pdf viewer */
  React.useEffect(() => {
    const unloadCallback = () => {
      savePDF();
    };

    window.addEventListener("beforeunload", unloadCallback);
    localStorage.setItem("save", true);
    setLoading(true);

    var token = null;
    if (process.env.NODE_ENV === "development") {
      token = localStorage.getItem("accessToken");
    }
    const options = {
      cMapUrl: document.location.origin + "/bcmaps/",
      cMapPacked: true,
      enableXfa: true,
      workerSrc: document.location.origin + "/gcpdfviewer.worker.js",
      language: localStorage.getItem("i18nextLng"),
      // supportApi: {
      //     apiUrl: urlJoin(resServerBaseUrl, "/GCPDF"),
      //     requestInit: {
      //         headers: {
      //             //Debug use, Need enhance
      //             "Authorization": 'Bearer ' + token
      //         }
      //     },
      //     webSocketUrl: false
      // }
    };
    const root = document.getElementById("viewer");

    //GcPdfViewer.LicenseKey = 'FlexSystem,E822687495317275#B01w4NMtkaTp5ajJnbrIjeys4YUVVZQdWZ9dkRGd5Lsp5LZJ5RqhHZVl6Rwg6LLJ5YWFzV0JFSGR4ZhlXUtZnUBdWYHRVN4lnb7JnVph6LK3SWupWQCZTN6knN4UFN6YFdv24dQhXS6olUWtSdxkUOwZWMrEWa4o7ZnJmeBF6Y7sUTMZUTWBFTGx6aVVjemNFOjRTQyRWQ7tUaQZnc7IlahREbZlleNVzM7RnMGl4Z8hENiJ4L7MGdMlnUHh4LMZ7dQFlZYhXVolTQ09WU5d6LwVmNrR5ahZTWjhnWwpGO8cVY5lnViojITJCLigjQzIUMyczMiojIIJCL6cTNyUzM4gTN0IicfJye35XX3JiUVhTTiojIDJCLi2mcwByMWBicldXZpZlZkB5YHJiOi8kI1tlOiQmcQJCLicjMxAjNwACNyYDMyIDMyIiOiQncDJCLiQjM7AjMyAjMiojIwhXRiwiItVGdzl7U8VGbGJiOiEmTDJCLlVnc4pjIsZXRiwiI5cjM7EzM5kDN7gjNyIDOiojIklkI1pjIEJCLi4TPn3yarZ6Ui9GZyZFMvklQOR4TEFlRvhmcVpkdjZTYBJnN5BTawgVMyh7drkES78USH3EVUBXOphzSDhHTul7QYF4KlhEdsljZKJ4VGRTcFVVcrt4V4NTQENkdrNEN05mQLx6dhlmNol7RSpmMtpkWLR4N';
    GcPdfViewer.i18n
      .init({
        //resources,
        fallbackLng: "en",
        debug: true,

        interpolation: {
          escapeValue: false,
        },
        backend: {
          // for all available options read the backend's repository readme file
          loadPath: "/locales/pdf-{{lng}}/{{ns}}.json",
        },
      })
      .then(function (t) {
        const viewer = new GcPdfViewer(root, options);

        viewer.addDefaultPanels();
        viewer.beforeUnloadConfirmation = true;
        viewer.options.friendlyFileName = name;
        viewer.applyOptions();

        viewer.toolbar.addItem({
          key: "copy-icon",
          icon: {
            type: "svg",
            content: React.createElement(
              "svg",
              {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                strokeWidth: 1.5,
                stroke: "currentColor",
                className: "w-6 h-6",
              },
              React.createElement("path", {
                strokeLinecap: "round",
                strokeLinejoin: "round",
                d: "M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z",
              })
            ),
          },
          title: "Copy as new",
          enabled: true,
          action: () => {
            navigate(`/case/${CaseSID}/${PlanItemSID}/triggered`);
          },
        });

        var annotationEditorButtons = [
          "custom-save",
          "edit-select",
          "edit-sign-tool",
          "$split",
          "edit-text",
          "edit-free-text",
          "edit-ink",
          "edit-square",
          "edit-circle",
          "edit-line",
          "edit-polyline",
          "edit-polygon",
          "edit-stamp",
          "edit-link",
          "$split",
          "edit-redact",
          "edit-redact-apply",
          "edit-erase",
          "$split",
          "$split",
        ];
        viewer.toolbarLayout.annotationEditor = {
          default: annotationEditorButtons,
          mobile: annotationEditorButtons,
          fullscreen: annotationEditorButtons,
        };

        if (readonly) {
          viewer.toolbarLayout.viewer.default = [
            "$navigation",
            "$split",
            "text-selection",
            "pan",
            "zoom",
            "$fullscreen",
            "copy-icon",
          ];
          viewer.toolbarLayout.viewer.mobile = [
            "$navigation",
            "$split",
            "text-selection",
            "pan",
            "zoom",
            "$fullscreen",
            "copy-icon",
          ];
          viewer.toolbarLayout.viewer.fullscreen = [
            "$navigation",
            "$split",
            "text-selection",
            "pan",
            "zoom",
            "$fullscreen",
            "copy-icon",
          ];
          viewer.onAfterOpen.register(function () {
            viewer.annotations.then(function (annotationsDataArr) {
              for (var i = 0; i < annotationsDataArr.length; i++) {
                viewer
                  .findAnnotation(
                    20, // 20 - AnnotationTypeCode.WIDGET
                    {
                      findField: "annotationType",
                      pageNumberConstraint: i,
                      findAll: true,
                    }
                  )
                  .then(function (annotationsDataArr) {
                    viewer.updateAnnotations(
                      0,
                      annotationsDataArr.map(function (data) {
                        data.annotation.locked = true;
                        return data.annotation;
                      })
                    );
                  });
              }
            });
          });

          root.style.width = "100%";
        } else {
          viewer.toolbarLayout.viewer.default = [
            "$navigation",
            "$split",
            "text-selection",
            "pan",
            "zoom",
            "$fullscreen",
          ];
          viewer.toolbarLayout.viewer.mobile = [
            "$navigation",
            "$split",
            "text-selection",
            "pan",
            "zoom",
            "$fullscreen",
          ];
          viewer.toolbarLayout.viewer.fullscreen = [
            "$navigation",
            "$split",
            "text-selection",
            "pan",
            "zoom",
            "$fullscreen",
          ];
          viewer.onAfterOpen.register(function () {
            viewer.annotations.then(function (PDFdata) {
              if (PDFdata && PDFdata.length > 0) {
                const pdfData = JSON.stringify(PDFdata);
                localStorage.setItem("PDFData", pdfData);
              }
            });
          });

          root.style.width = "100%";
        }

        viewer.onAfterUpdateAnnotation.register(function () {
          viewer.annotations.then(function (PDFdata) {
            if (PDFdata && PDFdata.length > 0) {
              const pdfData = JSON.stringify(PDFdata);
              const existingData = localStorage.getItem("PDFData");
              if (existingData) {
                localStorage.removeItem("PDFData");
              }
              localStorage.setItem("PDFData", pdfData);
            }
          });
        });

        _viewer.current = viewer;

        setPdfViewerReady(true);
      });

    return () => {
      savePDF();
      window.removeEventListener("beforeunload", unloadCallback);
      _viewer.current.dispose();
      localStorage.removeItem("PDFData");
      localStorage.removeItem("save");
    };
  }, []);

  /* update pdf viewer height when activity log open or close */
  React.useEffect(() => {
    const elem = document.querySelector(".gcpdfdocumentviewer");
    const root = elem?.shadowRoot;

    if (root) {
      const viewerHeaderHeight = document.querySelector(
        ".gcv-header-container"
      )?.offsetHeight;

      root.querySelector(".scrollview").style.height = `${
        viewerHeight - viewerHeaderHeight
      }px`;
    }
  }, [viewerHeight]);

  /* get pdf */
  React.useEffect(() => {
    const dataURItoBlob = (dataURI) => {
      const byteString = atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([intArray], { type: "application/pdf" });

      return URL.createObjectURL(blob);
    };

    if (!uri) {
      if (isNewTrigger) {
        const url = urlJoin(resServerBaseUrl, "/PDF/GetPDFTemplate");
        const data = JSON.stringify({
          uuid: uuid,
          planItemSID: PlanItemSID,
          copyAsNew: isCopyAsNew,
        });

        CallApiWithContext(url, authenticationContext, data)
          .then((res) => {
            if (res.pdfData && !uri) {
              setUri(dataURItoBlob(res.pdfData));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (caseTplUUID) {
        const url = urlJoin(resServerBaseUrl, "/PDF/GetPDF");
        const data = JSON.stringify({
          CaseSID: location.pathname.includes("triggered") ? null : CaseSID,
          PlanItemSID: PlanItemSID,
          IsOutbox: isOutbox,
          CaseTplUUID: caseTplUUID,
        });

        CallApiWithContext(url, authenticationContext, data)
          .then((res) => {
            if (res.pdfData) {
              setUri(dataURItoBlob(res.pdfData));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [
    // CaseID,
    CaseSID,
    TaskID,
    uuid,
    caseTplUUID,
    isOutbox,
    isNewTrigger,
    isCopyAsNew,
    PlanItemSID,
  ]);

  /* load the pdf to viewer */
  React.useEffect(() => {
    if (uri && pdfViwerReady) {
      _viewer.current.open(uri);
      setLoading(false);

      /* remove existing saved data */
      const url = urlJoin(resServerBaseUrl, "/PDF/DeletePDF_Saved");
      const data = JSON.stringify({
        CaseTplUUID: caseTplUUID,
        CaseSID: CaseSID,
      });
      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }, [uri, pdfViwerReady]);

  /* expose method to integrate with FormRuntime */
  React.useImperativeHandle(ref, () => ({
    actionButtonClick: (action) => {
      return handleActionButtonClick(action);
    },
    submitTask: (data) => {
      return submitTask(data);
    },
    onFormClose: () => {
      if (_viewer.current) {
        _viewer.current.dispose();
      }
    },
  }));

  const savePDF = () => {
    const save = localStorage.getItem("save");

    if (save === "true") {
      const pdfData = localStorage.getItem("PDFData");
      if (pdfData) {
        const url = urlJoin(resServerBaseUrl, "/PDF/SaveTemplateToServer");
        const data = JSON.stringify({
          CaseTplUUID: caseTplUUID,
          // CaseID: CaseID,
          CaseSID: CaseSID,
          Data: pdfData,
        });
        CallApiWithContext(url, authenticationContext, data)
          .then((response) => {
            _viewer.current.dispose();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleActionButtonClick = (action) => {
    return new Promise((resolve, rejected) => {
      if (loading) {
        rejected(
          t("formruntime.please_wait_for_the_form_load_complete_before_submit")
        );
      } else {
        if (action === "_Close_" || action === "_Trigger_") {
          localStorage.setItem("save", false);

          if (action === "_Close_") {
            _viewer.current.dispose();
          }
        }

        /* return success */
        resolve({ canProcess: true });
      }
    });
  };

  const submitTask = (data) => {
    return new Promise((resolve, rejected) => {
      _viewer.current.annotations.then(function (PDFdata) {
        if (PDFdata && PDFdata.length > 0) {
          const pdfData = JSON.stringify(PDFdata);
          const formData = JSON.stringify({
            ...data,
            // TempName: uri.split("/").pop(),
            PDFData: pdfData,
          });
          const url = urlJoin(resServerBaseUrl, "/PDF/SaveDocumentToServer");

          CallApiWithContext(url, authenticationContext, formData)
            .then((res) => {
              resolve({ result: "Succeeded" });
            })
            .catch((error) => {
              rejected(error);
            });
        } else {
          rejected(t("formruntime.gcpdf_viewer.pdf_viewer_cannot_be_found"));
        }
      });
    });
  };

  const optionalTaskOnClick = (item, index) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1);
      setTaskID(orginaltaskID);
      setFirstTaskName(originalFirstTaskName);
    } else {
      setSelectedIndex(index);
      setTaskID(item.planitemID);
      setFirstTaskName(item.name);
    }
  };

  const optionalStageOnClick = (item, index) => {
    const url = urlJoin(resServerBaseUrl, "/Case/GetOptionalTasks");
    const data = JSON.stringify({ StageID: item.planItem.id });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        var optionalStagesArr = [];
        response.stages.forEach((s) => {
          if (s[0].discretionary) {
            optionalStagesArr.push(s);
          }
        });
        setOptionalStage(
          optionalStage.filter((task) => task[0].name !== item.name)
        );
        console.log(response.tasks);
        if (optionalTask && optionalTask.length > 0) {
          var newOptionalTasks = response.tasks;
          setOptionalTask((optionalTask) => ({
            ...optionalTask,
            newOptionalTasks,
          }));
        } else {
          setOptionalTask(response.tasks);
        }

        setStageID(item.planItem.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return <div id="viewer" style={{ height: `${viewerHeight}px` }}></div>;
});

export default C1PdfViewer;
