import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PrimaryPositionTable = (props) => {
  const {
    PrimaryPositionObj,
    onClickFunction,
    AvailablePosLoading,
    AvailableArr,
  } = props;

  const { t } = useTranslation();

  return (
    <FormControl sx={{ textAlign: "start", overflow: "auto", height: "200px" }}>
      {AvailablePosLoading ? (
        <FormLabel id={"loading"}>
          {t("profile.proxy_and_delegation.loading_text")}
        </FormLabel>
      ) : AvailableArr.length > 0 ? (
        AvailableArr.map((c) => {
          return (
            <>
              <FormLabel id={c.Name} focused={false}>
                {c.Name}
              </FormLabel>
              <RadioGroup
                aria-labelledby={c.Name}
                value={PrimaryPositionObj[c.SID] ?? null}
                name={c.Name}
                onChange={(e) => onClickFunction(e, c.SID)}
              >
                {c.Positions.map((p) => (
                  <FormControlLabel
                    value={p.UUID}
                    control={<Radio />}
                    label={p.Name}
                  />
                ))}
              </RadioGroup>
            </>
          );
        })
      ) : (
        <FormLabel id={"no_position"}>
          {t("profile.no_available_positions")}
        </FormLabel>
      )}
    </FormControl>
    // <List
    //   sx={{
    //     width: "90%",
    //     bgcolor: "background.paper",
    //     position: "relative",
    //     overflowY: AvailableArr.length > 0 ? "scroll" : "auto",
    //     maxHeight: 300,
    //     "& ul": { padding: 0 },
    //     textAlign: "left",
    //   }}
    //   subheader={<li />}
    // >
    //   {AvailablePosLoading ? (
    //     <ListItem>
    //       <ListItemText
    //         primary={t("profile.proxy_and_delegation.loading_text")}
    //       />
    //     </ListItem>
    //   ) : AvailableArr.length > 0 ? (
    //     AvailableArr.map((c) => {
    //       return (
    //         <li key={c.Name}>
    //           <ul>
    //             <ListSubheader>{c.Name}</ListSubheader>
    //             {c.Positions.map((p) => (
    //               <ListItem key={c.Name + Object.keys(p)[0]}>
    //                 <ListItemIcon>
    //                   <FormControl>
    //                     <RadioGroup
    //                       name={p.Name + "_radio"}
    //                       value={PrimaryPositionObj[c.Name]}
    //                       onClick={(e) => onClickFunction(e, c.Name)}
    //                     >
    //                       <FormControlLabel
    //                         value={p.UUID}
    //                         control={<Radio />}
    //                       />
    //                     </RadioGroup>
    //                   </FormControl>
    //                 </ListItemIcon>
    //                 <ListItemText primary={p.Name} />
    //               </ListItem>
    //             ))}
    //           </ul>
    //         </li>
    //       );
    //     })
    //   ) : (
    //     <ListItem>
    //       <ListItemText primary={t("profile.no_available_positions")} />
    //     </ListItem>
    //   )}
    // </List>
  );
};

PrimaryPositionTable.propTypes = {
  PrimaryPositionArr: PropTypes.object,
  onClickFunction: PropTypes.func,
  AvailablePosLoading: PropTypes.bool,
  AvailableArr: PropTypes.array,
};

export default PrimaryPositionTable;
