//Convert date time form UTC 0 to local UTC
const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};
export function ConvertUTCDateToLocal(date) {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    var convertDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    var formatDate = convertDate.toLocaleString("en-GB", options);

    return formatDate;
  } else {
    return "";
  }
}

export function ConvertLocalDateToUTC(date) {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    var convertDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    return convertDate.toJSON();
  } else {
    return null;
  }
}

export const groupBy = (list, keyGetter) => {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};

export const stringAvatar = (name, style) => {
  const stringToColor = () => {
    const colors = [];
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < name?.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    for (i = 0; i < 3; i += 1) {
      colors.push((hash >> (i * 8)) & 0xff);
    }
    /* eslint-enable no-bitwise */

    return colors;
  };
  const contrastColor = (r, g, b) => {
    const luma = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luma > 0.5 ? "black" : "white";
  };
  const getInitial = () => {
    const ary = name?.split(" ");

    if (ary?.length > 1) {
      return `${ary[0][0]}${ary.pop()[0]}`;
    } else if (ary) {
      return `${ary[0][0]}`;
    }
    return "";
  };

  const [r, g, b] = stringToColor();
  const textColor = contrastColor(r, g, b);

  return {
    sx: {
      ...style,
      bgcolor: `rgb(${r},${g},${b})`,
      color: textColor,
    },
    children: getInitial(),
  };
};
